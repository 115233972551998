// src/components/OrgTabs/ServiceTypes/useServiceTypes.js

import { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { saveServiceTypes, fetchPaginatedServiceTypes } from '../../../services/v2/serviceTypesService';

export const useServiceTypes = () => {
    const initialServiceType = {
        id: '',
        name: '',
        capacity: 0,
        duration: 0,
        description: '',
        isBookable: false, // New property to indicate if the service can be booked
        isConnectedToHorse: false, // New property to indicate if the service is connected to a horse
    };
    const [serviceTypes, setServiceTypes] = useState([]);
    const { apiKey, token, organizationId } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        try {
            // Fetch initial service types from your API
            const fetchServices = async () => {
                const data = await fetchPaginatedServiceTypes(apiKey, organizationId, token, { pageSize: 1000 });
                setServiceTypes(data.items || []); // Set the service types to the fetched data
            };
            fetchServices();
        } catch (err) {
            console.error("Failed to fetch service types:", err);
        } finally {
            setLoading(false);
        }
    }, [apiKey, organizationId, token]);

    const handleAddServiceType = () => {
        setServiceTypes([...serviceTypes, { ...initialServiceType }]);
    };

    const handleFieldChange = (index, field, value) => {
        const updatedServiceTypes = serviceTypes.map((serviceType, i) => {
            if (i === index) {
                return { ...serviceType, [field]: value };
            }
            return serviceType;
        });
        setServiceTypes(updatedServiceTypes);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const data = await saveServiceTypes(serviceTypes, apiKey, organizationId, token);
        } catch (err) {
            console.error("Failed to save service types:", err);
        } finally {
            setLoading(false);
        }
    };
    // Inside your useServiceTypes hook
    const handleRemoveServiceType = (index) => {
        // Filter out the service type at the given index
        const updatedServiceTypes = serviceTypes.filter((_, i) => i !== index);
        setServiceTypes(updatedServiceTypes);
    };


    return {
        serviceTypes,
        loading,
        handleAddServiceType,
        handleFieldChange,
        handleSave,
        handleRemoveServiceType,
    };
};
