// src/components/RidingLessons/RidingLessonsOverview.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  LinearProgress,
  IconButton,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { usePreferences } from '../../context/UserPreferencesContext';

// If your new paginated function is named "fetchBookingPaginated(...)"
import { fetchBookingPaginated } from '../../services/v2/bookingService';

// If these are large sets, you can also use a paginated approach for each:
import { fetchTeacherSettingPaginatedData } from '../../services/v2/TeacherSettingsService';
import { fetchPaginatedServiceTypes } from '../../services/v2/serviceTypesService';
import { fetchHorsesPaginated } from '../../services/v2/horseService';
import { fetchOwnersPaginated } from '../../services/v2/ownerService';

// DataViewWrapper for table vs. card switching, plus filter/sort
import DataViewWrapper from '../../components/DataViewWrapper';

// Custom pagination hook
import { usePaginatedData } from '../../hooks/usePaginatedData';

// Your booking modal
import RidingLessonBookingModal from '../../components/RidingLessonBookingModal';

const RidingLessonsOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { apiKey, userId, userType, organizationId, token: jwtToken } = useAuth();
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // ------------- PAGINATED DATA HOOK FOR BOOKINGS -------------
  const {
    items: bookings,
    loading: bookingsLoading,
    continuationToken,
    loadNextPage,
    loadFirstPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchBookingPaginated(apiKey, organizationId, jwtToken, options),
    { pageSize: 25 }
  );

  // We’ll store teachers, service types, horses, and owners in local state
  // If needed, you can also fetch these with separate paginated calls for each.
  const [teachers, setTeachers] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [horses, setHorses] = useState([]);
  const [owners, setOwners] = useState([]);
  const [auxLoading, setAuxLoading] = useState(false);

  // Local view mode: table or card
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode || (isMobile ? 'card' : 'table')
  );

  // State for the booking modal
  const [modalOpen, setModalOpen] = useState(false);
  const initialBookingData = {
    id: '',
    riderName: '',
    riderId: '',
    horseId: '',
    bookingName: '',
    teacherId: '',
    dateTime: new Date(),
    duration: '',
    serviceTypeId: '',
    status: 'Booked',
    isPaid: false,
  };
  const [selectedBooking, setSelectedBooking] = useState(initialBookingData);

  // Payment chip
  const renderPaymentStatus = (isPaid) => {
    if (isPaid) {
      return (
        <Chip
          icon={<CheckCircleIcon />}
          label={t('RidingLessonsOverview.table.paid')}
          color="success"
        />
      );
    } else {
      return (
        <Chip
          icon={<CancelIcon />}
          label={t('RidingLessonsOverview.table.unpaid')}
          color="error"
        />
      );
    }
  };

  // ------------------ FETCH RESOURCES (TEACHERS, SERVICE TYPES, HORSES, OWNERS) ------------------
  useEffect(() => {
    let isCancelled = false;
    const fetchAllData = async () => {
      setAuxLoading(true);
      try {
        // If these datasets are large, fetch them paginated in do/while loops as needed
        let allTeachers = [];
        let allServiceTypes = [];
        let allHorses = [];
        let allOwners = [];

        // TEACHERS
        {
          let nextToken = null;
          do {
            const resp = await fetchTeacherSettingPaginatedData(apiKey, organizationId, jwtToken, {
              pageSize: 100,
              continuationToken: nextToken,
            });
            allTeachers = [...allTeachers, ...(resp.items || [])];
            nextToken = resp.continuationToken;
          } while (nextToken && !isCancelled);
        }

        // SERVICE TYPES
        {
          let nextToken = null;
          do {
            const resp = await fetchPaginatedServiceTypes(apiKey, organizationId, jwtToken, {
              pageSize: 100,
              continuationToken: nextToken,
            });
            allServiceTypes = [...allServiceTypes, ...(resp.items || [])];
            nextToken = resp.continuationToken;
          } while (nextToken && !isCancelled);
        }

        // HORSES
        {
          let nextToken = null;
          if (userType === 'admin') {
            do {
              const resp = await fetchHorsesPaginated(apiKey, organizationId, jwtToken, {
                pageSize: 100,
                continuationToken: nextToken,
              });
              allHorses = [...allHorses, ...(resp.items || [])];
              nextToken = resp.continuationToken;
            } while (nextToken && !isCancelled);
          } else {
            // For normal users, you might fetch only the user's horses in a single call
            // or do a smaller paginated approach
            // For now, let's do one shot if you have a user-based fetch
            // or simply fetchHorsesPaginated with a filter
            const resp = await fetchHorsesPaginated(apiKey, organizationId, jwtToken, {
              pageSize: 100,
              filter: { userId: userId }, // If your backend supports a filter
            });
            allHorses = resp.items || [];
          }
        }

        // OWNERS
        {
          let nextToken = null;
          do {
            const resp = await fetchOwnersPaginated(apiKey, organizationId, jwtToken, {
              pageSize: 100,
              continuationToken: nextToken,
            });
            allOwners = [...allOwners, ...(resp.items || [])];
            nextToken = resp.continuationToken;
          } while (nextToken && !isCancelled);
        }

        if (!isCancelled) {
          setTeachers(allTeachers);
          setServiceTypes(allServiceTypes);
          setHorses(allHorses);
          setOwners(allOwners);
        }
      } catch (err) {
        console.error('Failed to fetch resources:', err);
      } finally {
        if (!isCancelled) setAuxLoading(false);
      }
    };
    fetchAllData();
    return () => {
      isCancelled = true;
    };
  }, [apiKey, organizationId, jwtToken, userType, userId]);

  // Keep local view mode in sync with user preferences
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  // Toggle between table/card
  const toggleViewMode = () => {
    const newMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newMode);
    updateDevicePreferences('viewMode', newMode);
  };

  // Open booking modal
  const openBookingModal = (bookingId) => {
    if (bookingId) {
      // find the existing booking
      const found = bookings.find((b) => b.id === bookingId);
      if (found) setSelectedBooking(found);
    }
    setModalOpen(true);
  };

  // Close booking modal
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedBooking(initialBookingData);

    loadFirstPage(); // Refresh the bookings after closing the modal

  };

  // After saving a new or updated booking, re-fetch or re-load the page
  const handleSave = async () => {
    // after the modal saves, re-fetch the bookings from server
    // your "usePaginatedData" automatically does a "loadFirstPage()" if you change filter/sort
    // or you can do a manual re-fetch approach. For simplicity, we can:
    // (1) close the modal
    handleModalClose();
    // (2) Optionally re-run the "loadFirstPage()" from the custom hook if we have it
    // or manually do:
    // e.g. loadFirstPage() or setFilter(...) to force refresh. 
    // For now, do nothing if your hook automatically re-renders after saving
  };

  // Transform bookings to DataViewWrapper structure
  const transformData = useMemo(() => ({
    headers: [
      {
        key: 'riderName',
        label: t('RidingLessonsOverview.table.name'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'horseName',
        label: t('RidingLessonsOverview.table.horseName'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'teacher',
        label: t('RidingLessonsOverview.table.teacher'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'dateTime',
        label: t('RidingLessonsOverview.table.dateTime'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'duration',
        label: t('RidingLessonsOverview.table.duration'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'isPaid',
        label: t('RidingLessonsOverview.table.isPaid'),
        display: { value: true, chipColor: false },
      },
    ],
    rows: bookings.map((row) => {
      const horseName = horses.find((h) => h.id === row.horseId)?.name || '';
      const teacherName = teachers.find((tch) => tch.id === row.teacherId)?.name || '';
      return {
        id: row.id,
        riderName: { value: row.riderName },
        horseName: { value: horseName },
        teacher: { value: teacherName },
        dateTime: { value: new Date(row.dateTime).toLocaleString() },
        duration: { value: row.duration },
        isPaid: {
          value: null,
          icon: renderPaymentStatus(row.isPaid),
        },
      };
    }),
  }), [bookings, horses, teachers, t]);

  const isLoading = bookingsLoading || auxLoading;

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('RidingLessonsOverview.title')}
      </Typography>

     
      {/* Loading or DataView */}
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <DataViewWrapper
            structure={transformData}
            viewMode={viewMode}
            onViewModeToggle={toggleViewMode}
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            onRowClick={(bookingId) => openBookingModal(bookingId)}
            pageId="/riding-lessons"
            toolbarActions={[
              <Button
                key="add"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => openBookingModal(null)}
              >
                {t('RidingLessonsOverview.addLesson')}
              </Button>,
            ]}
          />

          {/* Load More button if there's more data */}
          {continuationToken && (
            <Box mt={2} textAlign="center">
              <Button
                variant="contained"
                onClick={loadNextPage}
                disabled={isLoading}
              >
                {t('common.loadMore')}
              </Button>
            </Box>
          )}
        </>
      )}

      {/* Booking Modal for add/edit */}
      <RidingLessonBookingModal
        open={modalOpen}
        onClose={handleModalClose}
        lessonData={selectedBooking}
        horses={horses}
        teachers={teachers}
        serviceTypes={serviceTypes}
        owners={owners}
        onSave={handleSave}
      />
    </Paper>
  );
};

export default RidingLessonsOverview;
