// DocumentReferencesList.js
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Avatar,
    CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchDocumentById } from '../services/v2/BusinessDocumentService';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoneyIcon from '@mui/icons-material/Money';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const DocumentReferencesList = ({ references, apiKey, organizationId, token }) => {
    const [referencesData, setReferencesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        async function fetchReferencesData() {
            try {
                const data = await Promise.all(
                    references.map(async (reference) => {
                        const documentData = await fetchDocumentById(
                            reference.sourceDocumentType,
                            reference.sourceDocumentId,
                            apiKey,
                            organizationId,
                            token
                        );
                        return {
                            ...reference,
                            documentNumber: documentData[`${reference.sourceDocumentType}Number`] || '',
                        };
                    })
                );
                setReferencesData(data);
            } catch (err) {
                console.error('Error fetching document references:', err);
                setError(t('documentReferencesList.fetchError'));
            } finally {
                setLoading(false);
            }
        }

        if (references && references.length > 0) {
            fetchReferencesData();
        } else {
            setLoading(false);
        }
    }, [references, apiKey, organizationId, token, t]);

    const handleCardClick = (reference) => {
        const path = getDocumentDetailPath(reference.sourceDocumentType, reference.sourceDocumentId);
        navigate(path);
    };

    const getDocumentDetailPath = (documentType, documentId) => {
        switch (documentType) {
            case 'costEstimation':
                return `/sales-process/costEstimations/detail/${documentId}`;
            case 'salesOrder':
                return `/sales-process/sales-orders/detail/${documentId}`;
            case 'deliveryNote':
                return `/sales-process/deliveryNotes/detail/${documentId}`;
            case 'invoice':
                return `/sales-process/invoices/detail/${documentId}`;
            // Add other document types as needed
            default:
                return '/';
        }
    };

    const getDocumentIcon = (documentType) => {
        switch (documentType) {
            case 'costEstimation':
                return <DescriptionIcon />;
            case 'salesOrder':
                return <ShoppingCartIcon />;
            case 'deliveryNote':
                return <LocalShippingIcon />;
            case 'invoice':
                return <ReceiptIcon />;
            // Add other document types as needed
            default:
                return <MoneyIcon />;
        }
    };

    return (
        <Grid container spacing={2}>
            {loading ? (
                <Grid item xs={12} display="flex" justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : error ? (
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        color="error"
                        align="center"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <ErrorOutlineIcon sx={{ mr: 1 }} />
                        {error}
                    </Typography>
                </Grid>
            ) : referencesData.length > 0 ? (
                referencesData.map((reference, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                            elevation={3}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': { boxShadow: 6 },
                                transition: 'box-shadow 0.3s ease-in-out',
                            }}
                            onClick={() => handleCardClick(reference)}
                        >
                            <CardContent>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                                            {getDocumentIcon(reference.sourceDocumentType)}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6" component="div">
                                            {t(`documentReferencesList.documentTypes.${reference.sourceDocumentType}`)}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {reference.documentNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography variant="body1" color="text.secondary" align="center">
                        {t('documentReferencesList.noReferences')}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default DocumentReferencesList;
