import React, { useState } from 'react';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

function CalendarView({
  localizer,
  events,
  startAccessor = 'start',
  endAccessor = 'end',
  selectable = true,
  onSelectSlot = () => { },
  onSelectEvent = () => { },
  dayPropGetter,
  slotPropGetter,
  step = 30,
  timeslots = 2,
  eventPropGetter,
  style = { height: 600 },
  views = ['week', 'month'],
  view,
  onView,
  onNavigate,
  formats,
}) {
  const [date, setDate] = useState(new Date());

  // Dynamically compute scrollToTime so it's always "12 pm of the active date"
  const scrollToTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    8, // 12 hours
    0,
    0
  );
  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor={startAccessor}
      endAccessor={endAccessor}
      selectable={selectable}
      onSelectSlot={onSelectSlot}
      onSelectEvent={onSelectEvent}
      dayPropGetter={dayPropGetter}
      slotPropGetter={slotPropGetter}
      step={step}
      timeslots={timeslots}
      eventPropGetter={eventPropGetter}
      style={style}
      views={views}
      view={view}
      onView={onView}
      onNavigate={onNavigate}
      formats={formats}
      scrollToTime={scrollToTime}
    />
  );
}

export default CalendarView;
