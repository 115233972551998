import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchPaginatedPricing } from '../../services/v2/PricingService'; // This service needs to be implemented
import { useAuth } from '../../context/AuthContext';

const PricingSettings = () => {
    const navigate = useNavigate();
    const [pricing, setPricing] = useState([]);
    const { apiKey, token, organizationId } = useAuth();

    useEffect(() => {
        // Fetch pricing settings from the backend and set the local state
        fetchPaginatedPricing(apiKey, organizationId, token, { pageSize: 1000 }).then(data => setPricing(data.items || []))
            .catch(err => console.error("Failed to fetch pricing settings:", err));
    }, [apiKey, organizationId, token]);

    const handleRowClick = (id) => {
        navigate(`/organization-settings/pricing/edit/${id}`);
    };

    const handleAddNewClick = () => {
        navigate('/organization-settings/pricing/new');
    };

    return (
        <div>
            <h1>Pricing Settings</h1>
            <Button variant="contained" color="primary" onClick={handleAddNewClick}>
                Add New Pricing
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Brutto Price</TableCell>
                            <TableCell>VAT</TableCell>
                            <TableCell>Netto Price</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pricing.map((priceSetting) => (
                            <TableRow key={priceSetting.Id} hover onClick={() => handleRowClick(priceSetting.pricingId)} style={{ cursor: 'pointer' }}>
                                <TableCell>{priceSetting.description}</TableCell>
                                <TableCell>{priceSetting.currency}</TableCell>
                                <TableCell>{priceSetting.bruttoPrice}</TableCell>
                                <TableCell>{priceSetting.VAT}</TableCell>
                                <TableCell>{priceSetting.nettoPrice}</TableCell>
                                <TableCell>{priceSetting.active ? 'Active' : 'Inactive'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PricingSettings;
