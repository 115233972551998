// src/services/measureUnitsService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const MEASURE_UNITS_API_BASE_URL = '/api/v2/measureUnits';

export async function fetchMeasureUnits(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(MEASURE_UNITS_API_BASE_URL);
  return response.data;
}

export async function saveMeasureUnit(measureUnit, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(MEASURE_UNITS_API_BASE_URL, measureUnit);
  return response.data;
}

export async function updateMeasureUnit(measureUnit, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${MEASURE_UNITS_API_BASE_URL}?id=${measureUnit.id}`;
  const response = await axiosInstance.put(url, measureUnit);
  return response.data;
}

export async function deleteMeasureUnit(measureUnitId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${MEASURE_UNITS_API_BASE_URL}?id=${measureUnitId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function fetchPaginatedMeasureUnits(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  return fetchPaginatedData(MEASURE_UNITS_API_BASE_URL, options, { apiKey, organizationId, jwtToken });
}

export default {
  fetchMeasureUnits,
  saveMeasureUnit,
  updateMeasureUnit,
  deleteMeasureUnit,
  fetchPaginatedMeasureUnits,
};
