// src/components/AzureB2CAuthentication.js

import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Container, Typography, Button, Grid, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../context/authConfig';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { renewToken } from '../context/tokenUtils';

const AzureB2CAuthentication = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { login, loginPostInformation, setIsLoggedIn } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleLoginRedirect = () => {
    setIsLoading(true);
    console.log('Logging in with redirect...');
  
    const loginAuthority = `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.onmicrosoft.com/B2C_1_signIn.equistab`;
  
    const loginRequestWithAuthority = {
      ...loginRequest,
      authority: loginAuthority,
      state: JSON.stringify({ Demo: true, signUpType: 'azureLogin' }),
    };
  
    instance.loginRedirect(loginRequestWithAuthority).catch((error) => {
      if (error.errorMessage && error.errorMessage.indexOf('AADB2C90118') > -1) {
        handleForgotPassword();
      } else {
        console.error('Login error:', error);
      }
      setIsLoading(false);
    });
  };
  

  const handleForgotPassword = () => {
    // Redirect to the forgotten password route of your application
    navigate('/forgot-password');
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6">{t('Authentication.waitingText')}</Typography>
        <LinearProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        {t('Authentication.title')}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLoginRedirect}
          >
            {t('Authentication.login')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleForgotPassword}
          >
            {t('Authentication.forgotPassword')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AzureB2CAuthentication;
