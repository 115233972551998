// src/components/DeliveryComponents/VehicleDialog.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, LinearProgress } from '@mui/material';
import { fetchVehicleById, addVehicle, updateVehicle } from '../../services/v2/VehicleService';
import { useAuth } from '../../context/AuthContext';

import { useTranslation } from 'react-i18next';

const VehicleDialog = ({ open, onClose, vehicleId = null, initialData = null, onSave }) => {
    const { apiKey, organizationId, token } = useAuth();
    const [vehicleData, setVehicleData] = useState(initialData || { make: '', licensePlate: '', capacity: '', model: '', name: '' });
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (vehicleId && !initialData) {
            loadVehicleData(vehicleId);
        }
    }, [vehicleId, initialData, t]);

    const loadVehicleData = async (id) => {
        setLoading(true);
        try {
            const data = await fetchVehicleById(apiKey, organizationId, token, id);
            setVehicleData(data);
        } catch (error) {
            console.error(t('VehicleDialog.errorFetchingVehicle', { error: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            if (vehicleId) {
                await updateVehicle(vehicleData, apiKey, organizationId, token);
            } else {
                await addVehicle(vehicleData, apiKey, organizationId, token);
            }
            onSave(); // Callback to refresh the parent list or indicate save success
            onClose();
        } catch (error) {
            console.error(t('VehicleDialog.errorSavingVehicle', { error: error.message }));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{vehicleId ? t('VehicleDialog.editVehicle') : t('VehicleDialog.addVehicle')}</DialogTitle>
            {loading && <LinearProgress />}
            <DialogContent>
                <TextField
                    margin="normal"
                    fullWidth
                    label={t('VehicleDialog.make')}
                    value={vehicleData.make}
                    onChange={(e) => setVehicleData({ ...vehicleData, make: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label={t('VehicleDialog.model')}
                    value={vehicleData.model}
                    onChange={(e) => setVehicleData({ ...vehicleData, model: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label={t('VehicleDialog.name')}
                    value={vehicleData.name}
                    onChange={(e) => setVehicleData({ ...vehicleData, name: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label={t('VehicleDialog.licensePlate')}
                    value={vehicleData.licensePlate}
                    onChange={(e) => setVehicleData({ ...vehicleData, licensePlate: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label={t('VehicleDialog.capacity')}
                    value={vehicleData.capacity}
                    onChange={(e) => setVehicleData({ ...vehicleData, capacity: e.target.value })}
                    disabled={loading}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>{t('VehicleDialog.cancelButton')}</Button>
                <Button variant="contained" onClick={handleSave} disabled={loading}>
                    {vehicleId ? t('VehicleDialog.saveChanges') : t('VehicleDialog.createVehicle')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VehicleDialog;
