import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    fetchDashboardStatistics,
    fetchMonthlyCategorizedTransactions
} from '../../../services/v2/AdminDashboardService';
import { getHorseActivityVotesAdminStatistics } from '../../../services/v2/horseActivityVoteService';

export const useDashboardData = (
    apiKey,
    organizationId,
    jwtToken,
    userId,
    month,
    year,
    isEquistab
) => {
    const { t } = useTranslation();

    // High-level counts & financial totals
    const [horseCount, setHorseCount] = useState(0);
    const [ownerCount, setOwnerCount] = useState(0);
    const [coRiderCount, setCoRiderCount] = useState(0);
    const [todayBookingCount, setTodayBookingCount] = useState(0);
    const [monthlyExpenses, setMonthlyExpenses] = useState(0);
    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [financialStatus, setFinancialStatus] = useState(0);
    const [taxStatus, setTaxStatus] = useState(0);

    // Data for bar charts (detailed categories/subcategories)
    const [currentMonthStackedExpenses, setCurrentMonthStackedExpenses] = useState([]);
    const [activeSubcategories, setActiveSubcategories] = useState(new Set());
    const [currentMonthIncomeByCategory, setCurrentMonthIncomeByCategory] = useState([]);

    // Data for line charts
    const [last12MonthsData, setLast12MonthsData] = useState([]);
    const [last30DaysItemUsageData, setLast30DaysItemUsageData] = useState([]);

    // Translated keys for the last12Months line chart
    const [translatedExpenseKey, setTranslatedExpenseKey] = useState('');
    const [translatedIncomeKey, setTranslatedIncomeKey] = useState('');

    // Inventory warnings & services data
    const [warningItems, setWarningItems] = useState({ data: [], columns: [] });
    const [services, setServices] = useState({ data: [], columns: [] });

    // Busiest times data
    const [busiestTimes, setBusiestTimes] = useState({});
    const [busiestTimesDate, setBusiestTimesDate] = useState(new Date());

    const baseOrange = '#FFA500'; // Base orange color in hex

    // Table columns
    const inventoryColumns = [
        { id: 'name', label: t('Home.AdminDashboard.itemName', { defaultValue: 'Item Name' }) },
        { id: 'quantity', label: t('Home.AdminDashboard.quantity', { defaultValue: 'Quantity' }) },
        { id: 'warningLevel', label: t('Home.AdminDashboard.warningLevel', { defaultValue: 'Warning Level' }) }
    ];

    const serviceGroupColumns = [
        { id: 'boxType', label: t('Home.AdminDashboard.boxType', { defaultValue: 'Box Type' }) },
        { id: 'serviceType', label: t('Home.AdminDashboard.serviceType', { defaultValue: 'Service Type' }) },
        { id: 'horses', label: t('Home.AdminDashboard.horses', { defaultValue: 'Horses' }) }
    ];

    // 1) Fetch main dashboard stats & set summary info
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Instead of calling separate endpoints, we ONLY call fetchDashboardStatistics now.
                const stats = await fetchDashboardStatistics(apiKey, organizationId, jwtToken, userId);

                // If Equistab, fill in the relevant counts (these now come from `stats`).
                if (isEquistab) {
                    setHorseCount(stats.horseCount || 0);
                    setOwnerCount(stats.ownerCount || 0);
                    setCoRiderCount(stats.coRiderCount || 0);
                    setTodayBookingCount(stats.todayBookingCount || 0);
                }

                // 1A) Set numeric monthly expenses/income for the SELECTED month
                //     from the summary array (stats.Statistics).
                const currentMonthData =
                    stats.Statistics.find(
                        (m) => m.monthNumber === month && m.year === year
                    ) || { expenses: 0, income: 0 };

                setMonthlyExpenses(currentMonthData.expenses);
                setMonthlyIncome(currentMonthData.income);
                setFinancialStatus(currentMonthData.income - currentMonthData.expenses);

                // 1B) We can still use the "currentMonth" block from the server
                //     for taxes, inventory usage, warnings, etc. if that is your back-end design
                setTaxStatus(stats?.currentMonth?.taxes?.estimatedTax || 0);
                setLast30DaysItemUsageData(stats?.currentMonth?.inventory || []);

                // 1C) Prepare warnings & services table data from 'stats'
                const inventoryWarnings = prepareInventoryWarnings(stats.stockWarnings);
                const serviceGroups = prepareServiceGroups(stats.serviceGroups);
                if (isEquistab) {
                    setWarningItems({ data: inventoryWarnings, columns: inventoryColumns });
                    setServices({ data: serviceGroups, columns: serviceGroupColumns });
                }

                // 1D) Prepare last12Months line chart
                setTranslatedExpenseKey(
                    t('Home.AdminDashboard.expense', { defaultValue: 'Expenses' })
                );
                setTranslatedIncomeKey(
                    t('Home.AdminDashboard.income', { defaultValue: 'Income' })
                );

                // Create a copy of stats.Statistics with translated keys for Recharts
                const translatedLast12MonthsData = stats.Statistics.map((mData) => ({
                    ...mData,
                    [t('Home.AdminDashboard.expense')]: mData.expenses,
                    [t('Home.AdminDashboard.income')]: mData.income
                }));
                setLast12MonthsData(translatedLast12MonthsData);

                // 1E) Fetch busiest times for the default busiestTimesDate
                const date = busiestTimesDate.toISOString().split('T')[0];
                const busiestTimesData = await getHorseActivityVotesAdminStatistics(
                    apiKey,
                    organizationId,
                    jwtToken,
                    date
                );
                setBusiestTimes(busiestTimesData);

            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchData();
    }, [
        apiKey,
        organizationId,
        jwtToken,
        userId,
        isEquistab,
        month,
        year,
        t,
        busiestTimesDate
    ]);

    // 2) Fetch the *detailed* monthly category/subcategory transactions for the SELECTED month
    useEffect(() => {
        const fetchMonthData = async () => {
            try {
                // This call should return an object with { expenses: [...], income: {...} }
                // for the user-selected month and year
                const currentMonthData = await fetchMonthlyCategorizedTransactions(
                    apiKey,
                    organizationId,
                    jwtToken,
                    userId,
                    month,
                    year
                );

                // Generate stacked expenses data for Recharts
                const newActiveSubcategories = new Set();
                const totalCategoriesAndSubcategories = currentMonthData.expenses.reduce(
                    (acc, category) => acc + 1 + category.subcategories.length,
                    0
                );
                const orangeShades = generateOrangeShades(baseOrange, totalCategoriesAndSubcategories);
                let shadeIndex = 0;

                /*const expensesData = currentMonthData.expenses.map((category) => {
                    // Each "category" may have subcategories
                    const categoryData = {
                        category: category.name,
                        color: orangeShades[shadeIndex++] || baseOrange
                    };

                    // Add each subcategory as a field on the category object
                    category.subcategories.forEach((subcategory) => {
                        categoryData[subcategory.name] = subcategory.total;
                        newActiveSubcategories.add({
                            label: subcategory.name,
                            color: orangeShades[shadeIndex++] || baseOrange
                        });
                    });

                    return categoryData;
                });*/

                const expensesData = currentMonthData.expenses.map(cat => {
                    const row = { category: cat.name };
                    cat.subcategories.forEach((sub) => {
                        row[sub.name] = parseFloat(sub.total) || 0;
                    });
                    return row;
                });



                const subcategoriesSet = new Set();
                currentMonthData.expenses.forEach(cat => {
                    cat.subcategories.forEach(sub => {
                        subcategoriesSet.add({
                            label: sub.name,
                            color: '#FFA500' // or however you generate a color
                        });
                    });
                });


                setActiveSubcategories(subcategoriesSet);
                setCurrentMonthStackedExpenses(expensesData);

                /*setActiveSubcategories(newActiveSubcategories);
                setCurrentMonthStackedExpenses(expensesData);*/

                // Generate income data for Recharts
                const currentMonthInvoiceData = Object.entries(currentMonthData.income).map(
                    ([category, amount]) => ({
                        category: getItemTypeTranslation(category),
                        amount
                    })
                );
                setCurrentMonthIncomeByCategory(currentMonthInvoiceData);

            } catch (error) {
                console.error('Error fetching month data:', error);
            }
        };

        if (month !== undefined && year !== undefined) {
            fetchMonthData();
        }
    }, [apiKey, organizationId, jwtToken, userId, month, year, t]);

    // ––– Utilities –––
    const getItemTypeTranslation = (type) => {
        return t(`invoiceDetailPage.itemTypes.${type.toLowerCase()}`, type);
    };

    const prepareInventoryWarnings = (inventoryData) => {
        return inventoryData?.map((item) => ({
            id: item.id,
            name: item.name,
            warningLevel: item.warningQuantity,
            quantity: `${item.quantity} units`
        })) || [];
    };

    const prepareServiceGroups = (serviceGroups) => {
        return serviceGroups?.map((group) => ({
            boxType: group.boxTypeName,
            serviceType: group.services.map(s => s.serviceTypeName).join(', '),
            horses: group.services
                .filter(s => s.horses.length > 0)
                .flatMap(s => s.horses.map(horse => horse.name))
                .join(', ')
        })) || [];
    };

    function hexToHSL(H) {
        // ...
    }

    function generateOrangeShades(baseOrange, count) {
        // ...
    }

    // Busiest Times navigation
    async function busiestTimeNextDay() {
        const nextDay = new Date(busiestTimesDate);
        nextDay.setDate(nextDay.getDate() + 1);
        setBusiestTimesDate(nextDay);
        const fetchedData = await getHorseActivityVotesAdminStatistics(
            apiKey,
            organizationId,
            jwtToken,
            nextDay.toISOString().split('T')[0]
        );
        setBusiestTimes(fetchedData);
    }

    async function busiestTimePrevDay() {
        const prevDay = new Date(busiestTimesDate);
        prevDay.setDate(prevDay.getDate() - 1);
        setBusiestTimesDate(prevDay);
        const fetchedData = await getHorseActivityVotesAdminStatistics(
            apiKey,
            organizationId,
            jwtToken,
            prevDay.toISOString().split('T')[0]
        );
        setBusiestTimes(fetchedData);
    }

    return {
        // Basic counts
        horseCount,
        ownerCount,
        coRiderCount,
        todayBookingCount,

        // Summaries
        monthlyExpenses,
        monthlyIncome,
        financialStatus,
        taxStatus,

        // Detailed bar chart data
        currentMonthStackedExpenses,
        currentMonthIncomeByCategory,
        activeSubcategories,

        // Last12Months, items usage
        last12MonthsData,
        translatedExpenseKey,
        translatedIncomeKey,
        last30DaysItemUsageData,

        // Inventory & services
        warningItems,
        services,

        // Busiest times
        busiestTimes,
        busiestTimesDate,
        busiestTimePrevDay,
        busiestTimeNextDay
    };
};
