import React, { useState, useEffect } from 'react';
import {
  Button, Box, Typography, Card, CardContent, CardActions,
  MenuItem, Select, FormControl, InputLabel, CircularProgress, Alert
} from '@mui/material';
import {
  fetchDevices, getAuthorizationUrl, subscribeToCapability,
  unsubscribeFromCapability, updateSubscription,
} from '../../services/v2/SmartThingsService';
import { fetchPaginatedItems } from '../../services/v2/itemService';
import { useAuth } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';
import SnackbarAlert from '../SnackbarAlert';
import ConfirmationDialog from '../ConfirmationDialog';

const SmartThingsSettings = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedCapabilities, setSelectedCapabilities] = useState({});
  const [stockItems, setStockItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { apiKey, organizationId, token } = useAuth();
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [currentDevice, setCurrentDevice] = useState(null);
  const [registeredDevices, setRegisteredDevices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchDevicesFromBackend();
      await fetchItems();
      setLoading(false);
    };
    fetchData();
  }, [location.search]);

  const fetchDevicesFromBackend = async () => {
    try {
      const deviceList = await fetchDevices(apiKey, organizationId, token);
      if (deviceList.smartThingsDevices || deviceList.registered) {
        const filteredDevices = deviceList.smartThingsDevices.filter(device =>
          device.components.some(component =>
            component.capabilities.some(capability =>
              capability.id === 'button' || capability.id === 'temperatureMeasurement'
            )
          )
        );
        setDevices(filteredDevices);
        setRegisteredDevices(deviceList.registered);
        setIsConnected(true);
      }
    } catch (error) {
      console.error('Error fetching devices:', error);
      setError('Failed to fetch devices.');
      setIsConnected(false);
    }
  };

  const fetchItems = async () => {
    try {
      const items = await fetchPaginatedItems(apiKey, organizationId, token, { pageSize: 1000 });
      setStockItems(items.items?.filter(item => item.stockItem === true));
    } catch (error) {
      console.error('Error fetching items:', error);
      setError('Failed to fetch items.');
    }
  };

  const handleConnect = async () => {
    try {
      const { url } = await getAuthorizationUrl(apiKey, organizationId, token);
      window.location.href = url;
    } catch (error) {
      console.error('Error getting authorization URL:', error);
      setError('Failed to get authorization URL.');
    }
  };

  const handleSubscribe = async (deviceId, componentId, capabilityId) => {
    const device = devices.find(device => device.deviceId === deviceId);
    const action = capabilityId === 'button' ? 'outgoing' : 'inoTemperature';
    const data = {
      componentId,
      deviceId,
      deviceType: device.type,
      deviceName: device.label,
      capabilityId,
      attribute: '*',
      action,
      itemId: selectedItem[deviceId]
    };
    try {
      await subscribeToCapability(apiKey, organizationId, token, data);
      setSnackbar({ open: true, message: `Subscribed to ${capabilityId} successfully!`, severity: 'success' });
      await fetchDevicesFromBackend(); // Refresh the devices list to update the subscription status
    } catch (error) {
      console.error(`Error subscribing to ${capabilityId} capability:`, error);
      setSnackbar({ open: true, message: `Failed to subscribe to ${capabilityId}.`, severity: 'error' });
    }
  };

  const handleUnsubscribe = async () => {
    const { deviceId, componentId, capabilityId, subscriptionId, id } = currentDevice;
    const data = {
      componentId,
      deviceId,
      capabilityId,
      attribute: '*',
      subscriptionId,
      id
    };
    try {
      await unsubscribeFromCapability(apiKey, organizationId, token, data);
      setSnackbar({ open: true, message: 'Unsubscribed successfully!', severity: 'success' });
      await fetchDevicesFromBackend(); // Refresh the devices list to update the subscription status
    } catch (error) {
      console.error('Error unsubscribing from capability:', error);
      setSnackbar({ open: true, message: 'Failed to unsubscribe.', severity: 'error' });
    }
  };

  const handleUpdateSubscription = async () => {
    const { deviceId, componentId, capabilityId, subscriptionId, id } = currentDevice;
    const data = {
      subscriptionId,
      id,
      componentId,
      deviceId,
      deviceType: currentDevice.deviceType,
      deviceName: currentDevice.deviceName,
      capabilityId,
      attribute: '*',
      action: currentDevice.action,
      itemId: selectedItem[deviceId]
    };
    try {
      await updateSubscription(apiKey, organizationId, token, data);
      setSnackbar({ open: true, message: 'Subscription updated successfully!', severity: 'success' });
      await fetchDevicesFromBackend(); // Refresh the devices list to update the subscription status
    } catch (error) {
      console.error('Error updating subscription:', error);
      setSnackbar({ open: true, message: 'Failed to update subscription.', severity: 'error' });
    }
  };

  const handleSelectItem = (deviceId, event) => {
    setSelectedItem({ ...selectedItem, [deviceId]: event.target.value });
  };

  const handleOpenConfirmDialog = (text, device, action) => {
    setConfirmDialogText(text);
    setCurrentDevice({ ...device, action });
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setCurrentDevice(null);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const isCapabilitySubscribed = (deviceId, capabilityId) => {
    return registeredDevices.some(
      (device) => device.deviceId === deviceId && device.capabilityId === capabilityId
    );
  };

  return (
    <>
      <Box>
        <Typography variant="h6">SmartThings Integration</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {!isConnected ? (
          <Button variant="contained" color="primary" onClick={handleConnect}>
            Connect to SmartThings
          </Button>
        ) : (
          <>
            <Typography variant="body1">Connected to SmartThings</Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Box display="flex" flexWrap="wrap" justifyContent="space-around">
                  {devices.map(device => (
                    <Card key={device.deviceId} style={{ margin: '1em', width: '300px' }}>
                      <CardContent>
                        <Typography variant="h6">{device.label}</Typography>
                        {device.components.map(component => (
                          <Box key={component.id} mt={2}>
                            {component.capabilities.map(capability => (
                              <Box key={capability.id} mb={2}>
                                {capability.id === 'button' ? (
                                  <>
                                    <Typography variant="body2">{device.label}</Typography>
                                    <FormControl fullWidth>
                                      <InputLabel>Select Item</InputLabel>
                                      <Select
                                        value={
                                          isCapabilitySubscribed(device.deviceId, capability.id)
                                            ? registeredDevices.find(redDevice => redDevice.deviceId === device.deviceId && redDevice.capabilityId === capability.id)?.itemId || ''
                                            : selectedItem[device.deviceId] || ''
                                        }
                                        onChange={(e) => handleSelectItem(device.deviceId, e)}
                                      >
                                        {stockItems.map(item => (
                                          <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <CardActions>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSubscribe(device.deviceId, component.id, capability.id)}
                                        disabled={
                                          isCapabilitySubscribed(device.deviceId, capability.id) || !selectedItem[device.deviceId]
                                        }
                                      >
                                        Subscribe to {capability.id}
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() =>
                                          handleOpenConfirmDialog(
                                            `Are you sure you want to unsubscribe from ${capability.id}?`,
                                            { deviceId: device.deviceId, componentId: component.id, capabilityId: capability.id, subscriptionId: registeredDevices.find(d => d.deviceId === device.deviceId && d.capabilityId === capability.id)?.subscriptionId, id: registeredDevices.find(d => d.deviceId === device.deviceId && d.capabilityId === capability.id)?.id },
                                            'unsubscribe'
                                          )
                                        }
                                        disabled={!isCapabilitySubscribed(device.deviceId, capability.id)}
                                      >
                                        Unsubscribe
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleOpenConfirmDialog(
                                            `Are you sure you want to update the subscription for ${capability.id}?`,
                                            { deviceId: device.deviceId, componentId: component.id, capabilityId: capability.id, subscriptionId: registeredDevices.find(d => d.deviceId === device.deviceId && d.capabilityId === capability.id)?.subscriptionId, id: registeredDevices.find(d => d.deviceId === device.deviceId && d.capabilityId === capability.id)?.id },
                                            'update'
                                          )
                                        }
                                        disabled={!isCapabilitySubscribed(device.deviceId, capability.id)}
                                      >
                                        Update Subscription
                                      </Button>
                                    </CardActions>
                                  </>
                                ) : null}
                              </Box>
                            ))}
                          </Box>
                        ))}
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
      <ConfirmationDialog
        isOpen={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onConfirm={currentDevice?.action === 'unsubscribe' ? handleUnsubscribe : handleUpdateSubscription}
        text={confirmDialogText}
      />
    </>
  );
};

export default SmartThingsSettings;
