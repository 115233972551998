import React, { useEffect, useState } from 'react';
import {
    TextField, IconButton, Card, CardContent, Checkbox, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent,
    DialogActions, Button, Box, Typography, LinearProgress, Snackbar, Alert
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { bookingStatistics, markBookingsAsPaid } from '../../services/v2/guidingMachineService';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { GiHorseHead } from 'react-icons/gi';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

import { fetchPricingByReference } from '../../services/v2/PricingService';

const UserDetails = () => {
    const [selectedDate, setSelectedDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { apiKey, organizationId, userId, token } = useAuth();
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const jwtToken = token;
    const [machineMap, setMachineMap] = useState({});
    const MachineIcon = FitnessCenterIcon;
    const HorseIcon = GiHorseHead;

    const [deviceTotals, setDeviceTotals] = useState({});

    useEffect(() => {
        const offset = selectedDate.getTimezoneOffset();
        const adjustedDate = new Date(selectedDate.getTime() - (offset * 60 * 1000));
        const date = adjustedDate.toISOString().slice(0, 10);

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await bookingStatistics(userId, date, apiKey, organizationId, jwtToken, 'month');
                const machineIds = [...new Set(response.bookingCounts.flatMap(user => user.bookings.map(booking => booking.machine_id)))];
                const pricingMap = await fetchPricingForMachines(machineIds, apiKey, organizationId, jwtToken);
                const matchedBookings = matchPricingWithBookings(response.bookingCounts, pricingMap);
                setMachineMap(organizeBookingsByMachine(matchedBookings));
                setData(matchedBookings);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, [selectedDate, fetchTrigger]);

    const fetchPricingForMachines = async (machineIds, apiKey, organizationId, jwtToken) => {
        const pricingMap = new Map();
        const pricingPromises = machineIds.map(async (machineId) => {
            const pricing = await fetchPricingByReference(apiKey, organizationId, jwtToken, machineId);
            pricingMap.set(machineId, pricing);
        });
        await Promise.all(pricingPromises);
        return pricingMap;
    };

    const matchPricingWithBookings = (users, pricingMap) => {
        return users.map((user) => {
            const matchedBookings = user.bookings.map((booking) => {
                const pricing = pricingMap.get(booking.machine_id);
                const numericPrice = Number(pricing?.bruttoPrice);

                if (isNaN(numericPrice)) {
                    console.error('Invalid price:', pricing?.bruttoPrice);
                    return { ...booking, pricing: 0 };
                }

                return { ...booking, pricing: numericPrice };
            });

            return { ...user, bookings: matchedBookings };
        });
    };

    const calculateTotalsPerDevice = () => {
        const totals = {};

        selectedUser?.bookings.forEach((booking) => {
            if (selectedBookings.includes(booking.bookingId) && booking.payed === "False") {
                if (!totals[booking.machine_id]) {
                    totals[booking.machine_id] = 0;
                }
                totals[booking.machine_id] += Number(booking.pricing || 0);
            }
        });

        return totals;
    };

    useEffect(() => {
        const deviceTotals = calculateTotalsPerDevice();
        setDeviceTotals(deviceTotals);
    }, [selectedBookings, selectedUser?.bookings]);

    const incrementMonth = () => {
        const newDate = new Date(selectedDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setSelectedDate(newDate);
    };

    const decrementMonth = () => {
        const newDate = new Date(selectedDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setSelectedDate(newDate);
    };

    const handleOpenDialog = (user) => {
        setSelectedUser(user);
        setSelectedBookings([]);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedBookings([]);
    };

    const callYourServiceWithSelectedBookings = async (bookingIds) => {
        try {
            await markBookingsAsPaid(bookingIds, apiKey, organizationId, jwtToken);
            handleCloseDialog();
            setSnackbarOpen(true);
            setFetchTrigger(fetchTrigger + 1);
        } catch (error) {
            console.log('Failed to send selected bookings', error);
        }
    };

    const toggleSelectedBooking = (bookingId) => {
        setSelectedBookings((prevSelected) => {
            if (prevSelected.includes(bookingId)) {
                return prevSelected.filter((id) => id !== bookingId);
            } else {
                return [...prevSelected, bookingId];
            }
        });
    };

    const formatPrice = (price) => {
        const numericPrice = Number(price);
        if (isNaN(numericPrice)) {
            console.error('Invalid price:', price);
            return 'N/A';
        }
        return `${numericPrice.toFixed(2)} ${i18n.language === 'en' ? '$' : '€'}`;
    };

    const organizeBookingsByMachine = (bookingCounts) => {
        const machineMap = {};
        bookingCounts.forEach(user => {
            user.machines.forEach(machine => {
                if (!machineMap[machine.id]) {
                    machineMap[machine.id] = {
                        name: machine.name,
                        paidCount: 0,
                        unpaidCount: 0,
                        bookings: []
                    };
                }
                machineMap[machine.id].bookings.push(...user.bookings.filter(booking => booking.machineId === machine.id));
                machineMap[machine.id].paidCount += machine.paidCount;
                machineMap[machine.id].unpaidCount += machine.count - machine.paidCount;
            });
        });
        return machineMap;
    };

    const calculateTotalPaidForMachine = (userBookings, machineId) => {
        return userBookings
            .filter(booking => booking.machine_id === machineId && booking.payed === "True")
            .reduce((total, booking) => total + (booking.pricing || 0), 0);
    };

    const calculateTotalUnpaidForMachine = (userBookings, machineId) => {
        return userBookings
            .filter(booking => booking.machine_id === machineId && booking.payed === "False")
            .reduce((total, booking) => total + (booking.pricing || 0), 0);
    };

    if (loading) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('GuidingMachinePage.UserDetails.waitingText')}</Typography>
                <LinearProgress />
            </Box>
        );
    }

    return (
        <div>
            <h2>{t('GuidingMachinePage.UserDetails.userInformation')}</h2>

            <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="nowrap" mb={2}>
                <IconButton onClick={decrementMonth}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography>{selectedDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })}</Typography>
                <IconButton onClick={incrementMonth}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('GuidingMachinePage.UserDetails.name')}</TableCell>
                            <TableCell>{t('GuidingMachinePage.UserDetails.totalBookings')}</TableCell>
                            <TableCell>{t('GuidingMachinePage.UserDetails.paidBookings')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((user) => (
                            <TableRow key={user.name} onClick={() => handleOpenDialog(user)} style={{ cursor: 'pointer' }}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        {user.count}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        {user.paidCount}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{selectedUser?.name + t('GuidingMachinePage.UserDetails.sHorses')}</DialogTitle>
                <DialogContent>
                    {selectedUser?.machines.map((machine) => {
                        const machineBooking = selectedUser?.bookings.filter(booking => booking.machine_id === machine.id);
                        const machineUnpaid = machine.count - machine.paidCount;
                        return (
                            <Card elevation={3} key={machine.id} style={{ marginBottom: '20px' }}>
                                <CardContent>
                                    <Typography variant="h5" alignItems="center">
                                        <MachineIcon /> {`${t('GuidingMachinePage.UserDetails.deviceName')}: ${machine.name}`}
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="space-around" mt={2} mb={4}>
                                        <Box display="flex" alignItems="center">
                                            {`${t('GuidingMachinePage.UserDetails.Paid')}: ${machine.paidCount}`}
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            {`${t('GuidingMachinePage.UserDetails.Unpaid')}: ${machineUnpaid}`}
                                        </Box>
                                    </Box>
                                    {selectedUser?.horses
                                        .filter(horse => {
                                            const relatedBookings = selectedUser.bookings.filter(booking => booking.horseId === horse.id && booking.machine_id === machine.id);
                                            return relatedBookings.length > 0;
                                        })
                                        .map((horse) => (
                                            <Card elevation={2} key={horse.id} style={{ margin: '10px 0' }}>
                                                <CardContent>
                                                    <Box key={horse.id}>
                                                        <Typography variant="h6">
                                                            <HorseIcon /> {horse.name}
                                                        </Typography>
                                                        {selectedUser.bookings &&
                                                            selectedUser.bookings.length > 0 &&
                                                            selectedUser?.bookings.filter((booking) => (booking.horseId === horse.id && booking.machine_id === machine.id).length > 0) ? (
                                                            <Box>
                                                                {selectedUser.bookings
                                                                    .filter((booking) => booking.horseId === horse.id && booking.machine_id === machine.id)
                                                                    .map((booking, index) => (
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            key={index}
                                                                        >
                                                                            {
                                                                                booking.payed === "True" ? (
                                                                                    <AttachMoneyIcon color="primary" />
                                                                                ) : (
                                                                                    <Checkbox
                                                                                        checked={selectedBookings.includes(booking.bookingId)}
                                                                                        onChange={() => toggleSelectedBooking(booking.bookingId)}
                                                                                    />
                                                                                )
                                                                            }
                                                                            <Typography style={{
                                                                                color: booking.payed === "True" ? "green" : "black"
                                                                            }}>
                                                                                {t('GuidingMachinePage.UserDetails.booking')}: {new Date(booking.startTime).toLocaleString()}
                                                                            </Typography>
                                                                        </Box>
                                                                    ))
                                                                }
                                                            </Box>
                                                        ) : (
                                                            <Typography>{t('GuidingMachinePage.UserDetails.NoBookings')}</Typography>
                                                        )}
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    <Box display="flex" justifyContent="space-between" flexDirection='column'>
                                        <Typography>
                                            {t('GuidingMachinePage.UserDetails.PaidSum')}{formatPrice(calculateTotalPaidForMachine(selectedUser.bookings, machine.id))}
                                        </Typography>
                                        <Typography>
                                            {t('GuidingMachinePage.UserDetails.UnPaidSum')}{formatPrice(calculateTotalUnpaidForMachine(selectedUser.bookings, machine.id))}
                                        </Typography>
                                        <Typography>
                                            {t('GuidingMachinePage.UserDetails.selectedSum')}{formatPrice(deviceTotals[machine.id] || 0)}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => callYourServiceWithSelectedBookings(selectedBookings)}
                    >
                        {t('GuidingMachinePage.UserDetails.SendSelection')}
                    </Button>
                    <Button onClick={handleCloseDialog}>{t('GuidingMachinePage.UserDetails.close')}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                    {t('GuidingMachinePage.UserDetails.notification')}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default UserDetails;
