// src/services/ownerService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/horseActivity';

export async function getHorseActivityVoteById(id, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?activityId=${id}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function getHorseActivityVotes(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function createHorseActivityVote(vote, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, vote);
  return response.data;
}

export async function updateHorseActivityVote(vote, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?activityId=${vote.id}`;
  const response = await axiosInstance.put(url, vote);
  return response.data;
}

export async function deleteHorseActivityVote(voteId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?activityId=${voteId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function getHorseActivityVotesAdminStatistics(apiKey, organizationId, jwtToken, date) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/statistic/voting?date=${date}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function getHorseActivityVotesPaginated(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  return fetchPaginatedData(API_BASE_URL, options, { apiKey, organizationId, jwtToken });
}