// src/pages/SalesOrdersListPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchPaginatedSalesOrders } from '../services/v2/SalesOrderService';
import { approveDocument, rejectDocument } from '../services/v2/BusinessDocumentService';
import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';
import DataViewWrapper from '../components/DataViewWrapper';
import SnackbarAlert from '../components/SnackbarAlert';

// New: hook for pagination
import { usePaginatedData } from '../hooks/usePaginatedData';

const SalesOrdersListPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // --- PAGINATED DATA HOOK ---
  const {
    items: salesOrders,
    loading: salesOrdersLoading,
    continuationToken,
    loadNextPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchPaginatedSalesOrders(apiKey, organizationId, token, userId, options),
    { pageSize: 25 }
  );

  // For view mode (table/card), use user/device preferences
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode || (isMobile ? 'card' : 'table')
  );

  // Snackbar for success/error messages
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  // Sync local `viewMode` with any changes from user preferences
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const toggleViewMode = () => {
    const newMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newMode);
    updateDevicePreferences('viewMode', newMode);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Navigate to detail when row/card is clicked
  const navigateToDetail = (orderId) => {
    navigate(`/sales-process/sales-orders/detail/${orderId}`);
  };

  const approveOrder = async (orderId) => {
    try {
      await approveDocument(apiKey, organizationId, token, userId, orderId, 'salesOrder');
      setSnackbar({
        open: true,
        message: t("salesOrdersListPage.orderApproved"),
        severity: 'success',
      });
      // Optimistically update local state
      // (Assuming item is in `salesOrders`; set its status to 'approved')
      // In a real app, you might re-fetch or do a more robust update.
    } catch (error) {
      console.error("Error approving sales order: ", error);
      setSnackbar({
        open: true,
        message: t("salesOrdersListPage.errorApprovingOrder"),
        severity: 'error',
      });
    }
  };

  // Renders a status chip
  const renderStatus = (order) => {
    if (order.cancel === true) {
      return <Chip icon={<WarningAmberIcon />} label={t("salesOrdersListPage.cancelled")} color="secondary" />;
    } else if (order.status === 'draft') {
      return <Chip icon={<CancelIcon />} label={t("salesOrdersListPage.draft")} color="default" />;
    } else if (order.status === 'approved') {
      return <Chip icon={<CheckCircleIcon />} label={t("salesOrdersListPage.approved")} color="primary" />;
    } else if (order.status === 'Completed') {
      return <Chip icon={<CheckCircleIcon />} label={t("salesOrdersListPage.completed")} color="primary" />;
    } else {
      return <Chip icon={<CancelIcon />} label={t("salesOrdersListPage.unapproved")} color="error" />;
    }
  };

  // Transform data for DataViewWrapper
  const transformData = useMemo(() => {
    return {
      headers: [
        {
          key: 'salesOrderNumber', label: t("salesOrdersListPage.salesOrderNumber"),
          display: { value: true, chipColor: false },
        },
        {
          key: 'customerName', label: t("salesOrdersListPage.customerName"),
          display: { value: true, chipColor: false },
        },
        {
          key: 'orderDate', label: t("salesOrdersListPage.orderDate"),
          display: { value: true, chipColor: false },
        },
        {
          key: 'totalAmount', label: t("salesOrdersListPage.totalAmount"),
          display: { value: true, chipColor: false },
        },
        {
          key: 'status', label: t("salesOrdersListPage.status"),
          display: { value: true, chipColor: false },
        },
      ],
      rows: salesOrders.map((order) => ({
        id: order.id,
        salesOrderNumber: { value: order.salesOrderNumber },
        customerName: { value: order.customerName },
        orderDate: {
          value: new Date(order.orderDate).toLocaleDateString(),
        },
        totalAmount: { value: order.totalAmount },
        status: {
          value: renderStatus(order),
        },
        actions: [
          {
            icon: <CheckCircleIcon />,
            handler: order.status !== 'draft' ? null : () => approveOrder(order.id),
            disabled: order.status !== 'draft',
            label: t("salesOrdersListPage.approve"),
          },
        ],
      })),
    };
  }, [salesOrders]);

  const isLoading = salesOrdersLoading;

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      {isLoading ? (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {/* DataViewWrapper instead of manual table/card */}
          <DataViewWrapper
            structure={transformData}
            viewMode={viewMode}
            onViewModeToggle={toggleViewMode}
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            onRowClick={navigateToDetail}
            pageId="/sales-orders"
            toolbarActions={[
              <Button
                key="add-order"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                component={Link}
                to="/sales-process/sales-orders/detail/new"
              >
                {t("salesOrdersListPage.addNewOrder")}
              </Button>,
            ]}
          />

          {/* Load More button if there's more to fetch */}
          {continuationToken && (
            <Box mt={2} textAlign="center">
              <Button variant="contained" onClick={loadNextPage} disabled={isLoading}>
                {t("common.loadMore")}
              </Button>
            </Box>
          )}
        </>
      )}

      {/* Snackbar for success/error messages */}
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default SalesOrdersListPage;
