// src/hooks/usePaginatedData.js
import { useState, useEffect } from 'react';

/**
 * A reusable hook to handle paginated data fetching for your v2 Cosmos DB endpoints.
 *
 * @param {Function} fetchFunction - A function that calls your "fetch*Paginated" service.
 *        Must accept an "options" object, e.g. { pageSize, continuationToken, filter, sort }.
 * @param {Object} initialQuery   - Optional initial query settings (pageSize, filter, sort).
 * @returns {Object} An object containing the paginated items, loading states, error states,
 *                   and functions (loadFirstPage, loadNextPage, setFilter, setSort, etc.)
 */
export function usePaginatedData(fetchFunction, initialQuery = {}) {
    const [items, setItems] = useState([]);
    const [continuationToken, setContinuationToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [pageSize, setPageSize] = useState(initialQuery.pageSize || 25);
    const [filter, setFilter] = useState(initialQuery.filter || {});
    const [sort, setSort] = useState(initialQuery.sort || '');

    const loadFirstPage = async () => {
        try {
            setLoading(true);
            setItems([]);
            setContinuationToken(null);

            const { items: fetchedItems, continuationToken: nextToken } =
                await fetchFunction({ pageSize, filter, sort });

            setItems(fetchedItems || []);
            
            const safeToken = nextToken ? encodeURIComponent(nextToken) : null;
            setContinuationToken(safeToken);


            setContinuationToken(safeToken);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const loadNextPage = async () => {
        if (!continuationToken) return;
        try {
            setLoading(true);
            const { items: fetchedItems, continuationToken: nextToken } =
                await fetchFunction({ pageSize, filter, sort, continuationToken });

            setItems((prev) => [...prev, ...(fetchedItems || [])]);

            const safeToken = nextToken ? encodeURIComponent(nextToken) : null;
            setContinuationToken(safeToken);

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadFirstPage();
    }, [filter, sort]);

    return {
        items, continuationToken, loading, error, pageSize, setPageSize, filter, setFilter, sort, setSort, loadFirstPage, loadNextPage
    };
}

