import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Button } from '@mui/material';
import { deleteFerry, fetchPaginatedFerries } from '../../services/v2/FerryService';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const FerrySettings = () => {
  const [ferries, setFerries] = useState([]);
  const navigate = useNavigate();
  const { apiKey, token, organizationId } = useAuth();

  useEffect(() => {
    // Assuming you have a function fetchAllFerries to get all ferry data
    fetchPaginatedFerries(apiKey, organizationId, token, { pageSize: 1000 })
      .then(data => {
        setFerries(data.items || []);
      })
      .catch(err => console.error('Failed to fetch ferries:', err));
  }, []);

  const handleDelete = (ferryId) => {
    deleteFerry(ferryId)
      .then(() => {
        // Remove the deleted ferry from the state
        setFerries(ferries.filter(ferry => ferry.id !== ferryId));
      })
      .catch(err => console.error('Failed to delete ferry:', err));
  };

  const handleRowClick = (id) => {
    navigate(`/organization-settings/ferries/edit/${id}`);
  };

  const handleAddNewClick = () => {
    navigate('/organization-settings/ferries/new');
  };

  return (
    <div>
      <h1>Ferry Settings</h1>
      <Button variant="contained" color="primary" onClick={handleAddNewClick}>
        Add New Ferry
      </Button>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>zipCode</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ferries.map(ferry => (
              <TableRow key={ferry.id} onClick={() => handleRowClick(ferry.id)} style={{ cursor: 'pointer' }}>
                <TableCell>{ferry.firstname} {ferry.surname}</TableCell>
                <TableCell>{ferry.zipCode}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default FerrySettings;
