// src/services/InvoiceService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/invoices';

// Fetch all invoices
export async function fetchAllInvoices(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Fetch a single invoice by ID
export async function fetchInvoiceById(apiKey, organizationId, jwtToken, id, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}&userId=${userId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Save a new invoice
export async function saveInvoice(invoice, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.post(url, invoice);
  return response.data;
}

// Update an existing invoice
export async function updateInvoice(invoice, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${invoice.id}&userId=${userId}`;
  const response = await axiosInstance.put(url, invoice);
  return response.data;
}

// Delete an invoice by ID
export async function deleteInvoice(invoiceId, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${invoiceId}&userId=${userId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function downloadInvoicePdf(apiKey, organizationId, jwtToken, invoiceId, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/pdf?id=${invoiceId}&userId=${userId}&organization-id=${organizationId}&action=getInvoice`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}

export async function sendInvoicePdf(apiKey, organizationId, jwtToken, invoiceId, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/pdf?id=${invoiceId}&userId=${userId}&organization-id=${organizationId}&action=sendInvoice`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}

export async function fetchUnpaidInvoices(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}&status=unpaid`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function fetchInvoicePaginatedData(apiKey, organizationId, jwtToken, options, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  return fetchPaginatedData(url, options, { apiKey, organizationId, jwtToken });
}

export default {
  fetchAllInvoices,
  fetchInvoiceById,
  saveInvoice,
  updateInvoice,
  deleteInvoice,
  downloadInvoicePdf,
  sendInvoicePdf,
  fetchUnpaidInvoices,
  fetchInvoicePaginatedData,
};
