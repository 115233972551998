import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { loginWithAzure } from '../services/v2/authService';
import { policies } from './authPolicies';

const tenantURL = process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME;
const tenantClientId = process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID;
const customScope = `https://${tenantURL}.onmicrosoft.com/API-Scopes/Permission.All`;

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const renewToken = async (instance, login, loginPostInformation, navigate, retryCount = 2) => {
    const account = instance.getAllAccounts()[0];

    if (!account) {
        console.warn('No account found. Redirecting to auth.');
        navigate('/auth');
        return;
    }

    const currentPolicy = localStorage.getItem('b2cPolicy') || policies.signInPolicy;
    const authority = `https://${tenantURL}.b2clogin.com/${tenantURL}.onmicrosoft.com/${currentPolicy}`;

    const silentRequest = {
        scopes: ['openid', 'profile', 'email', 'offline_access', customScope],
        account,
        authority, // <-- IMPORTANT!
        forceRefresh: true,
    };

    try {
        const response = await instance.acquireTokenSilent(silentRequest);
        if (!response.accessToken) {
            throw new InteractionRequiredAuthError('No access token returned');
        }
        await processTokenResponse(response, login, loginPostInformation);
    } catch (error) {
        console.error('🔁 Silent token acquisition failed:', error);

        if (error instanceof InteractionRequiredAuthError) {
            if (isSafari()) {
                console.warn('🚫 Safari detected. Skipping acquireTokenRedirect to prevent loop.');
                navigate('/auth'); // Or send to a soft logout UI
                return;
            }

            try {
                console.log('🔁 Falling back to acquireTokenRedirect...');
                await instance.acquireTokenRedirect(silentRequest);
                // App will reload + AuthRedirect will handle it
            } catch (interactiveError) {
                console.error('🚫 acquireTokenRedirect also failed:', interactiveError);
                navigate('/auth');
            }
        } else if (retryCount > 0) {
            console.log(`🕐 Retrying silent token acquisition... (${retryCount} attempts left)`);
            setTimeout(() => renewToken(instance, login, loginPostInformation, navigate, retryCount - 1), 1000);
        } else {
            console.warn('⛔ Exhausted retries. Navigating to /auth.');
            navigate('/auth');
        }
    }
};

const processTokenResponse = async (response, login, loginPostInformation) => {
    const token = response.accessToken;
    login(token);

    try {
        const decodedToken = jwtDecode(response.idToken);
        const email = decodedToken.emails?.[0] ?? '';
        const userData = await loginWithAzure(email, response.account.homeAccountId, token);
        await loginPostInformation(userData);
    } catch (error) {
        console.error('🚫 Failed to process token response:', error);
    }
};

export const renewTokenIfNeeded = async (instance, token, login, loginPostInformation, navigate) => {
    if (!token) return;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const timeBuffer = 300;

        if (decodedToken.exp - currentTime < timeBuffer) {
            console.log('⏳ Token is near expiration. Renewing...');
            await renewToken(instance, login, loginPostInformation, navigate);
        }
    } catch (err) {
        console.error('❌ Failed to parse token:', err);
        navigate('/auth');
    }
};
