import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { calculateTax, calculateTotal } from './salesOrderUtils';
import { fetchSalesOrderById, saveSalesOrder, updateSalesOrder, downloadSalesOrderPdf, sendSalesOrderPdf } from '../../services/v2/SalesOrderService';
import { fetchOwnersPaginated } from '../../services/v2/ownerService';
// import { getProductsByCustomerId } from '../../services/v2/productService';
import { fetchPaginatedPricing } from '../../services/v2/PricingService';
import { formatISO, add } from 'date-fns';
import { fetchServiceTypes } from '../../services/v2/serviceTypesService';
import { useTranslation } from "react-i18next";
import useMapDocumentToSalesOrder from './useMapDocumentToSalesOrder';
import { usePermissions } from '../../context/PermissionsContext';

export const useSalesOrder = (salesOrderId, apiKey, organizationId, jwtToken, userId) => {
    const { t } = useTranslation();
    const location = useLocation();
    const state = location.state || {};
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [customersWithProducts, setCustomersWithProducts] = useState([]);
    const [pricingOptions, setPricingOptions] = useState([]);
    const today = new Date();
    const defaultIssueDate = formatISO(today);
    const defaultDueDate = formatISO(add(today, { days: 14 }));
    const { entitlements } = usePermissions();
    const initialSalesOrderData = {
        id: '',
        recipientType: '',
        customerId: '',
        companyName: '',
        cancel: false,
        creationMethod: 'manual',
        customerName: '',
        companyBRN: '',
        orderDate: defaultIssueDate,
        deliveryDate: defaultDueDate,
        totalAmount: 0,
        totalNetAmount: 0,
        taxAmount: 0,
        status: 'draft',
        organizationId: '',
        reminderLevel: 0,
        reminderId: '',
        alreadyReminded: false,
        rows: [],
        address: {
            street: '',
            zipCode: '',
            city: '',
            country: '',
            email: '',
        },
        references: [],
    };
    const entitlement = entitlements.find(entitlement => entitlement.isActive) || {};
    const isEquistab = entitlement.internalName?.includes('equistab');
    const isErp = entitlement.internalName?.includes('erp');
    const [salesOrderData, setSalesOrderData] = useState(initialSalesOrderData);
    const [isCancelled, setIsCancelled] = useState(false);
    const [showAddRows, setShowAddRows] = useState(false);

    const checkForCancellation = () => {
        if (salesOrderData.cancel) {
            setIsCancelled(true);
        } else {
            setIsCancelled(false);
        }
    };

    useEffect(() => {
        checkForCancellation();
    }, [salesOrderData.id, apiKey, organizationId, jwtToken]);

    const calculateTotals = () => {
        const totalAmount = calculateTotal(salesOrderData.rows);
        const taxAmount = calculateTax(salesOrderData.rows);

        setSalesOrderData((prevData) => ({
            ...prevData,
            totalAmount: totalAmount,
            taxAmount: taxAmount,
            totalNetAmount: totalAmount - taxAmount,
        }));
    };

    useEffect(() => {
        if (salesOrderId !== 'new') {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const data = await fetchSalesOrderById(apiKey, organizationId, jwtToken, salesOrderId, userId);
                    setSalesOrderData(data);
                    setLoading(false);
                } catch (err) {
                    setError(err.message);
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [salesOrderId, apiKey, organizationId, jwtToken, userId]);

    useEffect(() => {
        async function fetchAndSetCustomers() {
            try {
                const customersData = await fetchOwnersPaginated(apiKey, organizationId, jwtToken, { pageSize: 1000 });
                setCustomersWithProducts(customersData.items);
            } catch (error) {
                console.error(error);
            }
        }
        fetchAndSetCustomers();
    }, [apiKey, organizationId, jwtToken]);

    useEffect(() => {
        const fetchPricing = async () => {
            try {
                const pricingData = await fetchPaginatedPricing(apiKey, organizationId, jwtToken, { pageSize: 1000 });
                setPricingOptions(pricingData.items);
            } catch (error) {
                console.error('Error fetching pricing settings:', error);
            }
        };
        fetchPricing();
    }, [apiKey, organizationId, jwtToken]);

    useEffect(() => {
        calculateTotals();
    }, [salesOrderData.rows]);

    useMapDocumentToSalesOrder(state, initialSalesOrderData, setSalesOrderData, salesOrderData);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = [...salesOrderData.rows];
        const row = updatedRows[index];

        // Parse input values to numeric types where applicable
        let numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
            numericValue = 0; // Default to 0 if the value is not a valid number
        }

        if (['quantity', 'bruttoPrice', 'discountAmount', 'discountPercentage', 'taxRate', 'netPrice'].includes(name)) {
            row[name] = numericValue;
        } else {
            row[name] = value; // Handle non-numeric fields normally
        }

        if (['discountPercentage', 'discountAmount'].includes(name)) {
            const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
            let discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
            let discountAmount = parseFloat(row.discountAmount) || 0;

            if (name === 'discountPercentage') {
                discountAmount = parseFloat((bruttoPrice * discountPercentage).toFixed(2));
                row.discountAmount = discountAmount;
            } else if (name === 'discountAmount') {
                discountPercentage = bruttoPrice !== 0 ? parseFloat((discountAmount / bruttoPrice).toFixed(2)) : 0;
                row.discountPercentage = (discountPercentage * 100).toFixed(2);
            }
        }

        if (['netPrice', 'bruttoPrice', 'quantity', 'discountPercentage', 'discountAmount', 'taxRate'].includes(name)) {
            const quantity = parseFloat(row.quantity) || 0;
            const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
            const taxRate = parseFloat(row.taxRate) / 100 || 0;
            const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
            const discountAmount = parseFloat(row.discountAmount) || 0;
            const netPrice = parseFloat(row.netPrice) || 0;

            if (name === 'netPrice') {
                // Calculate bruttoPrice based on netPrice
                row.bruttoPrice = parseFloat((netPrice * (1 + taxRate) + discountAmount).toFixed(2));
            } else {
                // Calculate netPrice based on bruttoPrice
                const discountedPrice = parseFloat((bruttoPrice - discountAmount).toFixed(2));
                row.netPrice = parseFloat((discountedPrice / (1 + taxRate)).toFixed(2));
            }

            // Update rowTotal based on quantity and discountedPrice
            const finalDiscountedPrice = parseFloat((row.bruttoPrice - discountAmount).toFixed(2));
            const rowTotal = parseFloat((quantity * finalDiscountedPrice).toFixed(2));
            row.rowTotal = rowTotal;
        }

        setSalesOrderData((prevData) => ({
            ...prevData,
            rows: updatedRows,
        }));

        calculateTotals();
    };

    const removeRow = (index) => {
        const updatedRows = [...salesOrderData.rows];
        updatedRows.splice(index, 1);
        setSalesOrderData({ ...salesOrderData, rows: updatedRows });
        calculateTotals();
    };

    const addRow = (row) => {
        if (!row) {
            setSalesOrderData({
                ...salesOrderData,
                rows: [...salesOrderData.rows, {
                    description: '',
                    rowNumber: '',
                    type: '',
                    quantity: 0,
                    taxRate: 0,
                    bruttoPrice: 0,
                    netPrice: 0,
                    discountPercentage: 0,
                    discountAmount: 0,
                    rowTotal: 0,
                    itemId: '',
                    organizationId: organizationId
                }]
            });
        }
        else {
            setSalesOrderData({ ...salesOrderData, rows: [...salesOrderData.rows, row] });
        }
    };

    const saveOrUpdateSalesOrder = async (setSnackbar) => {
        setLoading(true);
        calculateTotals();
        try {
            let responseData;
            if (salesOrderId !== 'new') {
                responseData = await updateSalesOrder(salesOrderData, apiKey, organizationId, jwtToken, userId);
            } else {
                responseData = await saveSalesOrder(salesOrderData, apiKey, organizationId, jwtToken, userId);
            }
            setSalesOrderData(initialSalesOrderData);
            setLoading(false);
            setSnackbar({ open: true, message: t("salesOrderDetailPage.saveSuccess"), severity: 'success' });
            navigate('/sales-process?tab=1');
            return true;
        } catch (err) {
            setLoading(false);
            setError(err.message);
            setSnackbar({ open: true, message: t("salesOrderDetailPage.saveError"), severity: 'error' });
            return false;
        }
    };

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');
        if (nameParts.length === 2) {
            const [addressKey, field] = nameParts;
            setSalesOrderData(prevData => ({
                ...prevData,
                [addressKey]: {
                    ...prevData[addressKey],
                    [field]: value,
                },
            }));
        }
    };

    const getHeaderAndAddressData = (selectedCustomer) => {
        if (!selectedCustomer) {
            return {
                customerName: '',
                companyName: '',
                companyBRN: '',
                recipientType: '',
                customerAddress: {
                    street: '',
                    zipCode: '',
                    city: '',
                    country: '',
                    email: '',
                }
            };
        }

        const headerAndAddress = {
            customerName: `${selectedCustomer.firstname} ${selectedCustomer.surname}`,
            companyName: selectedCustomer.ownerType === 'company' ? selectedCustomer.companyName : '',
            companyBRN: selectedCustomer.ownerType === 'company' ? selectedCustomer.companyBRN : '',
            recipientType: selectedCustomer.ownerType,
            customerAddress: {
                street: selectedCustomer.address.street || '',
                zipCode: selectedCustomer.address.zipCode || '',
                city: selectedCustomer.address.city || '',
                country: selectedCustomer.address.country || '',
                email: selectedCustomer.email || '',
            }
        };

        return headerAndAddress;
    };

    const getRowsforServices = async (userId, defaultIssueDate, customerId, apiKey, organizationId, jwtToken, pricingOptions) => {
        let serviceRows = [];
        try {
            const ConvertedDate = new Date(defaultIssueDate);
            const selectedDate = new Date(ConvertedDate.getFullYear(), ConvertedDate.getMonth(), 1);
            const offset = selectedDate.getTimezoneOffset();
            const adjustedDate = new Date(selectedDate.getTime() - (offset * 60 * 1000));
            const date = adjustedDate.toISOString().slice(0, 10);
            const serviceTypes = await fetchServiceTypes(apiKey, organizationId, jwtToken);
            const pricingSettingsResponse = await fetchPaginatedPricing(apiKey, organizationId, jwtToken, { pageSize: 1000 });
            const pricingSettings = pricingSettingsResponse.items;
            // Fetch bookings or services as needed and construct service rows
            // This part needs to be implemented based on your specific requirements
            // For example:
            // const bookings = await fetchBookings(userId, date, customerId, apiKey, organizationId, jwtToken);
            // serviceRows = bookings.map(booking => ({
            //     description: booking.serviceType,
            //     quantity: booking.quantity,
            //     taxRate: booking.taxRate,
            //     bruttoPrice: booking.bruttoPrice,
            //     netPrice: booking.netPrice,
            //     rowTotal: booking.rowTotal,
            //     type: 'Service',
            //     organizationId: organizationId,
            // }));

            // Placeholder implementation:
            serviceRows = serviceTypes.map(serviceType => ({
                description: serviceType.name,
                quantity: 1,
                taxRate: pricingSettings.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.VAT || 0,
                bruttoPrice: pricingSettings.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.bruttoPrice || 0,
                netPrice: pricingSettings.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.nettoPrice || 0,
                rowTotal: pricingSettings.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.bruttoPrice || 0,
                type: 'Service',
                organizationId: organizationId,
            }));
        } catch (error) {
            console.error('Error getting rows for services:', error);
        }
        return serviceRows;
    };

    const addServiceRow = async () => {
        try {
            setLoading(true);
            const serviceRows = await getRowsforServices(userId, defaultIssueDate, salesOrderData.customerId, apiKey, organizationId, jwtToken, pricingOptions);
            setSalesOrderData(prevData => ({
                ...prevData,
                rows: [...prevData.rows, ...serviceRows],
            }));
        } catch (error) {
            console.error('Error adding service row:', error);
        } finally {
            setLoading(false);
        }
    };

    const addProductRow = async () => {
        try {
            setLoading(true);
            // Implement fetching and adding product rows as needed
            // Example:
            // const productRows = await getRowsForProducts(salesOrderData.customerId, pricingOptions, apiKey, organizationId, jwtToken);
            // setSalesOrderData(prevData => ({
            //     ...prevData,
            //     rows: [...prevData.rows, ...productRows],
            // }));
        } catch (error) {
            console.error('Error adding product row:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCustomerChange = (event, newValue, reason) => {
        console.log('Customer change:', newValue, reason);
        if (reason === 'selectOption' && newValue && typeof newValue === 'object') {
            setShowAddRows(isEquistab);
            const selectedCustomer = newValue; // Since options are full customer objects
            const { customerName, companyName, companyBRN, recipientType, customerAddress } = getHeaderAndAddressData(selectedCustomer);
            setSalesOrderData(prevData => ({
                ...prevData,
                customerId: selectedCustomer.id,
                customerName: customerName,
                companyName: companyName,
                companyBRN: companyBRN,
                recipientType: recipientType,
                address: customerAddress,
            }));
        } else if (reason === 'clear') {
            setSalesOrderData(prevData => ({
                ...prevData,
                customerId: '',
                customerName: '',
                companyName: '',
                companyBRN: '',
                recipientType: '',
                address: {
                    street: '',
                    zipCode: '',
                    city: '',
                    country: '',
                    email: '',
                }
            }));
            setShowAddRows(false);
        } else if (reason === 'create-option' || reason === 'input') {
            setShowAddRows(false);
            setSalesOrderData(prevData => ({
                ...prevData,
                customerId: '',
                customerName: newValue || '',
                companyName: '',
                companyBRN: '',
                recipientType: '',
                address: {
                    street: '',
                    zipCode: '',
                    city: '',
                    country: '',
                    email: '',
                }
            }));
        }
    };

    const handleDownloadPdf = async (salesOrderId) => {
        try {
            const pdfBlob = await downloadSalesOrderPdf(apiKey, organizationId, jwtToken, salesOrderId, userId);
            const blobUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `sales-order-${salesOrderData.salesOrderNumber}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading sales order PDF:', error);
        }
    };

    const handleSendPdf = async (salesOrderId) => {
        try {
            await sendSalesOrderPdf(apiKey, organizationId, jwtToken, salesOrderId, userId);
        } catch (error) {
            console.error('Error sending sales order PDF:', error);
        }
    };

    const handleCancel = async () => {
        try {
            const response = await updateSalesOrder({ ...salesOrderData, cancel: true }, apiKey, organizationId, jwtToken, userId);
            setSalesOrderData(response);
            navigate('/sales-process?tab=1');
        } catch (error) {
            console.error('Error cancelling sales order:', error);
        }
    };

    const onTransfer = (documentId, documentType, targetType, targetTypeURL) => {
        navigate(`/sales-process/${targetTypeURL}/detail/new`, {
            state: {
                fromDocument: {
                    sourceDocumentType: documentType,
                    ...salesOrderData,
                    references: [
                        {
                            sourceDocumentType: documentType,
                            sourceDocumentId: documentId,
                            targetDocumentType: targetType,
                            targetDocumentId: '',
                        }
                    ],
                },
            },
        });
    };

    return {
        salesOrderData,
        customersWithProducts,
        isCancelled,
        showAddRows,
        addProductRow,
        addServiceRow,
        handleCustomerChange,
        handleDownloadPdf,
        handleSendPdf,
        setSalesOrderData,
        calculateTotals,
        handleInputChange,
        handleAddressChange,
        handleCancel,
        saveOrUpdateSalesOrder,
        removeRow,
        addRow,
        loading,
        error,
        onTransfer,
    };
};
