
import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Typography, Button, DialogActions, Grid, ListItemButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDateLocale } from '../hooks/useDateLocale';
import { format } from 'date-fns';
import { markBookingsAsPaid } from '../services/v2/bookingService';
import SnackbarAlert from './SnackbarAlert';
import { useAuth } from '../context/AuthContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

const RidingLessonPaymentDialog = ({ open, onClose, selectedRider = {} }) => {
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const [loading, setLoading] = useState(false);
    const dateLocale = useDateLocale();
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // Possible values: 'error', 'warning', 'info', 'success'
    });
    const { t } = useTranslation();

    const formatDateTime = (dateTimeString) => {
        return format(new Date(dateTimeString), 'P', { locale: dateLocale })

    };
    const sumPrices = (bookings) => bookings.reduce((total, booking) => total + (parseFloat(booking.pricing?.unitPrice) || 0), 0);
    const isAnySelectedUnpaid = selectedRider.lessons.flatMap(lesson =>
        lesson.bookings.filter(booking => selectedBookings.includes(booking.id) && !booking.isPaid)
    ).length > 0;

    const totalPaid = sumPrices(selectedRider.lessons.flatMap(lesson => lesson.bookings.filter(booking => booking.isPaid)));
    const totalUnpaid = sumPrices(selectedRider.lessons.flatMap(lesson => lesson.bookings.filter(booking => !booking.isPaid)));
    const selectedTotalSum = sumPrices(selectedRider.lessons.flatMap(lesson => lesson.bookings.filter(booking => selectedBookings.includes(booking.id))));

    const handleBookingSelection = (bookingId) => {
        const booking = selectedRider.lessons.flatMap(lesson => lesson.bookings).find(b => b.id === bookingId);
        if (booking && booking.isPaid) {
            // Ignore selection if booking is already paid
            return;
        }

        setSelectedBookings(currentSelected => {
            if (currentSelected.includes(bookingId)) {
                return currentSelected.filter(id => id !== bookingId);
            } else {
                return [...currentSelected, bookingId];
            }
        });
    };


    // Function to close the Snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway' && !loading) {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };


    // New function to mark selected bookings as paid
    const handleMarkAsPaid = async () => {
        try {
            setLoading(true);
            // Call the service function with the selected bookings
            await markBookingsAsPaid(selectedBookings, apiKey, organizationId, jwtToken);
            setSnackbar({
                open: true,
                message: t('RidingLessonPaymentDialog.markedAsPaidSuccess'),
                severity: 'success',
            });
            setSelectedBookings([]); // Reset selection
            onClose(); // Close the dialog
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const closeDialog = () => {
        setSelectedBookings([]); // Reset selection
        setSnackbar({ ...snackbar, open: false });
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                {t('RidingLessonPaymentDialog.title', { riderName: selectedRider.riderName })}
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {selectedRider.lessons.map((lesson, index) => (
                    <React.Fragment key={index}>
                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            {lesson.serviceType} {t('RidingLessonPaymentDialog.teacher')} - {lesson.teacher}
                        </Typography>
                        <List>
                            {lesson.bookings.map((booking, index) => (
                                <ListItemButton
                                    key={index}
                                    divider
                                    selected={selectedBookings.includes(booking.id)} // Assuming each booking has a unique 'id'
                                    onClick={() => handleBookingSelection(booking.id)}
                                    disabled={booking.isPaid}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        {booking.isPaid && (
                                            <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                        )}
                                        <ListItemText
                                            primary={`${t('RidingLessonPaymentDialog.date')}: ${formatDateTime(booking.dateTime)} - ${t('RidingLessonPaymentDialog.horse')}: ${booking.horseName}`}
                                            secondary={`${t('RidingLessonPaymentDialog.price')}: ${booking.pricing?.unitPrice ?? t('RidingLessonPaymentDialog.notSet')}`}
                                        />
                                    </Box>
                                </ListItemButton >
                            ))}
                        </List>
                    </React.Fragment>
                ))}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" gutterBottom>
                            {t('RidingLessonPaymentDialog.totalAmounts')}
                        </Typography>
                        <Typography variant="body2">{t('RidingLessonPaymentDialog.totalPaid')}: {totalPaid.toFixed(2)}</Typography>
                        <Typography variant="body2">{t('RidingLessonPaymentDialog.totalUnPaid')}: {totalUnpaid.toFixed(2)}</Typography>
                        <Typography variant="body2">{t('RidingLessonPaymentDialog.totalSelected')}: {selectedTotalSum.toFixed(2)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleMarkAsPaid}
                            color="primary"
                            variant="contained"
                            disabled={!isAnySelectedUnpaid || loading} >
                            {t('RidingLessonPaymentDialog.markAsPaidButton')} {selectedBookings.length}
                        </Button>
                        <Button onClick={closeDialog} variant="outlined" disabled={loading}>{t('RidingLessonPaymentDialog.cancelButton')}</Button>
                    </Grid>
                </Grid>
            </DialogActions>
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Dialog>
    );
};

export default RidingLessonPaymentDialog;