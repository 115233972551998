import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchBoxesPaginated } from '../../services/v2/BoxService'; // This service needs to be implemented
import { useAuth } from '../../context/AuthContext';

const BoxSettings = () => {
    const navigate = useNavigate();
    const [boxes, setBoxes] = useState([]);
    const { apiKey, token, organizationId } = useAuth();

    useEffect(() => {
        // Fetch box types from the backend and set the local state
        fetchBoxesPaginated(apiKey, organizationId, token, { pageSize: 1000 }).then(data => setBoxes(data.items || []))
            .catch(err => console.error("Failed to fetch box types:", err));
    }, []);

    const handleRowClick = (id) => {
        navigate(`/organization-settings/boxes/edit/${id}`);
    };

    const handleAddNewClick = () => {
        navigate('/organization-settings/boxes/new');
    };

    return (
        <div>
            <h1>Box Settings</h1>
            <Button variant="contained" color="primary" onClick={handleAddNewClick}>
                Add New Box Type
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {boxes.map((box) => (
                            <TableRow key={box.id} hover onClick={() => handleRowClick(box.id)} style={{ cursor: 'pointer' }}>
                                <TableCell>{box.name}</TableCell>
                                <TableCell>{box.location}</TableCell>
                                <TableCell>{box.amount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default BoxSettings;
