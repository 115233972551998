import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import KingBedIcon from '@mui/icons-material/KingBed';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import DoorSlidingIcon from '@mui/icons-material/DoorSliding'; // or any door icon
import { CellTypes } from './stableConstants';

/**
 * Cell displays content for either an empty space, a stall with a horse, or a room
 * and listens for clicks (to set as "selected") or drags/drops.
 */
const Cell = ({ cell, onClick, isSelected }) => {
  const handleClick = (e) => {
    // Prevent default text selection, etc.
    e.preventDefault();
    onClick(cell);
  };

  const renderIcon = () => {
    switch (cell.type) {
      case CellTypes.STALL:
        return <KingBedIcon sx={{ opacity: 0.6 }} />;
      case CellTypes.ROOM:
        // Example: tack room => door icon, otherwise a warehouse icon
        if (cell.room?.type === 'tack') {
          return <DoorSlidingIcon sx={{ opacity: 0.6 }} />;
        }
        return <WarehouseIcon sx={{ opacity: 0.6 }} />;
      default:
        return null;
    }
  };

  const getCellContent = () => {
    if (cell.type === CellTypes.EMPTY) {
      return (
        <Typography variant="body2" color="text.secondary">
          +
        </Typography>
      );
    }

    if (cell.type === CellTypes.STALL && cell.horse) {
      return (
        <Stack spacing={0.5} alignItems="center">
          {renderIcon()}
          <Typography variant="caption" fontWeight="bold">
            {cell.horse.name}
          </Typography>
        </Stack>
      );
    }

    if (cell.type === CellTypes.ROOM && cell.room) {
      return (
        <Stack spacing={0.5} alignItems="center">
          {renderIcon()}
          <Typography variant="caption" fontWeight="bold">
            {cell.room.name}
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
            {cell.room.type}
          </Typography>
        </Stack>
      );
    }

    // Default fallback
    return <>{renderIcon()}</>;
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: isSelected ? 'primary.main' : 'grey.300',
        backgroundColor:
          cell.type === CellTypes.EMPTY
            ? 'grey.50'
            : cell.type === CellTypes.STALL
            ? '#FFF9F2'
            : '#F2F8FF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      {getCellContent()}
    </Box>
  );
};

export default Cell;
