//src/components/OrgTabs/PricingForm.js

import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Container, FormControlLabel, Switch } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchPricingById, savePricing, updatePricing, deletePricing } from '../../services/v2/PricingService'; // Implement these services
import { useAuth } from '../../context/AuthContext';
import LinearProgress from '@mui/material/LinearProgress';
import { fetchPaginatedMachineSettings } from '../../services/v2/MachineSettingsService';
import { fetchBoxesPaginated } from '../../services/v2/BoxService';
import { fetchPaginatedServiceTypes } from '../../services/v2/serviceTypesService';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const PricingForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const mode = id ? 'edit' : 'add';
    const { apiKey, token, organizationId } = useAuth();
    const [loading, setLoading] = useState(false);
    const [combinedOptions, setCombinedOptions] = useState([]);
    const currencies = ['EUR', 'USD'];

    const [pricingData, setPricingData] = useState({
        pricingId: '',
        description: '',
        currency: '',
        bruttoPrice: '',
        referenceId: '',
        referencePartition: '',
        VAT: '',
        nettoPrice: '',
        active: false,
    });

    useEffect(() => {
        if (mode === 'edit') {
            setLoading(true);
            fetchPricingById(apiKey, organizationId, token, id)
                .then(data => {
                    setPricingData(data);
                })
                .catch(err => console.error("Failed to fetch pricing by ID:", err))
                .finally(() => setLoading(false));
        }
    }, [mode, id, apiKey, organizationId, token]);

    useEffect(() => {
        Promise.all([
            fetchBoxesPaginated(apiKey, organizationId, token, { pageSize: 1000 }),
            fetchPaginatedMachineSettings(apiKey, organizationId, token, { pageSize: 1000 }),
            fetchPaginatedServiceTypes(apiKey, organizationId, token, { pageSize: 1000 }),
        ]).then(([boxes, machines, serviceTypes]) => {
            const mappedBoxes = boxes.items?.map(box => ({
                id: box.id, // Assuming each box has a unique id
                type: 'Box',
                name: box.name,
                attribute: box.location // Or any other property that you consider an attribute
            }));

            const mappedMachines = machines.items?.map(machine => ({
                id: machine.id, // Assuming each machine has a unique id
                type: 'Machine',
                name: machine.machineType,
                attribute: machine.machineDescription // Or any other property that you consider an attribute
            }));

            const mappedServiceTypes = serviceTypes.items?.map(serviceType => ({
                id: serviceType.id, // Assuming each service type has a unique id
                type: 'Service',
                name: serviceType.name,
                attribute: serviceType.description // Or any other property that you consider an attribute
            }));

            setCombinedOptions([...mappedBoxes, ...mappedMachines, ...mappedServiceTypes]);
        }).catch(console.error);
    }, [apiKey, organizationId, token]);

    useEffect(() => {
        // Recalculate netto whenever brutto or VAT changes
        if (pricingData.bruttoPrice && pricingData.VAT) {
            const brutto = parseFloat(pricingData.bruttoPrice);
            const VAT = parseFloat(pricingData.VAT);
            const netto = brutto / (1 + VAT / 100);
            setPricingData({ ...pricingData, nettoPrice: netto.toFixed(2) });
        }
    }, [pricingData.bruttoPrice, pricingData.VAT]);

    const handleBruttoChange = (event) => {
        const { value } = event.target;
        // Allow only numbers and dot
        if (/^\d*\.?\d*$/.test(value)) {
            handleChange(event);
        }
    };

    const handleVATChange = (event) => {
        const { value } = event.target;
        // Allow only numbers and do not allow more than two digits
        if (/^\d{0,2}$/.test(value)) {
            handleChange(event);
        }
    };

    const handleSelectionChange = (event) => {
        const selectedOption = combinedOptions.find(option => option.id === event.target.value);
        if (selectedOption) {
            setPricingData({
                ...pricingData,
                referenceId: selectedOption.id,
                referencePartition: selectedOption.type,
            });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPricingData({
            ...pricingData,
            [name]: value,
        });
    };

    const handleActiveChange = (event) => {
        setPricingData({
            ...pricingData,
            active: event.target.checked,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const action = mode === 'edit' ? updatePricing : savePricing;
        action(pricingData, apiKey, organizationId, token)
            .then(() => {
                navigate('/organization-settings?tab=pricing-settings');
            })
            .catch(err => {
                console.error('Failed to save or update pricing:', err);
            });
    };

    const handleDelete = () => {
        deletePricing(pricingData.pricingId, apiKey, organizationId, token)
            .then(() => {
                //navigate('/organization-settings/pricing');
                navigate('/organization-settings?tab=pricing-settings');
            })
            .catch(err => {
                console.error('Failed to delete pricing:', err);
            });
    };

    return (
        <Container component={Paper} style={{ padding: '2em' }}>
            {loading && <LinearProgress />}
            <h1>{mode === 'add' ? 'Add New Pricing' : 'Edit Pricing'}</h1>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="combined-selector-label">Machine, Box or Service</InputLabel>
                    <Select
                        labelId="combined-selector-label"
                        id="combined-selector"
                        value={pricingData.referenceId || ''}
                        label="Machine, Box or Service"
                        name="combinedSelection"
                        onChange={handleSelectionChange}
                    >
                        {combinedOptions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {`${option.name} (${option.type}) - ${option.attribute}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField name="description" label="Description" variant="outlined" margin="normal" value={pricingData.description} fullWidth onChange={handleChange} />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="currency-selector-label">Currency</InputLabel>
                    <Select
                        labelId="currency-selector-label"
                        id="currency-selector"
                        value={pricingData.currency || ''}
                        label="Currency"
                        name="currency"
                        onChange={handleChange}
                    >
                        {currencies.map((currency) => (
                            <MenuItem key={currency} value={currency}>
                                {currency}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    name="bruttoPrice"
                    label="Brutto Price"
                    variant="outlined"
                    margin="normal"
                    value={pricingData.bruttoPrice}
                    onChange={handleBruttoChange}
                    fullWidth
                />
                <TextField
                    name="VAT"
                    label="VAT"
                    variant="outlined"
                    margin="normal"
                    value={pricingData.VAT}
                    onChange={handleVATChange}
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
                <TextField
                    name="nettoPrice"
                    label="Netto Price"
                    variant="outlined"
                    margin="normal"
                    value={pricingData.nettoPrice}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <FormControlLabel
                    control={<Switch checked={pricingData.active} onChange={handleActiveChange} name="active" />}
                    label="Active"
                />
                <Button type="submit" variant="contained" color="primary" sx={{ marginRight: 2 }}>
                    Save
                </Button>
                {mode === 'edit' && (
                    <Button variant="contained" color="error" onClick={handleDelete}>
                        Delete
                    </Button>
                )}
            </form>
        </Container>
    );
};

export default PricingForm;
