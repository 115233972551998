import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/v2/farmLayout';

export async function fetchFarmLayout(apiKey, organizationId, jwtToken, locationId, buildingId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL, {
    params: {
      locationId,
      buildingId,
    },
  });
  return response.data;
}

export async function saveFarmLayout(apiKey, organizationId, jwtToken, data) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const { id, layoutData, locationId, buildingId } = data;

  if (id) {
    // Update existing layout (use PUT for updates)
    const response = await axiosInstance.put(`${API_BASE_URL}?id=${id}`, { layoutData, locationId, buildingId });
    return response.data;
  } else {
    // Create new layout (use POST for creation)
    const response = await axiosInstance.post(API_BASE_URL, { layoutData, locationId, buildingId });
    return response.data;
  }
}

export async function deleteFarmLayout(apiKey, organizationId, jwtToken, id) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.delete(`${API_BASE_URL}/${id}`);
  return response.data;
}

export default {
  fetchFarmLayout,
  saveFarmLayout,
  deleteFarmLayout,
};
