// src/pages/inventoryManagement/inventoryManagement/InventoryTransactions.js

import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTheme, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';

import { useAuth } from '../../../context/AuthContext';
import { usePreferences } from '../../../context/UserPreferencesContext';
import { useTranslation } from 'react-i18next';
import { useDateLocale } from '../../../hooks/useDateLocale';

// Replace direct calls with a new paginated approach:
import { fetchPaginatedTransactions } from '../../../services/v2/transactionService';
import { fetchPaginatedItems } from '../../../services/v2/itemService';

// DataViewWrapper for table/card switching
import DataViewWrapper from '../../../components/DataViewWrapper';
// Custom pagination hook
import { usePaginatedData } from '../../../hooks/usePaginatedData';

const InventoryTransactions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dateLocale = useDateLocale();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { apiKey, organizationId, token: jwtToken } = useAuth();
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // ------------------ PAGINATED HOOK FOR TRANSACTIONS ------------------
  const {
    items: transactions,
    loading: transactionsLoading,
    continuationToken: transactionsContinuation,
    loadNextPage: loadNextTransactionsPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) =>
      fetchPaginatedTransactions(apiKey, organizationId, jwtToken, options),
    { pageSize: 25 }
  );

  // We'll fetch items in a separate paginated hook
  const {
    items,
    loading: itemsLoading,
    continuationToken: itemsContinuation,
    loadNextPage: loadNextItemsPage,
  } = usePaginatedData(
    (options) =>
      fetchPaginatedItems(apiKey, organizationId, jwtToken, options),
    { pageSize: 100 }
  );

  // We'll store the merged list in local state.
  const [mergedTransactions, setMergedTransactions] = useState([]);

  // Local view mode
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode || (isMobile ? 'card' : 'table')
  );

  // Combine (merge) transactions with items on each update
  useEffect(() => {
    const updated = transactions.map((transaction) => {
      const item = items.find((i) => i.id === transaction.itemId);
      return {
        ...transaction,
        dateDisplay: format(new Date(transaction.date), 'P', { locale: dateLocale }),
        itemName: item ? item.name : 'Unknown Item',
      };
    });
    setMergedTransactions(updated);
  }, [transactions, items, dateLocale]);

  // Keep local view mode in sync with user preferences
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const toggleViewMode = () => {
    const newMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newMode);
    updateDevicePreferences('viewMode', newMode);
  };

  const handleAddNewTransaction = () => {
    navigate('/inventory/transaction/new');
  };

  const handleRowClick = (transactionId) => {
    navigate(`/inventory/transaction/${transactionId}`);
  };

  const translateType = (type) => {
    return t(`TransactionManagement.${type}`);
  };

  // We simply inline the transformation logic now, no useMemo:
  const transformData = {
    headers: [
      {
        key: 'itemName',
        label: t('InventoryTransactions.item'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'date',
        label: t('InventoryTransactions.date'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'type',
        label: t('InventoryTransactions.type'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'quantity',
        label: t('InventoryTransactions.quantity'),
        display: { value: true, chipColor: false },
      },
    ],
    rows: mergedTransactions.map((transaction) => ({
      id: transaction.id,
      itemName: { value: transaction.itemName },
      date: { value: transaction.dateDisplay },
      type: { value: translateType(transaction.type) },
      quantity: { value: transaction.quantity },
    })),
  };

  const isLoading = transactionsLoading || itemsLoading;

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>

      {isLoading ? (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {/* DataViewWrapper for table/card mode, with optional filter/sort */}
          <DataViewWrapper
            structure={transformData}
            viewMode={viewMode}
            onViewModeToggle={toggleViewMode}
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            onRowClick={handleRowClick}
            pageId="/inventory/transactions"
            toolbarActions={[
              <Button
                key="add-transaction"
                variant="contained"
                color="primary"
                onClick={handleAddNewTransaction}
              >
                {t('InventoryTransactions.addNewTransaction')}
              </Button>,
            ]}
          />

          {/* If there's more transactions to fetch, show Load More */}
          {transactionsContinuation && (
            <Box mt={2} textAlign="center">
              <Button
                variant="contained"
                onClick={loadNextTransactionsPage}
                disabled={isLoading}
              >
                {t('common.loadMore')}
              </Button>
            </Box>
          )}

          {/* If there's more items to fetch, show load more for items (optional) */}
          {itemsContinuation && (
            <Box mt={2} textAlign="center">
              <Button
                variant="contained"
                onClick={loadNextItemsPage}
                disabled={isLoading}
              >
                {t('common.loadMoreItems')}
              </Button>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};

export default InventoryTransactions;
