// src/pages/ownerDetailsPage/OwnerDetails.js
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import { getOwnerById, updateOwner, createOwner, inviteOwnerByEmail, deleteOwner } from '../../services/v2/ownerService';
import { Button, Box, Typography, Container, Tabs, Tab, Paper, Snackbar, FormControlLabel, Checkbox } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { getUserByEmail, activateUser, deactivateUser, deleteUser } from '../../services/v2/userService';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OwnerInfoTab from './OwnerInfoTab';
import OwnerAddressTab from './OwnerAddressTab';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OwnerDetails = () => {
  const initialOwnerState = {
    firstname: '',
    surname: '',
    email: '',
    number: '',
    birthday: '',
    ownerType: 'private',  // Default to 'private'
    companyName: '',
    companyBRN: '',
    entityType: [],
    address: { street: "", zipCode: "", city: "", country: "" }
  };
  const entityTypeOptions = ["owner", "customer", "vendor"];
  const { ownerId } = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [owner, setOwner] = useState(initialOwnerState);
  const [user, setUser] = useState(null);  // Start with null so we know when loading is done
  const [loading, setLoading] = useState(true);  // New loading state
  const { t } = useTranslation();  // Hook for translation
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteUserWithOwner, setDeleteUserWithOwner] = useState(false);




  const { apiKey, organizationId, token } = useAuth();
  const jwtToken = token;


  useEffect(() => {
    console.log("Running owner fetch useEffect");
    setLoading(true);
    const fetchOwnerData = async () => {
      if (ownerId) {
        console.log("About to fetch owner data");
        const data = await getOwnerById(ownerId, apiKey, organizationId, jwtToken);
        if (data) {
          if (data.birthday) {
            data.birthday = new Date(data.birthday); // Convert back to Date object
          }
          setOwner(
            {
              ...initialOwnerState,
              ...data,
            }
          );
          try {
            const userdata = await getUserByEmail(data.email, apiKey, organizationId, jwtToken);
            if (userdata) {
              setUser(userdata);
            }
          } catch (error) {
            console.log("Error fetching user data:", error);
          }
        }
        else {
          console.log("No owner data received.");
        }
      }
      else {
        console.log("No owner ID.");
      }
      setLoading(false);  // Loading done after owner state update
    };
    fetchOwnerData();
  }, [ownerId, apiKey, organizationId, jwtToken]);

  // Close snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDelete = async () => {
    try {
      if (deleteUserWithOwner && user) {
        await deleteUser(user.id, apiKey, organizationId, jwtToken);
      }
      await deleteOwner(ownerId, apiKey, organizationId, jwtToken);
      setSnackbarMessage(t('OwnerDetails.deleteSuccess'));
      setSnackbarOpen(true);
      navigate('/owners');
    } catch (error) {
      // Handle error (show error message, etc.)
      setSnackbarMessage(t('OwnerDetails.deleteError'));
      setSnackbarOpen(true);
    }
  };



  const handleInvite = async () => {
    if (owner && owner.email) {
      await inviteOwnerByEmail(ownerId, apiKey, organizationId, jwtToken);
      setSnackbarMessage(t('OwnerDetails.inviteSuccess'));
      setSnackbarOpen(true);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Extract the fields from the owner object
    const { firstname, surname, email, number, birthday, ownerType, companyName, companyBRN, entityType } = owner;

    // Create a newOwner object, and conditionally add the birthday
    const newOwner = {
      id: ownerId || uuidv4(),
      firstname,
      surname,
      email,
      number,
      ownerType,
      companyName,
      companyBRN,
      entityType,
      address: owner.address || { street: "", zipCode: "", city: "", country: "" }
    };

    if (owner.birthday) {
      newOwner.birthday = owner.birthday.toISOString();
    } else {
      newOwner.birthday = "";
    }



    // Decide to update or create based on whether an ownerId is present
    if (ownerId) {
      await updateOwner(ownerId, newOwner, apiKey, organizationId, jwtToken);
    } else {
      await createOwner(newOwner, apiKey, organizationId, jwtToken);
    }

    navigate('/owners');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOwnerChange = (updatedOwner) => {
    setOwner(updatedOwner);
  };

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6">{t('OwnerDetails.waitingText')}</Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <><Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
        <Typography variant="h4" gutterBottom>
          <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
          {ownerId ? t('OwnerDetails.editOwner') : t('OwnerDetails.addOwner')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
          <form onSubmit={handleSubmit}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label={t('OwnerDetails.InfoTabName')} />
              <Tab label={t('OwnerDetails.AddressTabName')} />
            </Tabs>
            {tabValue === 0 && <OwnerInfoTab owner={owner} setOwner={setOwner} />}
            {tabValue === 1 && <OwnerAddressTab owner={owner} setOwner={setOwner} />}
            <Box sx={{ mt: 2 }}>
              <Button type="submit" variant="contained" color="primary">
                {ownerId ? t('OwnerDetails.UpdateOwner') : t('OwnerDetails.addOwner')}
              </Button>
            </Box>
            {ownerId && owner && !user && (
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" color="secondary" onClick={handleInvite}>
                  {t('OwnerDetails.Invite')}
                </Button>
              </Box>
            )}
            {ownerId && owner && (
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" color="error" onClick={() => setDialogOpen(true)}>
                  {t('OwnerDetails.Delete')}
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{t('OwnerDetails.deleteTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('OwnerDetails.deleteText')}
          </DialogContentText>
          {user && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={deleteUserWithOwner}
                  onChange={(e) => setDeleteUserWithOwner(e.target.checked)}
                  color="primary"
                />
              }
              label={t('OwnerDetails.deleteUserCheckbox')}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            {t('OwnerDetails.cancel')}
          </Button>
          <Button
            onClick={() => {
              handleDelete();
              setDialogOpen(false);
            }}
            color="secondary">
            {t('OwnerDetails.confirm')}
          </Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      />
    </Container>
    </>
  );
};

export default OwnerDetails;
