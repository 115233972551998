// src/components/SuggestionModal.js

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    Chip,
    IconButton,
    Select,
    MenuItem,
    Card,
    CardContent,
    Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CategoryIcon from '@mui/icons-material/Category';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackbarAlert from './SnackbarAlert';

import {
    saveCategory,
    updateCategory,
    fetchCategoriesPaginated
} from '../services/v2/categoryService';
import {
    fetchPaginatedMeasureUnits,
    saveMeasureUnit,
} from '../services/v2/measureUnitsService';
import { createItem } from '../services/v2/itemService';
import { useAuth } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';

const SuggestionModal = ({ open, onClose, suggestions, onSave }) => {
    const { t } = useTranslation();
    const { apiKey, organizationId, token } = useAuth();

    const [localSuggestions, setLocalSuggestions] = useState({
        rows: [],
        categories: [],
        measureUnits: [],
    });
    const [categories, setCategories] = useState([]);
    const [measureUnits, setMeasureUnits] = useState([]);
    const [combinedCategories, setCombinedCategories] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    useEffect(() => {
        // Defensive checks in case suggestions?.suggestions is null
        setLocalSuggestions({
            rows: suggestions?.rows || [],
            categories: suggestions?.suggestions?.categories || [],
            measureUnits: suggestions?.suggestions?.measureUnits || [],
        });
    }, [suggestions]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const catData = await fetchCategoriesPaginated(apiKey, organizationId, token,{pageSize: 1000});
                setCategories(catData.items || []);
                const muData = await fetchPaginatedMeasureUnits(apiKey, organizationId, token,{pageSize: 1000});
                setMeasureUnits(muData.items || []);
            } catch (err) {
                console.error('Error fetching initial data:', err);
            }
        };
        fetchData();
    }, [apiKey, organizationId, token]);

    useEffect(() => {
        setCombinedCategories(combineData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, localSuggestions.categories]);

    /**
     * Combine server-fetched categories with the locally suggested categories.
     */
    const combineData = () => {
        // 1. Copy real categories from DB
        const combined = categories.map((cat) => ({
            ...cat,
            subcategories: cat.subcategories.map((subcat) => ({
                ...subcat,
                subcategoryId: subcat.subcategoryId || uuidv4(),
            })),
        }));

        // 2. For each suggested category, see if we have an ID. If no ID => new
        localSuggestions.categories.forEach((suggestedCategory) => {
            const isNewCategory = !suggestedCategory.categoryId; // ID empty => new
            let existingCategory = combined.find(
                (c) => c.categoryId === suggestedCategory.categoryId
            );

            // If new or not found in DB, add to combined
            if (isNewCategory || !existingCategory) {
                const catId = suggestedCategory.categoryId || uuidv4();
                const subcats = (suggestedCategory.subcategories || []).map((sc) => ({
                    ...sc,
                    subcategoryId: sc.subcategoryId || uuidv4(),
                }));

                combined.push({
                    ...suggestedCategory,
                    categoryId: catId,
                    subcategories: subcats,
                });

                existingCategory = combined[combined.length - 1];
            } else {
                // Merge subcategories if needed
                mergeSubcategories(existingCategory, suggestedCategory);
            }

            // 3. Link row.categoryId if suggestionforRows
            if (Array.isArray(suggestedCategory.suggestionforRows)) {
                suggestedCategory.suggestionforRows.forEach((rowIndex) => {
                    const numericIndex = parseInt(rowIndex, 10);
                    const foundRow = localSuggestions.rows.find(
                        (row) => parseInt(row.rowNumber, 10) === numericIndex
                    );
                    if (foundRow) {
                        foundRow.categoryId = existingCategory.categoryId;
                    } else {
                        console.warn(
                            'No row found in localSuggestions.rows for rowNumber =',
                            rowIndex
                        );
                    }
                });
            }

            // 4. Link subcategory => row.subcategoryId
            (suggestedCategory.subcategories || []).forEach((suggestedSubcat) => {
                if (Array.isArray(suggestedSubcat.suggestionforRows)) {
                    suggestedSubcat.suggestionforRows.forEach((rowIndex) => {
                        const numericIndex = parseInt(rowIndex, 10);
                        const foundRow = localSuggestions.rows.find(
                            (row) => parseInt(row.rowNumber, 10) === numericIndex
                        );
                        if (foundRow) {
                            foundRow.subcategoryId = suggestedSubcat.subcategoryId;
                        } else {
                            console.warn(
                                'No row found for subcategory suggestion, rowNumber =',
                                rowIndex
                            );
                        }
                    });
                }
            });
        });

        return combined;
    };

    /** Merge subcategories from suggestedCategory into existingCategory if not present */
    const mergeSubcategories = (existingCategory, suggestedCategory) => {
        (suggestedCategory.subcategories || []).forEach((sc) => {
            const foundSub = existingCategory.subcategories.find(
                (es) => es.subcategoryId === sc.subcategoryId
            );
            if (!foundSub) {
                existingCategory.subcategories.push({
                    subcategoryId: sc.subcategoryId || uuidv4(),
                    subcategoryName: sc.subcategoryName || sc.name,
                    measureUnits: [],
                    suggestionforRows: sc.suggestionforRows,
                });
            } else {
                foundSub.suggestionforRows = sc.suggestionforRows;
            }
        });
    };

    /**
     * Handle user typing in text fields (category name, item name, etc.)
     */
    const handleInputChange = (type, field, parentIdx, value, subIdx) => {
        const updatedSuggestions = JSON.parse(JSON.stringify(localSuggestions));
        if (type === 'row') {
            updatedSuggestions.rows[parentIdx][field] = value;
        } else if (type === 'category') {
            updatedSuggestions.categories[parentIdx][field] = value;
        } else if (type === 'subcategory') {
            updatedSuggestions.categories[parentIdx].subcategories[subIdx][field] =
                value;
        }
        setLocalSuggestions(updatedSuggestions);
    };

    /**
     * Handle removing a row/category/subcategory from local suggestions
     */
    const handleRemove = (type, parentIdx, subIdx) => {
        const updatedSuggestions = JSON.parse(JSON.stringify(localSuggestions));
        if (type === 'row') {
            updatedSuggestions.rows.splice(parentIdx, 1);
        } else if (type === 'category') {
            updatedSuggestions.categories.splice(parentIdx, 1);
        } else if (type === 'subcategory') {
            updatedSuggestions.categories[parentIdx].subcategories.splice(subIdx, 1);
        }
        setLocalSuggestions(updatedSuggestions);
    };

    /**
     * Renders the "new" or "existing" Chip based on ID presence
     */
    const renderCategoryChip = (maybeIdOrProps) => {
        // If it's an object with subcategoryId or categoryId, check that
        // Or if it's measureUnit object with .id
        // For simplicity, let's check "newOrExisting" fallback, or check if ID is empty.
        const isEmptyId =
            !maybeIdOrProps ||
            maybeIdOrProps === '' ||
            maybeIdOrProps === null ||
            maybeIdOrProps === undefined;
        return <Chip label={isEmptyId ? t('new') : t('existing')} />;
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    /**
     * Called when user clicks "Save" in the modal.
     * 1) Save measureUnits that have empty ID => new
     * 2) Save categories/subcategories that have empty ID => new, or update existing
     * 3) Save items that have empty itemId => new
     * 4) Pass final data up
     */
    const handleSave = async () => {
        try {
            // Make a copy so we don't mutate state while saving
            const cleanSuggestions = JSON.parse(JSON.stringify(localSuggestions));

            // 1) Save measureUnits
            await saveAllMeasureUnits(cleanSuggestions);

            // 2) Save categories
            await saveAllCategories(cleanSuggestions);

            // 3) Save items
            await saveAllItems(cleanSuggestions);

            setSnackbar({
                open: true,
                message: t('suggestions.saveSuccess'),
                severity: 'success',
            });

            // Pass final, updated data (with new IDs) back to parent
            onSave(cleanSuggestions);
        } catch (error) {
            console.error('Failed to save suggestions:', error);
            setSnackbar({
                open: true,
                message: t('suggestions.saveError'),
                severity: 'error',
            });
        }
    };

    const saveAllMeasureUnits = async (updatedSuggestions) => {
        for (const mu of updatedSuggestions.measureUnits) {
            const isNew = !mu.id; // empty => new
            if (isNew) {
                const newMeasureUnit = {
                    id: uuidv4(),
                    nameLong: mu.nameLong,
                    nameShort: mu.nameShort,
                };
                const saved = await saveMeasureUnit(
                    newMeasureUnit,
                    apiKey,
                    organizationId,
                    token
                );
                // Store new ID
                mu.id = saved.id;
            }
        }
    };

    const saveAllCategories = async (updatedSuggestions) => {
        for (const cat of updatedSuggestions.categories) {
            const isNewCat = !cat.categoryId;
            if (isNewCat) {
                await saveNewCategory(cat);
            } else {
                await updateExistingCategory(cat);
            }
        }
    };

    const saveNewCategory = async (cat) => {
        // Build a minimal object to save
        const newCategory = {
            categoryId: uuidv4(),
            categoryName: cat.categoryName,
            subcategories: [],
        };

        for (const sc of cat.subcategories || []) {
            const isNewSubcat = !sc.subcategoryId;
            if (isNewSubcat) {
                const newSubcat = {
                    subcategoryId: uuidv4(),
                    subcategoryName: sc.name || sc.subcategoryName,
                    measureUnits: [],
                };
                newCategory.subcategories.push(newSubcat);
                sc.subcategoryId = newSubcat.subcategoryId; // store newly created subcat ID
            }
        }

        const savedCategory = await saveCategory(
            newCategory,
            apiKey,
            organizationId,
            token
        );
        // The backend might return { id: '...', ... }
        cat.categoryId = savedCategory.id;
    };

    const updateExistingCategory = async (cat) => {
        const existingCatInState = categories.find(
            (c) => c.categoryId === cat.categoryId
        );
        if (existingCatInState) {
            // Merge subcategories
            const updatedSubcats = [...(existingCatInState.subcategories || [])];
            for (const sc of cat.subcategories || []) {
                const isNewSubcat = !sc.subcategoryId;
                if (isNewSubcat) {
                    const newSubcat = {
                        subcategoryId: uuidv4(),
                        subcategoryName: sc.name || sc.subcategoryName,
                        measureUnits: [],
                    };
                    updatedSubcats.push(newSubcat);
                    sc.subcategoryId = newSubcat.subcategoryId;
                }
            }

            const updatedCatObject = {
                ...existingCatInState,
                id: cat.categoryId, // some APIs use .id or .categoryId
                subcategories: updatedSubcats,
            };

            await updateCategory(updatedCatObject, apiKey, organizationId, token);

            // Also reflect newly assigned subcategory IDs in local suggestions
            setLocalSuggestions((prev) => ({
                ...prev,
                categories: prev.categories.map((c) => {
                    if (c.categoryId === cat.categoryId) {
                        return {
                            ...c,
                            subcategories: c.subcategories.map((sc) => {
                                if (!sc.subcategoryId) {
                                    const match = updatedSubcats.find(
                                        (usc) =>
                                            usc.subcategoryName ===
                                            (sc.name || sc.subcategoryName)
                                    );
                                    return {
                                        ...sc,
                                        subcategoryId: match?.subcategoryId || uuidv4(),
                                    };
                                }
                                return sc;
                            }),
                        };
                    }
                    return c;
                }),
            }));
        }
    };

    const saveAllItems = async (updatedSuggestions) => {
        for (const row of updatedSuggestions.rows) {
            const isNewItem = !row.itemId && row.stockItem; // empty => new
            if (isNewItem) {
                const newItem = {
                    name: row.itemDescription,
                    category: row.categoryId || '', // might be newly assigned above
                    subcategory: row.subcategoryId || '',
                    measureUnit: row.measureUnitId || '',
                    stockItem: row.stockItem,
                    lastBoughtPrice: 0,
                    quantity: 0,
                    warningQuantity: 0,
                    showInDashboard: false,
                    organizationId,
                };
                const savedItem = await createItem(
                    newItem,
                    apiKey,
                    organizationId,
                    token
                );
                row.itemId = savedItem.id; // store new ID in local suggestions
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                {t('suggestions.title')}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <ListAltIcon sx={{ mr: 1 }} />
                        {t('suggestions.item.title')}
                    </Typography>
                    {localSuggestions.rows
                        ?.filter((r) => r.stockItem) // only stock items
                        .map((row, rowIdx) => (
                            <ItemCard
                                key={rowIdx}
                                row={row}
                                rowIdx={rowIdx}
                                combinedCategories={combinedCategories}
                                measureUnits={measureUnits}
                                handleInputChange={handleInputChange}
                                handleRemove={handleRemove}
                                renderCategoryChip={renderCategoryChip}
                                t={t}
                            />
                        ))}
                </Paper>

                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <CategoryIcon sx={{ mr: 1 }} />
                        {t('suggestions.category.title')}
                    </Typography>
                    {localSuggestions.categories.map((category, catIdx) => (
                        <CategoryCard
                            key={catIdx}
                            category={category}
                            catIdx={catIdx}
                            handleInputChange={handleInputChange}
                            handleRemove={handleRemove}
                            renderCategoryChip={renderCategoryChip}
                            t={t}
                        />
                    ))}
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {t('cancel')}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {t('save')}
                </Button>
            </DialogActions>
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Dialog>
    );
};

/** Renders a single item row in the SuggestionModal */
const ItemCard = ({
    row,
    rowIdx,
    combinedCategories,
    measureUnits,
    handleInputChange,
    handleRemove,
    renderCategoryChip,
    t,
}) => {
    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                    {row.itemDescription}{' '}
                    {renderCategoryChip(row.itemId)} {/* empty => new */}
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleRemove('row', rowIdx)}
                        sx={{ ml: 'auto' }}
                        disabled={Boolean(row.itemId)} // if it has an itemId, treat as existing
                    >
                        <DeleteIcon />
                    </IconButton>
                </Typography>

                <TextField
                    label={t('suggestions.item.name')}
                    value={row.itemDescription || ''}
                    onChange={(e) =>
                        handleInputChange('row', 'itemDescription', rowIdx, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    disabled={Boolean(row.itemId)} // if there's already an itemId, it's existing
                />

                <Select
                    label={t('suggestions.item.category')}
                    value={row.categoryId || ''}
                    onChange={(e) =>
                        handleInputChange('row', 'categoryId', rowIdx, e.target.value)
                    }
                    fullWidth
                    disabled={Boolean(row.itemId)}
                    sx={{ my: 2 }}
                >
                    {combinedCategories.map((cat) => (
                        <MenuItem key={cat.categoryId} value={cat.categoryId}>
                            {cat.categoryName}
                        </MenuItem>
                    ))}
                </Select>

                <Select
                    label={t('suggestions.item.subcategory')}
                    value={row.subcategoryId || ''}
                    onChange={(e) =>
                        handleInputChange('row', 'subcategoryId', rowIdx, e.target.value)
                    }
                    fullWidth
                    disabled={Boolean(row.itemId)}
                    sx={{ mb: 2 }}
                >
                    {combinedCategories
                        .find((cat) => cat.categoryId === row.categoryId)
                        ?.subcategories?.map((subcat) => (
                            <MenuItem key={subcat.subcategoryId} value={subcat.subcategoryId}>
                                {subcat.subcategoryName}
                            </MenuItem>
                        ))}
                </Select>

                <Select
                    label={t('suggestions.item.measureUnit')}
                    value={row.measureUnitId || ''}
                    onChange={(e) =>
                        handleInputChange('row', 'measureUnitId', rowIdx, e.target.value)
                    }
                    fullWidth
                    disabled={Boolean(row.itemId)}
                >
                    {measureUnits.map((unit) => (
                        <MenuItem key={unit.id} value={unit.id}>
                            {unit.nameLong}
                        </MenuItem>
                    ))}
                </Select>
            </CardContent>
        </Card>
    );
};

/** Renders one category card with subcategories in the SuggestionModal */
const CategoryCard = ({
    category,
    catIdx,
    handleInputChange,
    handleRemove,
    renderCategoryChip,
    t,
}) => {
    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                    {category.categoryName}{' '}
                    {renderCategoryChip(category.categoryId)} {/* empty => new */}
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleRemove('category', catIdx)}
                        sx={{ ml: 'auto' }}
                        disabled={Boolean(category.categoryId)} // if non-empty, treat as existing
                    >
                        <DeleteIcon />
                    </IconButton>
                </Typography>

                <TextField
                    label={t('suggestions.category.name')}
                    value={category.categoryName || ''}
                    onChange={(e) =>
                        handleInputChange('category', 'categoryName', catIdx, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    disabled={Boolean(category.categoryId)}
                />

                {(category.subcategories || []).map((subcategory, subIdx) => (
                    <SubcategoryCard
                        key={subIdx}
                        subcategory={subcategory}
                        catIdx={catIdx}
                        subIdx={subIdx}
                        handleInputChange={handleInputChange}
                        handleRemove={handleRemove}
                        renderCategoryChip={renderCategoryChip}
                        t={t}
                    />
                ))}
            </CardContent>
        </Card>
    );
};

/** Renders one subcategory inside a CategoryCard */
const SubcategoryCard = ({
    subcategory,
    catIdx,
    subIdx,
    handleInputChange,
    handleRemove,
    renderCategoryChip,
    t,
}) => {
    return (
        <Box ml={2} mt={2}>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                {subcategory.name || subcategory.subcategoryName}{' '}
                {renderCategoryChip(subcategory.subcategoryId)} {/* empty => new */}
                <IconButton
                    aria-label="delete"
                    onClick={() => handleRemove('subcategory', catIdx, subIdx)}
                    sx={{ ml: 'auto' }}
                    disabled={Boolean(subcategory.subcategoryId)}
                >
                    <DeleteIcon />
                </IconButton>
            </Typography>
            <TextField
                label={t('suggestions.subcategory.name')}
                value={subcategory.name || subcategory.subcategoryName || ''}
                onChange={(e) =>
                    handleInputChange(
                        'subcategory',
                        'name',
                        catIdx,
                        e.target.value,
                        subIdx
                    )
                }
                fullWidth
                margin="normal"
                disabled={Boolean(subcategory.subcategoryId)}
            />
        </Box>
    );
};

export default SuggestionModal;
