import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GenericCardView from '../GenericCardView';
import LocationDialog from '../LocationDialog';
import { useAuth } from '../../context/AuthContext';
import { deleteLocationSettings, fetchPaginatedLocationSettings } from '../../services/v2/LocationSettingsService';

const LocationsSettings = () => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const { apiKey, organizationId, token: jwtToken } = useAuth();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const data = await fetchPaginatedLocationSettings(apiKey, organizationId, jwtToken, { pageSize: 1000 });
        setLocations(data.items || []);
      } catch (error) {
        console.error('Failed to fetch locations:', error);
      }
    };
    fetchLocations();
  }, []);

  const openDialog = (data = null) => {
    setCurrentData(data);
    setDialogOpen(true);
  };

  const handleDialogClose = async (success) => {
    if (success) {
      const fetchLocations = async () => {
        try {
          const data = await fetchPaginatedLocationSettings(apiKey, organizationId, jwtToken, { pageSize: 1000 });
          setLocations(data.items || []);
        } catch (error) {
          console.error('Failed to fetch locations:', error);
        }
      };
      fetchLocations();
    }
    setDialogOpen(false);
    setCurrentData(null);
  };

  const handleEdit = (locationId) => {
    const location = locations.find(location => location.id === locationId);
    console.log('Edit location Id:', locationId);
    openDialog(location);
  };

  const handleDeleteClick = (locationId) => {
    setSelectedLocationId(locationId);
    setConfirmDialogOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteLocationSettings(selectedLocationId, apiKey, organizationId, jwtToken);
      setSnackbar({ open: true, message: t('Location deleted successfully'), severity: 'success' });
      setLocations(locations.filter(location => location.id !== selectedLocationId));
    } catch (error) {
      setSnackbar({ open: true, message: t('Failed to delete location'), severity: 'error' });
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const structure = {
    headers: [
      { key: 'name', label: t('locations.name'), display: { value: true } },
      { key: 'buildings', label: t('locations.buildings'), display: { value: true } }
    ],
    rows: locations.map(location => ({
      id: location.id,
      name: {
        value: location.name,
        icon: null
      },
      buildings: {
        value: location.buildings.map(building => building.name).join(', '),
        icon: null
      },
      actions: [
        { label: t('common.edit'), handler: () => handleEdit(location.id), icon: <EditIcon /> },
        { label: t('common.delete'), handler: () => handleDeleteClick(location.id), icon: <DeleteIcon /> }
      ]
    }))
  };

  return (
    <Box>
      <Typography variant="h6">{t('OrganizationSettings.Tabs.Locations')}</Typography>
      <Button onClick={() => openDialog()}>{t('locations.addLocation')}</Button>
      <GenericCardView structure={structure} onClick={handleEdit} />

      <LocationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        initialData={currentData}
      />

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
      >
        <DialogTitle>{t('Confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure you want to delete this location?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>{t('Cancel')}</Button>
          <Button onClick={handleDeleteConfirmation} color="primary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Box>
  );
};

export default LocationsSettings;
