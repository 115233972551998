import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import AddIcon from '@mui/icons-material/Add';

import GenericTableView from './GenericTableView';
import GenericCardView from './GenericCardView';
import { useTranslation } from 'react-i18next';
import { usePreferences } from '../context/UserPreferencesContext';

const DataViewWrapper = ({
  structure,
  viewMode: externalViewMode,
  onViewModeToggle: externalOnViewModeToggle,
  filter,
  setFilter,
  sort,
  setSort,
  onRowClick,
  pageId,
  toolbarActions = [],
  /**
   * type PredefinedFilter = {
   *   label: string; // e.g. "My Horse: Sunshine"
   *   field: string; // e.g. "horseId"
   *   value: string; // e.g. "abc123"
   * };
   */
  predefinedFilters = [],

  enableFilterControls = true,
  enableSortControls = true,
}) => {
  const { t } = useTranslation();
  const { updateDevicePreferences, userPreferences, updatePageFilters } = usePreferences();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Manage internal view mode if not controlled externally
  const [internalViewMode, setInternalViewMode] = useState(
    externalViewMode ?? (isMobile ? 'card' : 'table')
  );
  const actualViewMode = externalViewMode ?? internalViewMode;

  // Show/hide filter & sort collapsible UIs
  const [showFilters, setShowFilters] = useState(false);
  const [showSort, setShowSort] = useState(false);

  // FILTER state (for user “saved filters”)
  const [selectedFilterField, setSelectedFilterField] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [savedFilters, setSavedFilters] = useState(
    userPreferences.savedFilters?.[pageId] || []
  );

  // SORT state
  const [selectedSortField, setSelectedSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  // Track active Predefined Filters separate from savedFilters
  const [activePredefinedFilters, setActivePredefinedFilters] = useState({});

  // Control collapsing of predefined filters if there are more than 3
  const [expandPredefinedFilters, setExpandPredefinedFilters] = useState(false);

  // ---------------------------------------
  //  useEffect: Load and apply saved filters
  // ---------------------------------------
  useEffect(() => {
    const pageFilters = userPreferences.savedFilters?.[pageId] || [];
    setSavedFilters(pageFilters);
  }, [pageId, userPreferences.savedFilters]);

  // ---------------------------------------
  //  Combine saved filters + activePredefinedFilters => setFilter
  // ---------------------------------------
  useEffect(() => {
    // 1) Build combined filters from savedFilters + activePredefinedFilters
    const combined = {};
    savedFilters.forEach((f) => {
      if (f.enabled) {
        combined[f.field] = f.value;
      }
    });
    Object.entries(activePredefinedFilters).forEach(([field, value]) => {
      combined[field] = value;
    });

    // 2) Compare with the current `filter` passed from the parent
    const filterKeys = Object.keys(filter);
    const combinedKeys = Object.keys(combined);

    const sameLength = filterKeys.length === combinedKeys.length;
    const sameEntries =
      sameLength && combinedKeys.every((key) => filter[key] === combined[key]);

    // 3) Only call setFilter if there's a difference
    if (!sameEntries) {
      setFilter(combined);
    }
  }, [savedFilters, activePredefinedFilters, filter, setFilter]);

  // ---------------------------------------
  //  View Mode
  // ---------------------------------------
  const handleViewModeToggle = () => {
    const newMode = actualViewMode === 'table' ? 'card' : 'table';
    if (externalOnViewModeToggle) {
      externalOnViewModeToggle(newMode);
    } else {
      setInternalViewMode(newMode);
    }
    // Also update device prefs
    updateDevicePreferences('viewMode', newMode);
  };

  // ---------------------------------------
  //  FILTER: Add new user filter
  // ---------------------------------------
  const handleAddFilter = () => {
    if (!selectedFilterField || !filterValue) return;
    const newFilter = {
      field: selectedFilterField,
      value: filterValue,
      enabled: true,
    };

    const updated = savedFilters.some((f) => f.field === newFilter.field)
      ? savedFilters.map((f) => (f.field === newFilter.field ? { ...f, ...newFilter } : f))
      : [...savedFilters, newFilter];

    setSavedFilters(updated);
    updatePageFilters(pageId, updated);

    // Reset form fields
    setSelectedFilterField('');
    setFilterValue('');
  };

  // ---------------------------------------
  //  FILTER: Toggle user filter on/off
  // ---------------------------------------
  const handleToggleFilter = (field) => {
    const updated = savedFilters.map((f) =>
      f.field === field ? { ...f, enabled: !f.enabled } : f
    );
    setSavedFilters(updated);
    updatePageFilters(pageId, updated);
  };

  // ---------------------------------------
  //  FILTER: Delete user filter
  // ---------------------------------------
  const handleDeleteFilter = (field) => {
    const updated = savedFilters.filter((f) => f.field !== field);
    setSavedFilters(updated);
    updatePageFilters(pageId, updated);
  };

  // ---------------------------------------
  //  SHOW/HIDE Filter & Sort Panels
  // ---------------------------------------
  const toggleFilterControls = () => {
    setShowFilters(!showFilters);
    setShowSort(false);
  };

  const toggleSortControls = () => {
    setShowSort(!showSort);
    setShowFilters(false);
  };

  // ---------------------------------------
  //  SORT: Apply user’s chosen sort
  // ---------------------------------------
  const handleApplySort = () => {
    if (selectedSortField) {
      setSort({
        field: selectedSortField,
        order: sortDirection,
      });
    }
  };

  // ---------------------------------------
  //  PREDEFINED FILTERS LOGIC
  // ---------------------------------------
  const handlePredefinedFilterClick = (pref) => {
    setActivePredefinedFilters((prev) => {
      const newState = { ...prev };
      // If it's already active with the same field+value, toggle it off.
      if (newState[pref.field] === pref.value) {
        delete newState[pref.field];
      } else {
        newState[pref.field] = pref.value;
      }
      return newState;
    });
  };

  // Helper to see if a predefined filter is active
  const isPredefinedActive = (pref) => {
    return activePredefinedFilters[pref.field] === pref.value;
  };

  // Calculate how many filters to show when collapsed vs expanded
  const showCollapse = predefinedFilters.length > 3;
  const displayedPredefinedFilters =
    showCollapse && !expandPredefinedFilters
      ? predefinedFilters.slice(0, 3)
      : predefinedFilters;

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {/* 
        MAIN TOOLBAR
      */}
      <Toolbar
        sx={{
          gap: 2,
          p: 1,
          flexWrap: 'wrap',
          backgroundColor: 'background.paper',
          boxShadow: 1,
          borderRadius: 2,
          m: 1,
        }}
      >
        {/* Render any custom actions passed from parent */}
        {toolbarActions.map((node, idx) => (
          <React.Fragment key={idx}>{node}</React.Fragment>
        ))}

        {/* TABLE/CARD View Toggle */}
        <IconButton
          onClick={handleViewModeToggle}
          color="primary"
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            p: 1.5,
            ml: 'auto',
          }}
        >
          {actualViewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>

        {/* FILTER / SORT Toggle */}
        {enableFilterControls && (
          <Button
            variant={showFilters ? 'contained' : 'outlined'}
            startIcon={<FilterListIcon />}
            onClick={toggleFilterControls}
            sx={{ borderRadius: 5 }}
          >
            {!isMobile && t('common.filter')}
          </Button>
        )}
        {enableSortControls && (
          <Button
            variant={showSort ? 'contained' : 'outlined'}
            startIcon={<SortIcon />}
            onClick={toggleSortControls}
            sx={{ borderRadius: 5 }}
          >
            {!isMobile && t('common.sort')}
          </Button>
        )}
      </Toolbar>

      {/* FILTER PANEL */}
      <Collapse in={showFilters}>
        <Box
          sx={{
            p: 2,
            m: 1,
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ minWidth: 120, flex: 1 }}>
              <InputLabel>{t('filter.field')}</InputLabel>
              <Select
                value={selectedFilterField}
                onChange={(e) => setSelectedFilterField(e.target.value)}
                label={t('filter.field')}
              >
                {structure.headers.map((header) => (
                  <MenuItem key={header.key} value={header.key}>
                    {header.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label={t('filter.value')}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleAddFilter()}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddFilter} edge="end" color="primary">
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Collapse>

      {/* SORT PANEL */}
      <Collapse in={showSort}>
        <Box
          sx={{
            p: 2,
            m: 1,
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ minWidth: 120, flex: 1 }}>
              <InputLabel>{t('sort.field')}</InputLabel>
              <Select
                value={selectedSortField}
                onChange={(e) => setSelectedSortField(e.target.value)}
                label={t('sort.field')}
              >
                {structure.headers.map((header) => (
                  <MenuItem key={header.key} value={header.key}>
                    {header.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120, flex: 1 }}>
              <InputLabel>{t('sort.direction')}</InputLabel>
              <Select
                value={sortDirection}
                onChange={(e) => setSortDirection(e.target.value)}
                label={t('sort.direction')}
              >
                <MenuItem value="asc">{t('sort.ascending')}</MenuItem>
                <MenuItem value="desc">{t('sort.descending')}</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={handleApplySort}
              sx={{ height: 56, alignSelf: isMobile ? 'flex-start' : 'auto' }}
            >
              {t('sort.apply')}
            </Button>
          </Box>
        </Box>
      </Collapse>

      {/* 
        PREDEFINED FILTER CHIPS
        - Collapsed if more than 3. The toggle is another Chip.
      */}
      {predefinedFilters.length > 0 && (
        <Box sx={{ p: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {displayedPredefinedFilters.map((pref) => {
            const active = isPredefinedActive(pref);
            return (
              <Chip
                key={`${pref.field}::${pref.value}`}
                label={pref.label}
                onClick={() => handlePredefinedFilterClick(pref)}
                sx={{
                  border: active ? '2px solid' : '1px dashed',
                  borderColor: active ? 'primary.main' : 'divider',
                  backgroundColor: active ? 'background.paper' : 'action.hover',
                  opacity: active ? 1 : 0.8,
                }}
              />
            );
          })}

          {/* Toggle Chip if we have more than 3 total */}
          {showCollapse && (
            !expandPredefinedFilters ? (
              <Chip
                label={`+${predefinedFilters.length - 3} ${t('common.more')}`}
                onClick={() => setExpandPredefinedFilters(true)}
                sx={{ border: '1px dashed', borderColor: 'divider', opacity: 0.8 }}
              />
            ) : (
              <Chip
                label={t('common.less')}
                onClick={() => setExpandPredefinedFilters(false)}
                sx={{ border: '1px dashed', borderColor: 'divider', opacity: 0.8 }}
              />
            )
          )}
        </Box>
      )}

      {/* 
        SAVED FILTER CHIPS 
        (only user-created ones)
      */}
      {savedFilters.length > 0 && (
        <Box sx={{ p: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {savedFilters.map((f) => {
            const header = structure.headers.find((h) => h.key === f.field);
            const label = header ? header.label : f.field;
            return (
              <Chip
                key={`${f.field}-${f.value}`}
                label={`${label}: ${f.value.replace(/\*/g, '')}`}
                onClick={() => handleToggleFilter(f.field)}
                onDelete={() => handleDeleteFilter(f.field)}
              />
            );
          })}
        </Box>
      )}

      {/* MAIN DATA VIEW */}
      {actualViewMode === 'table' ? (
        <GenericTableView structure={structure} onClick={onRowClick} />
      ) : (
        <GenericCardView structure={structure} onClick={onRowClick} />
      )}
    </Box>
  );
};

export default DataViewWrapper;
