// src/pages/FarrierVisitPage.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Button,
    Typography,
    LinearProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { format } from 'date-fns';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';
import { useDateLocale } from '../hooks/useDateLocale';

// Paginated fetch services
import { fetchPaginatedFarrierVisits } from '../services/v2/FarrierVisitService';
import { fetchHorsesPaginated, getHorsesByUserId } from '../services/v2/horseService';
import { fetchPaginatedFerries } from '../services/v2/FerryService';

import DataViewWrapper from '../components/DataViewWrapper';
import FarrierVisitForm from '../components/FarrierVisitModal';
import { usePaginatedData } from '../hooks/usePaginatedData';

const FarrierVisitPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dateLocale = useDateLocale();

    const {
        apiKey,
        organizationId,
        token,
        userType,
        ownerId,
        userId,
    } = useAuth();
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    // ------ Paginated data for farrier visits ------
    const {
        items: farrierVisits,
        loading: visitsLoading,
        continuationToken,
        loadNextPage,
        loadFirstPage,
        filter,
        setFilter,
        sort,
        setSort,
    } = usePaginatedData(
        (options) => fetchPaginatedFarrierVisits(apiKey, organizationId, token, options),
        { pageSize: 25 }
    );

    // ------ Horses & Farriers ------
    const [horses, setHorses] = useState([]);
    const [farriers, setFarriers] = useState([]);
    const [auxLoading, setAuxLoading] = useState(false);

    const otherHorses = horses.filter(h => h.ownerId !== ownerId);
    const ownerHorses = horses.filter(h => h.ownerId === ownerId);
    const sortedHorses = [...ownerHorses, ...otherHorses];

    // ------ Local state for view mode & modal ------
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || (isMobile ? 'card' : 'table')
    );
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);

    // Keep local viewMode in sync if devicePreferences changes:
    useEffect(() => {
        setViewMode(devicePreferences.viewMode || (isMobile ? 'card' : 'table'));
    }, [devicePreferences.viewMode, isMobile]);

    // ------ Fetch Horses & Farriers ------
    useEffect(() => {
        let isCancelled = false;
        const fetchResources = async () => {
            setAuxLoading(true);
            try {
                // Horses
                let allHorses = [];
                if (userType === 'admin') {
                    let nextToken = null;
                    do {
                        const resp = await fetchHorsesPaginated(apiKey, organizationId, token, {
                            pageSize: 1000,
                            continuationToken: nextToken,
                        });
                        allHorses = [...allHorses, ...(resp.items || [])];
                        nextToken = resp.continuationToken;
                    } while (nextToken && !isCancelled);
                } else {
                    // normal user: get user-specific horses
                    const response = await getHorsesByUserId(userId, apiKey, organizationId, token);
                    allHorses = response.items || [];
                }

                // Farriers
                let allFarriers = [];
                let nextFarrierToken = null;
                do {
                    const resp = await fetchPaginatedFerries(apiKey, organizationId, token, {
                        pageSize: 1000,
                        continuationToken: nextFarrierToken,
                    });
                    allFarriers = [...allFarriers, ...(resp.items || [])];
                    nextFarrierToken = resp.continuationToken;
                } while (nextFarrierToken && !isCancelled);

                if (!isCancelled) {
                    setHorses(allHorses);
                    setFarriers(allFarriers);
                }
            } catch (err) {
                console.error('Failed to fetch horses/farriers:', err);
            } finally {
                if (!isCancelled) setAuxLoading(false);
            }
        };
        fetchResources();
        return () => {
            isCancelled = true;
        };
    }, [apiKey, organizationId, token, userType, userId]);

    // ------ Modal Handlers ------
    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentRecord(null);

        // Reload data after closing modal
        loadFirstPage();
    };

    const handleAddVisit = () => {
        setCurrentRecord(null);
        setModalOpen(true);
    };

    const handleEditFarrierVisit = (visitId) => {
        const record = farrierVisits.find((v) => v.id === visitId);
        setCurrentRecord(record || null);
        setModalOpen(true);
    };

    const toggleViewMode = () => {
        const newMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newMode);
        updateDevicePreferences('viewMode', newMode);
    };

    // ------ Utilities ------
    const getHorseNameById = (horseId) => {
        const found = horses.find((h) => h.id === horseId);
        return found ? found.name : 'Unknown Horse';
    };

    const farrierServiceCategories = [
        {
            category: t('farrierVisits.serviceCategories.basicServices'),
            services: [
                { key: 'barehoof', label: t('farrierVisits.services.barehoof') },
                { key: 'lostShoe', label: t('farrierVisits.services.lostShoe') },
            ],
        },
        {
            category: t('farrierVisits.serviceCategories.shoeingOptions'),
            services: [
                { key: '2horseshoes', label: t('farrierVisits.services.2horseshoes') },
                { key: '4horseshoes', label: t('farrierVisits.services.4horseshoes') },
                { key: 'specialShoeing', label: t('farrierVisits.services.specialShoeing') },
            ],
        },
    ];

    const getServiceLabel = (serviceKey) => {
        for (const category of farrierServiceCategories) {
            const service = category.services.find((s) => s.key === serviceKey);
            if (service) return service.label;
        }
        return 'Unknown Service';
    };

    // ------ Transform data for DataViewWrapper ------
    const transformData = (rows) => ({
        headers: [
            {
                key: 'horseName',
                label: t('farrierVisits.TableHeaders.horseName'),
                display: { value: true },
            },
            {
                key: 'dateOfVisit',
                label: t('farrierVisits.TableHeaders.dateOfVisit'),
                display: { value: true },
            },
            {
                key: 'service',
                label: t('farrierVisits.TableHeaders.service'),
                display: { value: true },
            },
        ],
        rows: rows.map((visit) => ({
            id: visit.id,
            horseName: { value: getHorseNameById(visit.horseId) },
            dateOfVisit: {
                value: format(new Date(visit.dateOfVisit), 'P', { locale: dateLocale }),
            },
            service: { value: getServiceLabel(visit.service) },
        })),
    });

    // Merge visit loading + auxiliary loading states
    const isLoading = visitsLoading || auxLoading;

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('farrierVisits.Page.title')}
            </Typography>

            {/* Show a linear progress bar if loading, but always keep DataViewWrapper mounted */}
            {isLoading && <LinearProgress />}

            <DataViewWrapper
                structure={transformData(farrierVisits || [])}
                viewMode={viewMode}
                onViewModeToggle={toggleViewMode}
                filter={filter}
                setFilter={setFilter}
                sort={sort}
                setSort={setSort}
                onRowClick={handleEditFarrierVisit}
                pageId="/farrier-visits"
                toolbarActions={[
                    <Button
                        key="add-farrier-visit"
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddVisit}
                    >
                        {t('farrierVisits.Page.addNewVisit')}
                    </Button>,
                ]}
                predefinedFilters={sortedHorses.map(h => ({
                    label: h.name,
                    field: 'horseId',
                    value: h.id,
                    // enabled: false, // optionally match vet page default
                }))}
                enableFilterControls={false}
                enableSortControls={false}
            />

            {/* Load More if there's additional visits */}
            {continuationToken && (
                <Box mt={2} textAlign="center">
                    <Button variant="contained" onClick={loadNextPage} disabled={visitsLoading}>
                        {t('common.loadMore')}
                    </Button>
                </Box>
            )}

            {/* Farrier Visit Form (modal) */}
            <FarrierVisitForm
                isOpen={isModalOpen}
                onClose={handleModalClose}
                record={currentRecord}
                horses={horses}
                farriers={farriers}
            />
        </Paper>
    );
};

export default FarrierVisitPage;
