import React, { useState, useEffect } from 'react';
import {
    TextField, Button, Paper, Container, Checkbox, FormControl, FormControlLabel, Select, MenuItem, InputLabel,
    LinearProgress
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteBox, fetchBoxById, saveBox, updateBox } from '../../services/v2/BoxService';
import { useAuth } from '../../context/AuthContext';
import { fetchPaginatedLocationSettings } from '../../services/v2/LocationSettingsService';
import { WidthProvider } from 'react-grid-layout';

const BoxTypeForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const mode = id ? 'edit' : 'add';
    const { apiKey, token, organizationId } = useAuth();
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);

    const [boxTypeData, setBoxTypeData] = useState({
        id: '',
        name: '',
        location: '',
        locationId: '',
        buildingId: '',
        amount: '',
        width: 1,
        length: 1,
        autoGenerateInvoice: false,
    });

    useEffect(() => {
        setLoading(true);
        if (mode === 'edit') {
            fetchBoxById(apiKey, organizationId, token, id)
                // conmsole out data and se the boxTypeData
                .then(data => {
                    setBoxTypeData(data);
                })
                .catch(err => console.error("Failed to fetch box type by ID:", err));
        }

        fetchPaginatedLocationSettings(apiKey, organizationId, token, { pageSize: 1000 })
            .then(data => setLocations(data.items || []))
            .catch(err => console.error("Failed to fetch locations:", err))
            .finally(() => setLoading(false));
    }, [mode, id, apiKey, organizationId, token]);

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        let updatedBoxTypeData = { ...boxTypeData };
        if (name === 'autoGenerateInvoice') {
            updatedBoxTypeData = { ...boxTypeData, [name]: checked };
        } else if (name === 'width' || name === 'length' || name === 'amount') {
            updatedBoxTypeData = { ...boxTypeData, [name]: parseFloat(value) };
        } else {
            updatedBoxTypeData = { ...boxTypeData, [name]: value };
        }
        setBoxTypeData(updatedBoxTypeData);
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        const updatedBoxTypeData = { ...boxTypeData, [name]: value };

        if (name === 'buildingId') {
            const location = locations.find(location => location.id === boxTypeData.locationId);
            const building = location?.buildings.find(building => building.id === value);

            // Check if location is empty or matches the current building name
            if (building) {
                // If location is empty, set it to the new building's name
                if (!boxTypeData.location) {
                    updatedBoxTypeData.location = building.name;
                }
                // If location matches current building name, update it to new building's name
                else if (boxTypeData.location === building.name) {
                    updatedBoxTypeData.location = building.name;
                }
            }
        }

        setBoxTypeData(updatedBoxTypeData);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const saveOrUpdate = mode === 'edit' ? updateBox : saveBox;
        saveOrUpdate(boxTypeData, apiKey, organizationId, token)
            .then(() => navigate('/organization-settings?tab=box-settings'))
            .catch(err => console.error(`Failed to ${mode === 'edit' ? 'update' : 'save'} box type:`, err));
    };

    const filteredBuildings = () => {
        const location = locations.find(location => location.id === boxTypeData.locationId);
        return location ? location.buildings : [];
    };

    const handleDelete = () => {
        deleteBox(boxTypeData.id, apiKey, organizationId, token)
            .then(() => navigate('/organization-settings?tab=box-settings'))
            .catch(err => console.error("Failed to delete box type:", err));
    };

    return (
        <Container component={Paper} style={{ padding: '2em' }}>
            {loading && <LinearProgress />}
            <h1>{mode === 'add' ? 'Add New Box Type' : 'Edit Box Type'}</h1>
            <form onSubmit={handleSubmit}>

                <TextField
                    required
                    name="name"
                    label="Box Type Name"
                    variant="outlined"
                    margin="normal"
                    value={boxTypeData.name}
                    fullWidth
                    onChange={handleChange}
                />

                <FormControl fullWidth margin="normal">
                    <InputLabel id="location-label">Location</InputLabel>
                    <Select
                        required
                        labelId="location-label"
                        name="locationId"
                        value={boxTypeData.locationId}
                        onChange={handleSelectChange}
                    >
                        {locations.map(location => (
                            <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="building-label">Building</InputLabel>
                    <Select
                        required
                        labelId="building-label"
                        name="buildingId"
                        value={boxTypeData.buildingId}
                        onChange={handleSelectChange}
                    >
                        {filteredBuildings()?.length > 0 ? (
                            filteredBuildings().map(building => (
                                <MenuItem key={building.id} value={building.id}>
                                    {building.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="">
                                No buildings found
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>

                <TextField
                    required
                    name="location"
                    label="Location"
                    variant="outlined"
                    margin="normal"
                    value={boxTypeData.location}
                    fullWidth
                    onChange={handleChange}
                />

                <TextField
                    required
                    name="amount"
                    label="Amount"
                    type="number"
                    variant="outlined"
                    margin="normal"
                    value={boxTypeData.amount}
                    fullWidth
                    onChange={handleChange}
                />
                <TextField
                    required
                    name="width"
                    label="Width in meters"
                    type="number"
                    variant="outlined"
                    margin="normal"
                    placeholder="e.g. 2"
                    value={boxTypeData.width || ''}
                    fullWidth
                    onChange={handleChange}
                />
                <TextField
                    required
                    name="length"
                    label="Length in meters"
                    placeholder="e.g. 3"
                    type="number"
                    variant="outlined"
                    margin="normal"
                    value={boxTypeData.length || ''}
                    fullWidth
                    onChange={handleChange}
                />

                <FormControlLabel
                    control={<Checkbox checked={boxTypeData.autoGenerateInvoice} onChange={handleChange} name="autoGenerateInvoice" />}
                    label="Automatically generate invoices monthly"
                />

                <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
                    Save
                </Button>

                {mode === 'edit' && (
                    <Button variant="contained" color="secondary" onClick={handleDelete}>
                        Delete
                    </Button>
                )}
            </form>
        </Container>
    );
};

export default BoxTypeForm;
