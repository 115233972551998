import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import { createHorse, getHorseById, updateHorse, deleteHorse, getHorsePermissions, upsertHorsePermissions } from '../services/v2/horseService';
import { Button, TextField, Container, Box, Tab, Tabs, Typography, LinearProgress, Paper, Grid } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import MealPopup from '../components/MealPopup';
import DetailsTab from '../components/HorseInfoPageTabs/DetailsTab';
import ContactTab from '../components/HorseInfoPageTabs/ContactTab';
import FoodTab from '../components/HorseInfoPageTabs/FoodManagement';
import DocumentTab from '../components/HorseInfoPageTabs/DocumentTab';
import PermissionsTab from '../components/HorseInfoPageTabs/PermissionsTab';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ServicesType from '../components/HorseInfoPageTabs/ServicesType';

import { getAllOwners, getOwnerById, fetchOwnersPaginated } from '../services/v2/ownerService';
import { fetchFoodSettings, fetchPaginatedFoodSettings } from '../services/v2/FoodSettingsService';
import { getAllUsers, fetchPaginatedUsers } from '../services/v2/userService';
import { fetchAllFerries, fetchPaginatedFerries } from '../services/v2/FerryService';
import { fetchAllVeterinarians, fetchPaginatedVeterinarians } from '../services/v2/VeterinarianService';
import { fetchDocuments, fetchDocumentMetadata, scanDocument, fetchPaginatedDocuments } from '../services/v2/documentService';

const HorseInfoPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = id !== 'new';
  const { organizationId } = useAuth();
  const { apiKey, userType, token, ownerId } = useAuth();
  const { t } = useTranslation();
  const [mealPopupOpen, setMealPopupOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [foodSettings, setFoodSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [owners, setOwners] = useState([]);
  const [currentOwner, setCurrentOwner] = useState(null);
  const jwtToken = token;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [horseDocuments, setHorseDocuments] = useState([]);
  const [veterinarians, setVeterinarians] = useState([]);
  const [farriers, setFarriers] = useState([]);
  const { state } = location;
  const [updateTrigger, setUpdateTrigger] = useState(false);

  // State for horse permissions
  const [permissions, setPermissions] = useState([]);
  const [users, setUsers] = useState([]); // State to hold the list of users

  const [horse, setHorse] = useState({
    id: '',
    name: '',
    birthday: '',
    breed: '',
    ownerId: '',
    coRiders: [],
    emergencyContact: {
      name: '',
      number: '',
    },
    veterinarianId: '',
    farrierId: '',
    foodPortions: {},
    boxTypeId: '',
    pricingId: '',
    identificationNumber: '',
    sex: '',
    color: '',
    microchipIdentification:
    {
      dateOfInsertion: null,
      locationOfInsertion: '',
      number: '',
      issuedBy: '',
      barcode: '',
    },
    breeder:
    {
      name: '',
      address: '',
      id: '',
    },
    sire:
    {
      name: '',
      identificationNumber: '',
    },
    dam:
    {
      name: '',
      identificationNumber: '',
    },
    branding:
    {
      brandNumber: '',
      location: '',
      brandingAgent: '',
    },
    markings:
    {
      head: '',
      legs:
      {
        frontLeft: '',
        frontRight: '',
        backLeft: '',
        backRight: '',
      },
      body: '',
    },
    documents: [
    ],
    services: [],
  });

  useEffect(() => {
    const fetchOwners = fetchOwnersPaginated(apiKey, organizationId, jwtToken, { pageSize: 1000 });
    const fetchVeterinarians = fetchPaginatedVeterinarians(apiKey, organizationId, jwtToken, { pageSize: 1000 });
    const fetchFarriers = fetchPaginatedFerries(apiKey, organizationId, jwtToken, { pageSize: 1000 });
    const fetchHorse = isEditMode ? getHorseById(id, apiKey, organizationId, jwtToken) : Promise.resolve(null);
    const fetchPermissions = isEditMode ? getHorsePermissions(id, organizationId, apiKey, jwtToken) : Promise.resolve([]);
    const fetchUsers = userType === 'admin' ? fetchPaginatedUsers(apiKey, organizationId, jwtToken, { pageSize: 1000 }) : Promise.resolve([]); // Fetch users if admin

    Promise.all([fetchOwners, fetchHorse, fetchVeterinarians, fetchFarriers, fetchPermissions, fetchUsers])
      .then(([fetchedOwners, fetchedHorse, fetchedVeterinarians, fetchedFarriers, fetchedPermissions, fetchedUsers]) => {
        setOwners(fetchedOwners.items);
        setVeterinarians(fetchedVeterinarians.items);
        setFarriers(fetchedFarriers.items);
        setPermissions(fetchedPermissions);
        setUsers(fetchedUsers.items);

        if (fetchedHorse) {
          // set horse data and convert birthday to date object
          setHorse({
            ...fetchedHorse,
            birthday: new Date(fetchedHorse.birthday),
          });


          fetchedHorse.documents.forEach((document) => {
            fetchDocumentMetadata(document.id, apiKey, organizationId, jwtToken).then((data) => {
              setHorseDocuments((prev) => [...prev, data]);
            })
              .catch((error) => {
                console.error('Failed to fetch document metadata:', error);
                setHorse((prevHorse) => {
                  return { ...prevHorse, documents: prevHorse.documents.filter(doc => doc.id !== document.id) };
                });
              });
          });

          if (fetchedHorse.ownerId) {
            return getOwnerById(fetchedHorse.ownerId, apiKey, organizationId, jwtToken);
          } else {
            return Promise.resolve(null);
          }
        }
        return Promise.resolve(null);
      })
      .then((fetchedOwner) => {
        if (fetchedOwner) {
          setCurrentOwner(fetchedOwner);
        }
        setLoading(false); // Set loading to false only when all data is loaded
      })
      .catch((error) => {
        console.error("There was an error fetching the data", error);
        setLoading(false);
      });

    const fetchSettings = async () => {
      const fetchedSettings = await fetchPaginatedFoodSettings(apiKey, organizationId, jwtToken, { pageSize: 1000 });
      setFoodSettings(fetchedSettings.items);
    };

    if (userType === 'admin') {
      // fetch all documents
      fetchDocuments(apiKey, organizationId, jwtToken).then((data) => {
        setDocuments(data);
      });
    }

    fetchSettings();
  }, [id, isEditMode]);

  useEffect(() => {
    setDataLoading(true);
    const fetchOwners = fetchOwnersPaginated(apiKey, organizationId, jwtToken, { pageSize: 1000 });
    const fetchVeterinarians = fetchPaginatedVeterinarians(apiKey, organizationId, jwtToken, { pageSize: 1000 });
    const fetchFarriers = fetchPaginatedFerries(apiKey, organizationId, jwtToken, { pageSize: 1000 });
    const fetchHorse = isEditMode ? getHorseById(id, apiKey, organizationId, jwtToken) : Promise.resolve(null);
    const fetchPermissions = isEditMode ? getHorsePermissions(id, organizationId, apiKey, jwtToken) : Promise.resolve([]);
    const fetchUsers = userType === 'admin' ? fetchPaginatedUsers(apiKey, organizationId, jwtToken, { pageSize: 1000 }) : Promise.resolve([]); // Fetch users if admin

    Promise.all([fetchOwners, fetchHorse, fetchVeterinarians, fetchFarriers, fetchPermissions, fetchUsers])
      .then(([fetchedOwners, fetchedHorse, fetchedVeterinarians, fetchedFarriers, fetchedPermissions, fetchedUsers]) => {
        setOwners(fetchedOwners.items);
        setVeterinarians(fetchedVeterinarians.items);
        setFarriers(fetchedFarriers.items);
        setPermissions(fetchedPermissions);
        setUsers(fetchedUsers.items);

        if (fetchedHorse) {
          setHorse({
            ...fetchedHorse,
            birthday: new Date(fetchedHorse.birthday),
          });

          fetchedHorse.documents.forEach((document) => {
            fetchDocumentMetadata(document.id, apiKey, organizationId, jwtToken).then((data) => {
              setHorseDocuments((prev) => [...prev, data]);
            })
              .catch((error) => {
                console.error('Failed to fetch document metadata:', error);
                setHorse((prevHorse) => {
                  return { ...prevHorse, documents: prevHorse.documents.filter(doc => doc.id !== document.id) };
                });
              });
          });

          if (fetchedHorse.ownerId) {
            return getOwnerById(fetchedHorse.ownerId, apiKey, organizationId, jwtToken);
          } else {
            return Promise.resolve(null);
          }
        }
        return Promise.resolve(null);
      })
      .then((fetchedOwner) => {
        if (fetchedOwner) {
          setCurrentOwner(fetchedOwner);
        }
        setDataLoading(false); // Set loading to false only when all data is loaded
      })
      .catch((error) => {
        console.error("There was an error fetching the data", error);
        setDataLoading(false);
      });

    const fetchSettings = async () => {
      const fetchedSettings = await fetchPaginatedFoodSettings(apiKey, organizationId, jwtToken, { pageSize: 1000 });
      setFoodSettings(fetchedSettings.items);
    };

    if (userType === 'admin') {
      // fetch all documents
      fetchDocuments(apiKey, organizationId, jwtToken).then((data) => {
        setDocuments(data);
      });
    }

    fetchSettings();
  }, [updateTrigger]);

  // Call this function whenever you need to refetch data, like after adding a document or updating horse data
  const triggerDataRefetch = () => {
    setUpdateTrigger(!updateTrigger); // Toggle the trigger to initiate refetch
  };

  useEffect(() => {
    // Check if navigating with state
    if (location.state?.fromDocument) {
      const { fromDocument } = location.state;
      updateDataFromScan(fromDocument)
    }
  }, [location.state]);

  const updateDataFromScan = (fromDocument) => {
    // Determine the document type and map data accordingly
    console.log('Updating data from scan:', fromDocument);
    if (fromDocument) {
      const horseData = fromDocument;
      setHorse(prevHorse => ({
        ...prevHorse,
        name: horseData.horseName || horse.name,
        identificationNumber: horseData.identificationNumber || horse.identificationNumber,
        breed: horseData.breed || horse.breed,
        birthday: new Date(horseData.dateOfBirth) || new Date(horse.birthday),
        sex: horseData.sex || horse.sex,
        color: horseData.color || horse.color,
        microchipIdentification: {
          dateOfInsertion: horseData.microchipIdentification.dateOfInsertion || horse.microchipIdentification.dateOfInsertion,
          location: horseData.microchipIdentification.location || horse.microchipIdentification.location,
          number: horseData.microchipIdentification.number || horse.microchipIdentification.number,
          issuedBy: horseData.microchipIdentification.issuedBy || horse.microchipIdentification.issuedBy,
          barcode: horseData.microchipIdentification.barcode || horse.microchipIdentification.barcode,
        },
        breeder: {
          name: horseData.breeder.name || horse.breeder.name,
          address: horseData.breeder.address || horse.breeder.address,
        },
        sire: {
          name: horseData.sire.name || horse.sire.name,
          identificationNumber: horseData.sire.identificationNumber || horse.sire.identificationNumber,
        },
        dam: {
          name: horseData.dam.name || horseData.dam.name,
          identificationNumber: horseData.dam.identificationNumber || horse.dam.identificationNumber,
        },
        branding: {
          brandNumber: horseData.branding.brandNumber || horse.branding.brandNumber,
          location: horseData.branding.location || horse.branding.location,
          brandingAgent: horseData.branding.brandingAgent || horse.branding.brandingAgent,
        },
        markings: {
          head: horseData.markings.head || horse.markings.head,
          legs: {
            frontLeft: horseData.markings.legs.leftForeLeg || horse.markings.legs.leftForeLeg,
            frontRight: horseData.markings.legs.rightForeLeg || horse.markings.legs.rightForeLeg,
            backLeft: horseData.markings.legs.leftHindLeg || horse.markings.legs.leftHindLeg,
            backRight: horseData.markings.legs.rightHindLeg || horse.markings.legs.rightHindLeg,
          },
          body: horseData.markings.body || horse.markings.body,
          other: horseData.markings.other || horse.markings.other,
        },
        documents: [
          ...prevHorse.documents,
        ],
        services: [
          ...prevHorse.services,
        ],
      }));
      if (fromDocument.documentId) {
        setHorse(prevHorse => ({
          ...prevHorse,
          documents: [
            ...prevHorse.documents,
            { id: fromDocument.documentId }
          ]
        }));
      }
    }
  };

  // Generic input change handler that supports nested paths
  const handleInputChange = (event) => {
    const name = event.target.name; // Could be a nested path like "microchipIdentification.number"
    const value = event.target.value;

    setHorse((prevHorse) => {
      return setNestedObjectValue(prevHorse, name, value);
    });
  };

  // Utility function to update nested state based on a path like "microchipIdentification.number"
  function setNestedObjectValue(obj, path, value) {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) => obj[key] = obj[key] || {}, obj);
    lastObj[lastKey] = value;
    return { ...obj };
  }

  const handleDelete = async () => {
    await deleteHorse(id, apiKey, organizationId, jwtToken);
    // Insert the delete horse logic here
    // Close the delete dialog
    setDeleteDialogOpen(false);
    // Navigate to another page after deletion, if necessary
    navigate('/horses');
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // Handles changes in food portions
  const handleFoodChange = (foodId, mealTime, portion) => {
    console.log('Food change:', foodId, 'Meal time:', mealTime, 'Portion:', portion);
    setHorse((prevHorse) => {
      const newState = { ...prevHorse };
      if (!newState.foodPortions) {
        newState.foodPortions = {};
      }
      if (!newState.foodPortions[foodId]) {
        newState.foodPortions[foodId] = {};
      }
      newState.foodPortions[foodId][mealTime] = portion;
      return newState;
    });
  };

  const handlePermissionsChange = (index, field, value) => {
    const updatedPermissions = [...permissions];
    updatedPermissions[index] = { ...updatedPermissions[index], [field]: value };
    if (field === 'userId') {
      updatedPermissions[index] = { ...updatedPermissions[index], userName: users.find((user) => user.id === value)?.firstname + ' ' + users.find((user) => user.id === value)?.surname };
    }
    setPermissions(updatedPermissions);
  };

  const handleAddPermission = (newPermission) => {
    setPermissions([...permissions, newPermission]);
  };

  const handleRemovePermission = (index) => {
    const updatedPermissions = permissions.filter((_, i) => i !== index);
    setPermissions(updatedPermissions);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleSave = async () => {
    const newHorse = {
      id: isEditMode ? horse.id : uuidv4(),
      name: horse.name,
      birthday: horse.birthday,
      breed: horse.breed,
      emergencyContact: horse.emergencyContact,
      veterinarianId: horse.veterinarianId,
      farrierId: horse.farrierId,
      foodPortions: horse.foodPortions,
      ownerId: horse.ownerId,
      pricingId: horse.pricingId,
      coRiders: horse.coRiders,
      boxTypeId: horse.boxTypeId,
      identificationNumber: horse.identification ? horse.identificationNumber : '',
      sex: horse.sex ? horse.sex : '',
      color: horse.color ? horse.color : '',
      microchipIdentification: horse.microchipIdentification ? horse.microchipIdentification : '',
      breeder: horse.breeder ? horse.breeder : '',
      sire: horse.sire ? horse.sire : '',
      dam: horse.dam ? horse.dam : '',
      branding: horse.branding ? horse.branding : '',
      markings: horse.markings ? horse.markings : '',
      services: horse.services ? horse.services : [],
      documents: horse.documents,
    };

    if (isEditMode) {
      await updateHorse(horse.id, newHorse, apiKey, organizationId, jwtToken);
      await upsertHorsePermissions(horse.id, permissions, organizationId, apiKey, jwtToken);
      navigate(`/horses/detail/${horse.id}`);
    } else {
      await createHorse(newHorse, apiKey, organizationId, jwtToken);
      await upsertHorsePermissions(horse.id, permissions, organizationId, apiKey, jwtToken);
      navigate('/horses');
    }
  };

  const handleMealPopupOpen = () => {
    setMealPopupOpen(true);
  };

  const handleMealPopupClose = () => {
    setMealPopupOpen(false);
  };

  const handleAddDocument = async (documentId) => {
    const newHorse = {
      id: isEditMode ? horse.id : uuidv4(),
      name: horse.name,
      birthday: horse.birthday,
      breed: horse.breed,
      emergencyContact: horse.emergencyContact,
      veterinarianId: horse.veterinarianId,
      farrierId: horse.farrierId,
      foodPortions: horse.foodPortions,
      ownerId: horse.ownerId,
      coRiders: horse.coRiders,
      boxTypeId: horse.boxTypeId,
      identificationNumber: horse.identification ? horse.identificationNumber : '',
      sex: horse.sex ? horse.sex : '',
      color: horse.color ? horse.color : '',
      microchipIdentification: horse.microchipIdentification ? horse.microchipIdentification : '',
      breeder: horse.breeder ? horse.breeder : '',
      sire: horse.sire ? horse.sire : '',
      dam: horse.dam ? horse.dam : '',
      branding: horse.branding ? horse.branding : '',
      markings: horse.markings ? horse.markings : '',
      documents: [...horse.documents, { id: documentId }],
      services: horse.services ? horse.services : [],
    };

    await updateHorse(horse.id, newHorse, apiKey, organizationId, jwtToken);
    triggerDataRefetch();
  };

  const handleUpdateData = async (docId) => {
    try {
      setDataLoading(true);

      const documentIndex = horseDocuments.findIndex(doc => doc.id === docId);
      if (documentIndex === -1) {
        console.error('Failed to update document data:', 'Document not found with id ' + docId);
      }
      const document = horseDocuments[documentIndex];

      // Check if structuredData is empty
      let structuredData = document.structuredData;
      if (!structuredData || Object.keys(structuredData).length === 0) {
        await scanDocument(docId, apiKey, organizationId, jwtToken);
        // Refetch the document metadata to get the updated structured data
        const updatedDocument = await fetchDocumentMetadata(docId, apiKey, organizationId, jwtToken);

        // Update the specific document in horseDocuments array
        const newHorseDocuments = [...horseDocuments];
        newHorseDocuments[documentIndex] = updatedDocument;
        setHorseDocuments(newHorseDocuments);

        // Use the updated document's structuredData for further processing
        structuredData = updatedDocument.structuredData;
      }
      // Now, you can use structuredData to update the horse data
      if (structuredData && Object.keys(structuredData).length > 0) {
        updateDataFromScan(structuredData);
      }
    } catch (error) {
      console.error('Failed to update document data:', error);
    } finally {
      setDataLoading(false);
      setValue(0); // Switch back to the details tab
    }
  };

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6">
          {t('HorseInfoPage.waitingText')}
        </Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          {dataLoading && <LinearProgress />}
          <Typography variant="h4" gutterBottom>
            <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
            {isEditMode ? t('HorseInfoPage.editHorse') : t('HorseInfoPage.addHorse')}
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile>
              <Tab label={t('HorseInfoPage.detailsTab')} />
              <Tab label={t('HorseInfoPage.contactPersonTab')} />
              <Tab label={t('HorseInfoPage.foodTab')} />
              <Tab label={t('HorseInfoPage.services')} />
              <Tab label={t('HorseInfoPage.documentsTab')} />
              {(userType === 'admin' || horse.ownerId === ownerId) && <Tab label={t('HorseInfoPage.permissionsTab')} />}
            </Tabs>
            {value === 0 && (
              <DetailsTab horse={horse} handleInputChange={handleInputChange} setHorse={setHorse} owners={owners} />
            )}
            {value === 1 && (
              <ContactTab horse={horse} handleInputChange={handleInputChange} setHorse={setHorse} veterinarians={veterinarians} farriers={farriers} />
            )}
            {value === 2 && (
              <FoodTab horse={horse} handleMealPopupOpen={handleMealPopupOpen} handleFoodChange={handleFoodChange} foodSettings={foodSettings} />
            )}
            {value === 3 && (
              <ServicesType horse={horse} setHorse={setHorse} />
            )}
            {value === 4 && (
              <DocumentTab
                horse={horse}
                handleInputChange={handleInputChange}
                onAddDocument={handleAddDocument}
                onRemoveDocument={(documentId) => setHorse({ ...horse, documents: horse.documents.filter(doc => doc.id !== documentId) })}
                documents={documents}
                horseDocuments={horseDocuments}
                onUpdateData={handleUpdateData}
                loading={dataLoading}
              />
            )}
            {(userType === 'admin' || horse.ownerId === ownerId) && value === 5 && (
              <PermissionsTab
                permissions={permissions}
                handlePermissionsChange={handlePermissionsChange}
                handleAddPermission={handleAddPermission}
                handleRemovePermission={handleRemovePermission}
                horse={horse} // Pass horse to the PermissionsTab
                users={users} // Pass users to the PermissionsTab
                isAdmin={userType === 'admin'}
              />
            )}
          </Box>
          <MealPopup open={mealPopupOpen} handleClose={handleMealPopupClose} />
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} sm={isEditMode && userType === 'admin' ? 6 : 12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={dataLoading}
                  fullWidth
                >
                  {t('HorseInfoPage.save')}
                </Button>
              </Grid>
              {isEditMode && userType === 'admin' && (
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteDialogOpen}
                    disabled={dataLoading}
                    fullWidth
                  >
                    {t('HorseInfoPage.delete')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>

        </Paper>
      </Container>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
      >
        <DialogTitle>{t('HorseInfoPage.deleteTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('HorseInfoPage.deleteText')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            {t('HorseInfoPage.cancel')}
          </Button>
          <Button onClick={handleDelete} color="primary">
            {t('HorseInfoPage.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HorseInfoPage;
