// src/components/AddRouteDialog.js

import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { fetchAllRoutes } from '../../services/v2/RouteService'; // Ensure this service function exists
import { useAuth } from '../../context/AuthContext'; // Assuming you have AuthContext

import { useTranslation } from 'react-i18next';

const AddRouteDialog = ({ open, onClose, onAdd }) => {
    const [option, setOption] = useState('new');
    const [availableRoutes, setAvailableRoutes] = useState([]);
    const [selectedRoutes, setSelectedRoutes] = useState([]);

    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        if (open) {
            // Fetch existing routes when dialog opens
            const loadRoutes = async () => {
                try {
                    const routes = await fetchAllRoutes(apiKey, organizationId, jwtToken);
                    setAvailableRoutes(routes);
                } catch (error) {
                    console.error(t('AddRouteDialog.errorFetchingRoutes', { error: error.message }));
                    // Optionally, set an error state to display in the UI
                }
            };
            loadRoutes();
        }
    }, [open, apiKey, organizationId, jwtToken, t]);

    const handleOptionChange = (event) => {
        setOption(event.target.value);
        setSelectedRoutes([]); // Reset selected routes when option changes
    };

    const handleToggleRoute = (routeId) => {
        setSelectedRoutes((prev) =>
            prev.includes(routeId)
                ? prev.filter((id) => id !== routeId)
                : [...prev, routeId]
        );
    };

    const handleAdd = () => {
        onAdd(option, selectedRoutes);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{t('AddRouteDialog.title')}</DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('AddRouteDialog.chooseOption')}</FormLabel>
                    <RadioGroup
                        value={option}
                        onChange={handleOptionChange}
                        row
                        sx={{ mt: 2 }}
                    >
                        <FormControlLabel
                            value="new"
                            control={<Radio />}
                            label={t('AddRouteDialog.createNewRoute')}
                        />
                        <FormControlLabel
                            value="existing"
                            control={<Radio />}
                            label={t('AddRouteDialog.selectExistingRoutes')}
                        />
                    </RadioGroup>
                </FormControl>

                {option === 'existing' && (
                    <List>
                        {availableRoutes.length === 0 ? (
                            <ListItem>
                                <ListItemText primary={t('AddRouteDialog.noExistingRoutesAvailable')} />
                            </ListItem>
                        ) : (
                            availableRoutes.map((route) => (
                                <ListItem
                                    key={route.id}
                                    button
                                    onClick={() => handleToggleRoute(route.id)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedRoutes.includes(route.id)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${route.id}` }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={`checkbox-list-label-${route.id}`} primary={route.name} />
                                </ListItem>
                            ))
                        )}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('AddRouteDialog.cancelButton')}</Button>
                <Button
                    onClick={handleAdd}
                    variant="contained"
                    color="primary"
                    disabled={option === 'existing' && selectedRoutes.length === 0}
                >
                    {t('AddRouteDialog.addButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddRouteDialog;
