// src/services/FarrierVisitService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/farrierVisits';

// Fetch all farrier visits
export async function fetchAllFarrierVisits(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}`);
  return response.data;
}

// Fetch a single farrier visit by ID
export async function fetchFarrierVisitById(apiKey, organizationId, jwtToken, id, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?farrierVisitId=${id}`);
  return response.data;
}

// Save a new farrier visit
export async function saveFarrierVisit(farrierVisit, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?ownerId=${ownerId}`;
  const response = await axiosInstance.post(url, farrierVisit);
  return response.data;
}

// Update an existing farrier visit
export async function updateFarrierVisit(farrierVisit, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?farrierVisitId=${farrierVisit.id}`;
  const response = await axiosInstance.put(url, farrierVisit);
  return response.data;
}

// Delete a farrier visit
export async function deleteFarrierVisit(farrierVisitId, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?farrierVisitId=${farrierVisitId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function fetchPaginatedFarrierVisits(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await fetchPaginatedData(API_BASE_URL, options, { apiKey, organizationId, jwtToken });
  return response;
}

export default {
  fetchAllFarrierVisits,
  fetchFarrierVisitById,
  saveFarrierVisit,
  updateFarrierVisit,
  deleteFarrierVisit,
  fetchPaginatedFarrierVisits
};
