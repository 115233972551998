import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CellTypes } from './stableConstants';
import { Button, Card, CardContent, CardHeader, TextField, Box, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import Crop75Icon from '@mui/icons-material/Crop75'; // expand
import CropDinIcon from '@mui/icons-material/CropDin'; // contract
import Grid from './Grid';
import EditorPanel from './EditorPanel';
import HorseList from './HorseList';
import { useReactToPrint } from 'react-to-print';
import PrintableLayout from './PrintableLayout';

/**
 * Example of a brand new stable layout using MUI (no Tailwind).
 * You can adapt to your existing data fetching as needed.
 */

// Mock horse data (replace with fetch from your backend)
const MOCK_HORSES = [
  { id: 'horse-1', name: 'Thunderbolt', breed: 'Thoroughbred' },
  { id: 'horse-2', name: 'Shadow', breed: 'Arabian' },
  { id: 'horse-3', name: 'Starlight', breed: 'Appaloosa' },
  { id: 'horse-4', name: 'Spirit', breed: 'Mustang' },
  { id: 'horse-5', name: 'Storm', breed: 'Quarter Horse' },
  { id: 'horse-6', name: 'Misty', breed: 'Welsh Pony' },
  { id: 'horse-7', name: 'Luna', breed: 'Andalusian' },
  { id: 'horse-8', name: 'Phoenix', breed: 'Morgan' },
  { id: 'horse-9', name: 'Apollo', breed: 'Friesian' },
  { id: 'horse-10', name: 'Daisy', breed: 'Shetland Pony' },
];

const StableDesigner = ({ initialLayout }) => {
  const [layoutName, setLayoutName] = useState('My Stable');
  const [selectedCellId, setSelectedCellId] = useState(null);
  const [isFullWidth, setIsFullWidth] = useState(false);

  const [layout, setLayout] = useState(() => {
    if (initialLayout) return initialLayout;
    // default layout if none provided
    const defaultRows = 6;
    const defaultCols = 10;
    const cells = [];
    for (let y = 0; y < defaultRows; y++) {
      for (let x = 0; x < defaultCols; x++) {
        cells.push({
          id: uuidv4(),
          x,
          y,
          type: CellTypes.EMPTY,
          width: 1,
          height: 1,
        });
      }
    }
    return {
      id: uuidv4(),
      name: 'My Stable',
      cells,
      rows: defaultRows,
      columns: defaultCols,
    };
  });

  // If we do a localStorage load for a saved layout
  useEffect(() => {
    try {
      const saved = localStorage.getItem('stable-layout');
      if (saved) {
        const parsed = JSON.parse(saved);
        setLayout(parsed);
        setLayoutName(parsed.name);
      }
    } catch (error) {
      console.error('Failed to load layout from localStorage', error);
    }
  }, []);


  const selectedCell = layout.cells.find((c) => c.id === selectedCellId) || null;

  // Assigned horse IDs
  const assignedHorseIds = layout.cells
    .filter((c) => c.type === CellTypes.STALL && c.horse)
    .map((c) => c.horse.id);

  // Available horses
  const availableHorses = MOCK_HORSES.filter(
    (h) => !assignedHorseIds.includes(h.id)
  );

  // Update a cell object in the layout
  const handleUpdateCell = (updatedCell) => {
    setLayout((prev) => {
      const newCells = prev.cells.map((c) => (c.id === updatedCell.id ? updatedCell : c));
      return { ...prev, cells: newCells };
    });
  };

  // Delete a cell => convert to empty cell
  const handleDeleteCell = (cellId) => {
    const cellToReset = layout.cells.find((c) => c.id === cellId);
    if (!cellToReset) return;
    handleUpdateCell({
      ...cellToReset,
      type: CellTypes.EMPTY,
      horse: undefined,
      room: undefined,
      width: 1,
      height: 1,
    });
    setSelectedCellId(null);
  };

  // Name change
  const handleNameChange = (e) => {
    setLayoutName(e.target.value);
    setLayout((prev) => ({ ...prev, name: e.target.value }));
  };

  // Save to localStorage
  const handleSave = () => {
    try {
      localStorage.setItem('stable-layout', JSON.stringify(layout));
      alert('Layout saved!');
    } catch (error) {
      console.error('Failed to save layout:', error);
      alert('Failed to save layout');
    }
  };

  // Toggle layout size
  const handleToggleWidth = () => {
    setIsFullWidth((prev) => !prev);
  };

  // **Drag & Drop**: swapping cells
  const handleCellDrop = (draggedCellId, targetCellId) => {
    setLayout((prev) => {
      const newCells = [...prev.cells];
      const iDragged = newCells.findIndex((c) => c.id === draggedCellId);
      const iTarget = newCells.findIndex((c) => c.id === targetCellId);
      if (iDragged === -1 || iTarget === -1) return prev;

      // swap coordinates
      const draggedCell = { ...newCells[iDragged] };
      const targetCell = { ...newCells[iTarget] };
      const tempX = draggedCell.x;
      const tempY = draggedCell.y;
      draggedCell.x = targetCell.x;
      draggedCell.y = targetCell.y;
      targetCell.x = tempX;
      targetCell.y = tempY;

      newCells[iDragged] = targetCell;
      newCells[iTarget] = draggedCell;

      alert(`Swapped two cells!`);
      return { ...prev, cells: newCells };
    });
  };

  // **Drag & Drop**: horse assignment
  const handleHorseDrop = (horseId, cellId) => {
    const cell = layout.cells.find((c) => c.id === cellId);
    if (!cell) return;
    // Must be a stall
    if (cell.type !== CellTypes.STALL) {
      alert('Horses can only go in stalls!');
      return;
    }
    // find the horse in our local array
    const foundHorse = MOCK_HORSES.find((h) => h.id === horseId);
    if (!foundHorse) return;
    handleUpdateCell({ ...cell, horse: foundHorse });
    alert(`${foundHorse.name} assigned!`);
  };

  // Direct "Assign" button
  const handleAssignHorse = (horse) => {
    if (!selectedCell) {
      alert('Select a stall first');
      return;
    }
    if (selectedCell.type !== CellTypes.STALL) {
      alert('Horses can only be assigned to stalls');
      return;
    }
    handleUpdateCell({ ...selectedCell, horse });
    alert(`${horse.name} assigned!`);
  };

  const handleSafePrint = async () => {
    try {
      console.log('printRef.current =', printRef.current);
      await Promise.resolve(handlePrint()); // wrap in promise to be safe
    } catch (err) {
      console.error('Error while printing', err);
    }
  };

  // Ref to your printable component
  const printRef = useRef(null);

  // Print function
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    documentTitle: layoutName,
    pageStyle: `
    @media print {
      body {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
      }
    }
  `
  });

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
      {/** Printable layout hidden or conditionally displayed */}
      <div style={{ position: 'absolute', top: 0, left: 0, opacity: 0, pointerEvents: 'none' }}>
        <PrintableLayout ref={printRef} layout={layout} />
      </div>

      {/** Designer header (not printed) */}
      <Box
        sx={{
          mb: 2,
          p: 2,
          border: '1px solid',
          borderColor: 'grey.200',
          borderRadius: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
        }}
        className="no-print"
      >
        <Box sx={{ flex: 1, minWidth: 200 }}>
          <Typography variant="body2" color="text.secondary">
            Stable Name
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={layoutName}
            onChange={handleNameChange}
            fullWidth
            sx={{ mt: 1 }}
          />
        </Box>
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            startIcon={<SaveIcon />}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            startIcon={isFullWidth ? <CropDinIcon /> : <Crop75Icon />}
            onClick={handleToggleWidth}
          >
            {isFullWidth ? 'Compact' : 'Expand'}
          </Button>
          <Button variant="contained" startIcon={<PrintIcon />} onClick={() => { handleSafePrint() }}>
            Print
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: isFullWidth ? 'wrap' : 'nowrap',
          gap: 2,
        }}
        className="no-print"
      >
        {/** Left: Grid */}
        <Box
          sx={{
            flex: 1,
            border: '1px solid',
            borderColor: 'grey.300',
            borderRadius: 2,
            p: 1,
            minWidth: 0,
          }}
        >
          <Grid
            layout={layout}
            onCellClick={(cell) => setSelectedCellId(cell.id)}
            selectedCellId={selectedCellId}
            onCellDrop={handleCellDrop}
            onHorseDrop={handleHorseDrop}
          />
        </Box>

        {/** Right: Editor + Horse List */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: isFullWidth ? '100%' : 300,
            minWidth: 300,
            maxHeight: 800,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <EditorPanel
              selectedCell={selectedCell}
              onUpdateCell={handleUpdateCell}
              onDeleteCell={handleDeleteCell}
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <HorseList
              horses={availableHorses}
              onAssignHorse={handleAssignHorse}
              assignedHorseIds={assignedHorseIds}
            />
          </Box>
        </Box>
      </Box>
    </Box >
  );
};

export default StableDesigner;
