// src/pages/InvitePage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import tokenHorsePermissionsService from '../services/v2/tokenHorsePermissionsService';

const InvitePage = () => {
  const { t } = useTranslation();
  const { apiKey, token, organizationId } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const inviteToken = query.get('token');

    const validateToken = async () => {
      try {
        const response = await tokenHorsePermissionsService.validateTokenAndAssignPermissions(
          inviteToken,
          apiKey,
          organizationId,
          token
        );
        setMessage(t('PermissionsTab.accessGranted'));
      } catch (error) {
        setMessage(t('PermissionsTab.invalidOrExpiredToken'));
      } finally {
        setLoading(false);
      }
    };

    if (inviteToken) {
      validateToken();
    } else {
      setMessage(t('PermissionsTab.missingToken'));
      setLoading(false);
    }
  }, [location.search, apiKey, token, organizationId, t]);

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h6">{message}</Typography>
          <Button onClick={handleGoBack} variant="contained" color="primary" sx={{ mt: 2 }}>
            {t('common.goBack')}
          </Button>
        </>
      )}
    </Box>
  );
};

export default InvitePage;
