// src/services/documentService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';
const DOCUMENT_API_BASE_URL = '/api/v2/organization/documents';

// Upload or update a document
export async function uploadDocument(documentFile, extension, apiKey, organizationId, jwtToken) {
  const formData = new FormData();
  formData.append('document', documentFile);
  formData.append('extension', extension);

  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(`${DOCUMENT_API_BASE_URL}/upload`, formData);
  return response.data;
}

// Fetch document metadata
export async function fetchDocuments(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${DOCUMENT_API_BASE_URL}/metadata`);
  return response.data;
}

export async function createDocumentMetadata(documentMetadata, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(`${DOCUMENT_API_BASE_URL}/metadata`, documentMetadata);
  return response.data;
}

// Fetch specific document metadata by ID
export async function fetchDocumentMetadata(documentId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${DOCUMENT_API_BASE_URL}/metadata?id=${documentId}`);
  return response.data;
}


// Delete a document metadata entry
export async function deleteDocumentMetadata(documentId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.delete(`${DOCUMENT_API_BASE_URL}/metadata?id=${documentId}`);
  return response.data;
}

export async function scanDocument(documentId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${DOCUMENT_API_BASE_URL}/analyze?id=${documentId}&scanType=document`);
  return response.data;
}

export async function scanItems(documentId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${DOCUMENT_API_BASE_URL}/analyze?id=${documentId}&scanType=items`);
  return response.data;
}


export async function fetchPaginatedDocuments(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  return fetchPaginatedData(`${DOCUMENT_API_BASE_URL}/metadata`, options, { apiKey, organizationId, jwtToken });
}

export default {
  uploadDocument,
  fetchDocuments,
  fetchDocumentMetadata,
  deleteDocumentMetadata,
  createDocumentMetadata,
  scanDocument,
  scanItems,
  fetchPaginatedDocuments,
};
