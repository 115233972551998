// src/pages/DeliveryNotesListPage.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { approveDocument } from '../services/v2/BusinessDocumentService';

// Replace this import with your new paginated function:
import { fetchPaginatedDeliveryNotes } from '../services/v2/DeliveryNoteService';
// e.g. a function that calls fetchPaginatedData("/api/v2/deliveryNotes", options, {...})

import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';

// Use DataViewWrapper instead of direct table/card
import DataViewWrapper from '../components/DataViewWrapper';
import SnackbarAlert from '../components/SnackbarAlert';

// Import your pagination hook
import { usePaginatedData } from '../hooks/usePaginatedData';

const DeliveryNotesListPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // Paginated data hook
  const {
    items: deliveryNotes,
    loading: notesLoading,
    continuationToken,
    loadNextPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchPaginatedDeliveryNotes(apiKey, organizationId, token, userId, options),
    { pageSize: 25 }
  );

  // Sync local view mode from preferences or device
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode || (isMobile ? 'card' : 'table')
  );

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  // Update local state whenever user prefs change
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const toggleViewMode = () => {
    const newMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newMode);
    updateDevicePreferences('viewMode', newMode);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const navigateToDetail = (noteId) => {
    navigate(`/sales-process/deliveryNotes/detail/${noteId}`);
  };

  const renderStatus = (note) => {
    if (note.cancel === true) {
      return (
        <Chip
          icon={<WarningAmberIcon />}
          label={t('deliveryNotesListPage.cancelled')}
          color="secondary"
        />
      );
    } else if (note.status === 'draft') {
      return (
        <Chip
          icon={<CancelIcon />}
          label={t('deliveryNotesListPage.draft')}
          color="default"
        />
      );
    } else if (note.status === 'approved') {
      return (
        <Chip
          icon={<CheckCircleIcon />}
          label={t('deliveryNotesListPage.approved')}
          color="primary"
        />
      );
    } else if (note.status === 'Completed') {
      return (
        <Chip
          icon={<CheckCircleIcon />}
          label={t('deliveryNotesListPage.completed')}
          color="primary"
        />
      );
    } else {
      return (
        <Chip
          icon={<CancelIcon />}
          label={t('deliveryNotesListPage.unapproved')}
          color="error"
        />
      );
    }
  };

  const approveDeliveryNote = async (noteId) => {
    try {
      await approveDocument(apiKey, organizationId, token, userId, noteId, 'deliveryNote');
      setSnackbar({
        open: true,
        message: t('deliveryNotesListPage.noteApproved'),
        severity: 'success',
      });
      // Optionally update local state or re-fetch data
    } catch (error) {
      console.error('Error approving delivery note: ', error);
      setSnackbar({
        open: true,
        message: t('deliveryNotesListPage.errorApprovingNote'),
        severity: 'error',
      });
    }
  };

  // Transform data for DataViewWrapper
  const transformData = useMemo(() => ({
    headers: [
      {
        key: 'deliveryNoteNumber',
        label: t('deliveryNotesListPage.deliveryNoteNumber'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'customerName',
        label: t('deliveryNotesListPage.customerName'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'deliveryDate',
        label: t('deliveryNotesListPage.deliveryDate'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'totalAmount',
        label: t('deliveryNotesListPage.totalAmount'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'status',
        label: t('deliveryNotesListPage.status'),
        display: { value: true, chipColor: false },
      },
    ],
    rows: deliveryNotes.map((note) => ({
      id: note.id,
      deliveryNoteNumber: { value: note.deliveryNoteNumber },
      customerName: { value: note.customerName },
      deliveryDate: {
        value: new Date(note.deliveryDate).toLocaleDateString(),
      },
      totalAmount: { value: note.totalAmount },
      status: {
        value: renderStatus(note),
      },
      actions: [
        {
          icon: <CheckCircleIcon />,
          handler:
            note.status !== 'draft' || note.cancel
              ? null
              : () => approveDeliveryNote(note.id),
          disabled: note.status !== 'draft' || note.cancel,
          label: t('deliveryNotesListPage.approve'),
        },
      ],
    })),
  }), [deliveryNotes]);

  const isLoading = notesLoading;

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      {isLoading ? (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {/* Use DataViewWrapper for table/card switching, filtering, sorting, etc. */}
          <DataViewWrapper
            structure={transformData}
            viewMode={viewMode}
            onViewModeToggle={toggleViewMode}
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            onRowClick={navigateToDetail}
            pageId="/delivery-notes"
            toolbarActions={[
              <Button
                key="add-note"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                component={Link}
                to="/sales-process/deliveryNotes/detail/new"
              >
                {t('deliveryNotesListPage.addNewDeliveryNote')}
              </Button>,
            ]}
          />

          {/* Load More button if there's more data available */}
          {continuationToken && (
            <Box mt={2} textAlign="center">
              <Button variant="contained" onClick={loadNextPage} disabled={isLoading}>
                {t('common.loadMore')}
              </Button>
            </Box>
          )}
        </>
      )}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default DeliveryNotesListPage;
