// src/components/RidingLessonBookingModal.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Autocomplete,
  Typography,
  Grid,
  IconButton,
  LinearProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

import ConfirmationDialog from './ConfirmationDialog';

// Services
import { getAllHorses, fetchHorsesPaginated } from '../services/v2/horseService';
import { fetchTeacherSettings, fetchTeacherSettingPaginatedData } from '../services/v2/TeacherSettingsService';
import { fetchBookableServiceTypes, fetchPaginatedServiceTypes } from '../services/v2/serviceTypesService';
import { getAllOwners, fetchOwnersPaginated } from '../services/v2/ownerService';
import { createBooking, updateBooking, deleteBooking } from '../services/v2/bookingService';
import { fetchLocationSettings, fetchPaginatedLocationSettings } from '../services/v2/LocationSettingsService'; // <-- Import your location settings service

import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import SnackbarAlert from './SnackbarAlert'; // Adjust the path as necessary

const RidingLessonBookingModal = ({
  open,
  onClose,
  lessonData = {},
  horses = [],
  teachers = [],
  serviceTypes = [],
  owners = [],
  onSave,
}) => {
  const initialFormData = {
    dateTime: new Date(),
    riderId: '',
    riderName: '',
    horseId: '',
    teacherId: '',
    serviceTypeId: '',
    duration: 0,
    notes: '',
    status: 'Booked',
    isPaid: false,

    /** Add buildingId to hold the selected location */
    buildingId: '',

    /** Collections */
    horses: [],
    teachers: [],
    serviceTypes: [],
    owners: [],
    buildings: [],  // We'll store filtered building options here
  };

  const { apiKey, userId, userEmail, userType, organizationId, token: jwtToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(!!lessonData?.id);
  const [formData, setFormData] = useState({
    ...initialFormData,
    ...lessonData,
    dateTime:
      lessonData && lessonData.dateTime && !isNaN(new Date(lessonData.dateTime).getTime())
        ? new Date(lessonData.dateTime)
        : new Date(),
  });

  const { t } = useTranslation();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const hasFetchedInitialData = useRef(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // Other values can be 'error', 'warning', 'info'
  });

  /**
   * Whenever the modal opens, ensure the form is updated with the current lesson data
   */
  useEffect(() => {
    setFormData((prev) => ({
      ...initialFormData,
      ...lessonData,
      dateTime:
        lessonData.dateTime && !isNaN(new Date(lessonData.dateTime).getTime())
          ? new Date(lessonData.dateTime)
          : new Date(),
    }));
    if (teachers.length === 1) {
      setFormData((current) => ({
        ...current,
        teacherId: teachers[0].id,
      }));
    }
  }, [open]);

  /**
   * Fetch Horses, Teachers, Service Types, Owners, AND Building (location) data.
   * We only fetch if these props are not provided as arguments or if we need them fresh.
   */
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // If no horses array is passed in, fetch them all
        const fetchedHorses =
          horses.length === 0
            ? (await fetchHorsesPaginated(apiKey, organizationId, jwtToken, { pageSize: 1000 })).items
            : horses;

        const fetchedTeachers =
          teachers.length === 0
            ? (await fetchTeacherSettingPaginatedData(apiKey, organizationId, jwtToken, { pageSize: 1000 })).items
            : teachers;

        const fetchedServiceTypes =
          serviceTypes.length === 0
            ? (await fetchPaginatedServiceTypes(apiKey, organizationId, jwtToken, { pageSize: 1000 })).items
            : serviceTypes;

        const fetchedOwners =
          owners.length === 0
            ? (await fetchOwnersPaginated(apiKey, organizationId, jwtToken, { pageSize: 1000 })).items
            : owners;

        // Now also fetch location settings
        const locationSettings = (await fetchPaginatedLocationSettings(apiKey, organizationId, jwtToken, { pageSize: 1000 })).items;
        // Filter to riding arenas only (modify if your condition differs)
        const allBuildings = locationSettings.flatMap((loc) =>
          loc.buildings.filter((b) => b.type.ridingArena)
        );

        setEditMode(!!lessonData?.id);

        setFormData((current) => ({
          ...current,
          dateTime:
            lessonData?.dateTime && !isNaN(new Date(lessonData.dateTime).getTime())
              ? new Date(lessonData.dateTime)
              : new Date(),
          horses: fetchedHorses,
          teachers: fetchedTeachers,
          serviceTypes: fetchedServiceTypes.filter((service) => service.isBookable),
          owners: fetchedOwners,
          buildings: allBuildings, // store the filtered building list
        }));
      } catch (err) {
        console.error('Error fetching data in RidingLessonBookingModal:', err);
        setSnackbar({
          open: true,
          message: t('RidingLessonBookingModal.loadError'),
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    if (open && !hasFetchedInitialData.current) {
      fetchData().then(() => {
        hasFetchedInitialData.current = true; // ensure fetch only happens once initially
      });
    }
  }, [open, apiKey, organizationId, jwtToken, t]);

  const handleFieldChange = (field, value) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      [field]: value,
    }));

    // If we changed the serviceTypeId, update the duration if relevant
    if (field === 'serviceTypeId') {
      const duration =
        formData.serviceTypes.find((service) => service.id === value)?.duration || '';
      setFormData((currentFormData) => ({
        ...currentFormData,
        duration,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Very basic check to ensure required fields are filled
      if (
        (!formData.riderId && !formData.riderName) ||
        !formData.horseId ||
        !formData.teacherId ||
        !formData.serviceTypeId ||
        !formData.buildingId
      ) {
        // Show an error if any required field is missing
        setSnackbar({
          open: true,
          message: t('RidingLessonBookingModal.requiredFieldsMissing'),
          severity: 'error',
        });
        setLoading(false);
        return;
      }

      const bookingData = {
        id: lessonData?.id || '',
        riderId: formData.riderId,
        riderName: formData.riderName,
        horseId: formData.horseId,
        teacherId: formData.teacherId,
        serviceTypeId: formData.serviceTypeId,
        duration: formData.duration,
        notes: formData.notes,
        dateTime: formData.dateTime,
        status: formData.status,
        isPaid: formData.isPaid,

        // Include buildingId in the request payload
        buildingId: formData.buildingId,
      };

      if (editMode) {
        await updateBooking(bookingData.id, bookingData, apiKey, organizationId, jwtToken);
      } else {
        await createBooking(bookingData, apiKey, organizationId, jwtToken);
      }

      handleClose();
      onSave(formData);

      clearFormData();

      setSnackbar({
        open: true,
        message: t('RidingLessonBookingModal.saveSuccess'),
        severity: 'success',
      });
    } catch (error) {
      console.error('Error saving the riding lesson booking:', error);
      setSnackbar({
        open: true,
        message: t('RidingLessonBookingModal.saveError'),
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const clearFormData = () => {
    setFormData({
      ...initialFormData,
      dateTime: new Date(),
    });
    hasFetchedInitialData.current = false;
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      if (lessonData?.id) {
        await deleteBooking(lessonData.id, apiKey, organizationId, jwtToken);
        setOpenDeleteConfirm(false);
        setSnackbar({
          open: true,
          message: t('RidingLessonBookingModal.deleteSuccess'),
          severity: 'success',
        });
        onClose();
      }
    } catch (error) {
      console.error('Error deleting the lesson booking:', error);
      setSnackbar({
        open: true,
        message: t('RidingLessonBookingModal.deleteError'),
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (newDate) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      dateTime: new Date(
        newDate?.getFullYear() ?? currentFormData.dateTime.getFullYear(),
        newDate?.getMonth() ?? currentFormData.dateTime.getMonth(),
        newDate?.getDate() ?? currentFormData.dateTime.getDate(),
        currentFormData.dateTime.getHours(),
        currentFormData.dateTime.getMinutes()
      ),
    }));
  };

  const handleTimeChange = (newTime) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      dateTime: new Date(
        currentFormData.dateTime.getFullYear(),
        currentFormData.dateTime.getMonth(),
        currentFormData.dateTime.getDate(),
        newTime?.getHours() ?? currentFormData.dateTime.getHours(),
        newTime?.getMinutes() ?? currentFormData.dateTime.getMinutes()
      ),
    }));
  };

  const handleAutocompleteChange = (event, newValue, reason) => {
    // Example for setting rider ID / name from an Autocomplete
    if (reason === 'selectOption' && newValue && typeof newValue === 'object') {
      setFormData((currentFormData) => ({
        ...currentFormData,
        riderId: newValue.id,
        riderName: `${newValue.firstname} ${newValue.surname}`,
      }));
      // If you also want to auto-select the horse for that owner:
      const horseForThisOwner = formData.horses.find((horse) => horse.ownerId === newValue.id);
      if (horseForThisOwner) {
        setFormData((current) => ({
          ...current,
          horseId: horseForThisOwner.id,
        }));
      }
    } else if (reason === 'clear') {
      setFormData((currentFormData) => ({
        ...currentFormData,
        riderId: '',
        riderName: '',
      }));
    } else if (typeof newValue === 'string') {
      setFormData((currentFormData) => ({
        ...currentFormData,
        riderId: '',
        riderName: newValue.trim(),
      }));
    }
  };

  const handleClose = () => {
    clearFormData();
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {editMode
            ? t('RidingLessonBookingModal.editLesson')
            : t('RidingLessonBookingModal.addLesson')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {loading && <LinearProgress />}
          <Grid container spacing={2}>
            {/* Rider (Owner) Autocomplete */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                freeSolo
                value={
                  formData?.owners?.find((owner) => owner.id === formData.riderId) || {
                    id: '',
                    firstname: formData.riderName.split(' ')[0] || '',
                    surname: formData.riderName.split(' ').slice(1).join(' '),
                    isExternal: true,
                  }
                }
                options={[
                  ...formData.owners,
                  { id: '', firstname: 'External Rider', surname: '', isExternal: true },
                ]}
                getOptionLabel={(option) =>
                  option.isExternal
                    ? `${option.firstname} ${option.surname}`.trim()
                    : `${option.firstname} ${option.surname}`.trim()
                }
                renderInput={(params) => (
                  <TextField {...params} label={t('RidingLessonBookingModal.riderName')} />
                )}
                onChange={handleAutocompleteChange}
                onInputChange={(event, newValue, reason) =>
                  handleAutocompleteChange(event, newValue, reason)
                }
                isOptionEqualToValue={(option, value) =>
                  option.id
                    ? option.id === value.id
                    : option.firstname === value.firstname && option.surname === value.surname
                }
              />
            </Grid>

            {/* Teacher */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="teacher">{t('RidingLessonBookingModal.teacher')}</InputLabel>
                <Select
                  labelId="teacher"
                  value={formData.teacherId || ''}
                  onChange={(e) => handleFieldChange('teacherId', e.target.value)}
                >
                  {formData.teachers?.map((teacher) => (
                    <MenuItem key={teacher.id} value={teacher.id}>
                      {teacher.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Service Type */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="service">{t('RidingLessonBookingModal.service')}</InputLabel>
                <Select
                  labelId="service"
                  value={formData.serviceTypeId || ''}
                  onChange={(e) => handleFieldChange('serviceTypeId', e.target.value)}
                >
                  {formData.serviceTypes?.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Date */}
            <Grid item xs={12} md={6}>
              <DatePicker
                label={t('RidingLessonBookingModal.date')}
                value={formData.dateTime}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>

            {/* Time */}
            <Grid item xs={12} md={6}>
              <TimePicker
                label={t('RidingLessonBookingModal.time')}
                value={formData.dateTime}
                onChange={handleTimeChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>

            {/* Duration */}
            <Grid item xs={12} md={6}>
              <TextField
                label={t('RidingLessonBookingModal.duration')}
                value={formData.duration || ''}
                onChange={(e) => handleFieldChange('duration', e.target.value)}
                fullWidth
              />
            </Grid>

            {/* Horse */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="horse">{t('RidingLessonBookingModal.horse')}</InputLabel>
                <Select
                  labelId="horse"
                  value={formData.horseId || ''}
                  onChange={(e) => handleFieldChange('horseId', e.target.value)}
                >
                  {formData.horses?.map((horse) => (
                    <MenuItem key={horse.id} value={horse.id}>
                      {horse.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Location (buildingId) */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel id="location-label">
                  {t('RidingLessonBookingModal.location')}
                </InputLabel>
                <Select
                  labelId="location-label"
                  value={formData.buildingId}
                  onChange={(e) => handleFieldChange('buildingId', e.target.value)}
                  required
                >
                  {formData.buildings.map((building) => (
                    <MenuItem key={building.id} value={building.id}>
                      {building.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Notes */}
            <Grid item xs={12}>
              <TextField
                label={t('RidingLessonBookingModal.notes')}
                value={formData.notes || ''}
                onChange={(e) => handleFieldChange('notes', e.target.value)}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Delete button only enabled in edit mode */}
          <Button
            onClick={() => setOpenDeleteConfirm(true)}
            color="error"
            disabled={!lessonData?.id}
            variant="contained"
          >
            {t('RidingLessonBookingModal.delete')}
          </Button>

          <Button onClick={handleClose} color="secondary" variant="contained">
            {t('RidingLessonBookingModal.cancel')}
          </Button>

          <Button onClick={handleSubmit} color="primary" variant="contained">
            {t('RidingLessonBookingModal.save')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for Deletion */}
      <ConfirmationDialog
        isOpen={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
        onConfirm={handleDeleteConfirm}
        text={t('RidingLessonBookingModal.deleteConfirmation')}
      />

      {/* Snackbar for feedback */}
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </>
  );
};

export default RidingLessonBookingModal;
