import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { fetchIcon } from '../services/v2/iconService';
import OwnerDashboard from '../components/HomeDashboards/OwnerDashboard';
import AdminDashboard from '../components/HomeDashboards/DashboardComponents/AdminDashboard';
import WorkerDashboard from '../components/HomeDashboards/WorkerDashboard';
import { styled } from '@mui/material/styles';
import FloatingIconButton from '../components/FloatingIconButton';
import { usePermissions } from '../context/PermissionsContext';

const Home = () => {
    const { t } = useTranslation();
    const { apiKey, userType, userId, userEmail, organizationId, organizationName, ownerId, token } = useAuth();
    const jwtToken = token;
    const [iconUrl, setIconUrl] = useState(null);
    const { entitlements } = usePermissions();

    const ResponsivePaper = styled(Paper)(({ theme }) => ({
        padding: '15px',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    }));

    // Determine the entitlement type
    const entitlement = entitlements.find(entitlement => entitlement.isActive) || {};
    const isEquistab = entitlement.internalName?.includes('equistab');
    const isErp = entitlement.internalName?.includes('erp');

    useEffect(() => {
        const fetchIconUrl = async () => {
            try {
                const iconData = await fetchIcon(apiKey, organizationId, token);
                if (iconData) {
                    setIconUrl(`data:image/png;base64,${iconData.icon}`);
                }
            } catch (error) {
                console.error('Error fetching icon:', error);
            }
        };

        if (organizationId) {
            fetchIconUrl();
        }
    }, [apiKey, organizationId, token]);

    // Customize the welcome message based on entitlement type
    const getWelcomeMessage = () => {
        if (isEquistab) {
            return t('Home.welcomeEquistab'); // Assuming you have this key in your translation files
        } else if (isErp) {
            return t('Home.welcomeErp'); // Assuming you have this key in your translation files
        } else {
            return t('Home.welcome'); // Default welcome message
        }
    };

    const getSubtitleMessage = () => {
        if (isEquistab) {
            return t('Home.enjoyEquistab');
        } else if (isErp) {
            return t('Home.enjoyErp');
        } else {
            return t('Home.enjoy');
        }
    };

    const getEntitlementType = () => {
        if (isEquistab) {
            return 'equistab';
        } else if (isErp) {
            return 'erp';
        } else {
            return 'default';
        }
    }

    return (
        <Container>
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h3" gutterBottom>{getWelcomeMessage()}</Typography>
                <ResponsivePaper elevation={3}>
                    {iconUrl && (
                        <img src={iconUrl} alt="Organization Icon" style={{ height: '100px', width: 'auto', marginRight: '15px' }} />
                    )}
                    <Box>
                        {organizationName &&
                            <Typography variant="h6" gutterBottom>
                                {getEntitlementType() === 'equistab' ? t('Home.range') : t('Home.organization')}: <span style={{ fontWeight: '500' }}>{organizationName}</span>
                            </Typography>}
                        <Typography variant="subtitle1" color="textSecondary">
                            {getSubtitleMessage()}
                        </Typography>
                    </Box>
                </ResponsivePaper>
            </Box>
            {userType === 'admin' ? (
                <AdminDashboard apiKey={apiKey} organizationId={organizationId} jwtToken={jwtToken} />
            ) : userType === 'worker' ? (
                <WorkerDashboard userId={userId} apiKey={apiKey} organizationId={organizationId} jwtToken={jwtToken} />
            ) : (
                <OwnerDashboard userEmail={userEmail} userId={userId} ownerId={ownerId} apiKey={apiKey} organizationId={organizationId} jwtToken={jwtToken} />
            )}
            <FloatingIconButton optionCount="all" userType={userType} />
        </Container>
    );
};

export default Home;
