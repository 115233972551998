// src/services/AdminDashboardService.js

import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/v2/dashboard';

// Fetch dashboard statistics (expenses and income over the last 12 months)
export async function fetchDashboardStatistics(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}/statistics?userId=${userId}`);
  return response.data;
}

export async function fetchMonthlyCategorizedTransactions(apiKey, organizationId, jwtToken, userId, month, year) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}/statistics/monthlyTransactions?userId=${userId}&month=${month}&year=${year}`);
  return response.data;
}

export default {
  fetchDashboardStatistics
};
