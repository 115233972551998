// src/services/paginatedApiService.js
import axiosInstance, {
  setApiKeyHeader,
  setOrganizationIdHeader,
  setAuthHeader,
} from '../../apiConfig/axiosConfig.js';

/**
 * Generic helper to fetch data with pagination, filtering, and sorting.
 * @param {string} resourcePath - e.g., '/api/vetvisits', '/api/salesOrders'
 * @param {Object} options
 * @param {number} [options.pageSize=25]
 * @param {string} [options.continuationToken=null]
 * @param {string} [options.filter={}] - e.g., { 'c.visitDate': '2022-01-01' } or { 'c.status': '*open*' }
 * @param {string} [options.sort={}] - e.g., { 'visitDate': 'desc' }
 * @returns {Object} { items, continuationToken }
 */
export async function fetchPaginatedData(
  resourcePath,
  {
    pageSize = 25,
    continuationToken = null,
    filter = {},
    sort = {},
  } = {},
  { apiKey, organizationId, jwtToken }
) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  let queryParams = [];

  if (pageSize) queryParams.push(`pageSize=${pageSize}`);
  if (continuationToken) queryParams.push(`continuationToken=${continuationToken}`);
  if (sort.field) queryParams.push(`$sort.${sort.field}=${sort.order}`);

  Object.entries(filter).forEach(([field, value]) => {
    queryParams.push(`filter.${field}=${value}`);
  });

  // Build the URL with proper handling of existing queries
  let url = resourcePath;
  if (queryParams.length > 0) {
    const separator = url.includes('?') ? '&' : '?';
    url += `${separator}${queryParams.join('&')}`;
  }

  const response = await axiosInstance.get(url);
  return response.data;
}

