// src/services/BusinessDocumentService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/v2/approvalService';


export async function approveDocument(apiKey, organizationId, token, userId, documentId, documentType) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(token, userId);
    const response = await axiosInstance.put(`${API_BASE_URL}?id=${documentId}&documentType=${documentType}&status=approve`);
    return response.data;
}

export async function rejectDocument(apiKey, organizationId, token, userId, documentId, documentType) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(token, userId);
    const response = await axiosInstance.put(`${API_BASE_URL}?id=${documentId}&documentType=${documentType}&status=reject`);
    return response.data;
}

export async function fetchDocumentById(documentType, documentId, apiKey, organizationId, token) {
    let endpoint;
    switch (documentType) {
        case 'costEstimation':
            endpoint = `/api/v2/costEstimations?id=${documentId}`;
            break;
        case 'salesOrder':
            endpoint = `/api/v2/salesOrders?id=${documentId}`;
            break;
        case 'deliveryNote':
            endpoint = `/api/v2/deliveryNotes?id=${documentId}`;
            break;
        case 'invoice':
            endpoint = `/api/v2/invoices?id=${documentId}`;
            break;
        default:
            throw new Error(`Unsupported document type: ${documentType}`);
    }

    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(token);
    const response = await axiosInstance.get(endpoint);
    return response.data;
}