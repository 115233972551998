// src/context/setPolicyBasedOnUrl.js

import { msalConfig } from './authConfig';
import { policies } from './authPolicies';
import { PublicClientApplication } from '@azure/msal-browser';


export function setPolicyBasedOnUrl() {
    const pathname = window.location.pathname;

    // Priority 1: force policy based on route
    if (pathname.includes('/password-password')) {
        return new PublicClientApplication(msalConfig(policies.passwordResetPolicy));
    }
    if (pathname.includes('/signup')) {
        return new PublicClientApplication(msalConfig(policies.signUpPolicy));
    }

    // Priority 2: fallback to localStorage
    const storedPolicy = localStorage.getItem('b2cPolicy');
    if (storedPolicy) {
        return new PublicClientApplication(msalConfig(storedPolicy));
    }

    // Priority 3: default
    return new PublicClientApplication(msalConfig(policies.signInPolicy));
}

