// src/components/DataImportTable.js
import React, { useState, useRef } from 'react';
import {
    Box,
    Button,
    Typography,
    Snackbar,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    CircularProgress,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Autocomplete,
    Paper,
} from '@mui/material';
import Papa from 'papaparse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { styled } from '@mui/material/styles';

import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

import importDefinitions from './DataImporter/importDefinitions.js';
import { importData } from '../../services/v2/importService.js';

// ====== Styled Components ======
const StyledTextField = styled(TextField)(({ theme }) => ({
    marginTop: theme.spacing(1),
    width: '100%',
}));

const RowCard = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

const DataImportTable = () => {
    const { t } = useTranslation();
    const { apiKey, organizationId, token } = useAuth();

    const [importType, setImportType] = useState('');
    const [typeDefinition, setTypeDefinition] = useState(null);
    const [dynamicData, setDynamicData] = useState({});
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [errors, setErrors] = useState({});
    const [warnings, setWarnings] = useState({});
    const [message, setMessage] = useState('');
    const [importing, setImporting] = useState(false);

    const tableRef = useRef(null);

    // ========== Handle Import Type Change ==========
    const handleImportTypeChange = async (event) => {
        const newType = event.target.value;
        setImportType(newType);
        setRows([]);
        setErrors({});
        setWarnings({});
        setMessage('');

        const def = importDefinitions[newType];
        setTypeDefinition(def || null);

        // Prepare columns from the schema
        if (def?.schema) {
            setColumns(Object.keys(def.schema));
        } else {
            setColumns([]);
        }

        // If there is a fetchData for this type, call it
        if (def?.fetchData) {
            const results = await def.fetchData({ apiKey, organizationId, token });
            setDynamicData(results || {});
        } else {
            setDynamicData({});
        }
    };

    // ========== Download Template ==========
    const handleDownloadTemplate = () => {
        if (!importType) {
            setMessage('Please select an import type first');
            return;
        }

        const def = importDefinitions[importType];
        const exampleRows = def?.exampleData || [];

        if (exampleRows.length === 0) {
            setMessage('No template data available for this type');
            return;
        }

        const csv = Papa.unparse(exampleRows);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${importType}_template.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // ========== Parsing & Validation ==========
    const parsePastedData = (dataString) => {
        Papa.parse(dataString, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const { data, errors: parseErrors } = results;
                if (parseErrors.length) {
                    setMessage(parseErrors[0].message);
                    return;
                }
                // Convert each row to an object with an ID
                const newRows = data.map((row, idx) => ({ id: idx, ...row }));
                setRows(newRows);
                validateAllRows(newRows);
            },
            error: (error) => {
                console.error(error);
                setMessage(t('DataImportTable.messages.importFailure'));
            },
        });
    };

    const validateAllRows = (data) => {
        const newErrors = {};
        const newWarnings = {};

        data.forEach((row, rowIndex) => {
            const { rowErrors, rowWarnings } = validateRow(row);
            if (Object.keys(rowErrors).length > 0) {
                newErrors[rowIndex] = rowErrors;
            }
            if (Object.keys(rowWarnings).length > 0) {
                newWarnings[rowIndex] = rowWarnings;
            }
        });

        setErrors(newErrors);
        setWarnings(newWarnings);
    };

    const validateRow = (row) => {
        const rowErrors = {};
        const rowWarnings = {};

        // ========== Basic Type checks (shared) ==========
        if (typeDefinition?.schema) {
            for (const key of columns) {
                const expectedType = typeDefinition.schema[key];
                const value = row[key];

                if (value == null || value === '') {
                    continue;
                }

                switch (expectedType) {
                    case 'bool':
                        if (
                            value.toString().toLowerCase() !== 'true' &&
                            value.toString().toLowerCase() !== 'false'
                        ) {
                            rowErrors[key] = t('Invalid boolean');
                        }
                        break;
                    case 'int32':
                        if (isNaN(parseInt(value, 10))) {
                            rowErrors[key] = t('Invalid integer');
                        }
                        break;
                    case 'double':
                        if (isNaN(parseFloat(value))) {
                            rowErrors[key] = t('Invalid number');
                        }
                        break;
                    case 'datetime':
                        if (isNaN(new Date(value).getTime())) {
                            rowErrors[key] = t('Invalid date');
                        }
                        break;
                    case 'array':
                        // e.g., if needed: row[key] = value.split(',').map((x) => x.trim());
                        break;
                    default:
                        // string or unknown
                        break;
                }
            }
        }

        // ========== Type-Specific Validation Hook ==========
        if (typeDefinition?.validateRow) {
            const { rowErrors: customErrors, rowWarnings: customWarnings } = typeDefinition.validateRow(
                row,
                typeDefinition.schema,
                t,
                dynamicData
            );
            Object.assign(rowErrors, customErrors);
            Object.assign(rowWarnings, customWarnings);
        }

        return { rowErrors, rowWarnings };
    };

    const handleCellChange = (rowIndex, key, newValue) => {
        const updatedRows = [...rows];
        updatedRows[rowIndex] = { ...updatedRows[rowIndex], [key]: newValue };
        setRows(updatedRows);

        // Re-validate that row
        const { rowErrors, rowWarnings } = validateRow(updatedRows[rowIndex]);

        // Update global errors
        const updatedErrors = { ...errors };
        if (Object.keys(rowErrors).length) {
            updatedErrors[rowIndex] = rowErrors;
        } else {
            delete updatedErrors[rowIndex];
        }
        setErrors(updatedErrors);

        // Update global warnings
        const updatedWarnings = { ...warnings };
        if (Object.keys(rowWarnings).length) {
            updatedWarnings[rowIndex] = rowWarnings;
        } else {
            delete updatedWarnings[rowIndex];
        }
        setWarnings(updatedWarnings);
    };

    // ========== Submitting Data ==========
    const handleSubmit = async () => {
        if (!rows.length) {
            setMessage(t('No data to import'));
            return;
        }
        if (Object.keys(errors).length > 0) {
            setMessage(t('Please fix all errors first'));
            return;
        }

        setImporting(true);

        const processedData = rows.map((row) => {
            const newRow = { ...row };
            delete newRow.id;

            // Basic type casting
            if (typeDefinition?.schema) {
                for (const key in typeDefinition.schema) {
                    const expectedType = typeDefinition.schema[key];
                    const val = newRow[key];
                    if (val == null || val === '') continue;

                    switch (expectedType) {
                        case 'bool':
                            newRow[key] = val.toString().toLowerCase() === 'true';
                            break;
                        case 'int32':
                            newRow[key] = parseInt(val, 10);
                            break;
                        case 'double':
                            newRow[key] = parseFloat(val);
                            break;
                        case 'datetime':
                            newRow[key] = new Date(val).toISOString();
                            break;
                        case 'array':
                            newRow[key] = val.split(',').map((i) => i.trim());
                            break;
                        default:
                            break;
                    }
                }
            }

            // Example: build 'address' for OwnerData
            if (importType === 'OwnerData') {
                const address = {};
                ['address.street', 'address.zipCode', 'address.city', 'address.country'].forEach(
                    (field) => {
                        if (newRow[field]) {
                            const subKey = field.split('.')[1];
                            address[subKey] = newRow[field];
                            delete newRow[field];
                        }
                    }
                );
                newRow.address = address;
            }

            // Common fields if needed
            newRow.organizationId = organizationId;
            newRow.archived = false;

            return newRow;
        });

        try {
            const response = await importData(
                processedData,
                importType,
                apiKey,
                organizationId,
                token
            );

            if (response.status === 200 || response.status === 201) {
                setMessage(t(`Imported ${processedData.length} rows successfully!`));
                setRows([]);
                setErrors({});
                setWarnings({});
            } else {
                setMessage(t('Import failed.'));
            }
        } catch (error) {
            console.error(error);
            setMessage(t('Import failed with error.'));
        } finally {
            setImporting(false);
        }
    };

    const handleCloseSnackbar = () => setMessage('');
    const handlePasteEvent = (event) => {
        event.preventDefault();
        const text = event.clipboardData.getData('Text');
        parsePastedData(text);
    };
    const handlePasteFromClipboard = async () => {
        try {
            const text = await navigator.clipboard.readText();
            parsePastedData(text);
        } catch (e) {
            console.error(e);
            setMessage(t('Failed to read clipboard.'));
        }
    };

    const availableTypes = Object.entries(importDefinitions);

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                {t('Import Data')}
            </Typography>

            {/* ========== Import Type Selection ========== */}
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="import-type-label">Select Data Type</InputLabel>
                <Select
                    labelId="import-type-label"
                    value={importType}
                    label="Select Data Type"
                    onChange={handleImportTypeChange}
                >
                    {availableTypes.map(([typeKey, def]) => (
                        <MenuItem key={typeKey} value={typeKey}>
                            {def.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* ========== Field Descriptions ========== */}
            {typeDefinition?.fieldDescriptions && (
                <Box mt={2}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">Field Descriptions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {Object.keys(typeDefinition.fieldDescriptions).map((key) => (
                                <Box key={key} mb={1}>
                                    <strong>{key}</strong>: {t(typeDefinition.fieldDescriptions[key])}
                                </Box>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}

            {/* ========== Paste Data + Template Download ========== */}
            {importType && (
                <Box mt={2} display="flex" alignItems="center" gap={2}>
                    <Button variant="contained" onClick={handlePasteFromClipboard}>
                        Paste Data
                    </Button>
                    <Button variant="outlined" onClick={handleDownloadTemplate}>
                        Download CSV Template
                    </Button>
                    <Typography variant="body2" color="textSecondary">
                        Or press <strong>Ctrl+V</strong> / <strong>Cmd+V</strong>
                    </Typography>
                </Box>
            )}

            {/* ========== Card-based Rows ========== */}
            {importType && rows.length > 0 && (
                <Box
                    ref={tableRef}
                    onPaste={handlePasteEvent}
                    mt={2}
                >
                    {rows.map((row, rowIndex) => (
                        <RowCard key={row.id}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle1">
                                        Row {rowIndex + 1}
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {/* Render each column as a field */}
                                    {columns.map((col) => {
                                        const cellError = errors[rowIndex]?.[col];
                                        const cellWarning = warnings[rowIndex]?.[col];
                                        const cellValue = row[col] ?? '';

                                        // For item data with dynamic autocomplete
                                        const isItemData = importType === 'ItemData';
                                        const isCategory = col === 'category';
                                        const isSubcategory = col === 'subcategory';
                                        const isMeasureUnit = col === 'measureUnit';

                                        if (isItemData && (isCategory || isSubcategory || isMeasureUnit)) {
                                            // Build suggestion list
                                            let options = [];

                                            if (isCategory) {
                                                options = (dynamicData.categories || []).map((c) => c.categoryName);
                                            } else if (isSubcategory) {
                                                const selectedCatName = row['category'];
                                                const catObj = (dynamicData.categories || []).find(
                                                    (c) => c.categoryName === selectedCatName
                                                );
                                                if (catObj?.subcategories) {
                                                    options = catObj.subcategories.map((s) => s.subcategoryName);
                                                }
                                            } else if (isMeasureUnit) {
                                                options = (dynamicData.measureUnits || []).map((m) => m.nameLong);
                                            }

                                            return (
                                                <Box key={col} mt={2}>
                                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                        {col}
                                                    </Typography>
                                                    <Box display="flex" alignItems="center">
                                                        <Autocomplete
                                                            freeSolo
                                                            fullWidth
                                                            options={options}
                                                            value={cellValue}
                                                            onInputChange={(event, newInputValue) => {
                                                                handleCellChange(rowIndex, col, newInputValue);
                                                            }}
                                                            onChange={(event, newOptionValue) => {
                                                                if (typeof newOptionValue === 'string') {
                                                                    handleCellChange(rowIndex, col, newOptionValue);
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    error={!!cellError}
                                                                />
                                                            )}
                                                        />
                                                        {cellError && (
                                                            <Tooltip title={cellError}>
                                                                <ErrorOutlineIcon color="error" fontSize="small" sx={{ ml: 0.5 }} />
                                                            </Tooltip>
                                                        )}
                                                        {!cellError && cellWarning && (
                                                            <Tooltip title={cellWarning}>
                                                                <WarningAmberIcon
                                                                    color="warning"
                                                                    fontSize="small"
                                                                    sx={{ ml: 0.5 }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </Box>
                                                </Box>
                                            );
                                        } else {
                                            // Normal text field
                                            return (
                                                <Box key={col} mt={2}>
                                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                        {col}
                                                    </Typography>
                                                    <Box display="flex" alignItems="center">
                                                        <StyledTextField
                                                            variant="outlined"
                                                            size="small"
                                                            value={cellValue}
                                                            onChange={(e) => handleCellChange(rowIndex, col, e.target.value)}
                                                            error={!!cellError}
                                                        />
                                                        {cellError && (
                                                            <Tooltip title={cellError}>
                                                                <ErrorOutlineIcon color="error" fontSize="small" sx={{ ml: 0.5 }} />
                                                            </Tooltip>
                                                        )}
                                                        {!cellError && cellWarning && (
                                                            <Tooltip title={cellWarning}>
                                                                <WarningAmberIcon
                                                                    color="warning"
                                                                    fontSize="small"
                                                                    sx={{ ml: 0.5 }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </Box>
                                                </Box>
                                            );
                                        }
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        </RowCard>
                    ))}
                </Box>
            )}

            {/* ========== Submit Button ========== */}
            {importType && rows.length > 0 && (
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleSubmit}
                        disabled={importing}
                    >
                        {importing ? <CircularProgress size={24} /> : 'Confirm Import'}
                    </Button>
                </Box>
            )}

            {/* ========== Snackbar ========== */}
            <Snackbar open={!!message} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DataImportTable;
