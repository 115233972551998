// src/pages/VaccinationPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  Typography,
  Box,
  Button,
  LinearProgress,
  Switch,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import VaccinationModal from '../components/VaccinationModal';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useDateLocale } from '../hooks/useDateLocale';
import { format } from 'date-fns';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../context/UserPreferencesContext';

// New hooks & services for pagination
import { usePaginatedData } from '../hooks/usePaginatedData';
import { fetchPaginatedVaccinations } from '../services/v2/VaccinationService';
import { fetchHorsesPaginated, getHorsesByUserId } from '../services/v2/horseService';
import { fetchPaginatedVeterinarians } from '../services/v2/VeterinarianService';

// DataViewWrapper replaces direct usage of GenericTableView / GenericCardView
import DataViewWrapper from '../components/DataViewWrapper';

const VaccinationPage = () => {
  const { t } = useTranslation();
  const dateLocale = useDateLocale();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    apiKey,
    organizationId,
    token,
    ownerId,
    userId,
    userType,
  } = useAuth();
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // ------------------ LOCAL STATE ------------------
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  // We’ll store all horses and all vets in these states (fetched via loop or direct calls).
  const [horses, setHorses] = useState([]);
  const [vets, setVets] = useState([]);

  // We track loading states for horses and vets
  const [horsesLoading, setHorsesLoading] = useState(false);
  const [vetsLoading, setVetsLoading] = useState(false);
  const ownerHorses = horses.filter(h => h.ownerId === ownerId);
  const otherHorses = horses.filter(h => h.ownerId !== ownerId);
  
  // Combine them so owned horses appear first
  const sortedHorses = [...ownerHorses, ...otherHorses];
  // ------------------ PAGINATED HOOK FOR VACCINATIONS ------------------
  // This provides vaccinations + filter/sort, continuation token, etc.
  const {
    items: vaccinations,
    continuationToken,
    loading: vaccinationsLoading,
    loadNextPage,
    loadFirstPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchPaginatedVaccinations(apiKey, organizationId, token, options),
    { pageSize: 25 }
  );

  // Combine loading states
  const isLoading = vaccinationsLoading || horsesLoading || vetsLoading;

  // ------------------ FETCH ALL HORSES (via pagination for admin) ------------------
  useEffect(() => {
    let isCancelled = false;
    const fetchAllHorses = async () => {
      setHorsesLoading(true);
      try {
        let allHorses = [];
        if (userType === 'admin') {
          // fetch in a loop until there's no continuationToken
          let nextToken = null;
          do {
            const response = await fetchHorsesPaginated(apiKey, organizationId, token, {
              pageSize: 1000,
              continuationToken: nextToken,
            });
            allHorses = [...allHorses, ...(response.items || [])];
            nextToken = response.continuationToken;
          } while (nextToken && !isCancelled);
        } else {
          // For non-admin users, limit to the user’s horses
          const response = await getHorsesByUserId(userId, apiKey, organizationId, token);
          allHorses = response?.items || [];
        }
        if (!isCancelled) {
          // Filter only those that allow vaccination handling
          setHorses(allHorses.filter((h) => h.permissions?.includes('handleVaccine')));
        }
      } catch (error) {
        console.error('Failed to fetch horses:', error);
      } finally {
        if (!isCancelled) setHorsesLoading(false);
      }
    };
    fetchAllHorses();
    return () => {
      isCancelled = true;
    };
  }, [apiKey, organizationId, token, userId, userType]);

  // ------------------ FETCH ALL VETS (via pagination) ------------------
  useEffect(() => {
    let isCancelled = false;
    const fetchAllVets = async () => {
      setVetsLoading(true);
      try {
        let allVets = [];
        let nextToken = null;
        do {
          const response = await fetchPaginatedVeterinarians(apiKey, organizationId, token, {
            pageSize: 1000,
            continuationToken: nextToken,
          });
          allVets = [...allVets, ...(response.items || [])];
          nextToken = response.continuationToken;
        } while (nextToken && !isCancelled);

        if (!isCancelled) setVets(allVets);
      } catch (error) {
        console.error('Failed to fetch veterinarians:', error);
      } finally {
        if (!isCancelled) setVetsLoading(false);
      }
    };
    fetchAllVets();
    return () => {
      isCancelled = true;
    };
  }, [apiKey, organizationId, token]);

  // ------------------ VIEW MODE ------------------
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode
      ? devicePreferences.viewMode
      : isMobile
        ? 'card'
        : 'table'
  );

  useEffect(() => {
    // If the user changes preferences or device changes,
    // sync local viewMode.
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const toggleViewMode = () => {
    const newView = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newView);
    updateDevicePreferences('viewMode', newView);
  };

  // ------------------ HANDLERS FOR MODAL ------------------
  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentRecord(null);

    // Reload the first page of vaccinations after closing the modal
    loadFirstPage();
  };

  const handleAddVaccination = () => {
    setCurrentRecord(null);
    setModalOpen(true);
  };

  const handleEditVaccination = (vaccinationId) => {
    const record = vaccinations.find((v) => v.id === vaccinationId);
    setCurrentRecord(record || null);
    setModalOpen(true);
  };


  // ------------------ TRANSFORM DATA FOR DataViewWrapper ------------------
  // You can adjust the table headers and row data as needed.
  const vaccinationTypesMapping = {
    Tetanus: t('Vaccination.Types.Tetanus'),
    EquineInfluenza: t('Vaccination.Types.EquineInfluenza'),
    EquineHerpesvirus: t('Vaccination.Types.EquineHerpesvirus'),
    WestNileVirus: t('Vaccination.Types.WestNileVirus'),
    Rabies: t('Vaccination.Types.Rabies'),
    EquineViralArteritis: t('Vaccination.Types.EquineViralArteritis'),
    Rotavirus: t('Vaccination.Types.Rotavirus'),
  };

  const transformData = (vaccineData) => ({
    headers: [
      {
        key: 'horseName', label: t('Vaccination.Page.TableHeaders.HorseName'),
        display: { value: true }
      },
      {
        key: 'vaccinationName', label: t('Vaccination.Page.TableHeaders.VaccinationName'),
        display: { value: true }
      },
      {
        key: 'vaccinationDate', label: t('Vaccination.Page.TableHeaders.VaccinationDate'),
        display: { value: true }
      },
      {
        key: 'nextVaccinationDate', label: t('Vaccination.Page.TableHeaders.NextVaccinationDate'),
        display: { value: true }
      },
    ],
    rows: vaccineData.map((vaccination) => {
      const horse = horses.find((h) => h.id === vaccination.horseId);
      return {
        id: vaccination.id,
        horseName: {
          value: horse?.name || t('Vaccination.Page.UnknownHorse'),
          icon: null,
        },
        vaccinationName: {
          value: vaccinationTypesMapping[vaccination.vaccinationName] || vaccination.vaccinationName,
          icon: null,
        },
        vaccinationDate: {
          value: vaccination.vaccinationDate
            ? format(new Date(vaccination.vaccinationDate), 'P', { locale: dateLocale })
            : t('Vaccination.Page.NoDate'),
          icon: null,
        },
        nextVaccinationDate: {
          value: vaccination.nextVaccinationDate
            ? format(new Date(vaccination.nextVaccinationDate), 'P', { locale: dateLocale })
            : t('Vaccination.Page.NoDate'),
          icon: null,
        },
      };
    }),
  });

  // ------------------ RENDER ------------------
  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('Vaccination.Page.Title')}
      </Typography>


      {/* Loading Indicator */}
      {isLoading && <LinearProgress />}

      {/* DataViewWrapper for the main listing of vaccinations */}
      <DataViewWrapper
        structure={transformData(vaccinations)}
        viewMode={viewMode}
        onViewModeToggle={toggleViewMode}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
        onRowClick={handleEditVaccination}
        pageId="/vaccinations"
        toolbarActions={[
          <Button
            key="add-vaccination"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddVaccination}
          >
            {t('Vaccination.Page.AddNewVaccinationButton')}
          </Button>,
        ]}
      // first list owners horses based on ownersHorsesId, and then all horses
        predefinedFilters={
          sortedHorses.map(h => ({
            label: `${h.name}`,    // or e.g. "My Horse: {h.name}"
            field: 'horseId',
            value: h.id,
            enabled: false,       // or omit this – we'll let DataViewWrapper handle
          }))
        }
        enableFilterControls={false}
        enableSortControls={false}
      />

      {/* "Load More" button if there's a continuationToken for vaccinations */}
      {continuationToken && (
        <Box mt={2} textAlign="center">
          <Button variant="contained" onClick={loadNextPage} disabled={vaccinationsLoading}>
            {t('common.loadMore')}
          </Button>
        </Box>
      )}

      {/* Vaccination Modal for Add/Edit */}
      <VaccinationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        record={currentRecord}
        horses={horses}
        vets={vets}
      />
    </Paper>
  );
};

export default VaccinationPage;
