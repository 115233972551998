// src/services/InvoicePaymentSettingsService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/v2/invoice-payment-settings';

export async function fetchInvoicePaymentSettings(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function updateInvoicePaymentSettings(settings, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, settings);
  return response.data;
}


export default {
  fetchInvoicePaymentSettings,
  updateInvoicePaymentSettings,
};
