export const CellTypes = {
    EMPTY: 'empty',
    STALL: 'stall',
    ROOM: 'room'
  };
  
  export const RoomTypes = {
    TACK: 'tack',
    FEED: 'feed',
    WASH: 'wash',
    STORAGE: 'storage',
    OFFICE: 'office',
    OTHER: 'other'
  };
  