// src/services/CostEstimationService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/costEstimations';

// Fetch all cost estimations
export async function fetchAllCostEstimations(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Fetch a single cost estimation by ID
export async function fetchCostEstimationById(apiKey, organizationId, jwtToken, id, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}&userId=${userId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Save a new cost estimation
export async function saveCostEstimation(estimation, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.post(url, estimation);
  return response.data;
}

// Update an existing cost estimation
export async function updateCostEstimation(estimation, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${estimation.id}&userId=${userId}`;
  const response = await axiosInstance.put(url, estimation);
  return response.data;
}

// Delete a cost estimation by ID
export async function deleteCostEstimation(estimationId, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${estimationId}&userId=${userId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function downloadCostEstimationPdf(apiKey, organizationId, jwtToken, estimationId, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/pdf?id=${estimationId}&userId=${userId}&action=getEstimation`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}

export async function sendCostEstimationPdf(apiKey, organizationId, jwtToken, estimationId, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/pdf?id=${estimationId}&userId=${userId}&action=sendEstimation`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}

export async function fetchUnsentCostEstimations(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}&status=unsent`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function approveCostEstimation(apiKey, organizationId, jwtToken, userId, estimationId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  // e.g. put or post on new v2 route
  const response = await axiosInstance.post(`${API_BASE_URL}/${estimationId}/approve`, {
    approvedBy: userId,
  });
  return response.data;
}

// 1) New Paginated fetch:
export async function fetchCostEstimationsPaginated(apiKey, organizationId, jwtToken, options) {
  // You might need to adjust the resourcePath if your final route is different
  return fetchPaginatedData(API_BASE_URL, options, {
    apiKey,
    organizationId,
    jwtToken
  });
}

export default {
  fetchAllCostEstimations,
  fetchCostEstimationById,
  saveCostEstimation,
  updateCostEstimation,
  deleteCostEstimation,
  downloadCostEstimationPdf,
  sendCostEstimationPdf,
  fetchUnsentCostEstimations,
  approveCostEstimation,
  fetchCostEstimationsPaginated
};
