import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/horses';

export async function getAllHorses(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function getHorsesByUserId(userId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?userId=${userId}`);
  return response.data;
}

export async function getHorsesByOwnerID(ownerId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?ownerId=${ownerId}`);
  return response.data;
}

export async function getHorsesByOwnerEmail(ownerEmail, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?ownerEmail=${ownerEmail}`);
  return response.data;
}

export async function getHorseById(id, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}/${id}`);
  return response.data;
}

export async function createHorse(horse, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, horse);
  return response.data;
}

export async function updateHorse(id, horse, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.put(`${API_BASE_URL}/${id}`, horse);
  return response.data;
}

export async function deleteHorse(id, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.delete(`${API_BASE_URL}/${id}`);
  return response.data;
}

export async function getHorsePermissions(horseId, organizationId, apiKey, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}/permissions?horseId=${horseId}`);
  return response.data;
}

export async function upsertHorsePermissions(horseId, permissions, organizationId, apiKey, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.put(`${API_BASE_URL}/permissions?horseId=${horseId}`, permissions);
  return response.data;
}

export async function printHorseProfile(horseId, organizationId, apiKey, jwtToken, language) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  // IMPORTANT: set responseType: 'blob' so we get the PDF as binary
  const response = await axiosInstance.get(`${API_BASE_URL}/printBoxLabel?horseId=${horseId}&lang=${language}`, {
    responseType: 'blob',
    headers: {
      'Accept': 'application/pdf' // helps the server know we expect a PDF
    }
  });

  // Here, response.data is a Blob containing the PDF
  return response.data;
}

/**
 * 
 * @param {*} apiKey 
 * @param {*} organizationId 
 * @param {*} jwtToken 
 * @param {number} [options.pageSize=25]
 * @param {string} [options.continuationToken=null]
 * @param {string} [options.filter={}] - e.g., { 'c.visitDate': '2022-01-01' } or { 'c.status': '*open*' }
 * @param {string} [options.sort={}] - e.g., { 'visitDate': 'desc' }
 * @returns 
 */
export async function fetchHorsesPaginated(apiKey, organizationId, jwtToken, options) {
  return fetchPaginatedData(API_BASE_URL, options, { apiKey, organizationId, jwtToken });
}

export default {
  getAllHorses,
  getHorsesByOwnerID,
  getHorsesByOwnerEmail,
  getHorseById,
  createHorse,
  updateHorse,
  deleteHorse,
  getHorsePermissions,
  upsertHorsePermissions,
  printHorseProfile,
  fetchHorsesPaginated,
};
