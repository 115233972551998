// src/components/HomeDashboards/DashboardComponents/ItemsUsageLineChart.js
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useDateLocale } from '../../../hooks/useDateLocale';

const ItemsUsageLineChart = ({ titleKey, data }) => {

  const { t } = useTranslation();
  const dateLocale = useDateLocale();
  // Inside your ItemsUsageLineChart component
  const chartData = useMemo(() => {
    return data.map(dayEntry => {
      const formattedDate = format(new Date(dayEntry.date), 'P', { locale: dateLocale }); // 'P' is the pattern for localized date (e.g., 12/31/2020)
      const dateObj = { date: formattedDate };
      dayEntry.items.forEach(item => {
        dateObj[item.name] = item.currentQuantity;
      });
      return dateObj;
    });
  }, [data]);

  // Dynamically get item names to generate lines for each
  const itemNames = useMemo(() => {
    const names = new Set();
    data.forEach(day => {
      day.items.forEach(item => {
        names.add(item.name);
      });
    });
    return Array.from(names);
  }, [data]);



  ItemsUsageLineChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  };


  function getLineColor(index) {
    // Simple function to return a color based on index. Customize as needed.
    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'];
    return colors[index % colors.length];
  }



  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="h6">
            {t(titleKey)}
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              {itemNames.map((name, index) => (
                <Line
                  key={name}
                  type="monotone"
                  dataKey={name}
                  stroke={getLineColor(index)} // Assuming you have a function to assign colors
                  activeDot={{ r: 8 }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default ItemsUsageLineChart;
