// src/services/DeliveryPlanService.js
import axiosInstance, {
    setApiKeyHeader,
    setOrganizationIdHeader,
    setAuthHeader,
} from '../apiConfig/axiosConfig';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/deliveryPlans';

// Fetch all delivery plans
export async function fetchAllDeliveryPlans(apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(API_BASE_URL);
    return response.data;
}

// Fetch a single delivery plan by ID
export async function fetchDeliveryPlanById(apiKey, organizationId, jwtToken, id) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    try {
        const response = await axiosInstance.get(`${API_BASE_URL}?id=${id}`);
        const data = response.data;

        // Transform date strings to Date objects
        data.date = new Date(data.date);
        data.routes = data.routes.map(route => ({
            ...route,
            salesOrders: route.salesOrders.map(order => ({
                ...order,
                deliveryDate: new Date(order.deliveryDate),
            })),
        }));

        return data;
    } catch (error) {
        throw new Error(`Failed to fetch delivery plan: ${error.message}`);
    }
}


// Save a new delivery plan
export async function saveDeliveryPlan(deliveryPlan, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.post(API_BASE_URL, deliveryPlan);
    return response.data;
}

// Update an existing delivery plan
export async function updateDeliveryPlan(deliveryPlan, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.put(`${API_BASE_URL}?id=${deliveryPlan.id}`, deliveryPlan);
    return response.data;
}

// Delete a delivery plan by ID
export async function deleteDeliveryPlan(deliveryPlanId, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.delete(`${API_BASE_URL}?id=${deliveryPlanId}`);
    return response.data;
}


// Create delivery notes for a given delivery plan
export async function createDeliveryNotesForDeliveryPlan(apiKey, organizationId, jwtToken, deliveryPlanId) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    try {
        const response = await axiosInstance.post(`${API_BASE_URL}/createDeliveryNotes?id=${deliveryPlanId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Failed to create delivery notes.');
    }
}

// Fetch all delivery plans with pagination
export async function fetchPaginatedDeliveryPlans(apiKey, organizationId, jwtToken, options) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    return fetchPaginatedData(API_BASE_URL, options, {
        apiKey,
        organizationId,
        jwtToken
    });
}


export default {
    fetchAllDeliveryPlans,
    fetchDeliveryPlanById,
    saveDeliveryPlan,
    updateDeliveryPlan,
    deleteDeliveryPlan,
    createDeliveryNotesForDeliveryPlan,
    fetchPaginatedDeliveryPlans,
};
