// src/Routes/RequireAuth.js
import React, { useEffect, useCallback } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, EventType } from '@azure/msal-browser';
import { Box, Typography, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Bars/Sidebar';
import TopBar from '../components/Bars/TopBar';
import { useAuth } from '../context/AuthContext';
import { renewToken, renewTokenIfNeeded } from '../context/tokenUtils';
import { useTheme, useMediaQuery } from '@mui/material';
import { DrawerStateProvider } from '../context/DrawerContext';
import MainContent from './MainContent';
import { UserPreferencesProvider } from '../context/UserPreferencesContext';

const RequireAuth = () => {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { login, loginPostInformation, loading, isLoggedIn, readyToUse, apiKeyLoading, token: authToken, logout } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Single effect to handle authentication on mount
    useEffect(() => {
        if (inProgress === 'none') {
            if (accounts.length > 0 && !isLoggedIn) {
                // If accounts exist but user is not marked logged in, renew token
                renewToken(instance, login, loginPostInformation, navigate);
            } else if (accounts.length === 0) {
                navigate('/auth');
            }
        }
    }, [inProgress, accounts, isLoggedIn]);

    // Check token expiry every minute using the token from the AuthContext
    const checkTokenExpiry = useCallback(() => {
        if (authToken) {
            renewTokenIfNeeded(instance, authToken, login, loginPostInformation, navigate);
        }
    }, [authToken, instance]);

    useEffect(() => {
        const intervalId = setInterval(checkTokenExpiry, 60000);
        return () => clearInterval(intervalId);
    }, [checkTokenExpiry]);

    const handleLogout = () => {
        instance.logoutRedirect().then(() => {
            logout();
        }).catch((e) => {
            console.error(e);
        });
    };

    // Consolidated waiting state UI
    if (loading || apiKeyLoading || inProgress !== "none" || accounts.length === 0 || !readyToUse) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('Authentication.waitingText')}</Typography>
                <LinearProgress />
            </Box>
        );
    }

    if (!isLoggedIn) {
        return <div>Please log in</div>;
    }

    const topBarHeight = isMobile ? '56px' : '64px';

    return (
        <UserPreferencesProvider>
            <DrawerStateProvider>
                <TopBar onLogout={handleLogout} />
                <Sidebar />
                <Box sx={{ marginTop: topBarHeight }}>
                    <MainContent>
                        <Outlet />
                    </MainContent>
                </Box>
            </DrawerStateProvider>
        </UserPreferencesProvider>
    );
};

export default RequireAuth;
