import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import * as itemService from '../services/v2/itemService'; // Example service for fetching items
import { useAuth } from '../context/AuthContext';  // To get the authentication tokens and IDs
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook

const ItemSelectionPopup = ({ open, onClose, onAddItem, availableItems }) => {
    const { t } = useTranslation(); // Initializing useTranslation hook
    const [searchTerm, setSearchTerm] = useState('');
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const { apiKey, organizationId, token, ownerId, userType } = useAuth();

    useEffect(() => {
        const fetchItems = async () => {
            setLoading(true);
            try {
                const fetchedItems = await itemService.fetchPaginatedItems(apiKey, organizationId, token, { pageSize: 1000 });
                setItems(fetchedItems.items);
            } catch (error) {
                console.error(t('itemSelectionPopup.errors.fetchingItemsError'), error);
            } finally {
                setLoading(false);
            }
        };

        if (!availableItems) {
            fetchItems();
        } else {
            setItems(availableItems);
        }
    }, [availableItems]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = items.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems(items);
        }
    }, [searchTerm, items]);

    const handleAddItem = () => {
        if (selectedItem) {
            onAddItem(selectedItem);
            onClose();
        }
    };

    const handleClose = () => {
        setSelectedItem(null); // Reset selection when dialog closes
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>{t('itemSelectionPopup.title')}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label={t('itemSelectionPopup.searchLabel')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        endAdornment: <SearchIcon />
                    }}
                    margin="normal"
                />
                {loading ? (
                    <Box display="flex" justifyContent="center" my={2}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <List>
                        {filteredItems.length > 0 ? (
                            filteredItems.map((item) => (
                                <ListItem
                                    button
                                    key={item.id}
                                    onClick={() => setSelectedItem(item)}
                                    selected={selectedItem?.id === item.id} // Highlight selected item
                                >
                                    <ListItemText primary={item.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            color={selectedItem?.id === item.id ? 'primary' : 'default'}
                                        >
                                            {selectedItem?.id === item.id ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        ) : (
                            <Typography>{t('itemSelectionPopup.noItemsFound')}</Typography>
                        )}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    {t('itemSelectionPopup.cancelButton')}
                </Button>
                <Button
                    onClick={handleAddItem}
                    color="primary"
                    disabled={!selectedItem} // Disable Add button if no item selected
                >
                    {t('itemSelectionPopup.addButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ItemSelectionPopup;
