import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../context/AuthContext';
import {
  fetchDocumentSettings,
  updateDocumentSettings,
} from '../../services/v2/DocumentSettingsService';
import {
  fetchInvoicePaymentSettings,
  updateInvoicePaymentSettings,
} from '../../services/v2/InvoicePaymentSettingsService';
import { usePermissions } from '../../context/PermissionsContext';

const INITIAL_DOCUMENT_SETTINGS = {
  numberStart: '',
  numberPrefix: '',
  numberLength: '',
  printAdditionalText: '',
  dueDays: '',
  useYearPrefix: true,
};

const INITIAL_INVOICE_SETTINGS = {
  invoiceNumberStart: '',
  invoicePrintAdditionalText: '',
  invoiceNumberPrefix: '',
  invoiceNumberLength: '',
  invoiceDueDays: '',
  useYearPrefix: 'true',
  reminderLevels: [],
};

const DocumentSettings = () => {
  const { entitlements } = usePermissions();
  // Extract features from entitlements
  const features = entitlements
    .map((entitlement) => entitlement.features)
    .flat()
    .filter((feature) => feature.status === 'enabled');

  const hasFeature = (featureName) => {
    return features.some((feature) => feature.name === featureName);
  };
  // feature name for delivery: "delivery-management"

  const [salesProcessCostEstimationSettings, setSalesProcessCostEstimationSettings] = useState({
    ...INITIAL_DOCUMENT_SETTINGS,
    documentType: 'salesProcessCostEstimation',
  });
  const [salesOrderSettings, setSalesOrderSettings] = useState({
    ...INITIAL_DOCUMENT_SETTINGS,
    documentType: 'salesOrder',
  });
  const [deliveryNoteSettings, setDeliveryNoteSettings] = useState({
    ...INITIAL_DOCUMENT_SETTINGS,
    documentType: 'deliveryNote',
  });
  const [deliveryPlanSettings, setDeliveryPlanSettings] = useState({
    ...INITIAL_DOCUMENT_SETTINGS,
    documentType: 'deliveryPlan',
  });
  const [invoiceSettings, setInvoiceSettings] = useState(INITIAL_INVOICE_SETTINGS);

  const { apiKey, organizationId, token } = useAuth();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const promises = [
      fetchInvoiceSettings(),
      fetchDocumentSettingsData(setSalesProcessCostEstimationSettings, 'salesProcessCostEstimation'),
      fetchDocumentSettingsData(setSalesOrderSettings, 'salesOrder'),
      fetchDocumentSettingsData(setDeliveryNoteSettings, 'deliveryNote'),
    ];

    if (hasFeature('delivery-management')) {
      promises.push(fetchDocumentSettingsData(setDeliveryPlanSettings, 'deliveryPlan'));
    }

    await Promise.all(promises);
  };

  const fetchDocumentSettingsData = async (setter, documentType) => {
    try {
      const data = await fetchDocumentSettings(apiKey, organizationId, token, documentType);
      if (Object.keys(data).length !== 0) {
        setter(data[0]);
      } else {
        console.log(`No existing ${documentType} settings found. Using initial settings.`);
      }
    } catch (error) {
      console.error(`Error fetching ${documentType} settings:`, error);
    }
  };

  const fetchInvoiceSettings = async () => {
    try {
      const data = await fetchInvoicePaymentSettings(apiKey, organizationId, token);
      if (Object.keys(data).length !== 0) {
        setInvoiceSettings(data);
      } else {
        console.log("No existing invoice settings found. Using initial settings.");
      }
    } catch (error) {
      console.error('Error fetching invoice settings:', error);
    }
  };

  const handleDocumentSettingsChange = (setter) => (e) => {
    const { name, value, type, checked } = e.target;
    setter((prevSettings) => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value,
      ...(name === 'numberStart' && { numberLength: value.length }),
    }));
  };

  const handleInvoiceSettingsChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInvoiceSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked.toString() : value,
      ...(name === 'invoiceNumberStart' && { invoiceNumberLength: value.length }),
    }));
  };

  const handleSettingsSubmit = (settings, updateFunction, documentType) => async (e) => {
    e.preventDefault();
    try {
      await updateFunction(settings, apiKey, organizationId, token, documentType);
      alert(`${documentType} Settings updated successfully!`);
    } catch (error) {
      console.error(`Error updating ${documentType} settings:`, error);
    }
  };

  const addReminderLevel = () => {
    if (invoiceSettings.reminderLevels.length < 3) {
      setInvoiceSettings((prevSettings) => ({
        ...prevSettings,
        reminderLevels: [
          ...prevSettings.reminderLevels,
          {
            reminderDueDays: '',
            diningNoticeFee: '',
            additionalFee: '',
            autoSend: false,
            reminderLevel: prevSettings.reminderLevels.length + 1,
            reminderType: 'Payment Reminder',
          },
        ],
      }));
    }
  };

  const removeReminderLevel = (index) => {
    setInvoiceSettings((prevSettings) => ({
      ...prevSettings,
      reminderLevels: prevSettings.reminderLevels.filter((_, idx) => idx !== index),
    }));
  };

  const handleReminderChange = (index, field) => (e) => {
    setInvoiceSettings((prevSettings) => ({
      ...prevSettings,
      reminderLevels: prevSettings.reminderLevels.map((reminder, idx) =>
        idx === index
          ? {
            ...reminder,
            [field]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            ...(field === 'reminderType' &&
              e.target.value === 'Payment Reminder' && {
              diningNoticeFee: '0',
              additionalFee: '0',
            }),
          }
          : reminder
      ),
    }));
  };

  return (
    <Box component="form" noValidate sx={{ mt: 3 }}>
      <AccordionSection
        title="Sales Process Cost Estimation Settings"
        settings={salesProcessCostEstimationSettings}
        handleChange={handleDocumentSettingsChange(setSalesProcessCostEstimationSettings)}
        handleSubmit={handleSettingsSubmit(
          salesProcessCostEstimationSettings,
          updateDocumentSettings,
          'salesProcessCostEstimation'
        )}
      />
      <AccordionSection
        title="Sales Order Settings"
        settings={salesOrderSettings}
        handleChange={handleDocumentSettingsChange(setSalesOrderSettings)}
        handleSubmit={handleSettingsSubmit(salesOrderSettings, updateDocumentSettings, 'salesOrder')}
      />
      <AccordionSection
        title="Delivery Note Settings"
        settings={deliveryNoteSettings}
        handleChange={handleDocumentSettingsChange(setDeliveryNoteSettings)}
        handleSubmit={handleSettingsSubmit(deliveryNoteSettings, updateDocumentSettings, 'deliveryNote')}
      />
      {hasFeature('delivery-management') && (
        <AccordionSection
          title="Delivery Plan Settings"
          settings={deliveryPlanSettings}
          handleChange={handleDocumentSettingsChange(setDeliveryPlanSettings)}
          handleSubmit={handleSettingsSubmit(deliveryPlanSettings, updateDocumentSettings, 'deliveryPlan')}
        />
      )}
      <AccordioInvoicenSection
        title="Invoice Settings"
        settings={invoiceSettings}
        handleChange={handleInvoiceSettingsChange}
        handleSubmit={handleSettingsSubmit(invoiceSettings, updateInvoicePaymentSettings)}
      >
        <PaymentReminderSection
          reminderLevels={invoiceSettings.reminderLevels}
          addReminderLevel={addReminderLevel}
          removeReminderLevel={removeReminderLevel}
          handleReminderChange={handleReminderChange}
        />
      </AccordioInvoicenSection>
    </Box>
  );
};

const AccordionSection = ({ title, settings, handleChange, handleSubmit, children }) => (
  <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h5">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <TextField
        margin="normal"
        fullWidth
        name="numberStart"
        label="Number Start"
        value={settings.numberStart}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        name="numberPrefix"
        label="Number Prefix"
        value={settings.numberPrefix}
        onChange={handleChange}
      />
      <FormControlLabel
        control={<Checkbox checked={settings.useYearPrefix} onChange={handleChange} name="useYearPrefix" />}
        label="Use Year Prefix"
      />
      <TextField
        margin="normal"
        fullWidth
        name="numberLength"
        label="Number Length"
        type="number"
        value={settings.numberLength}
        onChange={handleChange}
        disabled
      />
      <TextField
        margin="normal"
        fullWidth
        name="printAdditionalText"
        label="Print Additional Text"
        multiline
        rows={4}
        value={settings.printAdditionalText}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        name="dueDays"
        label="Due Days"
        type="number"
        value={settings.dueDays}
        onChange={handleChange}
      />
      {children}
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={handleSubmit}
      >
        Save {title}
      </Button>
    </AccordionDetails>
  </Accordion>
);

const AccordioInvoicenSection = ({ title, settings, handleChange, handleSubmit, children }) => (
  <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h5">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <TextField
        margin="normal"
        fullWidth
        name="invoiceNumberStart"
        label="Number Start"
        value={settings.invoiceNumberStart}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        name="invoiceNumberPrefix"
        label="Number Prefix"
        value={settings.invoiceNumberPrefix}
        onChange={handleChange}
      />
      <FormControlLabel
        control={<Checkbox checked={settings.useYearPrefix} onChange={handleChange} name="useYearPrefix" />}
        label="Use Year Prefix"
      />
      <TextField
        margin="normal"
        fullWidth
        name="invoiceNumberLength"
        label="Number Length"
        type="number"
        value={settings.invoiceNumberLength}
        onChange={handleChange}
        disabled
      />
      <TextField
        margin="normal"
        fullWidth
        name="invoicePrintAdditionalText"
        label="Print Additional Text"
        multiline
        rows={4}
        value={settings.invoicePrintAdditionalText}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        name="invoiceDueDays"
        label="Due Days"
        type="number"
        value={settings.invoiceDueDays}
        onChange={handleChange}
      />
      {children}
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={handleSubmit}
      >
        Save {title}
      </Button>
    </AccordionDetails>
  </Accordion>
);

const PaymentReminderSection = ({ reminderLevels, addReminderLevel, removeReminderLevel, handleReminderChange }) => (
  <>
    {reminderLevels.map((reminder, index) => (
      <Box key={index}>
        <Typography variant="h6">Reminder Level {index + 1}</Typography>
        <TextField
          margin="normal"
          fullWidth
          name="reminderDueDays"
          label="Reminder Due Days After Due Date"
          type="number"
          value={reminder.reminderDueDays}
          onChange={handleReminderChange(index, 'reminderDueDays')}
        />
        <TextField
          margin="normal"
          fullWidth
          name="reminderPrintAdditionalText"
          label="Reminder Print Additional Text"
          value={reminder.reminderPrintAdditionalText}
          onChange={handleReminderChange(index, 'reminderPrintAdditionalText')}
        />
        <Select
          margin="normal"
          fullWidth
          name="reminderType"
          label="Reminder Type"
          value={reminder.reminderType}
          onChange={handleReminderChange(index, 'reminderType')}
        >
          <MenuItem value="paymentReminder">Payment Reminder</MenuItem>
          <MenuItem value="dunningLetter">Dining Notice</MenuItem>
        </Select>
        <TextField
          margin="normal"
          fullWidth
          name="diningNoticeFee"
          label="Dining Notice Fee"
          value={reminder.diningNoticeFee}
          onChange={handleReminderChange(index, 'diningNoticeFee')}
          disabled={reminder.reminderType === 'paymentReminder'}
        />
        <FormControlLabel
          control={
            <Checkbox checked={reminder.autoSend} onChange={handleReminderChange(index, 'autoSend')} />
          }
          label="Send Payment Reminder Automatically"
        />
        <Button variant="outlined" color="error" onClick={() => removeReminderLevel(index)}>
          Remove Level
        </Button>
      </Box>
    ))}
    <Button onClick={addReminderLevel} disabled={reminderLevels.length >= 3} sx={{ mt: 2, mb: 2 }}>
      Add Reminder Level
    </Button>
  </>
);

export default DocumentSettings;
