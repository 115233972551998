import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CellTypes, RoomTypes } from './stableConstants';
import KingBedIcon from '@mui/icons-material/KingBed';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

/**
 * Displays info about the selected cell and allows user
 * to edit whether it's a stall, room, or empty, plus horse/room details.
 */
const EditorPanel = ({ selectedCell, onUpdateCell, onDeleteCell }) => {
  const [cellType, setCellType] = useState(CellTypes.EMPTY);
  const [horseName, setHorseName] = useState('');
  const [horseNotes, setHorseNotes] = useState('');
  const [roomName, setRoomName] = useState('');
  const [roomType, setRoomType] = useState(RoomTypes.OTHER);
  const [roomNotes, setRoomNotes] = useState('');

  useEffect(() => {
    if (!selectedCell) {
      setCellType(CellTypes.EMPTY);
      setHorseName('');
      setHorseNotes('');
      setRoomName('');
      setRoomType(RoomTypes.OTHER);
      setRoomNotes('');
      return;
    }

    setCellType(selectedCell.type);

    if (selectedCell.type === CellTypes.STALL && selectedCell.horse) {
      setHorseName(selectedCell.horse.name || '');
      setHorseNotes(selectedCell.horse.notes || '');
    } else {
      setHorseName('');
      setHorseNotes('');
    }

    if (selectedCell.type === CellTypes.ROOM && selectedCell.room) {
      setRoomName(selectedCell.room.name || '');
      setRoomType(selectedCell.room.type || RoomTypes.OTHER);
      setRoomNotes(selectedCell.room.notes || '');
    } else {
      setRoomName('');
      setRoomType(RoomTypes.OTHER);
      setRoomNotes('');
    }
  }, [selectedCell]);

  if (!selectedCell) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardHeader title="No cell selected" />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            Click on a cell to edit
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const handleCellTypeChange = (newType) => {
    // If user chooses STALL, create a new horse object if missing
    // If user chooses ROOM, create a new room object if missing
    // If user chooses EMPTY, remove any occupant
    let updated = { ...selectedCell, type: newType };

    if (newType === CellTypes.STALL) {
      updated = {
        ...updated,
        room: undefined,
        horse: selectedCell.horse || {
          id: `horse-${Date.now()}`,
          name: 'New Horse',
          notes: '',
        },
      };
    } else if (newType === CellTypes.ROOM) {
      updated = {
        ...updated,
        horse: undefined,
        room: selectedCell.room || {
          id: `room-${Date.now()}`,
          name: 'New Room',
          type: RoomTypes.TACK,
          notes: '',
        },
      };
    } else {
      // Empty
      updated = {
        ...updated,
        horse: undefined,
        room: undefined,
      };
    }
    onUpdateCell(updated);
  };

  const handleHorseUpdate = () => {
    if (!selectedCell || cellType !== CellTypes.STALL) return;
    onUpdateCell({
      ...selectedCell,
      horse: {
        id: selectedCell.horse?.id || `horse-${Date.now()}`,
        name: horseName || 'Unnamed Horse',
        notes: horseNotes || '',
      },
    });
  };

  const handleRoomUpdate = () => {
    if (!selectedCell || cellType !== CellTypes.ROOM) return;
    onUpdateCell({
      ...selectedCell,
      room: {
        id: selectedCell.room?.id || `room-${Date.now()}`,
        name: roomName || 'Unnamed Room',
        type: roomType,
        notes: roomNotes || '',
      },
    });
  };

  const handleDelete = () => {
    onDeleteCell(selectedCell.id);
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="Edit Cell"
        action={
          <IconButton color="error" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        }
      />
      <CardContent>
        {/* Cell Type */}
        <Box display="flex" gap={2} mb={2}>
          <IconButton
            color={cellType === CellTypes.EMPTY ? 'primary' : 'default'}
            onClick={() => handleCellTypeChange(CellTypes.EMPTY)}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton
            color={cellType === CellTypes.STALL ? 'primary' : 'default'}
            onClick={() => handleCellTypeChange(CellTypes.STALL)}
          >
            <KingBedIcon />
          </IconButton>
          <IconButton
            color={cellType === CellTypes.ROOM ? 'primary' : 'default'}
            onClick={() => handleCellTypeChange(CellTypes.ROOM)}
          >
            <WarehouseIcon />
          </IconButton>
        </Box>

        {/* Width & Height */}
        <Box display="flex" gap={2} mb={2}>
          <TextField
            label="Width"
            type="number"
            size="small"
            value={selectedCell.width}
            onChange={(e) => {
              const val = parseInt(e.target.value, 10);
              if (val >= 1 && val <= 3) {
                onUpdateCell({ ...selectedCell, width: val });
              }
            }}
          />
          <TextField
            label="Height"
            type="number"
            size="small"
            value={selectedCell.height}
            onChange={(e) => {
              const val = parseInt(e.target.value, 10);
              if (val >= 1 && val <= 3) {
                onUpdateCell({ ...selectedCell, height: val });
              }
            }}
          />
        </Box>

        {/* Stall/Horse Tab */}
        {cellType === CellTypes.STALL && (
          <Box display="flex" flexDirection="column" gap={1}>
            <TextField
              label="Horse Name"
              size="small"
              value={horseName}
              onChange={(e) => setHorseName(e.target.value)}
              onBlur={handleHorseUpdate}
            />
            <TextField
              label="Horse Notes"
              size="small"
              multiline
              rows={2}
              value={horseNotes}
              onChange={(e) => setHorseNotes(e.target.value)}
              onBlur={handleHorseUpdate}
            />
          </Box>
        )}

        {/* Room Tab */}
        {cellType === CellTypes.ROOM && (
          <Box display="flex" flexDirection="column" gap={1}>
            <TextField
              label="Room Name"
              size="small"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
              onBlur={handleRoomUpdate}
            />
            <TextField
              label="Room Type"
              size="small"
              select
              value={roomType}
              onChange={(e) => {
                setRoomType(e.target.value);
                setTimeout(handleRoomUpdate, 100); // update after
              }}
            >
              <MenuItem value={RoomTypes.TACK}>Tack</MenuItem>
              <MenuItem value={RoomTypes.FEED}>Feed</MenuItem>
              <MenuItem value={RoomTypes.WASH}>Wash</MenuItem>
              <MenuItem value={RoomTypes.STORAGE}>Storage</MenuItem>
              <MenuItem value={RoomTypes.OFFICE}>Office</MenuItem>
              <MenuItem value={RoomTypes.OTHER}>Other</MenuItem>
            </TextField>
            <TextField
              label="Room Notes"
              size="small"
              multiline
              rows={2}
              value={roomNotes}
              onChange={(e) => setRoomNotes(e.target.value)}
              onBlur={handleRoomUpdate}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default EditorPanel;
