import React, { forwardRef }  from 'react';
import { Box, Typography } from '@mui/material';
import { CellTypes } from './stableConstants';
import KingBedIcon from '@mui/icons-material/KingBed';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import DoorSlidingIcon from '@mui/icons-material/DoorSliding';
// Import your CSS
import './PrintableLayout.css';

const PrintableLayout = forwardRef(({ layout }, ref) => {
    console.log('Printable layout data:', layout); // Verify data is present
    const renderIcon = (cell) => {
        switch (cell.type) {
            case CellTypes.STALL:
                return <KingBedIcon fontSize="inherit" sx={{ color: '#555' }} />;
            case CellTypes.ROOM:
                if (cell.room?.type === 'tack') {
                    return <DoorSlidingIcon fontSize="inherit" sx={{ color: '#666' }} />;
                }
                return <WarehouseIcon fontSize="inherit" sx={{ color: '#666' }} />;
            default:
                return null;
        }
    };

    const getCellContent = (cell) => {
        if (cell.type === CellTypes.EMPTY) return null;
        if (cell.type === CellTypes.STALL && cell.horse) {
            return (
                <>
                    <Box display="flex" alignItems="center" gap={0.5}>
                        {renderIcon(cell)}
                        <Typography variant="caption" fontWeight="bold">
                            {cell.horse.name}
                        </Typography>
                    </Box>
                    {cell.horse.notes && (
                        <Typography variant="caption" display="block" sx={{ fontSize: '0.65rem' }}>
                            {cell.horse.notes}
                        </Typography>
                    )}
                </>
            );
        }
        if (cell.type === CellTypes.ROOM && cell.room) {
            return (
                <>
                    <Box display="flex" alignItems="center" gap={0.5}>
                        {renderIcon(cell)}
                        <Typography variant="caption" fontWeight="bold">
                            {cell.room.name}
                        </Typography>
                    </Box>
                    <Typography variant="caption" display="block" sx={{ fontSize: '0.65rem', fontStyle: 'italic' }}>
                        {cell.room.type}
                    </Typography>
                    {cell.room.notes && (
                        <Typography variant="caption" display="block" sx={{ fontSize: '0.65rem' }}>
                            {cell.room.notes}
                        </Typography>
                    )}
                </>
            );
        }
        return null;
    };

    const cellSize = '1.5cm'; // Adjust based on your needs
    const gridStyle = {
        display: 'grid',
        gridTemplateRows: `repeat(${layout.rows}, ${cellSize})`,
        gridTemplateColumns: `repeat(${layout.columns}, ${cellSize})`,
        gap: '3px',
        backgroundColor: '#f5f5f5',
        padding: '6px',
        borderRadius: '4px',
    };

    return (
        <div ref={ref} className="printable-layout">
            <Typography variant="h6" align="center" gutterBottom>
                {layout?.name} – Printed Layout
            </Typography>
            <Box sx={gridStyle}>
                {layout.cells.map((cell) => (
                    <Box
                        key={cell.id}
                        sx={{
                            gridColumn: `span ${cell.width}`,
                            gridRow: `span ${cell.height}`,
                            border: '1px solid #ccc',
                            backgroundColor:
                                cell.type === CellTypes.EMPTY
                                    ? '#fff'
                                    : cell.type === CellTypes.STALL
                                        ? '#fffaf0'
                                        : '#f0f8ff',
                            p: 0.5,
                            fontSize: '0.75rem',
                        }}
                    >
                        {getCellContent(cell)}
                    </Box>
                ))}
            </Box>

            <Box sx={{ mt: 2, fontSize: '0.75rem' }}>
                <Typography>Date: {new Date().toLocaleDateString()}</Typography>
                <Typography>
                    Stalls: {layout.cells.filter((c) => c.type === CellTypes.STALL).length} | Rooms:{' '}
                    {layout.cells.filter((c) => c.type === CellTypes.ROOM).length}
                </Typography>
            </Box>
        </div>
    );
});

export default PrintableLayout;