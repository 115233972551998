// src/pages/CostEstimationsListPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { approveDocument } from '../services/v2/BusinessDocumentService';

// Replace this import with your new paginated service function:
import { fetchCostEstimationsPaginated } from '../services/v2/CostEstimationService';
// i.e. a function that calls: fetchPaginatedData("/api/v2/costEstimations", options, ...)

import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';

// Instead of manual table/card usage, import DataViewWrapper
import DataViewWrapper from '../components/DataViewWrapper';
import SnackbarAlert from '../components/SnackbarAlert';

// Import your custom pagination hook
import { usePaginatedData } from '../hooks/usePaginatedData';

const CostEstimationsListPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // Use the custom paginated data hook
  const {
    items: estimations,
    loading: estimationsLoading,
    continuationToken,
    loadNextPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchCostEstimationsPaginated(apiKey, organizationId, token, userId, options),
    { pageSize: 25 }
  );

  // Local viewMode state in sync with user/device preferences
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode || (isMobile ? 'card' : 'table')
  );

  // Snackbar for success/error messages
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  // Sync local `viewMode` whenever user preferences change
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const toggleViewMode = () => {
    const newView = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newView);
    updateDevicePreferences('viewMode', newView);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const navigateToDetail = (estimationId) => {
    navigate(`/sales-process/costEstimations/detail/${estimationId}`);
  };

  const renderStatus = (estimation) => {
    if (estimation.cancel === true) {
      return (
        <Chip
          icon={<WarningAmberIcon />}
          label={t('costEstimationsListPage.cancelled')}
          color="secondary"
        />
      );
    } else if (estimation.status === 'draft') {
      return (
        <Chip
          icon={<CancelIcon />}
          label={t('costEstimationsListPage.draft')}
          color="default"
        />
      );
    } else if (estimation.status === 'approved') {
      return (
        <Chip
          icon={<CheckCircleIcon />}
          label={t('costEstimationsListPage.approved')}
          color="primary"
        />
      );
    } else if (estimation.status === 'Completed') {
      return (
        <Chip
          icon={<CheckCircleIcon />}
          label={t('costEstimationsListPage.completed')}
          color="primary"
        />
      );
    } else {
      return (
        <Chip
          icon={<CancelIcon />}
          label={t('costEstimationsListPage.unapproved')}
          color="error"
        />
      );
    }
  };

  const approveEstimation = async (estimationId) => {
    try {
      await approveDocument(apiKey, organizationId, token, userId, estimationId, 'costEstimation');
      setSnackbar({
        open: true,
        message: t('costEstimationsListPage.estimationApproved'),
        severity: 'success',
      });
      // Optionally update local state: find the item, set status to 'approved'
      // or re-fetch the data if that’s your preferred approach
    } catch (error) {
      console.error('Error approving cost estimation: ', error);
      setSnackbar({
        open: true,
        message: t('costEstimationsListPage.errorApprovingEstimation'),
        severity: 'error',
      });
    }
  };

  // Transform data for DataViewWrapper
  const transformData = useMemo(() => ({
    headers: [
      {
        key: 'estimationNumber',
        label: t('costEstimationsListPage.estimationNumber'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'customerName',
        label: t('costEstimationsListPage.customerName'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'issueDate',
        label: t('costEstimationsListPage.issueDate'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'totalAmount',
        label: t('costEstimationsListPage.totalAmount'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'status',
        label: t('costEstimationsListPage.status'),
        display: { value: true, chipColor: false },
      },
    ],
    rows: estimations.map((estimation) => ({
      id: estimation.id,
      estimationNumber: { value: estimation.estimationNumber },
      customerName: { value: estimation.customerName },
      issueDate: {
        value: new Date(estimation.issueDate).toLocaleDateString(),
      },
      totalAmount: { value: estimation.totalAmount },
      status: {
        value: renderStatus(estimation),
      },
      actions: [
        {
          icon: <CheckCircleIcon />,
          handler:
            estimation.status !== 'draft' || estimation.cancel
              ? null
              : () => approveEstimation(estimation.id),
          disabled: estimation.status !== 'draft' || estimation.cancel,
          label: t('costEstimationsListPage.approve'),
        },
      ],
    })),
  }), [estimations]);

  const isLoading = estimationsLoading;

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      {isLoading ? (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {/* Use DataViewWrapper for table/card switching, filtering, sorting, etc. */}
          <DataViewWrapper
            structure={transformData}
            viewMode={viewMode}
            onViewModeToggle={toggleViewMode}
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            onRowClick={navigateToDetail}
            pageId="/cost-estimations"
            toolbarActions={[
              <Button
                key="add-estimation"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => navigate('/sales-process/costEstimations/detail/new')}
              >
                {t('costEstimationsListPage.addNewEstimation')}
              </Button>,
            ]}
          />

          {/* Load More button if there's more data available */}
          {continuationToken && (
            <Box mt={2} textAlign="center">
              <Button
                variant="contained"
                onClick={loadNextPage}
                disabled={isLoading}
              >
                {t('common.loadMore')}
              </Button>
            </Box>
          )}
        </>
      )}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default CostEstimationsListPage;
