// src/pages/PaymentsListPage.js

import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Paper,
    Button,
    LinearProgress,
    IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';

import { useAuth } from '../../context/AuthContext';
import { usePreferences } from '../../context/UserPreferencesContext';

// Replace this import with your new paginated function:
import { fetchPaginatedPayments } from '../../services/v2/PaymentsService';
// e.g. a function that calls fetchPaginatedData("/api/v2/payments", options, ...)

import DataViewWrapper from '../../components/DataViewWrapper';
import { usePaginatedData } from '../../hooks/usePaginatedData';

const PaymentsListPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { apiKey, organizationId, token } = useAuth();
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    // Use our custom paginated data hook
    const {
        items: payments,
        loading: paymentsLoading,
        continuationToken,
        loadNextPage,
        filter,
        setFilter,
        sort,
        setSort,
    } = usePaginatedData(
        (options) => fetchPaginatedPayments(apiKey, organizationId, token, options),
        { pageSize: 25 }
    );

    // Local view mode state (table/card)
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || (isMobile ? 'card' : 'table')
    );

    // Sync local view mode whenever user preferences change
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const toggleViewMode = () => {
        const newMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newMode);
        updateDevicePreferences('viewMode', newMode);
    };

    const handleRowClick = (paymentId) => {
        navigate(`/payments/detail/${paymentId}`);
    };

    const handleNewPayment = () => {
        navigate('/payments/detail/new');
    };

    // Transform data for DataViewWrapper
    const transformData = useMemo(() => ({
        headers: [
            {
                key: 'payerName',
                label: t('paymentsListPage.payerName'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'amount',
                label: t('paymentsListPage.amount'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'date',
                label: t('paymentsListPage.paymentDate'),
                display: { value: true, chipColor: false },
            },
        ],
        rows: payments.map((payment) => ({
            id: payment.id,
            payerName: { value: payment.payerName },
            amount: { value: payment.amount },
            date: { value: new Date(payment.date).toLocaleDateString() },
            // If needed, you can add actions here for each row
        })),
    }), [payments, t]);

    const isLoading = paymentsLoading;

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            {isLoading ? (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress />
                </Box>
            ) : (
                <>
                    {/* DataViewWrapper for table/card, filtering, sorting, etc. */}
                    <DataViewWrapper
                        structure={transformData}
                        viewMode={viewMode}
                        onViewModeToggle={toggleViewMode}
                        filter={filter}
                        setFilter={setFilter}
                        sort={sort}
                        setSort={setSort}
                        onRowClick={handleRowClick}
                        pageId="/payments"
                        toolbarActions={[
                            <Button
                                key="add-payment"
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleNewPayment}
                            >
                                {t('paymentsListPage.addNewPayment')}
                            </Button>
                        ]}
                    />

                    {/* Load More button if there's more to fetch */}
                    {continuationToken && (
                        <Box mt={2} textAlign="center">
                            <Button
                                variant="contained"
                                onClick={loadNextPage}
                                disabled={isLoading}
                            >
                                {t('common.loadMore')}
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </Paper>
    );
};

export default PaymentsListPage;
