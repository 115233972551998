// src/pages/inventoryManagement/itemManagement/useItemManagement.js

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as itemService from '../../../services/v2/itemService';
import { useAuth } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { fetchCategoriesPaginated } from '../../../services/v2/categoryService';
import { fetchPaginatedMeasureUnits } from '../../../services/v2/measureUnitsService';

export const useItemManagement = (itemId) => {
  const navigate = useNavigate();
  const { apiKey, organizationId, token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation(); // Hook for translation
  const [categories, setCategories] = useState([]);
  const [measureUnits, setMeasureUnits] = useState([]);
  const initialItemData = {
    id: '',
    name: '',
    category: '',
    subcategory: '',
    stockItem: false,
    lastBoughtPrice: 0,
    quantity: 0,
    warningQuantity: 0,
    showInDashboard: false,
    measureUnit: '',
    isSaleItem: false,
    bruttoPrice: 0,
    netPrice: 0,
    taxPercentage: 0,
    salesDependencies: []
  };

  const [itemData, setItemData] = useState(initialItemData);
  const [items, setItems] = useState([]); // Available items for sales dependencies

  useEffect(() => {
    setLoading(true);

    // Combine the fetch calls into a single promise array 
    Promise.all([
      fetchCategoriesPaginated(apiKey, organizationId, token, { pageSizes: 1000 }),
      fetchPaginatedMeasureUnits(apiKey, organizationId, token, { pageSizes: 1000 }),
      itemService.fetchPaginatedItems(apiKey, organizationId, token, { pageSizes: 1000 })
    ]).then(([categoriesData, measureUnitsData, itemsData]) => {
      // Set categories and measure units once they are both fetched
      setCategories(categoriesData.items);
      setMeasureUnits(measureUnitsData.items);
      setItems(itemsData.items); // Store fetched items for sales dependencies

      // Now fetch the item details if the itemId is not 'new'
      if (itemId !== 'new') {
        return itemService.getItemById(itemId, apiKey, organizationId, token);
      }
    }).then(itemData => {
      // This will only be executed if the itemId is not 'new'
      if (itemData) {
        setItemData(itemData);
        setError(null);
      }
    }).catch(err => {
      setError(err.message);
    }).finally(() => {
      setLoading(false);
    });
  }, [itemId, apiKey, organizationId, token]);


  const handleSubmit = async (openSnackbar) => {
    setLoading(true);
    try {
      const response = itemId === 'new'
        ? await itemService.createItem(itemData, apiKey, organizationId, token)
        : await itemService.updateItem(itemId, itemData, apiKey, organizationId, token);

      setLoading(false);
      openSnackbar('Item successfully saved!', 'success');
      navigate('/inventory?tab=0');
      return response;
    } catch (err) {
      setLoading(false);
      setError(err.message);
      openSnackbar('Failed to save item', 'error');
      return false;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...itemData, [name]: value };

    // Handle toggles
    if (name === 'stockItem' || name === 'showInDashboard' || name === 'isSaleItem') {
      updatedData[name] = !itemData[name];
    }

    // Handle category changes
    if (name === 'category' && itemData.subcategory) {
      updatedData.subcategory = '';
      updatedData.measureUnit = '';
    }
    if (name === 'subcategory' && itemData.measureUnit) {
      updatedData.measureUnit = '';
    }

    // Update item name based on category, subcategory, and measure unit
    if (['category', 'subcategory', 'measureUnit'].includes(name)) {
      const createLabel = (key, subKey, measureUnit) => {
        const categoryLabel = categories.find(category => category.categoryId === key)?.categoryName || '';
        const subcategoryLabel = categoryLabel && categories.find(category => category.categoryId === key).subcategories?.find(subcategory => subcategory.subcategoryId === subKey)?.subcategoryName || '';
        const measureUnitLabel = measureUnit ? measureUnits.find(measure => measure.id === measureUnit)?.nameLong : '';
        return `${categoryLabel} ${subcategoryLabel} ${measureUnitLabel}`.trim();
      };

      const oldLabel = createLabel(itemData.category, itemData.subcategory, itemData.measureUnit);
      const newLabel = createLabel(updatedData.category, updatedData.subcategory, updatedData.measureUnit);
      const regex = new RegExp(`^${oldLabel}\\s*`);

      let itemNameWithoutLabel = itemData.name.replace(regex, '');
      updatedData.name = `${newLabel} ${itemNameWithoutLabel}`.trim();
    }

    // Handle price calculations
    updatedData = calculatePrices(updatedData, name, value);

    setItemData(updatedData);
  };

  const calculatePrices = (data, name, value) => {
    let updatedData = { ...data };

    // Parse the values to floats
    const taxPercentage = parseFloat(updatedData.taxPercentage) || 0;
    const bruttoPrice = parseFloat(updatedData.bruttoPrice) || 0;
    const netPrice = parseFloat(updatedData.netPrice) || 0;

    // Save the tax when entered
    if (name === 'taxPercentage') {
      updatedData.taxPercentage = taxPercentage;

      // Recalculate prices if net or brutto price is available
      if (bruttoPrice) {
        const calculatedNetPrice = bruttoPrice / (1 + taxPercentage / 100);
        updatedData.netPrice = parseFloat(calculatedNetPrice.toFixed(2)); // Convert back to a number
      } else if (netPrice) {
        const calculatedBruttoPrice = netPrice * (1 + taxPercentage / 100);
        updatedData.bruttoPrice = parseFloat(calculatedBruttoPrice.toFixed(2)); // Convert back to a number
      }
    }

    // If bruttoPrice is entered
    if (name === 'bruttoPrice') {
      updatedData.bruttoPrice = bruttoPrice;

      if (taxPercentage) {
        const calculatedNetPrice = bruttoPrice / (1 + taxPercentage / 100);
        updatedData.netPrice = parseFloat(calculatedNetPrice.toFixed(2)); // Convert back to a number
      } else if (!updatedData.netPrice) {
        // If netPrice is empty, just save the bruttoPrice as netPrice
        updatedData.netPrice = bruttoPrice;
      }
    }

    // If netPrice is entered
    if (name === 'netPrice') {
      updatedData.netPrice = netPrice;

      if (taxPercentage) {
        const calculatedBruttoPrice = netPrice * (1 + taxPercentage / 100);
        updatedData.bruttoPrice = parseFloat(calculatedBruttoPrice.toFixed(2)); // Convert back to a number
      } else if (!updatedData.bruttoPrice) {
        // If bruttoPrice is empty, just save the netPrice as bruttoPrice
        updatedData.bruttoPrice = netPrice;
      }
    }

    return updatedData;
  };


  // Handle adding a new sales dependency
  const addSalesDependency = (itemId) => {
    const selectedItem = items.find(item => item.id === itemId);
    if (!selectedItem) return;

    const newDependency = {
      id: selectedItem.id,
      name: selectedItem.name,
      quantity: 1 // Default quantity for sales dependency
    };

    setItemData(prevState => ({
      ...prevState,
      salesDependencies: [...prevState.salesDependencies, newDependency]
    }));
  };

  // Handle changing quantity of a sales dependency
  const handleSalesDependencyChange = (index, quantity) => {
    const updatedDependencies = [...itemData.salesDependencies];
    updatedDependencies[index].quantity = quantity;

    setItemData(prevState => ({
      ...prevState,
      salesDependencies: updatedDependencies
    }));
  };

  // Handle removing a sales dependency
  const removeSalesDependency = (index) => {
    const updatedDependencies = [...itemData.salesDependencies];
    updatedDependencies.splice(index, 1);

    setItemData(prevState => ({
      ...prevState,
      salesDependencies: updatedDependencies
    }));
  };

  return {
    itemData,
    categories,
    measureUnits,
    setItemData,
    handleInputChange,
    handleSubmit,
    loading,
    error,
    items, // Pass available items for sales dependencies
    addSalesDependency,
    handleSalesDependencyChange,
    removeSalesDependency
  };
};
