// src/pages/ActivityPage.js

import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    LinearProgress,
    IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDateLocale } from '../hooks/useDateLocale';
import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';

// Replace direct getHorseActivityVotes(...) with the new paginated function:
import {
    getHorseActivityVotesPaginated,
    deleteHorseActivityVote
} from '../services/v2/horseActivityVoteService';

// For horses, consider a paginated approach as well if your dataset is large.
import {
    fetchHorsesPaginated,
    getHorsesByUserId,
} from '../services/v2/horseService';

import DataViewWrapper from '../components/DataViewWrapper';
import HorseActivityVoteModal from '../components/HorseActivityVoteModal';
import ConfirmationDialog from '../components/ConfirmationDialog';
import SnackbarAlert from '../components/SnackbarAlert';

// usePaginatedData is your custom pagination hook
import { usePaginatedData } from '../hooks/usePaginatedData';

const ActivityPage = () => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { apiKey, organizationId, token, userType, userId } = useAuth();
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    // -------------- PAGINATED HOOK FOR ACTIVITY VOTES --------------
    const {
        items: activities,
        loading: activitiesLoading,
        continuationToken,
        loadNextPage,
        loadFirstPage,
        filter,
        setFilter,
        sort,
        setSort,
    } = usePaginatedData(
        (options) => getHorseActivityVotesPaginated(apiKey, organizationId, token, options),
        { pageSize: 50 }
    );

    // If we also need to fetch horses in a paginated fashion:
    const [horses, setHorses] = useState([]);
    const [horsesLoading, setHorsesLoading] = useState(false);

    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || (isMobile ? 'card' : 'table')
    );
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [activityIdToDelete, setActivityIdToDelete] = useState(null);

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: '',
    });

    // ------------------- FETCH HORSES -------------------
    useEffect(() => {
        let isCancelled = false;
        const fetchHorseData = async () => {
            setHorsesLoading(true);
            try {
                let horseData = [];
                if (userType === 'admin') {
                    // Admin: fetch all horses with pagination
                    let nextToken = null;
                    do {
                        const res = await fetchHorsesPaginated(apiKey, organizationId, token, {
                            pageSize: 1000,
                            continuationToken: nextToken,
                        });
                        horseData = [...horseData, ...(res.items || [])];
                        nextToken = res.continuationToken;
                    } while (nextToken && !isCancelled);
                } else {
                    // Non-admin: fetch horses for the specific user
                    horseData = (await getHorsesByUserId(userId, apiKey, organizationId, token)).items;
                }

                if (!isCancelled) {
                    setHorses(horseData);
                }
            } catch (error) {
                console.error('Failed to fetch horses:', error);
            } finally {
                if (!isCancelled) setHorsesLoading(false);
            }
        };
        fetchHorseData();
        return () => {
            isCancelled = true;
        };
    }, [apiKey, organizationId, token, userType, userId]);

    // Sync local viewMode with user preferences
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const toggleViewMode = () => {
        const newMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newMode);
        updateDevicePreferences('viewMode', newMode);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentRecord(null);

        //Reload the first page of activities after closing the modal
        loadFirstPage();
    };

    const handleAddActivity = () => {
        setCurrentRecord(null);
        setModalOpen(true);
    };

    const handleEditActivity = (id) => {
        const record = activities.find((a) => a.id === id);
        setCurrentRecord(record || null);
        setModalOpen(true);
    };

    const handleDeleteActivity = (id) => {
        setActivityIdToDelete(id);
        setConfirmDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        // Instead of a separate "isLoading" for everything, we can do a local flag or rely on the main loading
        try {
            await deleteHorseActivityVote(activityIdToDelete, apiKey, organizationId, token);
            // remove from local items
            // We'll do a local filter for now. If you prefer, re-fetch from server or re-run the paginated hook load.
            // But re-fetching the entire page might be simpler for your flow.
            // For now, let's do an immediate local update:
            // items are "activities" from the paginated hook, but that array is internal. We'll do a manual filter
            // in a real scenario, you'd do something like a re-fetch or setState:
            // setActivities((prev) => prev.filter((a) => a.id !== activityIdToDelete));
            setSnackbar({
                open: true,
                message: t('activities.deleteSuccess'),
                severity: 'success',
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: t('activities.deleteError'),
                severity: 'error',
            });
            console.error('Error deleting horse activity vote:', error);
        } finally {
            setConfirmDialogOpen(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const getHorseNameById = (horseId) => {
        const horse = horses.find((h) => h.id === horseId);
        return horse ? horse.name : '';
    };

    // Transform data for DataViewWrapper
    const transformData = useMemo(() => ({
        headers: [
            {
                key: 'activityType',
                label: t('activities.tableHeaders.activityType'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'selectedDate',
                label: t('activities.tableHeaders.selectedDate'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'horseId',
                label: t('activities.tableHeaders.horseName'),
                display: { value: true, chipColor: false },
            },
        ],
        rows: activities.map((activity) => ({
            id: activity.id,
            activityType: {
                value: activity.activityType
                    ? t(`HorseActivityModal.${activity.activityType}`)
                    : '',
            },
            selectedDate: {
                value: new Date(activity.selectedDate).toLocaleDateString(dateLocale),
            },
            horseId: {
                value: getHorseNameById(activity.horseId),
            },
        })),
    }), [activities, t, horses, dateLocale]);

    // Additional row-level actions
    const actions = [
        {
            icon: <DeleteIcon />,
            handler: handleDeleteActivity,
        },
    ];

    const loading = activitiesLoading || horsesLoading;

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('activities.pageTitle')}
            </Typography>

            
            {/* Loading or DataView */}
            {loading ? (
                <LinearProgress />
            ) : (
                <>
                    {/* DataViewWrapper for table/card, filtering, sorting, etc. */}
                    <DataViewWrapper
                        structure={transformData}
                        viewMode={viewMode}
                        onViewModeToggle={toggleViewMode}
                        filter={filter}
                        setFilter={setFilter}
                        sort={sort}
                        setSort={setSort}
                        onRowClick={handleEditActivity}
                        actions={actions}
                        pageId="/activities"
                        toolbarActions={[
                            <Button
                                key="add-activity"
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAddActivity}
                            >
                                {t('activities.addNewActivity')}
                            </Button>,
                        ]}
                        predefinedFilters={[]}
                        enableFilterControls={false}
                        enableSortControls={false}
                    />

                    {/* Load More if there's another page */}
                    {continuationToken && (
                        <Box mt={2} textAlign="center">
                            <Button variant="contained" onClick={loadNextPage} disabled={loading}>
                                {t('common.loadMore')}
                            </Button>
                        </Box>
                    )}
                </>
            )}

            {/* Modal for Add/Edit Activity */}
            <HorseActivityVoteModal
                open={isModalOpen}
                onClose={handleModalClose}
                activity={currentRecord || null}
            />

            {/* Delete Confirmation */}
            <ConfirmationDialog
                isOpen={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleDeleteConfirm}
                text={t('activities.confirmDelete')}
            />

            {/* Snackbar for success/error messages */}
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Paper>
    );
};

export default ActivityPage;
