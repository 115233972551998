// src/components/OrgTabs/FoodSettings/useFoodSettings.js

import { useState, useEffect } from 'react';
import { saveFoodSettings, deleteFoodSettings, fetchPaginatedFoodSettings } from '../../../services/v2/FoodSettingsService';
import { fetchPaginatedItems } from '../../../services/v2/itemService';
import { fetchPaginatedMeasureUnits } from '../../../services/v2/measureUnitsService';
import { v4 as uuidv4 } from 'uuid';

const useFoodSettings = (apiKey, organizationId, token) => {
    const [foods, setFoods] = useState([]);
    const [items, setItems] = useState([]);
    const [measureUnits, setMeasureUnits] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const PremisesItems = fetchPaginatedItems(apiKey, organizationId, token, { pageSize: 1000 });
        const PremisesFoodSettings = fetchPaginatedFoodSettings(apiKey, organizationId, token, { pageSize: 1000 });
        const PremisesMeasureUnits = fetchPaginatedMeasureUnits(apiKey, organizationId, token, { pageSize: 1000 });

        Promise.all([PremisesItems, PremisesFoodSettings, PremisesMeasureUnits])
            .then(([items, foodSettings, measureUnits]) => {
                setFoods(Array.isArray(foodSettings.items) ? foodSettings.items : []);
                setItems(Array.isArray(items.items) ? items.items : []);
                setMeasureUnits(Array.isArray(measureUnits.items) ? measureUnits.items : []);
            })
            .finally(() => setLoading(false));

    }, [apiKey]);

    const handleInputChange = (index, event) => {
        const updatedFoods = foods.map((food, idx) =>
            idx === index ? { ...food, [event.target.name]: event.target.value } : food
        );
        setFoods(updatedFoods);
    };


    const handleCheckboxChange = (index, event) => {
        const newFoods = [...foods];
        newFoods[index].availability[event.target.name] = event.target.checked;
        setFoods(newFoods);
    };


    const handleAddFood = () => {
        setFoods([...foods, {
            id: '', name: '',
            availability: { morning: false, midday: false, evening: false },
            portions: [{ name: '', id: '', measureUnitId: '', quantity: 0 }]
        }]);
    };

    const handleRemoveFood = (indexToRemove) => {
        const foodToRemove = foods[indexToRemove];
        if (foodToRemove.id) {
            deleteFoodSettings(foodToRemove.id, apiKey, organizationId, token)
                .then(() => {
                    setFoods(foods.filter((_, index) => index !== indexToRemove));
                });
        } else {
            setFoods(foods.filter((_, index) => index !== indexToRemove));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await Promise.all(foods.map(food => saveFoodSettings(food, apiKey, organizationId, token)))
            .then(data => {
                setFoods(data);
            });
    };


    const handleRemovePortion = (index, portionIndex) => {
        const newFoods = [...foods];
        newFoods[index].portions.splice(portionIndex, 1); // Remove a portion option
        setFoods(newFoods);
    }

    const handleAddPortion = (foodIndex) => {
        const updatedFoods = foods.map((food, index) => {
            if (index === foodIndex) {
                return { ...food, portions: [...food.portions, { name: '', id: uuidv4(), measureUnitId: '', quantity: 0 }] };
            }
            return food;
        });
        setFoods(updatedFoods);
    };


    const handlePortionChange = (index, portionIndex, key, value) => {
        const updatedFoods = [...foods];
        updatedFoods[index].portions[portionIndex][key] = value;
        setFoods(updatedFoods);
    };

    const handleAutocompleteChange = (event, newValue, reason, index) => {
        if (reason === 'selectOption') {
            // Make sure newValue is valid and has an id
            if (!newValue || !newValue.id) return;

            foods[index].itemId = newValue.id;
            const selectedItem = items.find(item => item.id === newValue.id);

            // If selectedItem is found, update food name and portions
            if (selectedItem) {
                foods[index].name = selectedItem.name;
                const updatedPortions = foods[index].portions.map(portion => ({
                    ...portion,
                    measureUnit: selectedItem.measureUnit || '',
                }));
                setFoods(foods.map((food, foodIndex) =>
                    foodIndex === index ? { ...food, portions: updatedPortions } : food
                ));
            }
        } else if (reason === 'clear' || newValue === null) {
            // The input was cleared
            foods[index].itemId = '';
            foods[index].name = '';
            // Reset portions
            const resetPortions = foods[index].portions.map(portion => ({
                ...portion,
                measureUnit: '',
                quantity: ''
            }));
            setFoods(foods.map((food, foodIndex) => foodIndex === index ? { ...food, portions: resetPortions } : food));
        } else {
            // The user typed something in the input

            foods[index].itemId = '';
            foods[index].printName = newValue;
            foods[index].name = newValue;
            setFoods([...foods]);

        }
    };



    return {
        foods,
        items,
        loading,
        measureUnits,
        handleInputChange,
        handleCheckboxChange,
        handleAddFood,
        handleRemoveFood,
        handleSubmit,
        handlePortionChange,
        handleAddPortion,
        handleRemovePortion,
        handleAutocompleteChange
    };
};

export default useFoodSettings;
