import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { saveMeasureUnit, updateMeasureUnit } from '../services/v2/measureUnitsService';

function MeasureUnitModal({ open, onClose, onSubmit, editMeasureUnit }) {
    const [nameLong, setNameLong] = useState('');
    const [nameShort, setNameShort] = useState('');
    const { apiKey, organizationId, token: jwtToken } = useAuth();


    useEffect(() => {
        if (editMeasureUnit) {
            setNameLong(editMeasureUnit.nameLong);
            setNameShort(editMeasureUnit.nameShort);
        } else {
            resetForm();
        }
    }, [editMeasureUnit]);

    const resetForm = () => {
        setNameLong('');
        setNameShort('');
    };

    const handleClose = () => {
        onClose();
        resetForm();
    };

    const handleSubmit = () => {
        const measureUnit = {
            nameLong,
            nameShort
        };
        if (editMeasureUnit) {
            measureUnit.id = editMeasureUnit.id;
            updateMeasureUnit(measureUnit, apiKey, organizationId, jwtToken)
                .then((data) => {
                    onSubmit(data);
                    handleClose();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            saveMeasureUnit(measureUnit, apiKey, organizationId, jwtToken)
                .then((data) => {
                    onSubmit(data);
                    handleClose();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{editMeasureUnit ? 'Edit Measure Unit' : 'Add New Measure Unit'}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="nameLong"
                    label="Name Long"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={nameLong}
                    onChange={(e) => setNameLong(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="nameShort"
                    label="Name Short"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={nameShort}
                    onChange={(e) => setNameShort(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}

export default MeasureUnitModal;
