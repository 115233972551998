import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CalendarEventInfoModal = ({ open, onClose, eventData }) => {
    // eventData might contain:
    //   eventData.title
    //   eventData.start
    //   eventData.end
    //   eventData.type
    //   eventData.riderName
    //   eventData.teacherName
    //   eventData.location
    //   eventData.notes
    // etc.

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                {/* A read-only title */}
                Event Details
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Title:</strong> {eventData?.title}</Typography>
                    </Grid>
                    {eventData?.start && (
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <strong>Start:</strong> {eventData.start.toLocaleString()}
                            </Typography>
                        </Grid>
                    )}
                    {eventData?.end && (
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <strong>End:</strong> {eventData.end.toLocaleString()}
                            </Typography>
                        </Grid>
                    )}
                    {eventData?.riderName && (
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <strong>Rider:</strong> {eventData.riderName}
                            </Typography>
                        </Grid>
                    )}
                    {eventData?.teacherName && eventData.type === 'booking' && (
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <strong>Teacher:</strong> {eventData.teacherName}
                            </Typography>
                        </Grid>
                    )}
                    {eventData?.location && (
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <strong>Location:</strong> {eventData.location}
                            </Typography>
                        </Grid>
                    )}
                    {eventData?.notes && (
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <strong>Notes:</strong> {eventData.notes}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CalendarEventInfoModal;
