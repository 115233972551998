import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, TextField, Button, Grid } from '@mui/material';
import { resetPassword } from '../services/v2/authService';  // Import the service here
import LinearProgress from '@mui/material/LinearProgress';
import { verifyOnlyToken } from '../services/v2/tokenService';


const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);


    // Parse URL parameters
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tokenParam = params.get('token');
        const emailParam = params.get('email');
        setToken(tokenParam);
        setEmail(emailParam);

        // Verify the token with your backend here
        const validateToken = async () => {
            const tokenValid = await verifyOnlyToken(tokenParam);
            setIsTokenValid(tokenValid);
            setIsLoading(false);
        };

        validateToken();
    }, [location.search]);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleResetPasswordSubmit = async (event) => {
        event.preventDefault();
    
        setIsResettingPassword(true); 
    
        try {
            const resetResponse = await resetPassword(email, password, token); 
    
            if (resetResponse && resetResponse.status === 'success') {
                console.log('Password reset successful');
                navigate('/auth');
            } else {
                console.log('Password reset failed');
            }
        } catch (error) {
            console.error('Error during password reset:', error);
        } finally {
            setIsResettingPassword(false);  
        }
    };

    if (isResettingPassword) {
        return (
            <Container maxWidth="sm">
                <Typography variant="h4" align="center" gutterBottom>
                    Resetting Password...
                </Typography>
                <LinearProgress />
            </Container>
        );
    }

    if (isLoading) {
        return (
            <Container maxWidth="sm">
                <Typography variant="h4" align="center" gutterBottom>
                    Verifying Token...
                </Typography>
                <LinearProgress />
            </Container>
        );
    }

    if (!isTokenValid) {
        return (
            <Container maxWidth="sm">
                <Typography variant="h4" align="center" gutterBottom>
                    Invalid or expired token
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" align="center" gutterBottom>
                Reset Password
            </Typography>
            <form onSubmit={handleResetPasswordSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            value={email}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="password"
                            label="New Password"
                            variant="outlined"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" color="primary" type="submit">
                            Reset Password
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default ResetPassword;
