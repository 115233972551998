// src/components/OrgTabs/TeacherSettings/useTeacher.js

import { useState, useEffect } from 'react';
import { saveTeacherSettings, deleteTeacherSettings, fetchTeacherSettingPaginatedData } from '../../../services/v2/TeacherSettingsService';
import { fetchPaginatedUsers } from '../../../services/v2/userService';
import { useAuth } from '../../../context/AuthContext';

export const useTeacher = () => {

    const [teachers, setTeachers] = useState([]);
    const { apiKey, token, organizationId } = useAuth();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        try {
            Promise.all([
                fetchTeacherSettingPaginatedData(apiKey, organizationId, token, { pageSize: 1000 }),
                fetchPaginatedUsers(apiKey, organizationId, token, { pageSize: 1000 })
            ]).then(([teachersData, usersData]) => {
                setTeachers(teachersData.items);
                setUsers(usersData.items);
            }).catch(err => console.error("Failed to fetch teachers:", err))
                .finally(() => setLoading(false));
        }
        catch (err) {
            console.log(err);
        }
        finally {
        }

    }, [apiKey]);

    const handleAddTeacher = () => {
        const newTeacher = { id: '', name: '', availability: {}, configureAvailability: false };
        setTeachers([...teachers, newTeacher]);
    };

    const handleSave = () => {
        saveTeacherSettings(teachers, apiKey, organizationId, token)
            .then(data => {
                setTeachers(data);
            })
            .catch(err => console.error("Failed to save teachers:", err))
            .finally(() => setLoading(false));

    };

    const handleAutocompleteChange = (teacherId, index, value, freesolo = false) => {
        let updatedTeachers = []
        if (teacherId) {
            updatedTeachers = teachers.map(teacher => {
                if (teacher.id === teacherId) {
                    if (freesolo) {
                        return { ...teacher, name: value, userId: '' };
                    } else {
                        return { ...teacher, userId: value.id, name: value.firstname + ' ' + value.surname };
                    }
                }
                return teacher;
            });
        } else {
            updatedTeachers = [...teachers]

            if (freesolo) {
                updatedTeachers[index].name = value;
                updatedTeachers[index].userId = '';
            } else {
                updatedTeachers[index].name = value.firstname + ' ' + value.surname;
                updatedTeachers[index].userId = value.id;
            }
        }
        setTeachers(updatedTeachers);
    }

    //(
    //(teacher.id, dayIndex, newValue, 'from')}
    const handleTimeChange = (teacherId, index, dayIndex, value, type) => {
        let updatedTeachers = []
        if (teacherId) {
            updatedTeachers = teachers.map(teacher => {
                if (teacher.id === teacherId) {
                    return {
                        ...teacher,
                        availability: {
                            ...teacher.availability,
                            [dayIndex]: {
                                ...teacher.availability[dayIndex],
                                [type]: value
                            }
                        }
                    };
                }
                return teacher;
            });
        } else {
            updatedTeachers = [...teachers]
            updatedTeachers[index].availability = {
                ...updatedTeachers[index].availability,
                [dayIndex]: {
                    ...updatedTeachers[index].availability[dayIndex],
                    [type]: value
                }
            };
        }
        setTeachers(updatedTeachers);
    }


    const toggleAvailabilityConfig = (teacherId, index) => {
        let updatedTeachers = []
        if (teacherId) {
            updatedTeachers = teachers.map(teacher => {
                if (teacher.id === teacherId) {
                    return { ...teacher, configureAvailability: !teacher.configureAvailability };
                }
                return teacher;
            });
        } else {
            updatedTeachers = [...teachers]
            updatedTeachers[index].configureAvailability = !updatedTeachers[index].configureAvailability;
        }
        setTeachers(updatedTeachers);
    };

    // Inside your useTeacher hook
    const handleRemoveTeacher = (index) => {
        setLoading(true);
        let updatedTeachers = [...teachers];
        const teacherToRemove = updatedTeachers[index];
        try {
            if (teacherToRemove.id) {
                deleteTeacherSettings(teacherToRemove.id, apiKey, organizationId, token)
                    .then(() => {
                        updatedTeachers.splice(index, 1);
                        setTeachers(updatedTeachers);
                    })
                    .catch(err => console.error("Failed to delete teacher:", err))
                    .finally(() => setLoading(false));
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
        }
    }


    return {
        teachers,
        users,
        loading,
        handleAddTeacher,
        handleSave,
        handleTimeChange,
        toggleAvailabilityConfig,
        handleAutocompleteChange,
        handleRemoveTeacher
    };

};