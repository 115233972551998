// src/pages/RouteDetailsPage.js
import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Box,
    Divider,
    List,
    ListItemText,
    Button,
    Chip,
    LinearProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItemButton,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SnackbarAlert from '../../components/SnackbarAlert';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import { useAuth } from '../../context/AuthContext';
import { fetchRouteById, deleteRoute } from '../../services/v2/RouteService';
import { fetchVehicles } from '../../services/v2/VehicleService';
import { fetchDrivers } from '../../services/v2/DriverService';
import { fetchBusinessPartners } from '../../services/v2/CustomerService';

import { useTranslation } from 'react-i18next';

const RouteDetailsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const { t } = useTranslation();

    const [printDialogOpen, setPrintDialogOpen] = useState(false);
    const [route, setRoute] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [confirmationDialog, setConfirmationDialog] = useState({ open: false, text: '' });

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const routeData = await fetchRouteById(apiKey, organizationId, jwtToken, id);
                setRoute(routeData);

                const [vehiclesData, driversData, customersData] = await Promise.all([
                    fetchVehicles(apiKey, organizationId, jwtToken),
                    fetchDrivers(apiKey, organizationId, jwtToken),
                    fetchBusinessPartners(apiKey, organizationId, jwtToken),
                ]);

                setVehicles(vehiclesData);
                setDrivers(driversData);
                setCustomers(customersData);
            } catch (error) {
                console.error('Error loading data:', error);
                setSnackbar({ open: true, message: t('RouteDetailsPage.errorLoadingData', { error: error.message }), severity: 'error' });
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [id, apiKey, organizationId, jwtToken, t]);

    const handleEdit = () => {
        navigate(`/deliveryPlanning/routes/edit/${id}`);
    };

    const handleDelete = () => {
        setConfirmationDialog({
            open: true,
            text: t('RouteDetailsPage.deleteConfirmation'),
            onConfirm: confirmDelete,
        });
    };

    const confirmDelete = async () => {
        setConfirmationDialog({ ...confirmationDialog, open: false });
        try {
            await deleteRoute(apiKey, organizationId, jwtToken, id);
            setSnackbar({ open: true, message: t('RouteDetailsPage.routeDeleted'), severity: 'success' });
            navigate('/routes');
        } catch (error) {
            console.error('Error deleting route:', error);
            setSnackbar({ open: true, message: t('RouteDetailsPage.errorDeletingRoute', { error: error.message }), severity: 'error' });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleCloseConfirmationDialog = () => {
        setConfirmationDialog({ ...confirmationDialog, open: false });
    };

    if (loading) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('RouteDetailsPage.loadingRoute')}</Typography>
                <LinearProgress sx={{ width: '100%', mt: 2 }} />
            </Box>
        );
    }

    if (!route) {
        return (
            <Typography variant="h6">{t('RouteDetailsPage.routeNotFound')}</Typography>
        );
    }

    const getVehicleName = (vehicleId) => {
        const vehicle = vehicles.find(v => v.id === vehicleId);
        return vehicle ? vehicle.name : t('RouteDetailsPage.unassigned');
    };

    const getDriverName = (driverId) => {
        const driver = drivers.find(d => d.id === driverId);
        return driver ? driver.name : t('RouteDetailsPage.unassigned');
    };

    const assignedCustomers = route.customers.map(customer => {
        return customers.find(c => c.id === customer.id) || { id: customer.id, name: t('RouteDetailsPage.unknownCustomer') };
    });

    return (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    {t('RouteDetailsPage.title')}
                </Typography>
                <Box display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={handleEdit}
                        sx={{ mr: 2 }}
                    >
                        {t('RouteDetailsPage.editButton')}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                    >
                        {t('RouteDetailsPage.deleteButton')}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6">{t('RouteDetailsPage.routeName')}: {route.name || t('RouteDetailsPage.notAvailable')}</Typography>
                <Typography variant="body1">{t('RouteDetailsPage.driver')}: {getDriverName(route.driverId)}</Typography>
                <Typography variant="body1">{t('RouteDetailsPage.vehicle')}: {getVehicleName(route.vehicleId)}</Typography>
                <Typography variant="body1">
                    {t('RouteDetailsPage.status')}: {' '}
                    <Chip
                        label={route.status ? route.status.charAt(0).toUpperCase() + route.status.slice(1) : t('RouteDetailsPage.active')}
                        color={route.status === 'inactive' ? 'default' : 'success'}
                        size="small"
                    />
                </Typography>
                <Divider sx={{ my: 2 }} />

                <Typography variant="h6">{t('RouteDetailsPage.assignedCustomers')}</Typography>
                <List>
                    {assignedCustomers.map((customer) => (
                        <ListItemButton
                            key={customer.id}
                            onClick={() => navigate(`/owners/${customer.id}`)}
                            sx={{ pl: 0 }}
                        >
                            <ListItemText
                                primary={customer.firstname ? `${customer.firstname} ${customer.surname}` : t('RouteDetailsPage.unknownCustomer')}
                                secondary={customer.address ? `${customer.address.street}, ${customer.address.city}` : t('RouteDetailsPage.addressNotAvailable')}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Box>

            <ConfirmationDialog
                isOpen={confirmationDialog.open}
                onClose={handleCloseConfirmationDialog}
                onConfirm={confirmationDialog.onConfirm}
                text={confirmationDialog.text}
            />

            <SnackbarAlert
                open={snackbar.open}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Paper>
    );
};

export default RouteDetailsPage;
