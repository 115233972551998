// src/pages/VeterinarianVisitPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    LinearProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useTheme, useMediaQuery } from '@mui/material';

import VeterinarianVisitModal from '../components/VeterinarianVisitModal';
import { usePreferences } from '../context/UserPreferencesContext';
import { useDateLocale } from '../hooks/useDateLocale';

// -- New hooks & services for pagination --
import { usePaginatedData } from '../hooks/usePaginatedData';
import { fetchPaginatedVetVisits } from '../services/v2/VetVisitService';
import { fetchHorsesPaginated, getHorsesByUserId } from '../services/v2/horseService';
import { fetchPaginatedVeterinarians } from '../services/v2/VeterinarianService';

// -- DataViewWrapper replaces direct usage of GenericTableView / GenericCardView --
import DataViewWrapper from '../components/DataViewWrapper';

const VeterinarianVisitPage = () => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        apiKey,
        organizationId,
        token,
        userType,
        userId,
        ownerId,
    } = useAuth();

    const { devicePreferences, updateDevicePreferences } = usePreferences();

    // ------------------ LOCAL STATE ------------------
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);

    // We’ll store all horses and all vets in these states (fetched via do/while loop).
    const [horses, setHorses] = useState([]);
    const [vets, setVets] = useState([]);

    // We'll track loading states for horses and vets
    const [horsesLoading, setHorsesLoading] = useState(false);
    const [vetsLoading, setVetsLoading] = useState(false);

    const ownerHorses = horses.filter(h => h.ownerId === ownerId);
    const otherHorses = horses.filter(h => h.ownerId !== ownerId);

    // Combine them so owned horses appear first
    const sortedHorses = [...ownerHorses, ...otherHorses];

    // ------------------ PAGINATED HOOK FOR VISITS ------------------
    // This is for the DataViewWrapper’s table/card listing of Vet Visits.
    // You get visits, plus filter/sort, continuation token, etc.
    const {
        items: vetVisits,
        continuationToken,
        loading: visitsLoading,
        loadNextPage,
        loadFirstPage,
        filter,
        setFilter,
        sort,
        setSort,
    } = usePaginatedData(
        (options) => fetchPaginatedVetVisits(apiKey, organizationId, token, options),
        { pageSize: 25 }
    );

    // We unify the loading states for the main listing + the resources needed for the modal:
    const isLoading = visitsLoading || horsesLoading || vetsLoading;

    // ------------------ FETCH ALL HORSES (via pagination loop) ------------------
    useEffect(() => {
        let isCancelled = false;
        const fetchAllHorses = async () => {
            setHorsesLoading(true);
            try {
                let allHorses = [];
                let nextToken = null;
                if (userType === 'admin') {
                    do {
                        const response = await fetchHorsesPaginated(apiKey, organizationId, token, {
                            pageSize: 1000,
                            continuationToken: nextToken,
                        });
                        allHorses = [...allHorses, ...(response.items || [])];
                        nextToken = response.continuationToken;
                    } while (nextToken && !isCancelled);
                } else {
                    const response = (await getHorsesByUserId(userId, apiKey, organizationId, token)).items;
                    allHorses = response || [];
                }
                if (!isCancelled) setHorses(allHorses);
            } catch (error) {
                console.error('Failed to fetch horses:', error);
            } finally {
                if (!isCancelled) setHorsesLoading(false);
            }
        };
        fetchAllHorses();
        return () => {
            isCancelled = true;
        };
    }, [apiKey, organizationId, token]);

    // ------------------ FETCH ALL VETS (via pagination loop) ------------------
    useEffect(() => {
        let isCancelled = false;
        const fetchAllVets = async () => {
            setVetsLoading(true);
            try {
                let allVets = [];
                let nextToken = null;
                do {
                    const response = await fetchPaginatedVeterinarians(apiKey, organizationId, token, {
                        pageSize: 1000,
                        continuationToken: nextToken,
                    });
                    allVets = [...allVets, ...(response.items || [])];
                    nextToken = response.continuationToken;
                } while (nextToken && !isCancelled);

                if (!isCancelled) setVets(allVets);
            } catch (error) {
                console.error('Failed to fetch veterinarians:', error);
            } finally {
                if (!isCancelled) setVetsLoading(false);
            }
        };
        fetchAllVets();
        return () => {
            isCancelled = true;
        };
    }, [apiKey, organizationId, token]);

    // ------------------ VIEW MODE ------------------
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode
            ? devicePreferences.viewMode
            : isMobile
                ? 'card'
                : 'table'
    );

    useEffect(() => {
        // If the user changes preferences or device changes,
        // sync the local viewMode.
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const toggleViewMode = () => {
        const newView = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newView);
        updateDevicePreferences('viewMode', newView);
    };

    // ------------------ HANDLERS FOR MODAL ------------------
    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentRecord(null);

        // Reload the first page of visits after closing the modal
        loadFirstPage();
    };

    const handleAddVisit = () => {
        setCurrentRecord(null);
        setModalOpen(true);
    };

    const handleEditVisit = (visitId) => {
        const record = vetVisits.find(v => v.id === visitId);
        setCurrentRecord(record || null);
        setModalOpen(true);
    };

    // ------------------ UTILS FOR DISPLAY ------------------
    const getVetNameById = (vetId, visit) => {
        const found = vets.find(v => v.id === vetId);
        if (found) {
            return `${found.firstname} ${found.surname}`;
        }
        // fallback if not found in vets array
        return visit.vetName || 'Unknown Vet';
    };

    const getHorseNameById = (horseId) => {
        const found = horses.find(h => h.id === horseId);
        return found ? found.name : 'Unknown Horse';
    };


    // ------------------ TRANSFORM DATA FOR DataViewWrapper ------------------
    const transformData = (visitsData) => {
        return {
            headers: [
                {
                    key: 'horseName',
                    label: t('veterinarianVisits.Page.tableHeaders.horseName'),
                    display: { value: true },
                },
                {
                    key: 'dateOfVisit',
                    label: t('veterinarianVisits.Page.tableHeaders.dateOfVisit'),
                    display: { value: true },
                },
                {
                    key: 'vetName',
                    label: t('veterinarianVisits.Page.tableHeaders.vetName'),
                    display: { value: true },
                },
            ],
            rows: visitsData?.map((visit) => ({
                id: visit.id,
                horseName: {
                    value: getHorseNameById(visit.horseId),
                    icon: null,
                },
                dateOfVisit: {
                    value: format(new Date(visit.visitDate), 'P', { locale: dateLocale }),
                    icon: null,
                },
                vetName: {
                    value: getVetNameById(visit.vetId, visit),
                    icon: null,
                },
            })),
        };
    };

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('veterinarianVisits.Page.title')}
            </Typography>



            {/* Loading Indicator */}
            {isLoading && <LinearProgress />}

            {/* MAIN VISITS DATA VIEW */}
            <DataViewWrapper
                structure={transformData(vetVisits || [])}
                viewMode={viewMode}
                onViewModeToggle={toggleViewMode}
                filter={filter}
                setFilter={setFilter}
                sort={sort}
                setSort={setSort}
                onRowClick={handleEditVisit}
                pageId="/veterinarian-visits"
                toolbarActions={[
                    <Button
                        key="add-visit"
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddVisit}
                    >
                        {t('veterinarianVisits.Page.addNewVisit')}
                    </Button>,
                ]}
                predefinedFilters={
                    sortedHorses.map(h => ({
                        label: `${h.name}`,    // or e.g. "My Horse: {h.name}"
                        field: 'horseId',
                        value: h.id,
                        enabled: false,       // or omit this – we'll let DataViewWrapper handle
                    }))
                }
                enableFilterControls={false}
                enableSortControls={false}
            />

            {/* LOAD MORE BUTTON (only for visits) */}
            {continuationToken && (
                <Box mt={2} textAlign="center">
                    <Button variant="contained" onClick={loadNextPage} disabled={visitsLoading}>
                        {t('common.loadMore')}
                    </Button>
                </Box>
            )}

            {/* MODAL FOR ADD/EDIT */}
            <VeterinarianVisitModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                record={currentRecord}
                horses={horses}   // passing your entire horses array for the dropdown
                vets={vets}       // passing your entire vets array for the dropdown
            />
        </Paper>
    );
};

export default VeterinarianVisitPage;
