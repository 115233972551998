import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { fetchPaymentById, createPayment, updatePayment, deletePayment } from '../../services/v2/PaymentsService';
import { da } from 'date-fns/locale';
import { fetchOwnersPaginated } from '../../services/v2/ownerService';
import { useNavigate } from 'react-router-dom';
import { formatISO, add } from 'date-fns'; // Make sure to install date-fns if you haven't
// Import other necessary services and utilities

export const usePayment = (paymentId) => {
    const today = new Date();
    const defaultDate = formatISO(today);
    const initialPaymentDetail = {
        id: '',
        amount: '',
        date: defaultDate,
        payerName: '',
        payerContact: '',
        payerReference: '',
        paymentMethod: '',
        referenceId: '',
        referenceType: '',
        notes: ''
    };
    const [paymentData, setPaymentData] = useState(initialPaymentDetail);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const [owners, setOwners] = useState([]);
    const navigate = useNavigate();

    const fetchPaymentData = async () => {
        setLoading(true);
        try {
            const data = await fetchPaymentById(apiKey, organizationId, jwtToken, paymentId);
            setPaymentData(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Call fetchPaymentData in useEffect
    useEffect(() => {
        if (paymentId !== 'new') {
            fetchPaymentData();
        }
    }, [paymentId]);


    // get all owners with horses
    useEffect(() => {
        async function fetchAndSetOwners() {
            try {
                const data = await fetchOwnersPaginated(apiKey, organizationId, jwtToken, { pageSize: 1000 });
                setOwners(data.items);
            } catch (error) {
                console.error(error);
            }
        }
        fetchAndSetOwners();
    }, [apiKey, organizationId, jwtToken]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPaymentData({ ...paymentData, [name]: value });
    };


    // Save or Update Payment
    const savePayment = async () => {
        setLoading(true);
        try {
            let responseData;
            if (paymentId !== 'new') {
                responseData = await updatePayment(paymentData, apiKey, organizationId, jwtToken);
            } else {
                responseData = await createPayment(paymentData, apiKey, organizationId, jwtToken);
            }
            setPaymentData(responseData);
            navigate('/sales-process?tab=4');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleAutocompleteChange = (event, newValue) => {
        // Find the owner by matching the full name
        const selectedOwner = owners.find(owner => `${owner.firstname} ${owner.surname}` === newValue);
        if (selectedOwner) {
            setPaymentData({
                ...paymentData,
                payerName: `${selectedOwner.firstname} ${selectedOwner.surname}`, // Full name
                payerContact: selectedOwner.email // Assuming owners have an 'email' property
            });
        } else {
            // Handle the case when the input is cleared or an unmatched value is entered
            setPaymentData({
                ...paymentData,
                payerName: newValue,
                payerContact: ''
            });
        }
    };

    // Inside usePayment hook
    const removePayment = async () => {
        setLoading(true);
        try {
            // Call an API or perform actions to remove the payment
            // For example: await deletePayment(paymentId, apiKey, organizationId, jwtToken);
            await deletePayment(paymentId, apiKey, organizationId, jwtToken);
            setLoading(false);
            // You might want to navigate to another page after deletion
            navigate('/sales-process?tab=4');
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };


    return {
        paymentData,
        setPaymentData,
        removePayment,
        handleInputChange,
        handleAutocompleteChange,
        owners,
        savePayment,
        loading,
        error,
    };
};
