// src/pages/DocumentsPage.js

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Button,
    IconButton,
    LinearProgress,
} from '@mui/material';

import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../context/UserPreferencesContext';

// Replace this with your new paginated function:
import { fetchPaginatedDocuments } from '../services/v2/documentService';
// e.g. function that calls fetchPaginatedData("/api/v2/documents", options, ...)

import DocumentUploadModal from '../components/DocumentUploadModal';
import DataViewWrapper from '../components/DataViewWrapper';
import { usePaginatedData } from '../hooks/usePaginatedData';

const DocumentsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { apiKey, token, organizationId } = useAuth();
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    // Use our paginated hook for documents
    const {
        items: documents,
        loading: documentsLoading,
        continuationToken,
        loadNextPage,
        filter,
        setFilter,
        sort,
        setSort,
    } = usePaginatedData(
        (options) => fetchPaginatedDocuments(apiKey, organizationId, token, options),
        { pageSize: 25 }
    );

    // Local state for card/table view
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || (isMobile ? 'card' : 'table')
    );

    // For controlling the upload modal
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);

    // Sync local view mode with user preferences
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    // Toggle between table and card
    const toggleViewMode = () => {
        const newMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newMode);
        updateDevicePreferences('viewMode', newMode);
    };

    // Navigate to a document detail
    const handleRowClick = (documentId) => {
        navigate(`/documents/details/${documentId}`);
    };

    // Transform data for DataViewWrapper
    const transformData = useMemo(() => ({
        headers: [
            {
                key: 'originalName',
                // You had "orginalName" in your sample, but let's correct to "originalName" if that's the real field name
                label: t('documentData.list.tableHeader.name'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'uploadDate',
                label: t('documentData.list.tableHeader.uploadDate'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'description',
                label: t('documentData.list.tableHeader.description'),
                display: { value: true, chipColor: false },
            },
        ],
        rows: documents.map((doc) => ({
            id: doc.id,
            originalName: { value: doc.orginalName || doc.originalName },
            // fallback if you have inconsistent field naming
            uploadDate: {
                value: new Date(doc.uploadDate).toLocaleDateString(),
            },
            description: { value: doc.description },
        })),
    }), [documents, t]);

    const isLoading = documentsLoading;

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('documentData.list.title')}
            </Typography>

            {/* Loading or Data View */}
            {isLoading ? (
                <LinearProgress />
            ) : (
                <>
                    {/* DataViewWrapper for table/card + filtering/sorting */}
                    <DataViewWrapper
                        structure={transformData}
                        viewMode={viewMode}
                        onViewModeToggle={toggleViewMode}
                        filter={filter}
                        setFilter={setFilter}
                        sort={sort}
                        setSort={setSort}
                        onRowClick={handleRowClick}
                        pageId="/documents"
                        toolbarActions={[
                            <Button
                                key="add-document"
                                variant="contained"
                                color="primary"
                                onClick={() => setUploadModalOpen(true)}
                            >
                                {t('documentData.list.uploadButton')}
                            </Button>,
                        ]}
                    />

                    {/* Load More if continuationToken is present */}
                    {continuationToken && (
                        <Box mt={2} textAlign="center">
                            <Button
                                variant="contained"
                                onClick={loadNextPage}
                                disabled={isLoading}
                            >
                                {t('common.loadMore')}
                            </Button>
                        </Box>
                    )}
                </>
            )}

            {/* Upload Modal */}
            <DocumentUploadModal
                isOpen={isUploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
            />
        </Paper>
    );
};

export default DocumentsPage;
