// src/pages/DewormingPage.js

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Paper, Button, IconButton, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';

import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';
import { useDateLocale } from '../hooks/useDateLocale';

// Replace direct getAllDewormings with your new paginated function:
import { fetchPaginatedDewormings } from '../services/v2/dewormingService';
// e.g. a function that calls fetchPaginatedData("/api/v2/dewormings", options, ...)

// DataViewWrapper for table/card
import DataViewWrapper from '../components/DataViewWrapper';
// your custom pagination hook
import { usePaginatedData } from '../hooks/usePaginatedData';

const DewormingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dateLocale = useDateLocale();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { apiKey, organizationId, token } = useAuth();
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    // Paginated data hook for dewormings
    const {
        items: dewormings,
        loading: dewormingsLoading,
        continuationToken,
        loadNextPage,
        filter,
        setFilter,
        sort,
        setSort,
    } = usePaginatedData(
        (options) => fetchPaginatedDewormings(apiKey, organizationId, token, options),
        { pageSize: 25 }
    );

    // Local state for table/card view
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || (isMobile ? 'card' : 'table')
    );

    // Keep local view mode in sync with user preferences
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    // Navigate to detail
    const handleRowClick = (id) => {
        navigate(`/dewormings/detail/${id}`);
    };

    const handleAddDeworming = () => {
        navigate('/dewormings/detail/new');
    };

    // Toggle between table and card
    const toggleViewMode = () => {
        const newMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newMode);
        updateDevicePreferences('viewMode', newMode);
    };

    // Utility to format dates
    const formattedDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return format(date, 'PPP', { locale: dateLocale });
    };

    // Transform data for DataViewWrapper
    const transformData = useMemo(() => ({
        headers: [
            {
                key: 'date',
                label: t('dewormingPage.table.date'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'nextDueDate',
                label: t('dewormingPage.table.nextDueDate'),
                display: { value: true, chipColor: false },
            },
        ],
        rows: dewormings.map((dw) => ({
            id: dw.id,
            date: { value: formattedDate(dw.date) },
            nextDueDate: { value: formattedDate(dw.nextDueDate) },
        })),
    }), [dewormings, t, dateLocale]);

    const isLoading = dewormingsLoading;

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('dewormingPage.title')}
            </Typography>

           {/* Loading Indicator or Data View */}
            {isLoading ? (
                <LinearProgress />
            ) : (
                <>
                    <DataViewWrapper
                        structure={transformData}
                        viewMode={viewMode}
                        onViewModeToggle={toggleViewMode}
                        filter={filter}
                        setFilter={setFilter}
                        sort={sort}
                        setSort={setSort}
                        onRowClick={handleRowClick}
                        pageId="/dewormings"
                        toolbarActions={[
                            <Button
                                key="add-deworming"
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAddDeworming}
                            >
                                {t('dewormingPage.addDeworming')}
                            </Button>,
                        ]}
                    />

                    {/* Load More if there's a next page */}
                    {continuationToken && (
                        <Box mt={2} textAlign="center">
                            <Button
                                variant="contained"
                                onClick={loadNextPage}
                                disabled={isLoading}
                            >
                                {t('common.loadMore')}
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </Paper>
    );
};

export default DewormingPage;
