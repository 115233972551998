// src/components/OrgTabs/UserManagement.js

import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchPaginatedUsers } from '../../services/v2/userService'; // Implement this service
import { useAuth } from '../../context/AuthContext';
import { set } from 'date-fns';


const UserManagement = () => {
    const navigate = useNavigate();
    const initialUser = {
        id: '',
        Email: '',
        status: '',
        Role: '',
        organizationId: '',
    };
    const [users, setUsers] = useState([]);
    const [owners, setOwners] = useState([]);
    const [loading, setLoading] = useState(true);
    const { apiKey, token, organizationId } = useAuth();

    const roleOptions = [
        { value: 'admin', label: 'Admin' },
        { value: 'customer', label: 'Customer' },
        { value: 'worker', label: 'Worker' },
        { value: 'teacher', label: 'Teacher' }
    ];

    useEffect(() => {
        try {
            setLoading(true);
            fetchPaginatedUsers(apiKey, organizationId, token, { pageSize: 1000 })
                .then(data => setUsers(data.items || []))
                .catch(err => console.error("Failed to fetch users:", err))
                .finally(() => setLoading(false));
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }, []);


    // Function to get role label from value
    const getRoleLabel = (roleValue) => {
        const role = roleOptions.find(option => option.value === roleValue);
        return role ? role.label : roleValue; // Fallback to value if not found
    };

    const handleRowClick = (userId) => {
        navigate(`/organization-settings/users/edit/${userId}`);
    };

    const handleAddNewClick = () => {
        navigate('/organization-settings/users/new');
    };

    return (
        <div>
            <h1>User Management</h1>
            <Button variant="contained" color="primary" onClick={handleAddNewClick}>
                Add New User
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Firstname</TableCell>
                            <TableCell>Lastname</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id} hover onClick={() => handleRowClick(user.id)} style={{ cursor: 'pointer' }}>
                                <TableCell>{user.firstname}</TableCell>
                                <TableCell>{user.surname}</TableCell>
                                <TableCell>{user.Email}</TableCell>
                                <TableCell>{getRoleLabel(user.Role)}</TableCell>
                                <TableCell>{user.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default UserManagement;
