import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, LinearProgress, Button } from '@mui/material';
import { verifyToken } from '../services/v2/tokenService';
import { useTranslation } from 'react-i18next';
import { loginRequest } from '../context/authConfig';
import { useMsal } from '@azure/msal-react';

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');
  const organizationId = params.get('organizationId');
  const signUpType = params.get('signuptype') || 'default';

  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const tokenValid = await verifyToken(token, organizationId);
        setIsTokenValid(tokenValid);
      } catch (err) {
        console.error('Token verification failed:', err);
        setErrorMessage(t('SignUp.tokenVerificationFailed'));
      } finally {
        setIsLoading(false);
      }
    };

    validateToken();
  }, [token, organizationId]);

  const initiateSignUp = () => {
    if (isAuthenticating) return;

    setIsAuthenticating(true);
    const loginRequestWithState = {
      ...loginRequest,
      state: JSON.stringify({ signUpType, token, organizationId, email }),
    };

    instance.loginRedirect(loginRequestWithState);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        {t('SignUp.title')}
      </Typography>
      {isLoading ? (
        <LinearProgress />
      ) : isTokenValid ? (
        <Button fullWidth variant="contained" color="primary" onClick={initiateSignUp}>
          {t('SignUp.signUpButton')}
        </Button>
      ) : (
        <Typography variant="h6" color="error" align="center">
          {errorMessage || t('SignUp.invalidOrExpiredToken')}
        </Typography>
      )}
    </Container>
  );
};

export default SignUp;
