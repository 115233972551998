// src/services/VetVisitService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/vetvisits';

export async function fetchAllVetVisits(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function fetchVetVisitById(apiKey, organizationId, jwtToken, id, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?vetVisitId=${id}`;
  const response = await axiosInstance.get(url);
  return response.data[0];
}

export async function saveVetVisit(vetVisit, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}`;
  const response = await axiosInstance.post(url, vetVisit);
  return response.data;
}

export async function updateVetVisit(vetVisit, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?vetVisitId=${vetVisit.id}`;
  const response = await axiosInstance.put(url, vetVisit);
  return response.data;
}

export async function deleteVetVisit(vetVisitId, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?vetVisitId=${vetVisitId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function fetchPaginatedVetVisits(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  return fetchPaginatedData(API_BASE_URL, options, { apiKey, organizationId, jwtToken });
}

export default {
  fetchAllVetVisits,
  fetchVetVisitById,
  saveVetVisit,
  updateVetVisit,
  deleteVetVisit,
  fetchPaginatedVetVisits,
};
