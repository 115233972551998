// src/pages/PaymentReminders/PaymentRemindersListPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
    Paper,
    Button,
    Chip,
    Box,
    IconButton,
    Typography,
    LinearProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CancelIcon from '@mui/icons-material/Cancel';
import { format } from 'date-fns';

import { useTheme, useMediaQuery } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { usePreferences } from '../../context/UserPreferencesContext';
import { useDateLocale } from '../../hooks/useDateLocale';

// Replace this import with your new paginated function:
import { fetchPaginatedPaymentReminders } from '../../services/v2/PaymentReminderService';
// e.g. a function that calls fetchPaginatedData("/api/v2/paymentReminders", options, ...)

import DataViewWrapper from '../../components/DataViewWrapper';
import SnackbarAlert from '../../components/SnackbarAlert';

// Import your pagination hook
import { usePaginatedData } from '../../hooks/usePaginatedData';

const PaymentRemindersListPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dateLocale = useDateLocale();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { apiKey, token, organizationId } = useAuth();
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    // Use our paginated data hook for Payment Reminders
    const {
        items: paymentReminders,
        loading: remindersLoading,
        continuationToken,
        loadNextPage,
        filter,
        setFilter,
        sort,
        setSort,
    } = usePaginatedData(
        (options) => fetchPaginatedPaymentReminders(apiKey, organizationId, token, options),
        { pageSize: 25 }
    );

    // Local view mode state
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || (isMobile ? 'card' : 'table')
    );

    // If you need a Snackbar for success/error messages, define it here:
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: '',
    });

    // Sync local `viewMode` whenever user preferences change
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const toggleViewMode = () => {
        const newMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newMode);
        updateDevicePreferences('viewMode', newMode);
    };

    const handleAddPaymentReminder = () => {
        navigate('/payment-reminders/new');
    };

    const handlePaymentReminderClick = (paymentReminderId) => {
        navigate(`/payment-reminders/${paymentReminderId}`);
    };

    // Example for closing the snackbar
    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Utility to render status chips
    const renderStatus = (status) => {
        if (status === 'unpaid') {
            return (
                <Chip
                    label={t('paymentReminder.listPage.unpaid')}
                    color="error"
                    icon={<CancelIcon />}
                />
            );
        } else if (status === 'paid') {
            return (
                <Chip
                    label={t('paymentReminder.listPage.paid')}
                    color="success"
                    icon={<CheckCircleIcon />}
                />
            );
        } else if (status === 'escalated') {
            return (
                <Chip
                    label={t('paymentReminder.listPage.escalated')}
                    color="warning"
                    icon={<WarningAmberIcon />}
                />
            );
        }
        // optionally, default fallback
        return null;
    };

    // Transform data for DataViewWrapper
    const transformData = useMemo(() => ({
        headers: [
            {
                key: 'customer',
                label: t('paymentReminder.listPage.customer'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'date',
                label: t('paymentReminder.listPage.date'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'amount',
                label: t('paymentReminder.listPage.amount'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'status',
                label: t('paymentReminder.listPage.status'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'level',
                label: t('paymentReminder.listPage.level'),
                display: { value: true, chipColor: false },
            },
        ],
        rows: paymentReminders.map((reminder) => ({
            id: reminder.id,
            customer: { value: reminder.payerName },
            date: {
                value: reminder.creationDate
                    ? format(new Date(reminder.creationDate), 'P', { locale: dateLocale })
                    : '',
            },
            amount: { value: reminder.totalGrossAmount },
            level: { value: reminder.level },
            status: {
                value: renderStatus(reminder.status),
            },
            // If you want row-specific actions, you can add them here:
            // actions: [ ... ],
        })),
    }), [paymentReminders, dateLocale, t]);

    const isLoading = remindersLoading;

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            {/* Loading indicator */}
            {isLoading ? (
                <LinearProgress />
            ) : (
                <>
                    {/* DataViewWrapper for table/card mode, filtering, etc. */}
                    <DataViewWrapper
                        structure={transformData}
                        viewMode={viewMode}
                        onViewModeToggle={toggleViewMode}
                        filter={filter}
                        setFilter={setFilter}
                        sort={sort}
                        setSort={setSort}
                        onRowClick={handlePaymentReminderClick}
                        pageId="/payment-reminders"
                        toolbarActions={[
                            <Button
                                key="add-reminder"
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAddPaymentReminder}
                            >
                                {t('paymentReminder.listPage.addButton')}
                            </Button>
                        ]}
                    />

                    {/* Load More button if there's more data */}
                    {continuationToken && (
                        <Box mt={2} textAlign="center">
                            <Button
                                variant="contained"
                                onClick={loadNextPage}
                                disabled={isLoading}
                            >
                                {t('common.loadMore')}
                            </Button>
                        </Box>
                    )}
                </>
            )}

            {/* If you have a Snackbar for feedback */}
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Paper>
    );
};

export default PaymentRemindersListPage;
