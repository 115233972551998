import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { loginWithAzure, signUpUser } from '../services/v2/authService';
import { jwtDecode } from 'jwt-decode';
import SnackbarAlert from './SnackbarAlert';
import { Typography, Box, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';


const tenantURL = process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME;
const tenantClientId = process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID;

function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

function AuthRedirect() {
    const { instance, accounts } = useMsal();
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const location = useLocation();
    const { login, loginPostInformation } = useAuth();
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info',
    });

    const params = new URLSearchParams(location.search);
    const signUpType = params.get('signuptype') || 'default';
    const customScope = `https://${tenantURL}.onmicrosoft.com/API-Scopes/Permission.All`;

    useEffect(() => {
        const waitForDOMAndHandle = async () => {
            if (document.readyState !== 'complete') {
                await new Promise((resolve) => window.addEventListener('load', resolve));
            }
            await new Promise((res) => setTimeout(res, 200)); // Safari hydration delay
            await handleAuthentication();
        };

        waitForDOMAndHandle();
    }, []);

    const handleAuthentication = async () => {
        // Clear potential stuck state
        sessionStorage.removeItem(`msal.${tenantClientId}.interaction.status`);


        let attempts = 0;
        const maxRetries = 2;

        while (attempts < maxRetries) {
            try {
                const authResult = await instance.handleRedirectPromise();

                if (authResult) {
                    const policyUsed = authResult.idTokenClaims?.tfp || authResult.idTokenClaims?.acr;
                    localStorage.setItem('b2cPolicy', policyUsed);
                    const { accessToken, idToken, account, state } = authResult;
                    const decodedToken = jwtDecode(idToken);
                    const email = decodedToken.emails ? decodedToken.emails[0] : '';
                    const decodedState = state ? JSON.parse(decodeURIComponent(state)) : null;
                    console.log('Decoded state:', decodedState);

                    if (decodedState?.signUpType !== "azureLogin") {
                        // Handle sign-up process
                        const userData = {
                            homeAccountId: account.homeAccountId,
                            azSubject: decodedToken.sub,
                            email: email,
                            oldEmail: decodedState.email,
                            organizationId: decodedState.organizationId,
                        };

                        await signUpUser(userData);

                        let redirectPath = '/';
                        switch (decodedState.signUpType) {
                            case 'guided':
                                redirectPath = '/auth/guided-signup';
                                break;
                            case 'organization':
                                redirectPath = '/auth/organization-signup';
                                break;
                            default:
                                break;
                        }
                        navigate(redirectPath, { state: decodedState });
                    } else {
                        try {
                            await login(accessToken);
                            const userData = await loginWithAzure(email, account.homeAccountId, accessToken);

                            if (!userData) throw new Error('User not found in the system. Please contact support.');
                            await loginPostInformation(userData);
                            navigate('/');
                        } catch (loginError) {
                            console.error('Login error:', loginError);
                            setSnackbar({
                                open: true,
                                message: t('Authentication.error', { error: loginError.message }),
                                severity: 'error',
                            });

                            if (loginError.message.includes('User not found')) {
                                navigate('/error', { state: { message: loginError.message } });
                            } else {
                                navigate('/auth');
                            }
                        }
                    }
                    return;
                } else {
                    // Safari-specific redirect loop guard
                    if (isSafari()) {
                        console.warn('Safari detected: authResult is null. Avoiding redirect loop.');
                        setSnackbar({
                            open: true,
                            message: t('Authentication.safariLoopWarning'),
                            severity: 'warning',
                        });
                        navigate('/auth');
                        return;
                    }

                    attempts++;
                    if (attempts < maxRetries) {
                        await new Promise((res) => setTimeout(res, 500));
                    }
                }
            } catch (error) {
                console.error('Error during authentication process:', error);
                setSnackbar({
                    open: true,
                    message: t('Authentication.error', { error: error.message }),
                    severity: 'error',
                });
                navigate('/auth');
                return;
            }
        }

        // Try acquireTokenSilent if accounts exist
        if (accounts.length > 0) {
            try {
                const account = accounts[0];
                const policy = localStorage.getItem('b2cPolicy') || 'B2C_1_signIn.equistab';
                const authority = `https://${tenantURL}.b2clogin.com/${tenantURL}.onmicrosoft.com/${policy}`;

                const response = await instance.acquireTokenSilent({
                    scopes: [customScope],
                    account: account,
                    authority
                });
                const decodedToken = jwtDecode(response.idToken);
                const email = decodedToken.emails ? decodedToken.emails[0] : '';

                try {
                    await login(response.accessToken);
                    const userData = await loginWithAzure(email, account.homeAccountId, response.accessToken);

                    if (!userData) throw new Error('User not found in the system. Please contact support.');
                    await loginPostInformation(userData);
                    navigate('/');
                } catch (loginError) {
                    console.error('Silent login error:', loginError);
                    setSnackbar({
                        open: true,
                        message: t('Authentication.error', { error: loginError.message }),
                        severity: 'error',
                    });

                    if (loginError.message.includes('User not found')) {
                        navigate('/error', { state: { message: loginError.message } });
                    } else {
                        navigate('/auth');
                    }
                }
            } catch (silentError) {
                console.error('Silent token acquisition failed:', silentError);
                setSnackbar({
                    open: true,
                    message: t('Authentication.silentAuthFailed'),
                    severity: 'warning',
                });
                navigate('/auth');
            }
        } else {
            setSnackbar({
                open: true,
                message: t('Authentication.notAuthenticated'),
                severity: 'warning',
            });
            navigate('/auth');
        }
    };

    return (
        <div>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('Authentication.waitingText')}</Typography>
                <LinearProgress />
            </Box>
            <SnackbarAlert
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </div>
    );
}

export default AuthRedirect;
