// src/components/DeliverySettings.js

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../context/AuthContext';
import GenericTableView from '../GenericTableView';
import DriverDialog from '../DeliveryComponents/DriverDialog';
import VehicleDialog from '../DeliveryComponents/VehicleDialog';
import { fetchDrivers, fetchPaginatedDrivers, deleteDriver } from '../../services/v2/DriverService';
import { fetchVehicles, fetchPaginatedVehicles, deleteVehicle } from '../../services/v2/VehicleService';

import { useTranslation } from 'react-i18next';

const DeliverySettings = () => {
    const { apiKey, organizationId, token } = useAuth();
    const [drivers, setDrivers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [openDriverDialog, setOpenDriverDialog] = useState(false);
    const [openVehicleDialog, setOpenVehicleDialog] = useState(false);
    const [currentDriverId, setCurrentDriverId] = useState(null);
    const [currentVehicleId, setCurrentVehicleId] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        loadDrivers();
        loadVehicles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadDrivers = async () => {
        try {
            const data = await fetchPaginatedDrivers(apiKey, organizationId, token, { pageSize: 1000 });
            setDrivers(data.items);
        } catch (error) {
            console.error(t('DeliverySettings.errorFetchingDrivers', { error: error.message }));
            // Optionally, display a snackbar or alert
        }
    };

    const loadVehicles = async () => {
        try {
            const data = await fetchPaginatedVehicles(apiKey, organizationId, token, { pageSize: 1000 });
            setVehicles(data.items);
        } catch (error) {
            console.error(t('DeliverySettings.errorFetchingVehicles', { error: error.message }));
            // Optionally, display a snackbar or alert
        }
    };

    const handleDeleteDriver = async (driverId) => {
        try {
            await deleteDriver(driverId, apiKey, organizationId, token);
            loadDrivers();
            // Optionally, display a success message
        } catch (error) {
            console.error(t('DeliverySettings.errorDeletingDriver', { error: error.message }));
            // Optionally, display an error message
        }
    };

    const handleDeleteVehicle = async (vehicleId) => {
        try {
            await deleteVehicle(vehicleId, apiKey, organizationId, token);
            loadVehicles();
            // Optionally, display a success message
        } catch (error) {
            console.error(t('DeliverySettings.errorDeletingVehicle', { error: error.message }));
            // Optionally, display an error message
        }
    };

    const driverTableStructure = {
        headers: [
            { key: 'name', label: t('DeliverySettings.tableHeaders.driverName'), display: { value: true } },
            { key: 'licenseNumber', label: t('DeliverySettings.tableHeaders.licenseNumber'), display: { value: true } },
        ],
        rows: drivers.map((driver) => ({
            id: driver.id,
            name: { value: driver.name },
            licenseNumber: { value: driver.licenseNumber },
            actions: [
                {
                    label: t('DeliverySettings.actions.edit'),
                    icon: <EditIcon />,
                    handler: () => {
                        setCurrentDriverId(driver.id);
                        setOpenDriverDialog(true);
                    },
                    ariaLabel: t('DeliverySettings.ariaLabels.editDriver'),
                },
                {
                    label: t('DeliverySettings.actions.delete'),
                    icon: <DeleteIcon />,
                    handler: () => handleDeleteDriver(driver.id),
                    ariaLabel: t('DeliverySettings.ariaLabels.deleteDriver'),
                },
            ],
        })),
    };

    const vehicleTableStructure = {
        headers: [
            { key: 'make', label: t('DeliverySettings.tableHeaders.make'), display: { value: true } },
            { key: 'model', label: t('DeliverySettings.tableHeaders.model'), display: { value: true } },
            { key: 'name', label: t('DeliverySettings.tableHeaders.name'), display: { value: true } },
            { key: 'licensePlate', label: t('DeliverySettings.tableHeaders.licensePlate'), display: { value: true } },
            { key: 'capacity', label: t('DeliverySettings.tableHeaders.capacity'), display: { value: true } },
        ],
        rows: vehicles.map((vehicle) => ({
            id: vehicle.id,
            make: { value: vehicle.make },
            model: { value: vehicle.model },
            name: { value: vehicle.name },
            licensePlate: { value: vehicle.licensePlate },
            capacity: { value: vehicle.capacity },
            actions: [
                {
                    label: t('DeliverySettings.actions.edit'),
                    icon: <EditIcon />,
                    handler: () => {
                        setCurrentVehicleId(vehicle.id);
                        setOpenVehicleDialog(true);
                    },
                    ariaLabel: t('DeliverySettings.ariaLabels.editVehicle'),
                },
                {
                    label: t('DeliverySettings.actions.delete'),
                    icon: <DeleteIcon />,
                    handler: () => handleDeleteVehicle(vehicle.id),
                    ariaLabel: t('DeliverySettings.ariaLabels.deleteVehicle'),
                },
            ],
        })),
    };

    return (
        <Box>
            {/* Manage Drivers Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">{t('DeliverySettings.manageDrivers')}</Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setCurrentDriverId(null);
                        setOpenDriverDialog(true);
                    }}
                >
                    {t('DeliverySettings.addDriverButton')}
                </Button>
            </Box>
            <GenericTableView
                structure={driverTableStructure}
                onClick={(driverId) => {
                    setCurrentDriverId(driverId);
                    setOpenDriverDialog(true);
                }}
            />

            {/* Manage Vehicles Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={4}>
                <Typography variant="h5">{t('DeliverySettings.manageVehicles')}</Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setCurrentVehicleId(null);
                        setOpenVehicleDialog(true);
                    }}
                >
                    {t('DeliverySettings.addVehicleButton')}
                </Button>
            </Box>
            <GenericTableView
                structure={vehicleTableStructure}
                onClick={(vehicleId) => {
                    setCurrentVehicleId(vehicleId);
                    setOpenVehicleDialog(true);
                }}
            />

            {/* Driver Dialog */}
            <DriverDialog
                open={openDriverDialog}
                onClose={() => setOpenDriverDialog(false)}
                driverId={currentDriverId}
                onSave={loadDrivers}
            />

            {/* Vehicle Dialog */}
            <VehicleDialog
                open={openVehicleDialog}
                onClose={() => setOpenVehicleDialog(false)}
                vehicleId={currentVehicleId}
                onSave={loadVehicles}
            />
        </Box>
    );

};

export default DeliverySettings;
