// path: frontend/equistab/src/services/apiConfig/axiosConfig.js

import axios from 'axios';
import i18n from '../../i18n'; // Ensure you import your i18n config correctly
import { useNavigate } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
    baseURL: backendUrl,
    withCredentials: true,  // Enable cookies by default
    headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
    },
});

export const setAuthHeader = (jwtToken) => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
};

export const setApiKeyHeader = (apiKey) => {
    axiosInstance.defaults.headers.common['x-functions-key'] = apiKey;
};

export const setOrganizationIdHeader = (organizationId) => {
    axiosInstance.defaults.headers.common['Organization-Id'] = organizationId;
};

export const setWithCredentials = (withCredentials) => {
    axiosInstance.defaults.withCredentials = withCredentials;
};

// Optional: Add a way to dynamically update the language header when the language changes in the app
export const updateLanguage = (language) => {
    axiosInstance.defaults.headers['Accept-Language'] = language;
};

i18n.on('languageChanged', (lng) => {
    updateLanguage(lng);
});

// Add a response interceptor to handle unauthorized errors globally
axiosInstance.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
        const navigate = useNavigate();
        if (error.response) {
            const { status } = error.response;

            // Redirect to login page for 401 or 403 errors
            if (status === 401) {
                navigate('/auth');
            }
        }

        return Promise.reject(error); // Reject the error to handle it further in specific API calls if needed
    }
);

export default axiosInstance;
