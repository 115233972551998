import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchPaginatedVeterinarians } from '../../services/v2/VeterinarianService';
import { useAuth } from '../../context/AuthContext';

const VeterinarianSettings = () => {
    const navigate = useNavigate();
    const [veterinarians, setVeterinarians] = useState([]);
    const { apiKey, token, organizationId } = useAuth();

    useEffect(() => {

        // Fetch veterinarians from the backend and set the local state
        fetchPaginatedVeterinarians(apiKey, organizationId, token, { pageSize: 1000 }).then(data => setVeterinarians(data.items || []))
            .catch(err => console.error("Failed to fetch veterinarians:", err));
    }, []);

    const handleRowClick = (id) => {
        navigate(`/organization-settings/veterinarians/edit/${id}`);
    };

    const handleAddNewClick = () => {
        navigate('/organization-settings/veterinarians/new');
    };


    return (
        <div>
            <h1>Veterinarian Settings</h1>
            <Button variant="contained" color="primary" onClick={handleAddNewClick}>
                Add New Veterinarian
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Specialty</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {veterinarians.map((vet) => (
                            <TableRow key={vet.id} hover onClick={() => handleRowClick(vet.id)} style={{ cursor: 'pointer' }}>
                                <TableCell>{vet.firstname} {vet.surname}</TableCell>
                                <TableCell>{vet.specialty}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default VeterinarianSettings;
