// src/services/DriverService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const DRIVER_API_BASE_URL = '/api/v2/organization/drivers';

// Fetch all drivers
export async function fetchDrivers(apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(`${DRIVER_API_BASE_URL}`);
    return response.data;
}

// Fetch specific driver by ID
export async function fetchDriverById(apiKey, organizationId, jwtToken, driverId) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(`${DRIVER_API_BASE_URL}?id=${driverId}`);
    return response.data;
}

// Create a new driver
export async function addDriver(driverData, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.post(`${DRIVER_API_BASE_URL}`, driverData);
    return response.data;
}

// Update an existing driver
export async function updateDriver(driverData, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.put(`${DRIVER_API_BASE_URL}?id=${driverData.id}`, driverData);
    return response.data;
}

// Delete a driver by ID
export async function deleteDriver(driverId, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.delete(`${DRIVER_API_BASE_URL}?id=${driverId}`);
    return response.data;
}

export async function fetchPaginatedDrivers(apiKey, organizationId, jwtToken, options) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    return fetchPaginatedData(DRIVER_API_BASE_URL, options, { apiKey, organizationId, jwtToken });
}

export default {
    fetchDrivers,
    fetchDriverById,
    addDriver,
    updateDriver,
    deleteDriver,
    fetchPaginatedDrivers,
};
