// src/components/OrgTabs/VeterinarianForm.js

import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { fetchFerryById, saveFerry, updateFerry, deleteFerry } from '../../services/v2/FerryService';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

const FerryForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const mode = id ? 'edit' : 'add';
    const { apiKey, token, organizationId } = useAuth();
    const [loading, setLoading] = useState(false);

    const [ferryData, setFerryData] = useState({
        id: '',
        firstname: '',
        surname: '',
        email: '',
        phoneNumber: '',
        address: '',
        zipCode: '',
        city: '',
        website: '',
    });

    useEffect(() => {
        if (mode === 'edit') {
            setLoading(true);
            fetchFerryById(apiKey, organizationId, token, id)
                .then(data => {
                    if (data) {
                        setFerryData(data);
                    }
                })
                .catch(err => console.error("Failed to fetch ferry by ID:", err))
                .finally(() => setLoading(false));
        }
    }, [mode, id]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFerryData({
            ...ferryData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mode === 'edit') {
            updateFerry(ferryData, apiKey, organizationId, token)
                .then(response => {
                    console.log('Updated successfully!', response);
                    navigate('/organization-settings?tab=ferry-settings');
                })
                .catch(error => {
                    console.error('Failed to update ferry:', error);
                });
        } else {
            saveFerry(ferryData, apiKey, organizationId, token)
                .then(response => {
                    console.log('Saved successfully!', response);
                    navigate('/organization-settings?tab=ferry-settings');
                })
                .catch(error => {
                    console.error('Failed to save ferry:', error);
                });
        }
        navigate('/organization-settings?tab=ferry-settings');
    };

    const handleDelete = () => {
        deleteFerry(id, apiKey, organizationId, token)
            .then(response => {
                console.log('Deleted successfully!', response);
                navigate('/organization-settings?tab=ferry-settings');
            })
            .catch(error => {
                console.error('Failed to delete ferry:', error);
            });
    };

    return (
        <Container component={Paper} style={{ padding: '2em' }}>
            {loading && <LinearProgress />}
            <h1>{mode === 'add' ? 'Add New Ferry' : 'Edit Ferry'}</h1>
            <form onSubmit={handleSubmit}>
                <TextField required name="firstname" label="Firstname" variant="outlined" margin="normal" value={ferryData.firstname} fullWidth onChange={handleChange} />
                <TextField required name="surname" label="Surname" variant="outlined" margin="normal" value={ferryData.surname} fullWidth onChange={handleChange} />
                <TextField required name="phoneNumber" label="Contact Number" variant="outlined" margin="normal" value={ferryData.phoneNumber} fullWidth onChange={handleChange} />
                <TextField name="email" label="Email" variant="outlined" margin="normal" value={ferryData.email} fullWidth onChange={handleChange} />
                <TextField name="address" label="Address" variant="outlined" margin="normal" value={ferryData.address} fullWidth onChange={handleChange} />
                <TextField name="zipCode" label="ZIP Code" variant="outlined" margin="normal" value={ferryData.zipCode} fullWidth onChange={handleChange} />
                <TextField name="city" label="City" variant="outlined" margin="normal" value={ferryData.city} fullWidth onChange={handleChange} />
                <TextField name="website" label="Website" variant="outlined" margin="normal" value={ferryData.website} fullWidth onChange={handleChange} />
                <Button type="submit" variant="contained" color="primary">
                    Save
                </Button>
                {mode === 'edit' && (
                    <Button variant="contained" color="secondary" onClick={handleDelete}>
                        Delete
                    </Button>
                )}
            </form>
        </Container>
    );
};

export default FerryForm;
