import React from 'react';
import { Box } from '@mui/material';

/**
 * Renders the 2D stable layout as a CSS grid.
 * Each cell can be dragged or can accept drops for swapping or assigning horses.
 */
import Cell from './Cell';

const Grid = ({ layout, onCellClick, selectedCellId, onCellDrop, onHorseDrop }) => {
  /**
   * HTML5 drag events for cells
   */
  const handleDragStart = (e, cellId) => {
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({
        type: 'cell',
        cellId
      })
    );
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e, targetCellId) => {
    e.preventDefault();
    try {
      const data = JSON.parse(e.dataTransfer.getData('application/json'));
      if (!data) return;

      // If we are dragging a cell, we can swap them
      if (data.type === 'cell' && data.cellId && data.cellId !== targetCellId) {
        onCellDrop(data.cellId, targetCellId);
        return;
      }

      // If we are dragging a horse
      if (data.type === 'horse' && data.horseId) {
        onHorseDrop(data.horseId, targetCellId);
      }
    } catch (error) {
      console.error('Error parsing drop data:', error);
    }
  };

  // We define CSS grid with layout.rows and layout.columns
  const gridStyles = {
    display: 'grid',
    gridTemplateRows: `repeat(${layout.rows}, 80px)`, // or any desired size
    gridTemplateColumns: `repeat(${layout.columns}, 80px)`,
    gap: '4px',
  };

  return (
    <Box sx={gridStyles}>
      {layout.cells.map((cell) => {
        const isSelected = selectedCellId === cell.id;
        return (
          <Box
            key={cell.id}
            draggable
            onDragStart={(e) => handleDragStart(e, cell.id)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, cell.id)}
            sx={{
              gridColumn: `span ${cell.width}`,
              gridRow: `span ${cell.height}`,
            }}
          >
            <Cell cell={cell} onClick={onCellClick} isSelected={isSelected} />
          </Box>
        );
      })}
    </Box>
  );
};

export default Grid;
