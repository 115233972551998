// src/services/FerryService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/ferries';

// Fetch all ferries
export async function fetchAllFerries(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

// Fetch a single ferry by ID
export async function fetchFerryById(apiKey, organizationId, jwtToken, id) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Save a new ferry
export async function saveFerry(ferry, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, ferry);
  return response.data;
}

// Update an existing ferry
export async function updateFerry(ferry, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${ferry.id}`;
  const response = await axiosInstance.put(url, ferry);
  return response.data;
}

// Delete a ferry
export async function deleteFerry(ferryId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${ferryId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function fetchPaginatedFerries(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  return fetchPaginatedData(API_BASE_URL, options, { apiKey, organizationId, jwtToken });
}

export default {
  fetchAllFerries,
  fetchFerryById,
  saveFerry,
  updateFerry,
  deleteFerry,
  fetchPaginatedFerries,
};
