// src/pages/SelfSignupPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Stepper, Step, StepLabel, Typography, Box, useTheme } from '@mui/material';
import PersonalInfo from '../components/SelfSignup/PersonalInfo';
import HorseOwnership from '../components/SelfSignup/HorseOwnership';
import SetPassword from '../components/SelfSignup/SetPassword';
import Summary from '../components/SelfSignup/Summary';
import { selfSignUp } from '../services/v2/authService';
import { verifyToken } from '../services/v2/tokenService';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';


const getSteps = (t) => [
    t('SelfSignupPage.steps.personalInformation'),
    t('SelfSignupPage.steps.horseOwnership'),
    t('SelfSignupPage.steps.setPassword'),
    t('SelfSignupPage.steps.summary')
];

const SelfSignupPage = () => {
    const { t } = useTranslation();
    const steps = getSteps(t)
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isTokenValid, setIsTokenValid] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [organizationId, setOrganizationId] = useState(null);
    const [token, setToken] = useState(null);
    const [stage, setStage] = useState(0);
    const [userInfo, setUserInfo] = useState({
        personalInfo: {
            firstname: '',
            surname: '',
            company: '',
            email: '',
            number: '',
        },
        horses: [{
            name: '',
            birthday: '',
            breed: '',
            ownerId: '',
            coRiders: [],
            emergencyContact: {
                name: '',
                number: '',
            },
            veterinarianId: '',
            farrierId: '',
            foodPortions: {}
        }],
        newPersons: {
            veterinarians: [],
            ferries: [],
        },
        password: ''
    });
    const [veterinarianData, setVeterinarianData] = useState([]);
    const [ferryData, setFerryData] = useState([]);

    const handleStepClick = (stepNumber) => {
        // Ensure users don't skip steps, can be modified according to your use case
        if (stepNumber <= stage) {
            setStage(stepNumber);
        }
    };
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const orgId = params.get('organizationId');
        const tkn = params.get('token');
        setOrganizationId(params.get('organizationId'));
        setToken(params.get('token'));
        console.log("token", tkn);

        const verifyTheToken = async () => {
            const isValidResponse = await verifyToken(tkn, orgId);
            if (!isValidResponse) {
                setIsLoading(false);
                setIsTokenValid(false);
                return;
            }
            const tokenValid = isValidResponse.valid
            setIsLoading(false);
            setIsTokenValid(isValidResponse.valid);  // set isTokenValid based on the validation result
            if (!tokenValid) {
                //navigate('/auth');
            } else {
                setVeterinarianData(isValidResponse.veterinarians);
                setFerryData(isValidResponse.ferries);
            }
        }
        verifyTheToken();
    }, [location]);

    const handleNext = (data) => {
        switch (stage) {
            case 0:
                setUserInfo(prev => ({ ...prev, personalInfo: data }));
                break;
            case 1:
                setUserInfo(prev => ({ ...prev, horses: data }));
                break;
            case 2:
                setUserInfo(prev => ({ ...prev, password: data }));
                break;
            default:
                break;
        }
        setStage(prev => prev + 1);
    };



    const handleSubmit = async () => {
        setIsSubmitting(true);  // Start the submission process
        // Call the API to save user signup info
        try {
            const response = await selfSignUp(userInfo, organizationId, token);

            if (response && response.status === "success") {
                navigate('/auth');
            } else {
                setSnackbarMessage(t("SelfSignupPage.signupFailMessage"));
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(t("SelfSignupPage.signupFailMessage"));
            setSnackbarOpen(true);
            console.error("Error during self-signup:", error);
        } finally {
            setIsSubmitting(false);
        }
    }


    const addNewPerson = (type, data) => {
        setUserInfo(prev => ({
            ...prev,
            newPersons: {
                ...prev.newPersons,
                [type]: [...prev.newPersons[type], data]
            }
        }));
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="md">
            {isLoading && (
                <>
                    <LinearProgress />
                    <Typography variant="h5" component="h2" gutterBottom>
                        {t('SelfSignupPage.tokenVerification')}
                    </Typography>
                </>
            )}

            {!isLoading && isTokenValid === false && (
                <Typography variant="h5" component="h2" gutterBottom>
                    {t('SelfSignupPage.expiredInvite')}
                </Typography>
            )}

            {!isLoading && isTokenValid && (
                <>
                    <Typography variant="h4" component="h1" gutterBottom>{t('SelfSignupPage.header')}</Typography> {/* Header */}
                    <Stepper activeStep={stage}
                        sx={{
                            '& .MuiStepper-root': {
                                '@media (max-width:600px)': {
                                    flexDirection: "column"
                                }
                            }
                        }}>
                        {steps.map((label, index) => (
                            <Step key={label} onClick={() => handleStepClick(index)}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box mt={3}>
                        {stage === 0 && <PersonalInfo onNext={handleNext} />}
                        {stage === 1 && <HorseOwnership onNext={handleNext} veterinarians={veterinarianData} ferries={ferryData} addNewPerson={addNewPerson} />}
                        {stage === 2 && <SetPassword onNext={handleNext} />}
                        {stage === 3 && <Summary data={userInfo} onSubmit={handleSubmit} />}
                    </Box>
                </>
            )}

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseSnackbar}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </Container>
    );
};

export default SelfSignupPage;
