// src/pages/InvoicesListPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import PaymentIcon from '@mui/icons-material/Payment';

import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';

// Replace with your new paginated function:
import { fetchInvoicePaginatedData } from '../services/v2/InvoiceService';
// e.g. a function calling: fetchPaginatedData("/api/v2/invoices", options, ...)

import { createPayment } from '../services/v2/PaymentsService';
import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';

// Use DataViewWrapper instead of manual table/card
import DataViewWrapper from '../components/DataViewWrapper';
import SnackbarAlert from '../components/SnackbarAlert';
import ConfirmationDialog from '../components/ConfirmationDialog';

// Custom pagination hook
import { usePaginatedData } from '../hooks/usePaginatedData';

const InvoicesListPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // Paginated data hook for invoices
  const {
    items: invoices,
    loading: invoicesLoading,
    continuationToken,
    loadFirstPage,
    loadNextPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchInvoicePaginatedData(apiKey, organizationId, token, options, userId),
    { pageSize: 25 }
  );

  // Local state for view mode
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode || (isMobile ? 'card' : 'table')
  );
  // For the payment confirmation dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  // Snackbar for success/error messages
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  // Update local `viewMode` whenever user preferences change
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const toggleViewMode = () => {
    const newMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newMode);
    updateDevicePreferences('viewMode', newMode);
  };

  // Payment logic: open confirmation dialog
  const handlePayment = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    setConfirmDialogOpen(true);
  };

  const handlePaymentConfirmation = async () => {
    try {
      const invoice = invoices.find((inv) => inv.id === selectedInvoiceId);
      if (!invoice) return;

      const paymentData = {
        amount: invoice.totalAmount,
        date: new Date().toISOString(),
        payerName: invoice.customerName,
        payerContact: invoice?.address?.email || '',
        payerReference: selectedInvoiceId,
        paymentMethod: 'cash', // Could be changed by user
        referenceId: selectedInvoiceId,
        referenceType: 'Invoice',
        notes: `Payment for Invoice ${selectedInvoiceId}`,
      };

      await createPayment(paymentData, apiKey, organizationId, token);

      setSnackbar({
        open: true,
        message: t('invoicesListPage.paymentSuccess'),
        severity: 'success',
      });
      loadFirstPage(); // Reload the first page to reflect changes

    } catch (error) {
      console.error('Error processing payment:', error);
      setSnackbar({
        open: true,
        message: t('invoicesListPage.errorProcessingPayment'),
        severity: 'error',
      });
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const navigateToDetail = (invoiceId) => {
    navigate(`/sales-process/invoices/detail/${invoiceId}`);
  };

  // For the PaymentStatus chip
  const renderPaymentStatus = (invoice) => {
    if (invoice.cancel === true) {
      return (
        <Chip
          icon={<WarningAmberIcon />}
          label={t('invoicesListPage.cancelled')}
          color="secondary"
        />
      );
    }

    if (invoice.isPaid) {
      return (
        <Chip
          icon={<CheckCircleIcon />}
          label={t('invoicesListPage.paid')}
          color="success"
        />
      );
    } else if (invoice.alreadyReminded) {
      return (
        <Chip
          icon={<WarningAmberIcon />}
          label={t('invoicesListPage.reminded')}
          color="warning"
        />
      );
    } else {
      return (
        <Chip
          icon={<CancelIcon />}
          label={t('invoicesListPage.unpaid')}
          color="error"
        />
      );
    }
  };

  // Transform data for DataViewWrapper
  const transformData = useMemo(() => ({
    headers: [
      {
        key: 'invoiceNumber',
        label: t('invoicesListPage.invoiceNumber'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'customerName',
        label: t('invoicesListPage.customerName'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'issueDate',
        label: t('invoicesListPage.issueDate'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'totalAmount',
        label: t('invoicesListPage.totalAmount'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'paymentStatus',
        label: t('invoicesListPage.paymentStatus'),
        display: { value: true, chipColor: false },
      },
    ],
    rows: invoices.map((invoice) => ({
      id: invoice.id,
      invoiceNumber: { value: invoice.invoiceNumber },
      customerName: { value: invoice.customerName },
      issueDate: {
        value: new Date(invoice.issueDate).toLocaleDateString(),
      },
      totalAmount: { value: invoice.totalAmount },
      paymentStatus: {
        value: renderPaymentStatus(invoice),
      },
      actions: [
        {
          icon: <PaymentIcon />,
          handler: invoice.isPaid ? null : () => handlePayment(invoice.id),
          disabled: invoice.isPaid || invoice.cancel || invoice.alreadyReminded,
          label: t('invoicesListPage.pay'),
        },
      ],
    })),
  }), [invoices]);

  const isLoading = invoicesLoading;

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      {isLoading ? (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {/* DataViewWrapper instead of GenericTableView / GenericCardView */}
          <DataViewWrapper
            structure={transformData}
            viewMode={viewMode}
            onViewModeToggle={toggleViewMode}
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            onRowClick={navigateToDetail}
            pageId="/invoices"
            toolbarActions={[
              <Button
                key="add-invoice"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                component={Link}
                to="/sales-process/invoices/detail/new"
              >
                {t('invoicesListPage.addNewInvoice')}
              </Button>,
            ]}
          />

          {/* Load More button if there's a continuationToken */}
          {continuationToken && (
            <Box mt={2} textAlign="center">
              <Button
                variant="contained"
                onClick={loadNextPage}
                disabled={isLoading}
              >
                {t('common.loadMore')}
              </Button>
            </Box>
          )}
        </>
      )}

      {/* Snackbar for success/error feedback */}
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      {/* Confirmation Dialog for Payment */}
      <ConfirmationDialog
        isOpen={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handlePaymentConfirmation}
        text={t('invoicesListPage.confirmPayment')}
      />
    </Paper>
  );
};

export default InvoicesListPage;
