// src/services/BoxService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';
const API_BASE_URL = '/api/v2/boxes';

// Fetch all boxes
export async function fetchAllBoxTypes(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

// Fetch a single box by ID
export async function fetchBoxById(apiKey, organizationId, jwtToken, id) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?id=${id}`);
  return response.data;
}

// Save a new box
export async function saveBox(box, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, box);
  return response.data;
}

// Update an existing box
export async function updateBox(box, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${box.id}`;
  const response = await axiosInstance.put(url, box);
  return response.data;
}

// Delete a box
export async function deleteBox(boxId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${boxId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function fetchBoxesPaginated(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await fetchPaginatedData(API_BASE_URL, options, {
    apiKey,
    organizationId,
    jwtToken,
  });
  return response;
}

export default {
  fetchAllBoxTypes,
  fetchBoxById,
  saveBox,
  updateBox,
  deleteBox,
  fetchBoxesPaginated,
};
