// src/pages/RouteEditPage.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    IconButton,
    LinearProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { v4 as uuidv4 } from 'uuid';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import SortableCustomerItem from './SortableCustomerItem';
import { useAuth } from '../../context/AuthContext';
import { fetchBusinessPartners } from '../../services/v2/CustomerService';
import { fetchRouteById, saveRoute, updateRoute } from '../../services/v2/RouteService';
import { fetchVehicles } from '../../services/v2/VehicleService';
import { fetchDrivers } from '../../services/v2/DriverService';
import AddIcon from '@mui/icons-material/Add';
import SnackbarAlert from '../../components/SnackbarAlert';

import { useTranslation } from 'react-i18next';

const RouteEditPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isEditMode = id !== 'new';
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const { t } = useTranslation();

    const [route, setRoute] = useState({
        id: '',
        name: '',
        driverId: '',
        vehicleId: '',
        customers: [],
    });
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [assignedCustomerIds, setAssignedCustomerIds] = useState([]);
    const [activeId, setActiveId] = useState(null);

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5, // Activate after moving 5 pixels
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const [customersData, vehiclesData, driversData] = await Promise.all([
                    fetchBusinessPartners(apiKey, organizationId, jwtToken),
                    fetchVehicles(apiKey, organizationId, jwtToken),
                    fetchDrivers(apiKey, organizationId, jwtToken),
                ]);

                setCustomers(customersData);
                setVehicles(vehiclesData);
                setDrivers(driversData);

                if (isEditMode) {
                    const existingRoute = await fetchRouteById(apiKey, organizationId, jwtToken, id);
                    setRoute(existingRoute);
                    setAssignedCustomerIds(existingRoute.customers.map(customer => customer.id));

                    // Only update the route name if it's empty
                    if (existingRoute.name.trim() === '') {
                        updateRouteName(existingRoute.driverId, existingRoute.vehicleId);
                    }
                } else {
                    const newRoute = { ...route, id: uuidv4() };
                    setRoute(newRoute);
                    // Optionally, set default name for new routes if desired
                    if (newRoute.name.trim() === '') {
                        updateRouteName(newRoute.driverId, newRoute.vehicleId);
                    }
                }

            } catch (error) {
                console.error('Error loading data:', error);
                setSnackbar({ open: true, message: t('RouteEditPage.errorLoadingData', { error: error.message }), severity: 'error' });
            } finally {
                setLoading(false);
            }
        };

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode, id, apiKey, organizationId, jwtToken, t]);

    const handleNameChange = (e) => {
        setRoute({ ...route, name: e.target.value });
    };

    const handleDriverChange = (event) => {
        const driverId = event.target.value;
        setRoute((prevRoute) => ({ ...prevRoute, driverId: driverId }));
        updateRouteName(driverId, route.vehicleId);
    };

    const handleVehicleChange = (event) => {
        const vehicleId = event.target.value;
        setRoute((prevRoute) => ({ ...prevRoute, vehicleId: vehicleId }));
        updateRouteName(route.driverId, vehicleId);
    };

    const updateRouteName = (driverId, vehicleId) => {
        // Only update the name if it's currently empty
        if (route.name.trim() === '') {
            const driver = drivers.find(d => d.id === driverId);
            const vehicle = vehicles.find(v => v.id === vehicleId);

            const driverName = driver ? driver.name : '';
            const vehicleName = vehicle ? vehicle.name : '';

            let newName = '';

            if (vehicleName && driverName) {
                newName = `${vehicleName} - ${driverName}`;
            } else if (vehicleName) {
                newName = vehicleName;
            } else if (driverName) {
                newName = driverName;
            } else {
                newName = t('RouteEditPage.defaultRouteName'); // Fallback name
            }

            setRoute(prevRoute => ({ ...prevRoute, name: newName }));
        }
    };

    const handleSave = async () => {
        const updatedRoute = {
            ...route,
            customers: assignedCustomerIds.map((customerId, index) => ({
                id: customerId,
                position: index,
            })),
        };

        try {
            if (isEditMode) {
                await updateRoute(updatedRoute, apiKey, organizationId, jwtToken);
                setSnackbar({ open: true, message: t('RouteEditPage.routeUpdated'), severity: 'success' });
            } else {
                await saveRoute(updatedRoute, apiKey, organizationId, jwtToken);
                setSnackbar({ open: true, message: t('RouteEditPage.routeCreated'), severity: 'success' });
            }
            navigate('/deliveryPlanning?tab=1');
        } catch (error) {
            console.error('Error saving route:', error);
            setSnackbar({ open: true, message: t('RouteEditPage.errorSavingRoute', { error: error.message }), severity: 'error' });
        }
    };

    const handleDragStart = (event) => {
        setActiveId(event.active.id);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) {
            setActiveId(null);
            return;
        }

        if (active.id !== over.id) {
            setAssignedCustomerIds((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }

        setActiveId(null);
    };

    const handleAddCustomer = (customerId) => {
        if (!assignedCustomerIds.includes(customerId)) {
            setAssignedCustomerIds([...assignedCustomerIds, customerId]);
        }
    };

    const handleRemoveCustomer = (customerId) => {
        setAssignedCustomerIds((items) => items.filter(id => id !== customerId));
    };

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                minHeight="100vh"
            >
                <Typography variant="h6">{t('RouteEditPage.loadingData')}</Typography>
                <LinearProgress sx={{ width: '100%', mt: 2 }} />
            </Box>
        );
    }

    const unassignedCustomers = customers.filter(
        (customer) => !assignedCustomerIds.includes(customer.id)
    );

    const assignedCustomers = assignedCustomerIds
        .map(id => customers.find(c => c.id === id))
        .filter(c => c !== undefined);

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                <IconButton onClick={() => navigate(-1)} aria-label={t('RouteEditPage.backButtonAriaLabel')}>
                    <ArrowBackIcon />
                </IconButton>
                {isEditMode ? t('RouteEditPage.editRoute') : t('RouteEditPage.createRoute')}
            </Typography>
            <Box sx={{ mt: 4 }}>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TextField
                        label={t('RouteEditPage.routeName')}
                        value={route.name}
                        onChange={handleNameChange}
                        sx={{ flex: 2 }}
                        placeholder={t('RouteEditPage.routeNamePlaceholder')}
                    />

                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel>{t('RouteEditPage.driver')}</InputLabel>
                        <Select value={route.driverId} label={t('RouteEditPage.driver')} onChange={handleDriverChange}>
                            <MenuItem value="">
                                <em>{t('RouteEditPage.none')}</em>
                            </MenuItem>
                            {drivers.map((driver) => (
                                <MenuItem key={driver.id} value={driver.id}>
                                    {driver.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel>{t('RouteEditPage.vehicle')}</InputLabel>
                        <Select value={route.vehicleId} label={t('RouteEditPage.vehicle')} onChange={handleVehicleChange}>
                            <MenuItem value="">
                                <em>{t('RouteEditPage.none')}</em>
                            </MenuItem>
                            {vehicles.map((vehicle) => (
                                <MenuItem key={vehicle.id} value={vehicle.id}>
                                    {vehicle.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', gap: 4 }}>
                    {/* Unassigned Customers List */}
                    <Box
                        sx={{
                            flex: 1,
                            maxWidth: '300px',
                            overflowY: 'auto',
                            maxHeight: '400px',
                        }}
                    >
                        <Typography variant="h5">{t('RouteEditPage.unassignedCustomers')}</Typography>
                        <Box
                            sx={{
                                mt: 1,
                                minHeight: '50px',
                                padding: '8px',
                                border: '2px dashed #ccc',
                                borderRadius: '4px',
                            }}
                        >
                            {unassignedCustomers.length === 0 ? (
                                <Box
                                    sx={{
                                        p: 2,
                                        textAlign: 'center',
                                        color: '#aaa',
                                    }}
                                >
                                    {t('RouteEditPage.allCustomersAssigned')}
                                </Box>
                            ) : (
                                unassignedCustomers.map((customer) => (
                                    <Box
                                        key={customer.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#f9f9f9',
                                            padding: '8px',
                                            marginBottom: '8px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        <Box flexGrow={1}>
                                            <Typography variant="body1">{customer.firstname} {customer.surname}</Typography>
                                            <Typography variant="body2">{customer.address?.street}, {customer.address?.city}</Typography>
                                        </Box>
                                        <IconButton
                                            onClick={() => handleAddCustomer(customer.id)}
                                            aria-label={t('RouteEditPage.addCustomerAriaLabel')}
                                            color="primary"
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                ))
                            )}
                        </Box>
                    </Box>

                    {/* Assigned Customers List */}
                    <Box
                        sx={{
                            flex: 2,
                            maxHeight: '400px',
                            overflowY: 'auto',
                        }}
                    >
                        <Paper
                            sx={{
                                p: 2,
                                mt: 2,
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                            }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">{route.name || t('RouteEditPage.route')}</Typography>
                                {/* Optional Reset Name Button */}
                               
                            </Box>

                            {/* Customers Sortable List */}
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle1">{t('RouteEditPage.assignedCustomers')}</Typography>

                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}
                                >
                                    <SortableContext
                                        items={assignedCustomerIds}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        <Box
                                            sx={{
                                                mt: 1,
                                                minHeight: '50px',
                                                padding: '8px',
                                                border: '2px dashed #ccc',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            {assignedCustomers.length === 0 ? (
                                                <Box
                                                    sx={{
                                                        p: 2,
                                                        textAlign: 'center',
                                                        color: '#aaa',
                                                    }}
                                                >
                                                    {t('RouteEditPage.noCustomersAssigned')}
                                                </Box>
                                            ) : (
                                                assignedCustomers.map((customer) => (
                                                    <SortableCustomerItem
                                                        key={customer.id}
                                                        id={customer.id}
                                                        customer={customer}
                                                        onRemove={() => handleRemoveCustomer(customer.id)}
                                                    />
                                                ))
                                            )}
                                        </Box>
                                    </SortableContext>

                                    <DragOverlay>
                                        {activeId ? (
                                            <SortableCustomerItem
                                                id={activeId}
                                                customer={customers.find((customer) => customer.id === activeId)}
                                                dragOverlay
                                            />
                                        ) : null}
                                    </DragOverlay>
                                </DndContext>
                            </Box>
                        </Paper>
                    </Box>
                </Box>

                <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
                    {isEditMode ? t('RouteEditPage.updateRoute') : t('RouteEditPage.createRoute')}
                </Button>

                <SnackbarAlert
                    open={snackbar.open}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    message={snackbar.message}
                    severity={snackbar.severity}
                />
            </Box>
        </Paper>
    );
};

export default RouteEditPage;
