import React, { useState, useEffect } from 'react';
import {
  Paper, Button, Box, LinearProgress, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fetchPaginatedEmployees } from '../../services/v2/EmployeeService';
import GenericTableView from '../../components/GenericTableView';
import SnackbarAlert from '../../components/SnackbarAlert';
import { useAuth } from '../../context/AuthContext';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';



const WorkingTimesPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const [workingTimes, setWorkingTimes] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employee = await fetchPaginatedEmployees(apiKey, organizationId, token, { pageSize: 100 });
        setEmployeeData(employee.items);
      } catch (error) {
        console.error('Error fetching working times:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiKey, organizationId, token, userId]);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const transformData = () => ({
    headers: [
      { key: 'employeeName', label: t('workingTimes.employeeName'), display: { value: true } },
    ],
    rows: employeeData.map((wt) => ({
      id: wt.id,
      employeeName: { value: wt.firstName + ' ' + wt.lastName },
      actions: [
        {
          icon: <EditIcon />,
          handler: () => navigate(`/working-times/detail/${wt.id}`),
          label: t('common.edit'),
        },
      ],
    })),
  });

  const handleClickedRow = (row) => {
    navigate(`/working-times/detail/${row}`);
  }


  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h4">{t('workingTimes.title')}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/working-times/detail/new')}
        >
          {t('workingTimes.addWorkingTime')}
        </Button>
      </Box>
      {loading ? (
        <LinearProgress />
      ) : (
        <GenericTableView structure={transformData()} onClick={handleClickedRow} />
      )}
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default WorkingTimesPage;
