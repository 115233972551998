// src/pages/HorseDetailPage.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getHorseById, printHorseProfile } from '../services/v2/horseService';
import { getOwnerById } from '../services/v2/ownerService';
import { fetchPaginatedServiceTypes } from '../services/v2/serviceTypesService';  // Your service
import { fetchVeterinarianById } from '../services/v2/VeterinarianService';
import { fetchFerryById } from '../services/v2/FerryService';
import { fetchBoxById } from '../services/v2/BoxService';
import { fetchPaginatedFoodSettings } from '../services/v2/FoodSettingsService';
import { Box, Button, Typography, Card, CardContent, CardActions, LinearProgress, Grid, Paper } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import GroupIcon from '@mui/icons-material/Group';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GenericDetailView from '../components/GenericDetailView';
import SnackbarAlert from '../components/SnackbarAlert';


const HorseDetailPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { apiKey, organizationId, token, userType } = useAuth();
    const { t, i18n } = useTranslation();
    const [horse, setHorse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userStructure = {
        sections: [
            {
                "title": `${horse?.name}`,
                "fields": [
                    { "label": `${t('HorseDetailPage.breed')}`, "key": "breed" },
                    { "label": `${t('HorseDetailPage.birthday')}`, "key": "birthday" },
                    { "label": `${t('HorseDetailPage.boxType')}`, "key": "boxType" },
                    { "label": `${t('HorseDetailPage.services')}`, "key": "services" }
                ]
            },
            {
                "title": `${t('HorseDetailPage.contactsTitle')} `,
                "fields": [
                    { "label": `${t('HorseDetailPage.owner')}`, "key": "owner" },
                    { "label": `${t('HorseDetailPage.ownerNumber')}`, "key": "ownerNumber" },
                    { "label": `${t('HorseDetailPage.coRiders')}`, "key": "coRiders" },
                ]
            },
            {
                "title": `${t('HorseDetailPage.emergencyContact')}`,
                "fields": [
                    { "label": `${t('HorseDetailPage.emergencyContact')}`, "key": "emergencyContact" },
                    { "label": `${t('HorseDetailPage.emergencyContactNumber')}`, "key": "emergencyContactNumber" },
                    { "label": `${t('HorseDetailPage.veterinarian')}`, "key": "veterinarian" },
                    { "label": `${t('HorseDetailPage.veterinarianNumber')}`, "key": "veterinarianNumber" },
                    { "label": `${t('HorseDetailPage.farrier')}`, "key": "farrier" },
                    { "label": `${t('HorseDetailPage.farrierNumber')}`, "key": "farrierNumber" }
                ]
            },
            {
                "title": `${t('HorseDetailPage.foodPlanTitle')}`,
                "components": [
                    {
                        "type": "responsive",
                        "rowArrayKey": "mealTimes",
                        "headers": [
                            { "label": ``, "key": "meal", "display": { "value": true, "chipColor": false } },
                            { "label": `${t(`foodSettings.mealTimes.morning`)}`, "key": "morning", "display": { "value": true, "chipColor": false } },
                            { "label": `${t('foodSettings.mealTimes.midday')}`, "key": "midday", "display": { "value": true, "chipColor": false } },
                            { "label": `${t('foodSettings.mealTimes.evening')}`, "key": "evening", "display": { "value": true, "chipColor": false } },
                        ],
                        onClick: () => { },
                        actions: []
                    },
                ]
            }
        ]
    };
    const [formData, setFormData] = useState({});
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info',
    });

    /**
  * Trigger the PDF generation and open the PDF in a new tab
  */
    const handlePrintHorseProfile = async () => {
        try {
            const userLang = i18n.language;
            const pdfBlob = await printHorseProfile(horse.id, organizationId, apiKey, token, userLang);
            const blobUrl = URL.createObjectURL(pdfBlob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${horse.name}_${t('HorseDetailPage.boxLabel')}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);

            setSnackbar({
                open: true,
                message: t('HorseDetailPage.printProfileSuccess'),
                severity: 'success',
            });
        } catch (error) {
            console.error('Error printing horse profile:', error);
            setSnackbar({
                open: true,
                message: t('HorseDetailPage.printProfileError'),
                severity: 'error',
            });
        }
    };


    useEffect(() => {
        const fetchHorseDetails = async () => {
            setIsLoading(true);
            const fetchedHorse = await getHorseById(id, apiKey, organizationId, token);
            if (fetchedHorse.ownerId) {
                fetchedHorse.owner = await getOwnerById(fetchedHorse.ownerId, apiKey, organizationId, token);
            }
            if (fetchedHorse.veterinarianId) {
                fetchedHorse.veterinarian = await fetchVeterinarianById(apiKey, organizationId, token, fetchedHorse.veterinarianId);
            }
            if (fetchedHorse.farrierId) {
                fetchedHorse.farrier = await fetchFerryById(apiKey, organizationId, token, fetchedHorse.farrierId);
            }
            if (fetchedHorse.boxTypeId) {
                fetchedHorse.boxType = await fetchBoxById(apiKey, organizationId, token, fetchedHorse.boxTypeId);
            }
            if (fetchedHorse.foodPortions) {
                await fetchPaginatedFoodSettings(apiKey, organizationId, token, { pageSize: 1000 }).then((foodSettings) => {
                    const foodPortions = {};
                    Object.entries(fetchedHorse.foodPortions).forEach(([foodId, portions]) => {

                        const food = foodSettings.items.find(food => food.id === foodId);
                        if (food) {
                            // If found, add the food item's name and portions to the foodPortions object
                            const translatedPortions = ['morning', 'midday', 'evening'].reduce((acc, key) => {
                                acc[key] = portions[key] ? foodSettings.items.find(f => f.id === foodId).portions.find(p => p.id === portions[key]).name : null;
                                return acc;
                            }, {});
                            foodPortions[food.printName || food.name] = translatedPortions;
                        } else {
                            // Optionally handle the case where the food item is not found
                            // For example, you might want to log a warning or take some other action
                            console.warn(`Food item with ID ${foodId} not found in food settings.`);
                        }
                    });
                    fetchedHorse.foodPortions = foodPortions;
                });
            };
            if (fetchedHorse.services) {
                const servicesResponse = await fetchPaginatedServiceTypes(apiKey, organizationId, token, { pageSize: 1000 });
                const services = servicesResponse.items;
                fetchedHorse.services = fetchedHorse.services.map(service => services.find(s => s.id === service.serviceId));
            }
            setHorse(fetchedHorse);
            setFormData({
                breed: fetchedHorse.breed,
                birthday: new Date(fetchedHorse.birthday).toLocaleDateString(),
                boxType: fetchedHorse.boxType?.name,
                services: fetchedHorse.services?.map(service => service.name).join(', '),
                owner: `${fetchedHorse.owner?.firstname} ${fetchedHorse.owner?.surname}`,
                ownerNumber: fetchedHorse.owner?.number,
                coRiders: fetchedHorse.coRiders?.map(rider => `${rider.firstname} ${rider.surname}`).join(', '),
                emergencyContact: fetchedHorse.emergencyContact?.name,
                emergencyContactNumber: fetchedHorse.emergencyContact?.number,
                veterinarian: `${fetchedHorse.veterinarian?.firstname} ${fetchedHorse.veterinarian?.surname}`,
                veterinarianNumber: fetchedHorse.veterinarian?.phoneNumber,
                farrier: `${fetchedHorse.farrier?.firstname} ${fetchedHorse.farrier?.surname}`,
                farrierNumber: fetchedHorse.farrier?.phoneNumber,
                mealTimes: Object.entries(fetchedHorse.foodPortions || {}).map(([foodId, portions, index]) => {
                    const food = Object.keys(portions)[0];
                    return {
                        id: index,
                        meal: { value: foodId, icon: null },
                        morning: { value: portions.morning?.toString() || '', icon: null },
                        midday: { value: portions.midday?.toString() || '', icon: null },
                        evening: { value: portions.evening?.toString() || '', icon: null }
                    };
                })
            });
            setIsLoading(false);
        };
        fetchHorseDetails();
    }, [apiKey]);



    return (
        <Box sx={{ p: 2 }}>
            <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                <Typography variant="h4" gutterBottom>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                    {t('HorseDetailPage.title')}
                </Typography>

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mt: 4, mb: 2 }}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => navigate(`/horses/edit/${id}`)}
                        disabled={horse?.permissions?.indexOf('handleHorseProfile') === -1}
                    >
                        {t('HorseDetailPage.editButton')}
                    </Button>

                    {/* ADD A BUTTON TO PRINT THE HORSE PDF */}
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlePrintHorseProfile}
                    >
                        {t('HorseDetailPage.printProfileButton')}
                    </Button>
                </Box>

                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <GenericDetailView structure={userStructure} data={formData} />
                )}
            </Paper>
            <SnackbarAlert
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
};

export default HorseDetailPage;
