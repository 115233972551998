import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  Typography,
  Grid,
  Box,
  SpeedDial,
  SpeedDialAction,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SchoolIcon from '@mui/icons-material/School';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Your custom components
import CalendarView from '../components/CalendarView';
import SnackbarAlert from '../components/SnackbarAlert';
import CalendarEventInfoModal from '../components/CalendarEventInfoModal';
import HorseActivityVoteModal from '../components/HorseActivityVoteModal';
import RidingLessonBookingModal from '../components/RidingLessonBookingModal';

// Services + Auth
import { useAuth } from '../context/AuthContext';
import { getCalendarEvents } from '../services/v2/buildingCalenderViewService';
import { fetchLocationSettings } from '../services/v2/LocationSettingsService';

// Force Monday as the first day of the week (optional)
moment.updateLocale('en', {
  week: {
    dow: 1, // Monday
  },
});
const localizer = momentLocalizer(moment);

const BuildingCalenderView = () => {
  const { t } = useTranslation();
  const { token, apiKey, organizationId, userType } = useAuth();
  const navigate = useNavigate();

  // --- State for calendar ---
  const [events, setEvents] = useState([]);
  const getInitialView = () => {
    if (window.innerWidth < 768 || window.innerHeight > window.innerWidth) {
      return 'day'; // phones or portrait
    }
    return 'week'; // tablets/desktops
  };

  const [currentView, setCurrentView] = useState(getInitialView);
  const [currentDate, setCurrentDate] = useState(new Date());

  // --- State for location items + color mapping for riding arenas ---
  const [locations, setLocations] = useState([]);
  const [arenaColorMap, setArenaColorMap] = useState({}); // buildingId -> color

  // --- State for modals and notifications ---
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Horse activity vote modal
  const [isActivityModalOpen, setActivityModalOpen] = useState(false);
  const handleOpenActivityModal = () => setActivityModalOpen(true);
  const handleCloseActivityModal = () => {
    setActivityModalOpen(false);
    loadEvents(currentDate, currentView);
  };

  // Riding lesson modal
  const [isRidingLessonModalOpen, setRidingLessonModalOpen] = useState(false);
  const handleOpenRidingLessonModal = () => setRidingLessonModalOpen(true);
  const handleCloseRidingLessonModal = () => {
    setRidingLessonModalOpen(false);
    loadEvents(currentDate, currentView);
  };


  // 24h formats for react-big-calendar
  const formats = {
    timeGutterFormat: 'HH:mm',
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'HH:mm', culture) + ' – ' + localizer.format(end, 'HH:mm', culture),
    agendaTimeFormat: 'HH:mm',
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'MMM DD', culture) + ' – ' + localizer.format(end, 'MMM DD', culture),
    dayHeaderFormat: 'dddd, MMMM DD, YYYY',
  };

  // ------------------------------------------------------------------------
  // 1) Fetch events whenever date or view changes
  // ------------------------------------------------------------------------
  useEffect(() => {
    loadEvents(currentDate, currentView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, currentView]);

  const loadEvents = async (date, view) => {
    try {
      // Determine date range for the current view
      let startDate, endDate;
      if (view === 'month') {
        startDate = moment(date).startOf('month').toISOString();
        endDate = moment(date).endOf('month').toISOString();
      } else if (view === 'week') {
        startDate = moment(date).startOf('week').toISOString();
        endDate = moment(date).endOf('week').toISOString();
      } else {
        // fallback for day or other
        startDate = moment(date).startOf('day').toISOString();
        endDate = moment(date).endOf('day').toISOString();
      }

      // Make your service call to fetch combined events
      const combinedEvents = await getCalendarEvents(apiKey, organizationId, token, {
        startDate,
        endDate,
      });

      // Convert string dates to JS Date objects
      const mapped = combinedEvents.map(evt => ({
        ...evt,
        start: new Date(evt.start),
        end: new Date(evt.end),
        title: evt.type === 'activity' ? t(`HorseActivityModal.${evt.title}`) : evt.title,
      }));

      setEvents(mapped);
    } catch (error) {
      console.error('Error loading combined events:', error);
      setSnackbar({
        open: true,
        message: t('common.errorLoadingData'),
        severity: 'error',
      });
    }
  };

  // ------------------------------------------------------------------------
  // 2) Fetch location settings and create a color map for riding arenas
  // ------------------------------------------------------------------------
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        // This returns data in the shape: { items: [ { id, name, buildings: [...] }, ... ] }
        const data = await fetchLocationSettings(apiKey, organizationId, token);
        const locationItems = data.items || [];
        setLocations(locationItems);

        // A color palette for arenas
        const colorPalette = [
          '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
          '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
          '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
          '#ff5722', '#795548', '#9e9e9e', '#607d8b'
        ];
        let colorIndex = 0;

        // Build an arena -> color map
        const tempArenaColorMap = {};

        locationItems.forEach((loc) => {
          // Filter for riding arenas only
          const arenas = loc.buildings.filter(
            (bld) => bld.type.riding_arena === true || bld.type.ridingArena === true
          );

          arenas.forEach((arena) => {
            tempArenaColorMap[arena.id] = colorPalette[colorIndex % colorPalette.length];
            colorIndex++;
          });
        });

        setArenaColorMap(tempArenaColorMap);
      } catch (error) {
        console.error('Failed to fetch locations:', error);
      }
    };

    fetchLocations();
  }, [apiKey, organizationId, token]);

  // ------------------------------------------------------------------------
  // 3) Handle calendar interactions
  // ------------------------------------------------------------------------
  const handleSelectEvent = (event) => {
    setIsEditing(true);
    setEventToEdit(event);
    setModalOpen(true);
  };

  const handleSelectSlot = (slotInfo) => {
    console.log('Selected slot:', slotInfo);
    // For creating a new event if needed
  };

  // ------------------------------------------------------------------------
  // 4) eventStyleGetter: color only events in riding arenas
  // ------------------------------------------------------------------------
  const eventStyleGetter = (event) => {
    // If the event.buildingId is one of our arenas, color it
    const buildingId = event.buildingId;
    const backgroundColor = arenaColorMap[buildingId] || '#757575';
    return {
      style: {
        backgroundColor,
        color: '#fff',
        borderRadius: '4px',
        border: 'none',
        padding: '2px 4px',
      },
    };
  };

  // ------------------------------------------------------------------------
  // 5) Render UI + Legend (riding arenas only)
  // ------------------------------------------------------------------------
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('calendar.title')}
      </Typography>

      {/* ---------- Legend: show only riding arenas ---------- */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          {t('calendar.legend')} – {t('calendar.ridingArenas')}
        </Typography>
        <Grid container spacing={2}>
          {locations.map((loc) => {
            // filter for arenas
            const arenas = loc.buildings.filter(
              (bld) => bld.type.riding_arena === true || bld.type.ridingArena === true
            );
            return arenas.map((arena) => (
              <Grid item key={arena.id}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      width: 18,
                      height: 18,
                      backgroundColor: arenaColorMap[arena.id],
                      borderRadius: '4px',
                      mr: 1,
                    }}
                  />
                  <Typography variant="body1">{arena.name}</Typography>
                </Box>
              </Grid>
            ));
          })}
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CalendarView
            localizer={localizer}
            events={events}
            selectable
            views={['day', 'week', 'month']}
            view={currentView}
            onView={setCurrentView}
            onNavigate={(date) => setCurrentDate(date)}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            formats={formats}
            eventPropGetter={eventStyleGetter}
          />
        </Grid>
      </Grid>

      {/* ---------- Snackbars + Modals ---------- */}
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
      <CalendarEventInfoModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        eventData={eventToEdit}
      />
      <HorseActivityVoteModal
        open={isActivityModalOpen}
        onClose={handleCloseActivityModal}
      />
      {userType === 'admin' && (
        <RidingLessonBookingModal
          open={isRidingLessonModalOpen}
          onClose={handleCloseRidingLessonModal}
          onSave={() => loadEvents(currentDate, currentView)}
        />
      )}

      {/* ---------- Speed Dial with actions ---------- */}
      <SpeedDial
        ariaLabel="FAB Actions"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<AddIcon />}
      >
        <SpeedDialAction
          icon={<DirectionsRunIcon />}
          tooltipTitle={t('HorseActivityModal.title')}
          onClick={handleOpenActivityModal}
        />
        {userType === 'admin' && (
          <SpeedDialAction
            icon={<SchoolIcon />}
            tooltipTitle={t('RidingLessonBookingModal.addLesson')}
            onClick={handleOpenRidingLessonModal}
          />
        )}
      </SpeedDial>
    </Paper>
  );
};

export default BuildingCalenderView;
