import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Box,
  LinearProgress,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { usePreferences } from '../../context/UserPreferencesContext';
import { useTheme, useMediaQuery } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Visibility as EyeIcon } from '@mui/icons-material';

import { usePaginatedData } from '../../hooks/usePaginatedData';
import { fetchPaginatedEmployees } from '../../services/v2/EmployeeService';
import DataViewWrapper from '../../components/DataViewWrapper';
import SnackbarAlert from '../../components/SnackbarAlert';

const EmployeeManagementPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { devicePreferences, updateDevicePreferences } = usePreferences();
  const { apiKey, organizationId, token, userId } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  // ------------------ PAGINATED DATA HOOK ------------------
  const {
    items: employees,
    continuationToken,
    loading,
    loadNextPage,
    loadFirstPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchPaginatedEmployees(apiKey, organizationId, token, options),
    { pageSize: 25 }
  );

  // ------------------ VIEW MODE MANAGEMENT ------------------
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode || (isMobile ? 'card' : 'table')
  );

  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const toggleViewMode = () => {
    const newView = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newView);
    updateDevicePreferences('viewMode', newView);
  };

  // ------------------ HANDLERS ------------------
  const handleRowClick = (employeeId) => {
    navigate(`/employees/detail/${employeeId}`);
  };

  const handleDelete = (employeeId) => {
    console.log('Delete employee:', employeeId);
    // Implement delete logic here
    // After deletion, refresh the list:
    // loadFirstPage();
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // ------------------ DATA TRANSFORMATION ------------------
  const transformData = () => ({
    headers: [
      { key: 'name', label: t('employeeManagement.name'), display: { value: true } },
      { key: 'email', label: t('employeeManagement.email'), display: { value: true } },
      { key: 'role', label: t('employeeManagement.role'), display: { value: true } },
    ],
    rows: employees.map((employee) => ({
      id: employee.id,
      name: { value: `${employee.firstName} ${employee.lastName}` },
      email: { value: employee.email },
      role: { value: employee.role },
      actions: [
        {
          icon: <EyeIcon />,
          handler: () => navigate(`/employees/view/${employee.id}`),
        },
        {
          icon: <EditIcon />,
          handler: () => navigate(`/employees/detail/${employee.id}`),
          label: t('common.edit'),
        },
        {
          icon: <DeleteIcon />,
          handler: () => handleDelete(employee.id),
          label: t('common.delete'),
        },
      ],
    })),
  });

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('employeeManagement.title')}
      </Typography>

      {loading && <LinearProgress />}

      <DataViewWrapper
        structure={transformData()}
        viewMode={viewMode}
        onViewModeToggle={toggleViewMode}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
        onRowClick={handleRowClick}
        pageId="/employees"
        toolbarActions={[
          <Button
            key="add-employee"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/employees/detail/new')}
          >
            {t('employeeManagement.addEmployee')}
          </Button>
        ]}
      />

      {continuationToken && (
        <Box mt={2} textAlign="center">
          <Button
            variant="contained"
            onClick={loadNextPage}
            disabled={loading}
          >
            {t('common.loadMore')}
          </Button>
        </Box>
      )}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default EmployeeManagementPage;