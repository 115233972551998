import axiosInstance, { setOrganizationIdHeader, setAuthHeader, setWithCredentials } from '../apiConfig/axiosConfig.js';

export async function signUpUser(userdata) {
    try {
        setOrganizationIdHeader(userdata.organizationId);
        setWithCredentials(false);
        const response = await axiosInstance.post('/api/v2/signup', {
            userdata
        });
        return response.data;
    } catch (error) {
        console.error('Error during signup:', error);
        return null;
    }
}

export async function resetPassword(email, newPassword, token) {
    try {
        setWithCredentials(false);
        const response = await axiosInstance.post('/api/v2/reset-password', {
            email, token, password: newPassword
        });
        return response.data;
    } catch (error) {
        console.error('Error during password reset:', error);
        return null;
    }
}

export async function selfSignUp(data, organizationId, token, jwtToken) {
    try {
        setOrganizationIdHeader(organizationId);
        setWithCredentials(true);
        setAuthHeader(jwtToken);
        const response = await axiosInstance.post(`/api/v2/self-signup?token=${token}`, data);
        return response.data;
    } catch (error) {
        console.error('Error during self-signup:', error);
        return null;
    }
}

export async function fetchApiKey(jwtToken) {
    try {
        setAuthHeader(jwtToken);
        const response = await axiosInstance.post('/api/v2/hidden/getapikey');
        return response.data.defaultHostKey;  // Assuming the API key is returned under the "apiKey" field
    } catch (error) {
        console.error('Error during API key fetch:', error);
        return null;
    }
}

export async function loginWithEmailPassword(email, homeAccountId, jwtToken) {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    setAuthHeader(jwtToken);
    try {
        const response = await axiosInstance.post(`/api/v2/login`, {
            email,
            homeAccountId,
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error; // Rethrow the error for the caller to handle
    }
}
export async function loginWithAzure(email, homeAccountId, jwtToken) {
    try {
        setAuthHeader(jwtToken);
        const response = await axiosInstance.post('/api/v2/login', {
            authType: 'azure_login',
            email,
            homeAccountId,
        });
        return response.data;
    } catch (error) {
        console.error('Error during Azure login:', error);
        throw error; // Rethrow the error for the caller to handle
    }
}
export async function logoutUser() {
    try {
        // Assuming that the logout endpoint doesn't require any body or parameters
        const response = await axiosInstance.post('/api/v2/logout');
        return response.data;
    } catch (error) {
        console.error('Error during logout:', error);
        return null;
    }
}


export default {
    signUpUser,
    resetPassword,
    selfSignUp,
    fetchApiKey,
    loginWithEmailPassword,
    logoutUser
};
