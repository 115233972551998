import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    LinearProgress,
    IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import DeleteIcon from '@mui/icons-material/Delete';

import { useAuth } from '../../context/AuthContext';
import { usePreferences } from '../../context/UserPreferencesContext';
import DataViewWrapper from '../../components/DataViewWrapper';
import MachineBookingModal from '../../components/MachineBookingModal';
import SnackbarAlert from '../../components/SnackbarAlert';
import { usePaginatedData } from '../../hooks/usePaginatedData';
import {
    fetchPaginatedBookings,
    deleteBooking,
} from '../../services/v2/guidingMachineService';
import { fetchOwnersPaginated } from '../../services/v2/ownerService';
import { fetchHorsesPaginated } from '../../services/v2/horseService';
import { fetchPaginatedMachineSettings } from '../../services/v2/MachineSettingsService';

const TodaysBookings = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { apiKey, userId, userType, ownerId, organizationId, token: jwtToken } = useAuth();
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    // ------------- PAGINATED DATA HOOKS -------------
    const {
        items: bookings,
        loading: bookingsLoading,
        continuationToken,
        loadNextPage,
        filter,
        setFilter,
        sort,
        setSort,
        loadFirstPage,
    } = usePaginatedData(
        (options) => fetchPaginatedBookings(apiKey, organizationId, jwtToken, {
            ...options,
            filter: { ...options.filter, date: new Date().toISOString() }
        }),
        { pageSize: 50 }
    );

    // ------------- AUXILIARY DATA STATES -------------
    const [horseData, setHorseData] = useState([]);
    const [ownerData, setOwnerData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [auxLoading, setAuxLoading] = useState(false);

    // ------------- MODAL & FEEDBACK STATES -------------
    const [modalOpen, setModalOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

    // ------------- DATA FETCHING -------------
    useEffect(() => {
        let isCancelled = false;
        const fetchAuxData = async () => {
            setAuxLoading(true);
            try {
                // Fetch owners
                let allOwners = [];
                let nextToken = null;
                do {
                    const resp = await fetchOwnersPaginated(apiKey, organizationId, jwtToken, {
                        pageSize: 1000,
                        continuationToken: nextToken,
                    });
                    allOwners = [...allOwners, ...(resp.items || [])];
                    nextToken = resp.continuationToken;
                } while (nextToken && !isCancelled);

                // Fetch horses
                let allHorses = [];
                nextToken = null;
                do {
                    const resp = await fetchHorsesPaginated(apiKey, organizationId, jwtToken, {
                        pageSize: 1000,
                        continuationToken: nextToken,
                        ...(userType === 'customer' && { filter: { ownerId } })
                    });
                    allHorses = [...allHorses, ...(resp.items || [])];
                    nextToken = resp.continuationToken;
                } while (nextToken && !isCancelled);

                // Fetch devices
                const devices = await fetchPaginatedMachineSettings(apiKey, organizationId, jwtToken);

                if (!isCancelled) {
                    setOwnerData(allOwners);
                    setHorseData(allHorses);
                    setDeviceData(devices.items || []);
                }
            } catch (error) {
                setSnackbar({ open: true, message: t('Failed to fetch data'), severity: 'error' });
            } finally {
                if (!isCancelled) setAuxLoading(false);
            }
        };
        fetchAuxData();
        return () => isCancelled = true;
    }, [apiKey, organizationId, jwtToken, userType, ownerId]);

    // ------------- VIEW MANAGEMENT -------------
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || (isMobile ? 'card' : 'table')
    );

    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const toggleViewMode = () => {
        const newMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newMode);
        updateDevicePreferences('viewMode', newMode);
    };

    // ------------- BOOKING ACTIONS -------------
    const handleDelete = async (bookingId) => {
        try {
            await deleteBooking(bookingId, apiKey, organizationId, jwtToken);
            setSnackbar({ open: true, message: t('Booking deleted successfully'), severity: 'success' });
            loadFirstPage(); // Refresh the list
        } catch (error) {
            setSnackbar({ open: true, message: t('Error deleting booking'), severity: 'error' });
        }
    };

    // ------------- MODAL HANDLING -------------
    const handleClose = () => {
        setModalOpen(false);
        loadFirstPage(); // Refresh
    }

    // ------------- DATA TRANSFORMATION -------------
    const transformData = useMemo(() => ({
        headers: [
            { key: 'start_time', label: t('GuidingMachinePage.todaysBookings.startTime'), display: { value: true, chipColor: false }, },
            { key: 'horse', label: t('GuidingMachinePage.todaysBookings.horseText'), display: { value: true, chipColor: false }, },
            { key: 'owner', label: t('GuidingMachinePage.todaysBookings.owner'), display: { value: true, chipColor: false }, },
            { key: 'managementType', label: t('GuidingMachinePage.todaysBookings.managementType'), display: { value: true, chipColor: false }, },
            { key: 'comment', label: t('GuidingMachinePage.todaysBookings.commentCard'), display: { value: true, chipColor: false }, },
            { key: 'deviceName', label: t('GuidingMachinePage.todaysBookings.deviceName'), display: { value: true, chipColor: false }, },
        ],
        rows: bookings.map(booking => ({
            id: booking.id,
            start_time: { value: new Date(booking.start_time).toLocaleTimeString() },
            horse: {
                value: horseData.find(h => h.id === booking.horse_id)?.name || t('Unknown Horse')
            },
            owner: {
                value: ownerData.find(o => o.id === booking.owner_id)?.firstname + ' ' +
                    ownerData.find(o => o.id === booking.owner_id)?.surname || t('Unknown Owner')
            },
            managementType: { value: booking.whom },
            comment: { value: booking.description },
            deviceName: {
                value: deviceData.find(d => d.id === booking.device_id)?.machineType || t('Unknown Device')
            }
        }))
    }), [bookings, horseData, ownerData, deviceData, t]);

    const isLoading = bookingsLoading || auxLoading;

    return (
        <Paper elevation={3} sx={{ mt: 1, p: 2 }}>
            {isLoading ? (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress />
                </Box>
            ) : (
                <DataViewWrapper
                    structure={transformData}
                    viewMode={viewMode}
                    onViewModeToggle={toggleViewMode}
                    filter={filter}
                    setFilter={setFilter}
                    sort={sort}
                    setSort={setSort}
                    pageId="/todays-bookings"
                    toolbarActions={[
                        <Button
                            key="add-booking"
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => setModalOpen(true)}
                            disabled={isLoading}
                        >
                            {t('GuidingMachinePage.bookNewTimeButton')}
                        </Button>
                    ]}
                    rowActions={[
                        {
                            icon: <DeleteIcon />,
                            handler: handleDelete,
                            confirm: true,
                            confirmMessage: t('Are you sure you want to delete this booking?')
                        }
                    ]}
                    enableFilterControls={false}
                    enableSortControls={false}
                />
            )}
            {continuationToken && (
                <Box mt={2} textAlign="center">
                    <Button
                        variant="contained"
                        onClick={loadNextPage}
                        disabled={isLoading}
                    >
                        {t('common.loadMore')}
                    </Button>
                </Box>
            )}

            <MachineBookingModal
                isOpen={modalOpen}
                onClose={handleClose}
                devicesProps={deviceData}
                horsesProp={horseData}
            />

            <SnackbarAlert
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Paper>
    );
};

export default TodaysBookings;