import axiosInstance, { setOrganizationIdHeader, setWithCredentials } from '../apiConfig/axiosConfig.js';

export async function verifyToken(token, organizationId) {
    try {
        setOrganizationIdHeader(organizationId);
        setWithCredentials(false);
        const response = await axiosInstance.post('/api/v2/token', { token });
        return response.data;
    } catch (error) {
        console.error('Error during token verification:', error);
        return false;
    }
}

export async function verifyOnlyToken(token) {
    try {
        setWithCredentials(false);
        const response = await axiosInstance.post('/api/v2/token', { token });
        return response.data.valid;
    } catch (error) {
        console.error('Error during token verification:', error);
        return false;
    }
}

export async function renewrefreshToken(organizationId) {
    try {
        setOrganizationIdHeader(organizationId);
        const response = await axiosInstance.post('/api/v2/refresh-token');
        // Assuming the new access token is returned in the response body under the accessToken property
        return response.data.accessToken;
    } catch (error) {
        console.error('Error during token refresh:', error);
        return null;
    }
}

export default {
    verifyToken,
    verifyOnlyToken,
    renewrefreshToken
};
