import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tabs,
  Tab
} from '@mui/material';
import { DatePicker } from '@mui/lab';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import {
  fetchEmployeeById,
  saveEmployee,
  updateEmployee,
} from '../../services/v2/EmployeeService';
import SnackbarAlert from '../../components/SnackbarAlert';
import LinearProgress from '@mui/material/LinearProgress';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      style={{ paddingTop: '1rem' }}
    >
      {value === index && children}
    </div>
  );
}

const EmployeeDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { apiKey, organizationId, token, userId } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [employeeData, setEmployeeData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    email: '',
    phoneNumber: '',
    department: '',
    jobTitle: '',
    dateOfHire: null,
    status: 'Active',
    employmentType: 'Full-Time',
    manager: '',
    workLocation: '',
    street: '',
    zipCode: '',
    city: '',
    state: '',
    country: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    notes: '',
    baseSalary: '',
    currency: 'USD',
    paymentFrequency: 'Monthly',
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  useEffect(() => {
    if (id !== 'new') {
      setLoading(true);
      const fetchData = async () => {
        try {
          const data = await fetchEmployeeById(apiKey, organizationId, token, id, userId);
          console.log('data:', data);
          setEmployeeData(data);
        } catch (error) {
          console.error('Error fetching employee:', error);
        }
      };
      fetchData().then(() => setLoading(false));
    }
  }, [id, apiKey, organizationId, token, userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      if (id === 'new') {
        await saveEmployee(employeeData, apiKey, organizationId, token, userId);
      } else {
        await updateEmployee(id, employeeData, apiKey, organizationId, token, userId);
      }
      navigate('/employees');
    } catch (error) {
      console.error('Error saving employee:', error);
      setSnackbar({ open: true, message: t('employeeDetail.saveError'), severity: 'error' });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {id === 'new' ? t('employeeDetail.addEmployee') : t('employeeDetail.editEmployee')}
      </Typography>
      {loading && <LinearProgress />}
      <>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{ mt: 2 }}
        >
          <Tab label={t('employeeDetail.personalInfo')} />
          <Tab label={t('employeeDetail.contactInfo')} />
          <Tab label={t('employeeDetail.professionalInfo')} />
          <Tab label={t('employeeDetail.hrDetails')} />
          <Tab label={t('employeeDetail.compensation')} />
          <Tab label={t('employeeDetail.emergencyContact')} />
          <Tab label={t('employeeDetail.additionalNotes')} />
        </Tabs>

        {/* PERSONAL INFO */}
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.firstName')}
                name="firstName"
                value={employeeData.firstName}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.lastName')}
                name="lastName"
                value={employeeData.lastName}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label={t('employeeDetail.dateOfBirth')}
                value={employeeData.dateOfBirth}
                onChange={(date) => setEmployeeData({ ...employeeData, dateOfBirth: date })}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </Grid>
        </TabPanel>

        {/* CONTACT INFO */}
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.email')}
                name="email"
                value={employeeData.email}
                onChange={handleInputChange}
                fullWidth
                type="email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.phoneNumber')}
                name="phoneNumber"
                value={employeeData.phoneNumber}
                onChange={handleInputChange}
                fullWidth
                type="tel"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.workLocation')}
                name="workLocation"
                value={employeeData.workLocation}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.street')}
                name="street"
                value={employeeData.street}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.city')}
                name="city"
                value={employeeData.city}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.state')}
                name="state"
                value={employeeData.state}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.zipCode')}
                name="zipCode"
                value={employeeData.zipCode}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.country')}
                name="country"
                value={employeeData.country}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>

        {/* PROFESSIONAL INFO */}
        <TabPanel value={tabValue} index={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>{t('employeeDetail.department')}</InputLabel>
                <Select
                  name="department"
                  value={employeeData.department}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">{t('common.select')}</MenuItem>
                  <MenuItem value="engineering">Engineering</MenuItem>
                  <MenuItem value="sales">Sales</MenuItem>
                  <MenuItem value="hr">HR</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.jobTitle')}
                name="jobTitle"
                value={employeeData.jobTitle}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label={t('employeeDetail.dateOfHire')}
                value={employeeData.dateOfHire}
                onChange={(date) => setEmployeeData({ ...employeeData, dateOfHire: date })}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </Grid>
        </TabPanel>

        {/* HR DETAILS */}
        <TabPanel value={tabValue} index={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>{t('employeeDetail.status')}</InputLabel>
                <Select
                  name="status"
                  value={employeeData.status}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">{t('common.select')}</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="onLeave">On Leave</MenuItem>
                  <MenuItem value="terminated">Terminated</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>{t('employeeDetail.employmentType')}</InputLabel>
                <Select
                  name="employmentType"
                  value={employeeData.employmentType}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">{t('common.select')}</MenuItem>
                  <MenuItem value="fullTime">Full-Time</MenuItem>
                  <MenuItem value="partTime">Part-Time</MenuItem>
                  <MenuItem value="contractor">Contractor</MenuItem>
                  <MenuItem value="intern">Intern</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.manager')}
                name="manager"
                value={employeeData.manager}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>

        {/* COMPENSATION */}
        <TabPanel value={tabValue} index={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.baseSalary')}
                name="baseSalary"
                value={employeeData.baseSalary}
                onChange={handleInputChange}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel>{t('employeeDetail.currency')}</InputLabel>
                <Select
                  name="currency"
                  value={employeeData.currency}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">{t('common.select')}</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel>{t('employeeDetail.paymentFrequency')}</InputLabel>
                <Select
                  name="paymentFrequency"
                  value={employeeData.paymentFrequency}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">{t('common.select')}</MenuItem>
                  <MenuItem value="annually">Annually</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="biWeekly">Bi-weekly</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>

        {/* EMERGENCY CONTACT */}
        <TabPanel value={tabValue} index={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.emergencyContactName')}
                name="emergencyContactName"
                value={employeeData.emergencyContactName}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('employeeDetail.emergencyContactPhone')}
                name="emergencyContactPhone"
                value={employeeData.emergencyContactPhone}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>

        {/* ADDITIONAL NOTES */}
        <TabPanel value={tabValue} index={6}>
          <TextField
            label={t('employeeDetail.notes')}
            name="notes"
            value={employeeData.notes}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={3}
          />
        </TabPanel>
      </>

      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t('common.save')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => navigate('/employees')}>
            {t('common.cancel')}
          </Button>
        </Grid>
      </Grid>

      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </Paper>
  );
};

export default EmployeeDetailPage;
