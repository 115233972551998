// src/config/importDefinitions.js

import { fetchCategoriesPaginated } from '../../../services/v2/categoryService';
import { fetchPaginatedMeasureUnits } from '../../../services/v2/measureUnitsService';

// If you need to fetch owners / vets / farriers for validation
import {
  fetchOwnersPaginated,
} from '../../../services/v2/ownerService';
import {
  fetchPaginatedVeterinarians,
} from '../../../services/v2/VeterinarianService';
import {
  fetchPaginatedFerries,
} from '../../../services/v2/FerryService';

const importDefinitions = {
  OwnerData: {
    label: 'Business Partners',
    schema: {
      ownerType: 'string',
      companyName: 'string',
      companyBRN: 'string',
      birthday: 'datetime',
      email: 'string',
      firstname: 'string',
      surname: 'string',
      number: 'string',
      'address.street': 'string',
      'address.zipCode': 'string',
      'address.city': 'string',
      'address.country': 'string',
      entityType: 'array',
    },
    fieldDescriptions: {
      ownerType: 'ownerType',
      companyName: 'companyName',
      companyBRN: 'companyBRN',
      birthday: 'birthday',
      email: 'email',
      firstname: 'firstname',
      surname: 'surname',
      number: 'number',
      'address.street': 'addressStreet',
      'address.zipCode': 'addressZipCode',
      'address.city': 'addressCity',
      'address.country': 'addressCountry',
      entityType: 'entityType',
    },
    exampleData: [
      {
        ownerType: 'company',
        companyName: 'Tech Innovators Inc.',
        companyBRN: 'BRN123456',
        birthday: '',           // For a company, not typically used
        email: 'contact@techinnovators.com',
        firstname: 'John',
        surname: 'Doe',
        number: '+1234567890',
        'address.street': '123 Innovation Drive',
        'address.zipCode': '12345',
        'address.city': 'Techville',
        'address.country': 'USA',
        entityType: 'owner,vendor',
      },
      {
        ownerType: 'private',
        companyName: '',
        companyBRN: '',
        birthday: '1985-05-15',
        email: 'jane.smith@example.com',
        firstname: 'Jane',
        surname: 'Smith',
        number: '+0987654321',
        'address.street': '456 Elm Street',
        'address.zipCode': '67890',
        'address.city': 'Mapleton',
        'address.country': 'USA',
        entityType: 'customer',
      },
    ],
    fetchData: async ({ apiKey, organizationId, token }) => {
      // If you don't need anything, can return empty object
      return {};
    },
    validateRow: (row, schema, t) => {
      // If default validation is enough, just return empty
      return { rowErrors: {}, rowWarnings: {} };
    },
  },

  ItemData: {
    label: 'Items',
    schema: {
      name: 'string',
      category: 'string',
      subcategory: 'string',
      lastBoughtPrice: 'double',
      stockItem: 'bool',
      isSaleItem: 'bool',
      salesDependencies: 'string',
      bruttoPrice: 'double',
      netPrice: 'double',
      taxPercentage: 'double',
      quantity: 'int32',
      warningQuantity: 'int32',
      showInDashboard: 'bool',
      measureUnit: 'string',
    },
    fieldDescriptions: {
      name: 'name',
      category: 'category',
      subcategory: 'subcategory',
      lastBoughtPrice: 'lastBoughtPrice',
      stockItem: 'stockItem',
      isSaleItem: 'isSaleItem',
      salesDependencies: 'salesDependencies',
      bruttoPrice: 'bruttoPrice',
      netPrice: 'netPrice',
      taxPercentage: 'taxPercentage',
      quantity: 'quantity',
      warningQuantity: 'warningQuantity',
      showInDashboard: 'showInDashboard',
      measureUnit: 'measureUnit',
    },
    exampleData: [
      {
        name: 'UltraWidget 3000',
        category: 'Gadgets',
        subcategory: 'Widgets',
        lastBoughtPrice: 250.0,
        stockItem: true,
        isSaleItem: true,
        salesDependencies: '',
        bruttoPrice: 299.99,
        netPrice: 249.99,
        taxPercentage: 20.0,
        quantity: 50,
        warningQuantity: 10,
        showInDashboard: true,
        measureUnit: 'Piece',
      },
      {
        name: 'MegaGizmo Pro',
        category: 'Gadgets',
        subcategory: 'Gizmos',
        lastBoughtPrice: 500.0,
        stockItem: true,
        isSaleItem: true,
        salesDependencies: '',
        bruttoPrice: 599.99,
        netPrice: 499.99,
        taxPercentage: 20.0,
        quantity: 20,
        warningQuantity: 5,
        showInDashboard: true,
        measureUnit: 'Piece',
      },
    ],
    fetchData: async ({ apiKey, organizationId, token }) => {
      let categories = [];
      let measureUnits = [];
      try {
        const categoriesData = await fetchCategoriesPaginated(apiKey, organizationId, token, {
          pageSize: 1000,
        });
        categories = categoriesData.items || [];

        const measureUnitsData = await fetchPaginatedMeasureUnits(apiKey, organizationId, token, {
          pageSize: 1000,
        });
        measureUnits = measureUnitsData.items || [];
      } catch (error) {
        console.error('Failed to fetch categories/measureUnits:', error);
      }
      return { categories, measureUnits };
    },
    validateRow: (row, schema, t, dynamicData) => {
      const { categories, measureUnits } = dynamicData || {};
      const rowErrors = {};
      const rowWarnings = {};

      // Example category check -> Warning if not found
      if (row.category && categories) {
        const cat = categories.find((c) => c.categoryName === row.category);
        if (!cat) {
          rowWarnings.category = t('Category not found. Will be created.');
        } else {
          // Subcategory check
          if (row.subcategory) {
            const subcat = cat.subcategories?.find(
              (s) => s.subcategoryName === row.subcategory
            );
            if (!subcat) {
              rowWarnings.subcategory = t('Subcategory not found. Will be created.');
            }
          }
        }
      }

      // measureUnit -> Warning if not found
      if (row.measureUnit && measureUnits) {
        const mu = measureUnits.find(
          (m) => m.nameLong === row.measureUnit || m.nameShort === row.measureUnit
        );
        if (!mu) {
          rowWarnings.measureUnit = t('MeasureUnit not found. Will be created.');
        }
      }

      return { rowErrors, rowWarnings };
    },
  },

  // --------------------------------------------------------------------------
  // Add your new "HorseData" definition here
  // --------------------------------------------------------------------------
  HorseData: {
    label: 'Horses',
    schema: {
      name: 'string',
      birthday: 'datetime',
      breed: 'string',
      ownerId: 'string',
      coRiders: 'array',
      'emergencyContact.name': 'string',
      'emergencyContact.number': 'string',
      veterinarianId: 'string',
      farrierId: 'string',
      boxTypeId: 'string',
      pricingId: 'string',
      identificationNumber: 'string',
      sex: 'string',
      color: 'string',
      'microchipIdentification.dateOfInsertion': 'datetime',
      'microchipIdentification.locationOfInsertion': 'string',
      'microchipIdentification.number': 'string',
      'microchipIdentification.issuedBy': 'string',
      'microchipIdentification.barcode': 'string',
      'breeder.name': 'string',
      'breeder.address': 'string',
      'sire.name': 'string',
      'sire.identificationNumber': 'string',
      'dam.name': 'string',
      'dam.identificationNumber': 'string',
      'branding.brandNumber': 'string',
      'branding.location': 'string',
      'branding.brandingAgent': 'string',
      'markings.head': 'string',
      'markings.body': 'string',
      'markings.legs.frontLeft': 'string',
      'markings.legs.frontRight': 'string',
      'markings.legs.backLeft': 'string',
      'markings.legs.backRight': 'string',
      documents: 'array',
      services: 'array',
    },
    fieldDescriptions: {
      name: 'horseName',
      birthday: 'birthday',
      breed: 'breed',
      ownerId: 'ownerId',
      coRiders: 'coRiders',
      'emergencyContact.name': 'emergencyContactName',
      'emergencyContact.number': 'emergencyContactNumber',
      veterinarianId: 'veterinarianId',
      farrierId: 'farrierId',
      boxTypeId: 'boxTypeId',
      pricingId: 'pricingId',
      identificationNumber: 'identificationNumber',
      sex: 'sex',
      color: 'color',
      'microchipIdentification.dateOfInsertion': 'microchipDateOfInsertion',
      'microchipIdentification.locationOfInsertion': 'microchipLocationOfInsertion',
      'microchipIdentification.number': 'microchipNumber',
      'microchipIdentification.issuedBy': 'microchipIssuedBy',
      'microchipIdentification.barcode': 'microchipBarcode',
      'breeder.name': 'breederName',
      'breeder.address': 'breederAddress',
      'sire.name': 'sireName',
      'sire.identificationNumber': 'sireIdentificationNumber',
      'dam.name': 'damName',
      'dam.identificationNumber': 'damIdentificationNumber',
      'branding.brandNumber': 'brandNumber',
      'branding.location': 'brandLocation',
      'branding.brandingAgent': 'brandingAgent',
      'markings.head': 'markingsHead',
      'markings.body': 'markingsBody',
      'markings.legs.frontLeft': 'markingsFrontLeft',
      'markings.legs.frontRight': 'markingsFrontRight',
      'markings.legs.backLeft': 'markingsBackLeft',
      'markings.legs.backRight': 'markingsBackRight',
      documents: 'documents',
      services: 'services',
    },
    exampleData: [
      {
        name: 'Black Beauty',
        birthday: '2012-08-15',
        breed: 'Thoroughbred',
        ownerId: 'owner-uuid-123',
        coRiders: 'user-123,user-456', // Example of how an array might be represented in CSV
        'emergencyContact.name': 'Alice Rider',
        'emergencyContact.number': '+12015550123',
        veterinarianId: 'vet-uuid-123',
        farrierId: 'farrier-uuid-123',
        boxTypeId: 'boxType-xyz',
        pricingId: 'pricing-123',
        identificationNumber: 'HRS-12345',
        sex: 'Mare',
        color: 'Black',
        'microchipIdentification.dateOfInsertion': '2013-03-01',
        'microchipIdentification.locationOfInsertion': 'Left Neck',
        'microchipIdentification.number': '123456789',
        'microchipIdentification.issuedBy': 'Official Vet Org',
        'microchipIdentification.barcode': 'barcode123',
        'breeder.name': 'John Doe',
        'breeder.address': '100 Horse St, Farmville',
        'sire.name': 'Great Stallion',
        'sire.identificationNumber': 'SIRE-001',
        'dam.name': 'Swift Mare',
        'dam.identificationNumber': 'DAM-002',
        'branding.brandNumber': 'BB123',
        'branding.location': 'Left Shoulder',
        'branding.brandingAgent': 'BrandMaster Inc.',
        'markings.head': 'Star on forehead',
        'markings.body': 'White patch on left flank',
        'markings.legs.frontLeft': 'Sock',
        'markings.legs.frontRight': 'None',
        'markings.legs.backLeft': 'Half stocking',
        'markings.legs.backRight': 'None',
        documents: 'doc-uuid-1,doc-uuid-2',
        services: 'service-uuid-1,service-uuid-2',
      },
      {
        name: 'Spark',
        birthday: '2015-01-10',
        breed: 'Arabian',
        ownerId: 'owner-uuid-999',
        coRiders: '',
        'emergencyContact.name': 'Bob Owner',
        'emergencyContact.number': '+12015550100',
        veterinarianId: 'vet-uuid-789',
        farrierId: '',
        boxTypeId: '',
        pricingId: '',
        identificationNumber: 'HRS-67890',
        sex: 'Stallion',
        color: 'Chestnut',
        'microchipIdentification.dateOfInsertion': '',
        'microchipIdentification.locationOfInsertion': '',
        'microchipIdentification.number': '',
        'microchipIdentification.issuedBy': '',
        'microchipIdentification.barcode': '',
        'breeder.name': '',
        'breeder.address': '',
        'sire.name': '',
        'sire.identificationNumber': '',
        'dam.name': '',
        'dam.identificationNumber': '',
        'branding.brandNumber': '',
        'branding.location': '',
        'branding.brandingAgent': '',
        'markings.head': 'Blaze',
        'markings.body': '',
        'markings.legs.frontLeft': '',
        'markings.legs.frontRight': '',
        'markings.legs.backLeft': '',
        'markings.legs.backRight': '',
        documents: '',
        services: '',
      },
    ],
    // In case you need to fetch owners, veterinarians, farriers for validations:
    fetchData: async ({ apiKey, organizationId, token }) => {
      let owners = [];
      let vets = [];
      let farriers = [];

      try {
        // Example: fetch owners, vets, and farriers
        const ownersData = await fetchOwnersPaginated(apiKey, organizationId, token, { pageSize: 1000 });
        owners = ownersData.items || [];

        const vetsData = await fetchPaginatedVeterinarians(apiKey, organizationId, token, { pageSize: 1000 });
        vets = vetsData.items || [];

        const farriersData = await fetchPaginatedFerries(apiKey, organizationId, token, { pageSize: 1000 });
        farriers = farriersData.items || [];
      } catch (error) {
        console.error('Failed to fetch owners/vets/farriers:', error);
      }

      return {
        owners,
        vets,
        farriers,
      };
    },
    validateRow: (row, schema, t, dynamicData) => {
      const { owners, vets, farriers } = dynamicData || {};
      const rowErrors = {};
      const rowWarnings = {};

      // Validate ownerId
      if (row.ownerId && owners) {
        const ownerExists = owners.find((o) => o.id === row.ownerId);
        if (!ownerExists) {
          rowWarnings.ownerId = t('Owner not found. Will be created if supported.');
        }
      }

      // Validate veterinarianId
      if (row.veterinarianId && vets) {
        const vetExists = vets.find((v) => v.id === row.veterinarianId);
        if (!vetExists) {
          rowWarnings.veterinarianId = t('Veterinarian not found. Will be created if supported.');
        }
      }

      // Validate farrierId
      if (row.farrierId && farriers) {
        const farrierExists = farriers.find((f) => f.id === row.farrierId);
        if (!farrierExists) {
          rowWarnings.farrierId = t('Farrier not found. Will be created if supported.');
        }
      }

      // Example: require name
      if (!row.name) {
        rowErrors.name = t('Name is required for a horse.');
      }

      return { rowErrors, rowWarnings };
    },
  },

  // ... other import definitions if needed
};

export default importDefinitions;
