// src/pages/ExpenseListPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Button,
  Typography,
  Chip,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';
import { useTranslation } from 'react-i18next';

import { fetchPaginatedExpenses } from '../services/v2/expenseService';
import DocumentUploadModal from '../components/DocumentUploadModal';
import { scanDocument, fetchDocumentMetadata } from '../services/v2/documentService';

import DataViewWrapper from '../components/DataViewWrapper';
import { usePaginatedData } from '../hooks/usePaginatedData';

const ExpenseListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { apiKey, organizationId, token, userId } = useAuth();
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // Our paginated data hook for expenses
  const {
    items: expenses,
    loading: expensesLoading,
    continuationToken,
    loadNextPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchPaginatedExpenses(apiKey, organizationId, token, userId, options),
    { pageSize: 25 }
  );

  // Local state for the view mode (card/table)
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode ||
    (window.matchMedia('(max-width: 600px)').matches ? 'card' : 'table')
  );

  // Local state for upload modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  // Sync local view mode with user/device preferences
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  // Toggle the view mode
  const toggleViewMode = () => {
    const newMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newMode);
    updateDevicePreferences('viewMode', newMode);
  };

  // Navigate to expense detail
  const navigateToDetail = (expenseId) => {
    navigate(`/expenses/detail/${expenseId}`);
  };

  // Render status chip
  const renderPaymentStatus = (expense) => {
    if (expense.cancel === true) {
      return (
        <Chip
          icon={<WarningAmberIcon />}
          label={t('expenseListPage.cancelled')}
          color="warning"
        />
      );
    }
    return (
      <Chip
        icon={<CheckCircleIcon />}
        label={t('expenseListPage.valid')}
        color="success"
      />
    );
  };

  // Handle upload modal close
  const handleCloseUploadModal = async (documentId) => {
    if (!documentId || typeof documentId !== 'string') {
      setIsUploadModalOpen(false);
      return;
    }
    setIsUploadModalOpen(false);

    try {
      await scanDocument(documentId, apiKey, organizationId, token);
    } catch (error) {
      console.error('Error scanning document:', error);
    }

    try {
      const doc = await fetchDocumentMetadata(documentId, apiKey, organizationId, token);
      navigate(`/expenses/detail/new`, {
        state: { fromDocument: doc.structuredData },
      });
    } catch (error) {
      console.error('Error fetching document metadata:', error);
    }
  };

  // Open the upload modal
  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  // Inlined data transformation (no `useMemo`)
  const transformData = {
    headers: [
      {
        key: 'expenseNumber',
        label: t('expenseListPage.expenseNumber'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'expenseDate',
        label: t('expenseListPage.expenseDate'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'totalBruttoAmount',
        label: t('expenseListPage.totalBruttoAmount'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'status',
        label: t('expenseListPage.status'),
        display: { value: true, chipColor: false },
      },
    ],
    rows: expenses.map((expense) => ({
      id: expense.id,
      expenseNumber: { value: expense.expenseNumber },
      expenseDate: {
        value: new Date(expense.expenseDate).toLocaleDateString(),
      },
      totalBruttoAmount: { value: expense.totalBruttoAmount },
      status: {
        value: renderPaymentStatus(expense),
      },
    })),
  };

  const isLoading = expensesLoading;

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('expenseListPage.title')}
      </Typography>

      {expensesLoading ? (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <DataViewWrapper
            structure={transformData}
            viewMode={viewMode}
            onViewModeToggle={toggleViewMode}
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            onRowClick={navigateToDetail}
            pageId="/expenses"

            // 8) The magic: pass your custom toolbar actions
            toolbarActions={[
              <Button
                key="add-expense"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => navigateToDetail('new')}
              >
                {t('expenseListPage.addNewExpense')}
              </Button>,

              <Button
                key="upload-doc"
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={openUploadModal}
              >
                {t('expenseListPage.uploadDocument')}
              </Button>,
            ]}
          />

          {continuationToken && (
            <Box mt={2} textAlign="center">
              <Button variant="contained" onClick={loadNextPage} disabled={expensesLoading}>
                {t('common.loadMore')}
              </Button>
            </Box>
          )}
        </>
      )}

      {/* Document Upload Modal */}
      <DocumentUploadModal
        isOpen={isUploadModalOpen}
        onClose={handleCloseUploadModal}
        scanType="expense"
        predefinedDescription="Expense"
      />
    </Paper>
  );
};

export default ExpenseListPage;