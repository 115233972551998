// src/pages/DocumentsDetailsPage/useDocumentDetails.js
import { useState, useEffect } from 'react';
import {
    fetchDocumentMetadata,
    deleteDocumentMetadata,
    scanDocument,
    scanItems,
} from '../../services/v2/documentService';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const useDocumentDetails = (documentId) => {
    // Initial state for document details
    const initialDocumentDetails = {
        id: '',
        name: '',
        documentType: '',
        description: '',
        blobUrl: '',
        orginalName: '',
        url: '',
        businessType: '',
        structuredData: '{}',
        structuredItemSuggestion: { items: [], categories: [], measureUnits: [] },
        uploadDate: null,
    };

    const [documentDetails, setDocumentDetails] = useState(initialDocumentDetails);
    const [scanResult, setScanResult] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isScanLoading, setIsScanLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [itemSuggestionModalOpen, setItemSuggestionModalOpen] = useState(false);

    const navigate = useNavigate();
    const { apiKey, organizationId, token } = useAuth();

    // Fetch document details on component mount
    useEffect(() => {
        const fetchDocumentDetailsById = async () => {
            setIsPageLoading(true);
            try {
                const details = await fetchDocumentMetadata(
                    documentId,
                    apiKey,
                    organizationId,
                    token
                );
                setDocumentDetails(details);
            } catch (error) {
                console.error('Failed to fetch document details:', error);
            } finally {
                setIsPageLoading(false);
            }
        };

        if (documentId) {
            fetchDocumentDetailsById();
        }
    }, [documentId, apiKey, organizationId, token]);

    // Delete document function
    const deleteDocument = async (documentId) => {
        try {
            await deleteDocumentMetadata(documentId, apiKey, organizationId, token);
            navigate('/documents');
        } catch (error) {
            console.error('Failed to delete document:', error);
        }
    };

    // Download base64 file
    const downloadBase64File = (base64Data, fileName) => {
        const link = document.createElement('a');
        link.href = `data:${documentDetails.documentType};base64,${base64Data}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Handle document download
    const handleDownload = () => {
        downloadBase64File(
            documentDetails.base64,
            documentDetails.name || documentDetails.originalName
        );
    };

    // Handle item scan
    const handleItemScan = async () => {
        setIsScanLoading(true);
        try {
            await scanItems(documentId, apiKey, organizationId, token);
        } catch (error) {
            console.error('Failed to scan items:', error);
        } finally {
            setIsScanLoading(false);
        }
    };

    // Handle document scan
    const handleScan = async () => {
        setIsScanLoading(true);
        try {
            await scanDocument(documentId, apiKey, organizationId, token);
            const newDetails = await fetchDocumentMetadata(
                documentId,
                apiKey,
                organizationId,
                token
            );
            setDocumentDetails(newDetails);
        } catch (error) {
            console.error('Failed to scan document:', error);
        } finally {
            setIsScanLoading(false);
        }
    };

    // Handle showing structured data
    const handleShowStructuredData = async () => {
        try {
            let structuredData = documentDetails.structuredData;

            // Check if structuredData is empty
            if (
                !structuredData ||
                JSON.stringify(structuredData) === '{}' ||
                structuredData === null
            ) {
                setIsPageLoading(true);
                const details = await fetchDocumentMetadata(
                    documentId,
                    apiKey,
                    organizationId,
                    token
                );
                setDocumentDetails(details);
                structuredData = details.structuredData;
            }

            setScanResult(structuredData);
            setOpenModal(true);
        } catch (error) {
            console.error('Failed to fetch structured data:', error);
        } finally {
            setIsPageLoading(false);
        }
    };

    // Handle showing item suggestion
    const handleShowItemSuggestion = () => {
        setItemSuggestionModalOpen(true);
    };

    // Handle close modal
    const handleClose = () => setOpenModal(false);

    // Handle transfer click
    const handleTransferClick = () => {
        if (documentDetails.businessType === 'expense') {
            navigate(`/expenses/detail/new`, {
                state: { fromDocument: documentDetails.structuredData },
            });
        } else if (documentDetails.businessType === 'horsepass') {
            const transferData = {
                ...documentDetails.structuredData,
                documentId: documentDetails.id,
                documentName: documentDetails.name,
            };
            navigate(`/horses/create/new`, { state: { fromDocument: transferData } });
        }
    };

    return {
        documentDetails,
        handleDownload,
        deleteDocument,
        handleTransferClick,
        handleScan,
        handleItemScan,
        scanResult,
        handleShowStructuredData,
        handleShowItemSuggestion,
        isPageLoading,
        isScanLoading,
        openModal,
        setItemSuggestionModalOpen,
        itemSuggestionModalOpen,
        handleClose,
    };
};

export default useDocumentDetails;
