// src/pages/DeliveryPlanDetailsPage.js

import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Box,
    Divider,
    List,
    ListItemText,
    Button,
    Chip,
    LinearProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItemButton,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintIcon from '@mui/icons-material/Print';
import DeliveryPlanPrintDialog from './DeliveryPlanPrintDialog';
import SnackbarAlert from '../../components/SnackbarAlert';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import { useAuth } from '../../context/AuthContext';
import { fetchDeliveryPlanById } from '../../services/v2/DeliveryPlanService';
import { fetchVehicles } from '../../services/v2/VehicleService';
import { fetchDrivers } from '../../services/v2/DriverService';
import { fetchSummarySalesOrders } from '../../services/v2/SalesOrderService';
import { createDeliveryNotesForDeliveryPlan } from '../../services/v2/DeliveryPlanService';

import { useTranslation } from 'react-i18next';
import { format, isValid } from 'date-fns';
import { useDateLocale } from '../../hooks/useDateLocale';

const DeliveryPlanDetailsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const [printDialogOpen, setPrintDialogOpen] = useState(false);

    const [deliveryPlan, setDeliveryPlan] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [salesOrders, setSalesOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [confirmationDialog, setConfirmationDialog] = useState({ open: false, text: '' });

    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const planData = await fetchDeliveryPlanById(apiKey, organizationId, jwtToken, id);
                setDeliveryPlan(planData);

                const [vehiclesData, driversData, salesOrdersData] = await Promise.all([
                    fetchVehicles(apiKey, organizationId, jwtToken),
                    fetchDrivers(apiKey, organizationId, jwtToken),
                    fetchSummarySalesOrders(apiKey, organizationId, jwtToken),
                ]);

                setVehicles(vehiclesData);
                setDrivers(driversData);
                setSalesOrders(salesOrdersData);
            } catch (error) {
                console.error('Error loading data:', error);
                setSnackbar({ open: true, message: t('DeliveryPlanDetailsPage.errorLoadingData', { error: error.message }), severity: 'error' });
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [id, apiKey, organizationId, jwtToken, t]);

    const handleEdit = () => {
        navigate(`/deliveryPlanning/delivery-plans/edit/${id}`);
    };

    const getChipColor = (status) => {
        switch (status) {
            case 'draft':
                return 'default';
            case 'confirmed':
                return 'primary';
            case 'complete':
                return 'success';
            case 'canceled':
                return 'error';

            default:
                return 'default';
        }
    };

    const handlePrint = () => {
        setPrintDialogOpen(true);
    };

    const handleCreateDeliveryNotes = () => {
        setConfirmationDialog({
            open: true,
            text: t('DeliveryPlanDetailsPage.createDeliveryNotesConfirmation'),
            onConfirm: confirmCreateDeliveryNotes,
        });
    };

    const confirmCreateDeliveryNotes = async () => {
        setConfirmationDialog({ ...confirmationDialog, open: false });
        try {
            await createDeliveryNotesForDeliveryPlan(apiKey, organizationId, jwtToken, id);
            setSnackbar({ open: true, message: t('DeliveryPlanDetailsPage.deliveryNotesCreated'), severity: 'success' });
        } catch (error) {
            console.error('Error creating delivery notes:', error);
            setSnackbar({ open: true, message: t('DeliveryPlanDetailsPage.errorCreatingDeliveryNotes', { error: error.message }), severity: 'error' });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleCloseConfirmationDialog = () => {
        setConfirmationDialog({ ...confirmationDialog, open: false });
    };

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                minHeight="100vh"
            >
                <Typography variant="h6">{t('DeliveryPlanDetailsPage.loadingDeliveryPlanData')}</Typography>
                <LinearProgress sx={{ width: '100%', mt: 2 }} />
            </Box>
        );
    }

    if (!deliveryPlan) {
        return (
            <Typography variant="h6">{t('DeliveryPlanDetailsPage.deliveryPlanNotFound')}</Typography>
        );
    }

    const formatDate = (date) => {
        if (!date || !isValid(new Date(date))) return '';
        return format(new Date(date), 'P', { locale: dateLocale });
    };

    return (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    {t('DeliveryPlanDetailsPage.title')}
                </Typography>
                <Box display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                        onClick={handlePrint}
                        sx={{ mr: 2 }}
                    >
                        {t('DeliveryPlanDetailsPage.printButton')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={handleEdit}
                        sx={{ mr: 2 }}
                        disabled={deliveryPlan.status !== 'draft'}
                    >
                        {t('DeliveryPlanDetailsPage.editButton')}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCreateDeliveryNotes}
                        disabled={deliveryPlan.status === 'complete' || deliveryPlan.status === 'canceled'}
                    >
                        {t('DeliveryPlanDetailsPage.createDeliveryNotesButton')}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6">{t('DeliveryPlanDetailsPage.deliveryPlanNumber')}: {deliveryPlan.deliveryPlanNumber || t('DeliveryPlanDetailsPage.notAvailable')}</Typography>
                <Typography variant="body1">{t('DeliveryPlanDetailsPage.deliveryDate')}: {formatDate(deliveryPlan.deliveryDate)}</Typography>
                <Typography variant="body1">
                    {t('DeliveryPlanDetailsPage.status')}: {' '}
                    <Chip
                        label={deliveryPlan.status ? t(`DeliveryPlanDetailsPage.statusOptions.${deliveryPlan.status}`) : t('DeliveryPlanDetailsPage.draft')}
                        color={getChipColor(deliveryPlan.status)}
                        size="small"
                    />
                </Typography>
                <Divider sx={{ my: 2 }} />

                {/* Routes */}
                {deliveryPlan.routes.map((route) => {
                    const vehicle = vehicles.find((v) => v.id === route.vehicleId);
                    const driver = drivers.find((d) => d.id === route.driverId);

                    return (
                        <Accordion key={route.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`route-content-${route.id}`}
                                id={`route-header-${route.id}`}
                            >
                                <Typography variant="h6">{route.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1">
                                    <strong>{t('DeliveryPlanDetailsPage.vehicle')}:</strong> {vehicle ? vehicle.name : t('DeliveryPlanDetailsPage.unassigned')}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>{t('DeliveryPlanDetailsPage.driver')}:</strong> {driver ? driver.name : t('DeliveryPlanDetailsPage.unassigned')}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>{t('DeliveryPlanDetailsPage.numberOfOrders')}:</strong> {route.salesOrders.length}
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                                <List>
                                    {route.salesOrders.map((order) => {
                                        const salesOrder = salesOrders.find((so) => so.id === order.id);
                                        if (!salesOrder) return null;
                                        return (
                                            <ListItemButton
                                                key={order.id}
                                                onClick={() => navigate(`/sales-process/sales-orders/detail/${salesOrder.id}`)}
                                                sx={{ pl: 0 }}
                                            >
                                                <ListItemText
                                                    primary={`${t('DeliveryPlanDetailsPage.orderNumber')}: ${salesOrder.salesOrderNumber} - ${salesOrder.customerName}`}
                                                    secondary={`${t('DeliveryPlanDetailsPage.items')}: ${salesOrder.summary.totalItem} - ${t('DeliveryPlanDetailsPage.totalAmount')}: ${salesOrder.summary.totalAmount.toFixed(2)}`}
                                                />
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>

            {/* DeliveryPlanPrintDialog */}
            <DeliveryPlanPrintDialog
                open={printDialogOpen}
                onClose={() => setPrintDialogOpen(false)}
                deliveryPlan={deliveryPlan}
                vehicles={vehicles}
                drivers={drivers}
                salesOrders={salesOrders}
            />

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                isOpen={confirmationDialog.open}
                onClose={handleCloseConfirmationDialog}
                onConfirm={confirmationDialog.onConfirm}
                text={confirmationDialog.text}
            />

            {/* Snackbar Alert */}
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Paper>
    );
};

export default DeliveryPlanDetailsPage;
