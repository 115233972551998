// src/services/VaccinationService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/vaccinations';

export async function fetchAllVaccinations(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function fetchVaccinationById(apiKey, organizationId, jwtToken, id, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?vaccinationId=${id}`;
  const response = await axiosInstance.get(url);
  return response.data[0];
}

export async function saveVaccination(vaccination, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}`;
  const response = await axiosInstance.post(url, vaccination);
  return response.data;
}

export async function updateVaccination(vaccination, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?vaccinationId=${vaccination.id}`;
  const response = await axiosInstance.put(url, vaccination);
  return response.data;
}

export async function deleteVaccination(vaccinationId, apiKey, organizationId, jwtToken, ownerId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?vaccinationId=${vaccinationId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function fetchPaginatedVaccinations(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);
  return fetchPaginatedData(API_BASE_URL, options, { apiKey, organizationId, jwtToken });
}

export default {
  fetchAllVaccinations,
  fetchVaccinationById,
  saveVaccination,
  updateVaccination,
  deleteVaccination,
  fetchPaginatedVaccinations,
};
