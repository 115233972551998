// src/services/ExpenseService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from '../apiConfig/axiosConfig.js';
import { fetchPaginatedData } from './helpers/paginatedApiService.js';

const API_BASE_URL = '/api/v2/expenses';

// Fetch all expenses
export async function fetchAllExpenses(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?userId=${userId}`);
  return response.data;
}

// Fetch a single expense by ID
export async function fetchExpenseById(apiKey, organizationId, jwtToken, id, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?id=${id}&userId=${userId}`);
  return response.data;
}

// Save a new expense
export async function saveExpense(expense, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.post(url, expense);
  return response.data;
}

// Update an existing expense
export async function updateExpense(expense, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${expense.id}&userId=${userId}`;
  const response = await axiosInstance.put(url, expense);
  return response.data;
}

// Delete an expense by ID
export async function deleteExpense(expenseId, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${expenseId}&userId=${userId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function fetchPaginatedExpenses(apiKey, organizationId, jwtToken, options) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  return fetchPaginatedData(API_BASE_URL, options, { apiKey, organizationId, jwtToken });
}

export default {
  fetchAllExpenses,
  fetchExpenseById,
  saveExpense,
  updateExpense,
  deleteExpense,
  fetchPaginatedExpenses,
};
