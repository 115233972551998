import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Stack
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

/**
 * Renders the list of available horses and allows dragging them or clicking 'Assign'
 * assignedHorseIds = array of horse IDs assigned in the layout
 */
const HorseList = ({ horses, onAssignHorse, assignedHorseIds }) => {
  const handleDragStart = (e, horseId) => {
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({ type: 'horse', horseId })
    );
    e.dataTransfer.effectAllowed = 'copy';
  };

  if (!horses.length) {
    return (
      <Card sx={{ height: '100%', mb: 2 }}>
        <CardHeader title="Available Horses" />
        <CardContent>
          <Typography align="center" color="text.secondary" sx={{ mt: 2 }}>
            All horses have been assigned.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="Available Horses"
        subheader="Drag a horse onto a stall or click Assign"
      />
      <CardContent>
        <Stack spacing={1}>
          {horses.map((horse) => {
            const isAssigned = assignedHorseIds.includes(horse.id);
            return (
              <Card
                key={horse.id}
                variant="outlined"
                draggable
                onDragStart={(e) => handleDragStart(e, horse.id)}
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  bgcolor: isAssigned ? 'action.hover' : 'inherit',
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <PersonIcon color="primary" />
                  <div>
                    <Typography variant="body2" fontWeight="bold">
                      {horse.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {horse.breed}
                    </Typography>
                  </div>
                </Stack>

                {isAssigned ? (
                  <CheckCircleIcon color="success" fontSize="small" />
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => onAssignHorse(horse)}
                  >
                    Assign
                  </Button>
                )}
              </Card>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default HorseList;
