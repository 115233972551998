import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchUserPreferences, updateUserPreferences } from '../services/v2/userPrefrencesService';
import { useAuth } from './AuthContext';

const UserPreferencesContext = createContext();

export const UserPreferencesProvider = ({ children }) => {
    const { apiKey, organizationId, token } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Device preferences (local storage)
    const [devicePreferences, setDevicePreferences] = useState(() => {
        const localPreferences = localStorage.getItem('devicePreferences');
        return localPreferences ? JSON.parse(localPreferences) : {
            viewMode: isMobile ? 'card' : 'table'
        };
    });

    // Page filters (local storage)
    const [savedFilters, setSavedFilters] = useState(() => {
        const localFilters = localStorage.getItem('pageFilters');
        return localFilters ? JSON.parse(localFilters) : {};
    });

    // User preferences (API managed)
    const [userPreferences, setUserPreferences] = useState({
        id: '',
        language: 'en-US',
        theme: 'light',
        favoriteActions: [],
    });

    useEffect(() => {
        const fetchAndSetUserPreferences = async () => {
            try {
                const fetchedPreferences = await fetchUserPreferences(apiKey, organizationId, token, userPreferences);
                if (fetchedPreferences) {
                    setUserPreferences(prev => ({
                        ...prev,
                        ...fetchedPreferences,
                        // Explicitly exclude savedFilters from API response
                        savedFilters: undefined
                    }));
                }
            } catch (error) {
                console.error('Error fetching user preferences:', error);
            }
        };

        fetchAndSetUserPreferences();
    }, [apiKey, organizationId, token]);

    // Local storage updates for page filters
    const updatePageFilters = (pageId, filters) => {
        setSavedFilters(prev => {
            const updated = {
                ...prev,
                [pageId]: filters
            };
            localStorage.setItem('pageFilters', JSON.stringify(updated));
            return updated;
        });
    };

    // Device preferences (local storage)
    const updateDevicePreferences = (field, value) => {
        setDevicePreferences(prev => {
            const updated = {
                ...prev,
                [field]: value
            };
            localStorage.setItem('devicePreferences', JSON.stringify(updated));
            return updated;
        });
    };

    // API-managed user preferences
    const updateFavoriteActions = async (actionId) => {
        const updatedFavorites = userPreferences.favoriteActions.includes(actionId)
            ? userPreferences.favoriteActions.filter(id => id !== actionId)
            : [...userPreferences.favoriteActions, actionId];

        return await updateUserPreference('favoriteActions', updatedFavorites);
    }

    const updateUserPreference = async (field, value) => {
        const updatedPreferences = {
            ...userPreferences,
            [field]: value
        };

        try {
            const response = await updateUserPreferences(updatedPreferences, apiKey, organizationId, token);
            setUserPreferences(updatedPreferences);
            return response?.userPreferences;
        } catch (error) {
            console.error("Error updating user preferences:", error);
            return null;
        }
    };

    return (
        <UserPreferencesContext.Provider value={{
            updateUserPreference,
            updateDevicePreferences,
            updateFavoriteActions,
            updatePageFilters,
            devicePreferences,
            userPreferences,
            savedFilters // Add savedFilters to context value
        }}>
            {children}
        </UserPreferencesContext.Provider>
    );
};

export const usePreferences = () => useContext(UserPreferencesContext);