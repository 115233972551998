// src/pages/inventoryManagement/itemManagement/ItemListPage.js

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Paper, IconButton, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import { useTheme, useMediaQuery } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { usePreferences } from '../../../context/UserPreferencesContext';
import { useTranslation } from 'react-i18next';

// Replace these imports with your new paginated methods:
import { fetchPaginatedItems } from '../../../services/v2/itemService';
import { fetchCategoriesPaginated } from '../../../services/v2/categoryService';

// DataViewWrapper for table/card switching
import DataViewWrapper from '../../../components/DataViewWrapper';
// usePaginatedData is your custom pagination hook
import { usePaginatedData } from '../../../hooks/usePaginatedData';

const ItemListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { apiKey, organizationId, token } = useAuth();
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  // -------------- PAGINATED HOOK FOR ITEMS --------------
  const {
    items: items,
    loading: itemsLoading,
    continuationToken,
    loadNextPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchPaginatedItems(apiKey, organizationId, token, options),
    { pageSize: 25 }
  );

  // We'll store categories in local state (fetched all at once or paginated if needed)
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  // Local view mode (table/card)
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode || (isMobile ? 'card' : 'table')
  );

  // Fetch categories once on mount
  useEffect(() => {
    const fetchCategories = async () => {
      setCategoriesLoading(true);
      try {
        // If you want them all at once, fetch in a loop or call a large pageSize
        // Or if you have a small set of categories, fetch once, no pagination needed
        let nextToken = null;
        let allCats = [];
        do {
          const resp = await fetchCategoriesPaginated(apiKey, organizationId, token, {
            pageSize: 100,
            continuationToken: nextToken,
          });
          allCats = [...allCats, ...(resp.items || [])];
          nextToken = resp.continuationToken;
        } while (nextToken);
        setCategories(allCats);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      } finally {
        setCategoriesLoading(false);
      }
    };
    fetchCategories();
  }, [apiKey, organizationId, token]);

  // Keep local view mode in sync with user prefs
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  // Toggles between table and card
  const toggleViewMode = () => {
    const newMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newMode);
    updateDevicePreferences('viewMode', newMode);
  };

  // Function to map category ID to name
  const getCategoryLabel = (catId) => {
    return categories.find((c) => c.categoryId === catId)?.categoryName || '';
  };

  // Navigate to the detail page
  const handleAddItem = () => {
    navigate('/inventory/item/new');
  };

  const handleRowClick = (itemId) => {
    navigate(`/inventory/item/${itemId}`);
  };

  // Prepare the data structure for DataViewWrapper
  const transformData = useMemo(() => ({
    headers: [
      {
        key: 'name',
        label: t('itemListPage.itemName'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'category',
        label: t('itemListPage.category'),
        display: { value: true, chipColor: false },
      },
      {
        key: 'quantity',
        label: t('itemListPage.quantity'),
        display: { value: true, chipColor: false },
      },
    ],
    rows: items.map((item) => ({
      id: item.id,
      name: { value: item.name },
      category: { value: getCategoryLabel(item.category) },
      quantity: { value: item.quantity },
    })),
  }), [items, categories, t]);

  const isLoading = itemsLoading || categoriesLoading;

  return (
    <Box>
      <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
     
        {isLoading ? (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            {/* Use DataViewWrapper for table/card + filter/sort + row click */}
            <DataViewWrapper
              structure={transformData}
              viewMode={viewMode}
              onViewModeToggle={toggleViewMode}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              onRowClick={handleRowClick}
              pageId="/inventory/items"
              toolbarActions={[
                <Button
                  key="add-item"
                  variant="contained"
                  color="primary"
                  onClick={handleAddItem}
                >
                  {t('itemListPage.addNewItem')}
                </Button>,
              ]}
            />

            {/* Show Load More if there's more items to fetch */}
            {continuationToken && (
              <Box mt={2} textAlign="center">
                <Button variant="contained" onClick={loadNextPage} disabled={isLoading}>
                  {t('common.loadMore')}
                </Button>
              </Box>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
};

export default ItemListPage;
